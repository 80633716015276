export const spacing = {
  none: 0,
  xxxs: 4,
  xxs: 8,
  xs: 16,
  s: 20,
  m: 24,
  l: 28,
  xl: 32,
  xxl: 56,
  xxxl: 80,
} as const;

export type SpacingKeys = keyof typeof spacing;
export type StandardSpacing = (typeof spacing)[SpacingKeys];
