import { COLORS } from "@bwll/bw-styles";

import { IconNames } from "../../atoms/Icon/Icon.types";

export type InfoBannerProps = {
  children?: React.ReactElement;
  testID?: string;
  icon: IconNames;
  iconColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  variant?: "warning" | "error" | "default";
};

interface IVariant {
  backgroundColor: string;
  borderColor: string;
  iconColor: string;
}
interface IVariants {
  warning: IVariant;
  error: IVariant;
  default: IVariant;
}

export const bannerVariants: IVariants = {
  warning: {
    backgroundColor: COLORS.YELLOW["050"],
    borderColor: COLORS.YELLOW["300"],
    iconColor: COLORS.YELLOW["900"],
  },
  error: {
    backgroundColor: COLORS.RED["050"],
    borderColor: COLORS.RED["300"],
    iconColor: COLORS.RED["900"],
  },
  default: {
    backgroundColor: COLORS.NEUTRAL.COOL["050"],
    borderColor: COLORS.NEUTRAL.COOL["300"],
    iconColor: COLORS.NEUTRAL.COOL["900"],
  },
};
