export const servicing = {
  "title": "Credit Builder",
  "headerTitle": "Build Credit",
  "welcome": "Hello {{user}}, welcome!",
  "cards": {
    "alertCards": {
      "notComplete": "Please connect your bank in the next {{days}} days or your application will be cancelled.",
      "bankReviewInProgressTitle": "Sign your PAD agreement",
      "bankReviewInProgress": "You will receive your agreement within **1-2** business days. Please sign it in the next **{{days}} day{{plural}}** or your application will be cancelled.",
      "bankReviewInProgressToday": "You will receive your agreement within **1-2** business days. Please sign it **today** or your application will be cancelled.",
      "cancelledTitle": "Restart your application",
      "cancelled": "Your Credit Builder was cancelled due to an incomplete application. But don’t worry, you can always reapply, it won’t hurt your credit score! Please [contact us]() if you have any questions.",
      "missed": "Your payment for {{missedDate}} failed. Please make your {{amount}} payment by {{dueDate}} to avoid any negative impact on your credit score. Please contact us at [buildcredit@borrowell.com](mailto:buildcredit@borrowell.com) to make your payment.",
      "missedAdhoc": {
        "title": "Payment Failed",
        "body": "Your {{missedDate}} payment failed. Please make up your {{amount}} payment by **{{latestAdhocDate}}** to avoid a negative impact on your credit score.",
        "bodyMultiple": "Your payments for {{missedDate1}} & {{missedDate2}} failed. Please make up your {{amount}} payment by **{{latestAdhocDate}}** to avoid a negative impact on your credit score."
      },
      "scheduledAdhoc": {
        "title": "You're back on track!",
        "body": "Your payment has been scheduled for {{scheduledAdhocDate}}, so make sure the funds will be available on that date. It may take up to 5-8 business days for your payment to appear as paid."
      }
    },
    "bankReviewNativePAD": {
      "title": "Banking review in progress",
      "body": "It is expected to take 1-2 business days for us to review the banking information provided. If your uploaded document is acceptable based on the criteria mentioned, your application will be complete. If there are any issues, our team will reach out and send an email to work with you to finalize your application."
    },
    "restart": {
      "analytics": "Restart Application",
      "callToAction": "RESTART"
    },
    "cancellationCard": {
      "title": "Cancellation Summary",
      "date": "Date",
      "reason": "Reason"
    },
    "creditBuilderSavings": {
      "title": "Cancellation Summary",
      "savingsToBeReturned": "Savings to be Returned",
      "totalSavings": "Total Payment",
      "programFees": "Program Fees",
      "estimatedDepositDate": "Estimated Deposit Date",
      "tooltipCopy": "This is the amount that was saved on a monthly basis and will be returned back into your bank account"
    },
    "programOverview": {
      "title": "Overview",
      "firstPaymentDate": "First Payment Date",
      "nextPaymentDate": "Next Payment Date",
      "paymentAmount": "Payment Amount",
      "yourSavings": "Your Savings",
      "programFee": "Program Fee",
      "creditBuilderSavings": "Credit Builder Savings",
      "viewAgreements": "VIEW AGREEMENTS"
    },
    "creditBuilderKnowledge": {
      "title": "About Credit Builder",
      "markdownContent": "* Every on time payment helps to gradually increase your credit score\n* It's important you don't miss any payments because payment history is the largest factor that influences your credit score\n* Your payments will be reported to Equifax about a month after the payment is made. \n\nLearn more about all of [the factors that impact your credit score]().",
      "bullet1": "Payment history is the most __[important factor that influences your credit score](https://borrowell.com/blog/how-to-rebuild-your-credit-in-canada)__",
      "bullet2": "Every on-time payment helps to gradually build your credit history",
      "bullet3": "A missed payment can cause a drop in your credit score so make sure to have the payment amount available in your bank account"
    },
    "creditBuilderPaymentHistory": {
      "title": "Payment History",
      "month": "Month {{month}}",
      "missed": "Missed",
      "reportedAs": "Reported as ",
      "reportingStatus": "Reporting Status",
      "paymentStatusTooltip": "This indicates whether the payment was processed.\nPaid - payment processed\nProcessing - payment is in progress and can take about 5 business days\nMissed -  payment wasn’t received",
      "estimatedDate": "Estimated Date: ",
      "paymentStatus": "Payment Status",
      "notAvailable": "Not Available",
      "reportingStatusTooltip": "We report to Equifax at the end of the following month after the payment date",
      "daysPastDueDate": " ({{daysPastDueDate}} {{days}} past due)",
      "noPaymentHistory": "No payment history to show"
    },
    "helpCentre": {
      "title": "Have questions about Credit Builder?",
      "linkTitle": "Visit Help Center"
    },
    "crossSell": {
      "learnMore": "LEARN MORE"
    },
    "viewAgreements": {
      "title": "Your Agreements",
      "cbAgreement": "Credit Builder Agreement",
      "padAgreement": "Pre-Authorized Debit Agreement",
      "download": "DOWNLOAD AGREEMENT"
    }
  },
  "statuses": {
    "goodStanding": "Good Standing",
    "applicationNotComplete": "Application Not Complete",
    "bankReviewInProgress": "Bank review in progress",
    "missedPayment": "Missed Payment",
    "cancelled": "Cancelled"
  },
  "buttons": {
    "connectAccount": "CONNECT BANK",
    "restartApplication": "RESTART APPLICATION",
    "makePayment": "MAKE PAYMENT"
  }
}
