export const rentConfirmationPage = {
  "selection": {
    "title": "Sélectionnez votre paiement de loyer",
    "consent": "En sélectionnant cette transaction, vous confirmez qu'il s'agit bien du paiement de votre loyer.",
    "cta": "SÉLECTIONNER LA TRANSACTION",
    "cantFindRentPaymentLink": "Je ne trouve pas le paiement de mon loyer",
    "amountsDontMatch": "Les montants ne correspondent pas",
    "changeSelection": "MODIFIER LA SÉLECTION",
    "selectedTransactions": "Transactions sélectionnées",
    "rentAmountFromLease": "Montant du loyer du bail",
    "popupContactSupport": "Si vous ne voyez pas toutes les transactions, veuillez [__contacter le support__]()",
    "notSeeingRent": "Vous ne voyez pas votre loyer?",
    "viewMore": "Voir plus de transactions",
    "contactSupport": "Si vous ne voyez pas vos transactions de loyer, veuillez [__contacter le support__]()"
  },
  "review": {
    "title": "Révision du paiement du loyer",
    "subtitle": "Une fois que vous aurez confirmé votre loyer, nous le déclarerons comme payé. Celui-ci apparaîtra à votre dossier de crédit dans un délai de 2 à 3 semaines.",
    "consent": "En continuant, vous confirmez que cette transaction correspond au paiement de votre loyer. Borrowell peut valider ces informations à tout moment.",
    "cta": "CONFIRMER LE LOYER",
    "sectionTitle": "Transaction sélectionnée",
    "sectionTitleMobile": "Montant à déclarer",
    "tooltipTitle": "Qu'est-ce que cela signifie?",
    "tooltipContent": "Il s'agit du montant que nous communiquerons à Equifax sur la base des transactions que vous avez sélectionnées.",
    "changeTransactions": "Sélectionner différentes transactions",
    "totalAmount": "Montant total",
    "changeLink": "Changer"
  }
}
