export const creditCards = {
  annualFees: {
    firstYearWaived: "First year waived",
  },
  preQualified: {
    text: "You're pre-qualified",
    tooltipKey: "pre-qualified",
    tooltipCopy:
      "Based on your credit report, you’ve been pre-qualified for this credit card. This means that you can apply for this product with a strong degree of confidence that you’ll be approved.\nTerms and conditions apply.",
  },
  badges: {
    preferredPartner: "Preferred Partner",
    prequalifiedPrefix: "You're ",
    prequalifiedSuffix: "pre-qualified!",
    exclusiveOffer: "Borrowell exclusive offer",
  },
  buttons: {
    details: "DETAILS",
    compare: "COMPARE",
    comparingCard: "Comparing card",
    remove: "REMOVE",
    view: "VIEW PRODUCT",
  },
  details: {
    legalDetails: "LEGAL DETAILS",
    title: "Details",
  },
  metrics: {
    annualFeesTerm: "Annual fees",
    annualInterestTerm: "Annual interest",
    approvalChanceTerm: "Approval Chance",
    balanceTransferTerm: "Balance transfer",
    cashAdvanceTerm: "Cash advance",
    purchaseInterestTerm: "Purchase interest",
    firstYearWaivedDefinition: "First year waived",
    percentageValue: "{{decimal, percentage}}",
  },
  mobile: {
    tabBarLabel: "Cards",
  },
  notApplicable: "N/A",
  pageTotalResults: "{{count}} result",
  pageTotalResults_plural: "{{count}} results",
  prequalifiedPopover: {
    content:
      "Based on your credit report, you’ve been pre-qualified for this credit card. This means that you can apply for this product with a strong degree of confidence that you’ll be approved.\nTerms and conditions apply.",
    title: "You’re pre-qualified!*",
  },
  sortAndFilters: {
    buttons: {
      reset: "RESET",
      showResults: "SHOW {{ count }} RESULT",
      showResults_none: "0 RESULTS",
      showResults_plural: "SHOW {{ count }} RESULTS",
    },
    filters: {
      title: "Filters",
      features: {
        balanceTransfer: "Balance transfer",
        creditBuilding: "Credit building",
        noFee: "No fee",
        title: "FEATURES",
      },
      networks: {
        americanExpress: "American Express",
        mastercard: "Mastercard",
        title: "NETWORKS",
        visa: "Visa",
      },
      rewards: {
        cashBack: "Cash back",
        points: "Points",
        title: "REWARDS",
        travel: "Travel",
      },
      type: {
        business: "Business",
        student: "Student",
        title: "TYPE",
      },
    },
    sort: {
      approvalChance: "Approval chance (high to low)",
      fees: "Fees (low to high)",
      interestRates: "Interest rates (low to high)",
      recommended: "Recommended",
      title: "Sort",
      firstYearValue: "First year value (high to low)",
    },
  },
  tags: {
    "Balance Transfer": "Balance Transfer",
    Business: "Business",
    "Cash Back": "Cash Back",
    Lifestyle: "Lifestyle",
    "Low Interest": "Low Interest",
    "Guaranteed Approval": "Guaranteed Approval",
    "Credit Builder": "Credit Builder",
    "Multi-Currency for Ecommerce Businesses": "Multi-Currency for Ecommerce Businesses",
    "Card with No Pre-set Spending Limit": "Card with No Pre-set Spending Limit",
    Rewards: "Rewards",
    Charge: "Charge",
    Points: "Points",
    Prepaid: "Prepaid",
    Secured: "Secured",
    Student: "Student",
    Travel: "Travel",
    "No fee": "No fee",
  },
  tiles: {
    perksTitle: "Perks",
  },
  title: "Credit Cards",
  tooltips: {
    annualInterest:
      "The interest rate your credit card provider charges you if you carry a balance on your credit card.",
    cashAdvance:
      "The interest rate your credit card provider will charge you if you decide to withdraw cash from your credit card account.",
    balanceTransfer:
      "The interest rate your credit card provider will charge you on the amount of debt you transfer to your credit card; this maybe an introductory rate & fees may also apply.",
    annualFees:
      "The annual fees charged by the credit card provider automatically to your account to allow you to keep the account open.",
  },
  comparison: {
    title: "Comparison",
    accessibility: {
      cardsContainerLabel: "Representations of your compared credit cards",
    },
    table: {
      rewards: "Rewards",
      signUpOffer: "Sign up offer",
      perks: "Perks",
    },
  },
  specialOfferCard: {
    title: "Special offer",
    description: "Check out our exclusive offer",
    otherOffers: "Other offers for you",
    approvalChance: "approval chance",
    welcomeOffer: "Welcome offer",
    type: "Type",
    rewards: "Rewards",
    annualFee: "Annual Fee",
    firstYearWaived: "First year waived!",
  },
  cashbackExperiment: {
    modal: {
      title: "Borrowell bonus disclosure",
      body: "In expressing our gratitude to our esteemed members, the Borrowell bonus stands as a testament to our commitment to giving back. We deeply value the trust our members place in our platform when applying for credit cards and other products, and it is our pleasure to reciprocate by extending a gift card equivalent to the bonus amount.\n\nKindly be advised that eligibility for the bonus necessitates the application for products exclusively through the Borrowell platform. Regrettably, members who are not approved or do not complete the acquisition of the intended product will not qualify for the bonus.\n\nWe kindly request your understanding as we diligently process and dispatch the bonuses, a procedure that may require a few weeks. Your patience is genuinely appreciated as we work to ensure a seamless experience for our valued members.",
    },
  },
  firstYearValue: {
    copy: "first year value",
    tooltipCopy:
      "The First Year Value is the estimated value you can get through your credit card features in the first year. We calculate this amount based on available information. Please visit the partner’s website for the most up to date value estimate.",
    legalCopy:
      "The First Year Value is the estimated value you can get through your credit card features in the first year. This amount is calculated by Borrowell based on available information and includes welcome offers, perks and the Borrowell Bonus (if applicable). Please consult the partner’s website for the most up to date value estimate.",
  },
  categories: {
    travel: {
      title: "Travel Cards",
      description: "Best cards for travel points and perks",
    },
  },
  highlights: "Highlights",
  ratesAndFees: "Rates & Fees",
};
