import { ReactNode } from "react";

import { useZendeskChat } from "@bwll/bw-hooks";

interface IProps {
  children: ReactNode;
}

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const ZendeskChat = ({ children }: IProps) => {
  useZendeskChat();

  return <>{children}</>;
};
