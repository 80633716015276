export const creditScore = {
  poor: "Poor",
  below: "Below Average",
  fair: "Fair",
  good: "Good",
  excellent: "Excellent",
  error: "There was an error calculating your score",
  noScore: "No score",
  delta: "{{value}} POINTS",
  noChange: "NO CHANGE IN SCORE",
  updating: "We are updating your information",
  up: "UP",
  down: "DOWN",
  nextUpdate: "Next score update",
  day: "day",
  days: "days",
  upDelta: "UP {{value}} POINTS",
  downDelta: "DOWN {{value}} POINTS",
};