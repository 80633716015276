import { ONBOARDING_STEP_COMPLETED, V2OnboardingSteps } from "@bwll/bw-types";

// temporary solution to keep sspa and standalone deployments
const SSPA_PATH_PREFIX = "rent-advantage";

export const interceptSingleSpaRoute = <T extends string>(route: T): `/${typeof SSPA_PATH_PREFIX}${T}` | T =>
  window.location.href.includes(SSPA_PATH_PREFIX) ? `/${SSPA_PATH_PREFIX}${route}` : route;

export const hasMemberStartedWithV2Onboarding = (
  onboardingStep: ONBOARDING_STEP_COMPLETED | undefined | null,
): boolean => (onboardingStep ? V2OnboardingSteps.has(onboardingStep) : false);
