export const transactionDetails = {
  title: "Provide transaction details for your proof of payment",
  description: "You can find transaction details on your bank statements.",
  descriptionOnboarding:
    "You can find transaction details on your bank statements. Fields below are required.",

  labels: {
    file: "File",
    leaseTerm: "Lease Term",
    transactionDate: "Transaction date",
    transactionAmount: "Transaction amount",
    transactionDescription: "Transaction description",
    rentDueDate: "Rent due date",
    comments: "Comments (Optional)",
    change: "CHANGE",
  },

  captions: {
    transactionDate: "",
    transactionAmount: "",
    transactionDescription: "Transaction description must be under 100 characters",
    transactionDescriptionOnboarding:
      "You can find transaction description on your bank statements. Try describing this transaction with details like “<b>e-Transfer to landlord</b>” or as this transaction appears in your account.",
    transactionDescriptionOnboardingMD:
      "You can find transaction description on your bank statements. Try describing this transaction with details like “**e-Transfer to landlord**” or as this transaction appears in your account.",
    comments: "Comment must be under 500 characters",
  },

  button: "CONTINUE",
  buttonSubmitProof: "SUBMIT PROOF OF PAYMENT",
  buttonOnboarding: "CONFIRM TRANSACTION DETAILS",

  errors: {
    transactionDate: "Transaction date must be between {{ date }} and today",
    descriptionField: "Please enter a description under {{ maxLength }} characters.",
    commentField: "Please enter a comment under {{ maxLength }} characters.",
    apiError: "Something went wrong while submitting your transaction.",
  },

  modal: {
    amountMismatch: {
      title: "Amounts don’t match",
      enteredLabel: "Transaction amount",
      requiredLabel: "Rent amount from lease",
      description:
        "If the amount you entered is incorrect, please update your transaction amount. Otherwise, provide details in the comments section.",
    },
    dateMismatch: {
      title: "Outdated transaction",
      enteredLabel: "Transaction date",
      requiredLabel: "Rent due date",
      description:
        "If the date you entered is incorrect, please update your transaction date. Otherwise, provide details in the comments section.",
    },
    buttons: {
      edit: "EDIT DETAILS",
      skip: "PROCEED ANYWAY",
    },
  },
};
