export const landingPage = {
  buttonGetStarted: "GET STARTED",
  buttonContinue: "CONTINUE",
  headerTitle: "Rent Advantage",
  hero: {
    title: "Build credit history with Rent Advantage™",
    description: "Report your rent and build credit history for just $5/month",
    descriptionWithAmount: "Report your rent and build credit history for just ${{ amount }}/month",
    info1: "Build credit history with Equifax Canada",
    info2: "Take on no additional debt. Use rent that you're already paying monthly.",
    info3: "Easy setup. No credit checks. No hidden fees. Cancel anytime.",
  },
  creditScoreGrowth: {
    mainTitle:
      "Rent Advantage members who make on-time payments on all accounts saw an average credit score increase of ",
    mainTitle2: " within 7 months.*",
    chipText: "32 points",
    ctaText: "BUILD CREDIT",
    subtitle: "No hard credit check to apply. Cancel anytime.",
    notes:
      "* Based on Rent Advantage members with an Equifax Canada ERS 2.0 credit score between 300-600 with no missed payments on any of their accounts. If members who made on-time payments on their Rent Advantage account but may have missed payments on other accounts are included, the average credit score increase was 20 points by month 8. Individual results may vary. Other credit score factors, including late payments and activity with your other accounts can impact your credit score.",
  },
  howDoesItWork: {
    title: "How does it work?",
    content: [
      {
        title: "Sign up for Borrowell Rent Advantage™",
        description: "Sign up and connect the account you use to make rent payments.",
      },
      {
        title: "Confirm your rent",
        description: "Log in to confirm your rent each month.",
      },
      {
        title: "Build your credit history with Equifax Canada",
        description:
          "Make your rent payments on time. The status of your rent transactions will be reported to Equifax Canada to help build your credit history.",
      },
      {
        title: "Watch your credit grow",
        description:
          "Track your rent payment history in your credit report using the Borrowell app. We recommend you subscribe for at least 6 months.",
      },
    ],
  },
  whatGoodCreditScoreGetMe: {
    title: "Why is good credit history important?",
    content: [
      {
        title: "Better interest rates",
        description:
          "Good credit history can help you get lower rates which will save you money in the long run.",
      },
      {
        title: "Qualify for more financial products",
        description:
          "Get access to better credit cards with higher rewards, and lower rates on loans and mortgages.",
      },
    ],
  },
  FAQ: {
    title: "Frequently asked questions",
    ctaButton: "VIEW ALL FAQS",
    content: [
      {
        title: "Which bureau are you reporting my rent to & how do I see it?",
        description:
          "Your rent payments are reported to Equifax Canada only. At this time, this product isn’t reported to TransUnion. Rent Advantage will appear on your credit report as an open credit under BRWL SELF-RPT RENT. Open credit is a type of credit that requires a full payment each month, a common example of open credit is your cell phone bill.",
      },
      {
        title: "What happens if I miss a rent payment or don't confirm one?",
        description:
          "If you miss a rent payment, it's important to make it up with your landlord right away. If your rent is more than 30 days past due, it will be reported to the credit bureau as missed/late. This will have a negative impact on your credit history.\n" +
          "\n" +
          "You have 14 days from your rent due date (1st of the month in most cases), to log in and confirm you’ve made your rent payment.\n" +
          "\n" +
          "If you forget to log in and confirm you’ve made your rent payment within 14 days, it may be reported as missed. However, if it’s the first time and you did make your payment, you can contact us and our support team will do their best to assist you with an adjustment if you can prove you’ve made your actual rent payment before your rent due date.",
      },
      {
        title: "What if I pay a different amount each month ?",
        description:
          "We understand that it’s possible that your exact monthly rent payment could fluctuate, which is why we do allow for some variance in your confirmed monthly rental payment amount.\n" +
          "\n" +
          "If you split rent, when you sign up for Rent Advantage you’ll be asked to provide your monthly rent amount, please only input the portion that you pay. When you’re asked to confirm your rent payment each month, be sure to select the banking transaction that shows only the portion you pay.",
      },
      {
        title: "How much will this improve my credit score?",
        description:
          "Rent Advantage is a product designed to help you build credit history by reporting your rent payments to Equifax Canada. Making on-time payments has a positive effect on your payment history, which impacts ~35% of your overall credit score. Please note that all payments (including missed payments) are reported to Equifax Canada. To see the full benefits of this product, we recommend to use it for at least 6 months. \n" +
          "\n" +
          "Borrowell doesn’t guarantee credit score improvement, which is dependent on a number of factors such as payment history. Building positive payment history can help improve your credit score.",
      },
      {
        title: "What happens to my credit if I cancel?",
        description:
          "Closing any actively reporting product or service may result in a small decrease to your score if you have a thin credit file with few open accounts. The longer an account is open, the better it is for your credit score. This impact however is usually only short-term. If cancelled, we would no longer be reporting your future rent payments to Equifax Canada. This means if you’re still paying rent, you could be missing out on a great credit building opportunity! \n" +
          "\n" +
          "Your rental payment history reported to Equifax Canada while Rent Advantage was active will remain on your report for up to 7 years from the date of last activity.",
      },
    ],
  },
};
