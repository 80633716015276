import { ReactNativeStyle } from "@emotion/native";

import {
  accentButtonStyles,
  primaryButtonStyles,
  secondaryButtonStyles,
  tertiaryButtonStyles,
} from "./Button.styles";
import { BUTTON_TYPE } from "./Button.types";

export type ThemeState = "dark" | "light";

export interface IButtonTheme {
  // As per product design - https://www.figma.com/file/kvDYbi6ik50av5BwsuPqz4/Components---Product-Design
  state?: ThemeState;
}

export const getButtonComponentStyleByType = ({
  type,
  disabled,
  state = "light",
  isLoading,
}: {
  type: BUTTON_TYPE;
  disabled?: boolean;
  state: ThemeState;
  isLoading?: boolean;
}): ReactNativeStyle => {
  const buttonComponentSchema = {
    [BUTTON_TYPE.PRIMARY]: primaryButtonStyles({ state, disabled, isLoading }),
    [BUTTON_TYPE.SECONDARY]: secondaryButtonStyles({ state, disabled, isLoading }),
    [BUTTON_TYPE.TERTIARY]: tertiaryButtonStyles({ state, disabled, isLoading }),
    [BUTTON_TYPE.ACCENT]: accentButtonStyles({ state, disabled, isLoading }),
  };

  return buttonComponentSchema[type as BUTTON_TYPE];
};
