import styled from "@emotion/native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";

import { RegularText } from "../Default";
import { StyledCaptionProps } from "./Caption.types";

export const StyledCaption = styled(RegularText)(({ color }: StyledCaptionProps) => ({
  fontSize: fontSize.xxs,
  lineHeight: spacing.xs,
  letterSpacing: letterSpacing.l,
  color: color ?? COLORS.NEUTRAL.WARM["700"],
}));
