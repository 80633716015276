import styled from "@emotion/native";

import { COLORS, WEB_MODAL_MAX_WIDTH, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const CenteredView = styled.SafeAreaView<{ isMobileView: boolean }>`
  flex: 1;
  align-items: center;
  padding-top: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.none) : styledValue(spacing.xxl))};
  justify-content: center;
  background-color: ${({ isMobileView }) => (isMobileView ? COLORS.WHITE : "rgba(0, 0, 0, 0.5)")};
`;

export const ModalView = styled.View<{ isMobileView: boolean }>`
  margin: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.none) : styledValue(spacing.s))};
  border-radius: ${styledValue(borderRadius.s)};
  padding: ${styledValue(spacing.xs)};
  background-color: ${COLORS.WHITE};
  max-width: ${styledValue(WEB_MODAL_MAX_WIDTH)};
`;

export const Content = styled.View`
  padding-top: ${styledValue(spacing.s)};
  padding-left: ${styledValue(spacing.xxs)};
  padding-right: ${styledValue(spacing.xxs)};
`;

export const StyledIconButton = styled.View<{ height: number }>`
  align-items: flex-end;
  height: ${(props) => styledValue(props.height)};
`;
