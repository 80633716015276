export const scoreAndReportUpdates = {
  "title": "Mises à jour de la cote et du dossier",
  "subtitle": "Contrôlez la manière dont vous souhaitez être informé des changements apportés à votre cote et à votre dossier de crédit.",
  "options": {
    "weekly": "M'avertir chaque semaine de la mise à jour de ma cote et de mon dossier.",
    "whenScoreChanges": "M'avertir uniquement en cas de modification de ma cote ou de mon dossier."
  },
  "cardNotificationText": {
    "weekly": "Vous serez informé de votre cote et de votre dossier de crédit une fois par semaine.",
    "whenScoreChanges": "Vous ne serez informé que lorsque nous détectons des changements comme une augmentation ou une diminution de votre cote, ou un nouvel élément dans votre dossier de crédit."
  }
}
