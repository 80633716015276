import styled from "@emotion/native";
import { Dimensions } from "react-native";

export const StyledScreen = styled.SafeAreaView(() => ({
  flex: 1,
  flexDirection: "column",
  minWidth: Dimensions.get("window").width,
  minHeight: Dimensions.get("window").height,
}));

export const StyledScreenHeader = styled.View(() => ({
  top: 0,
}));

export const StyledScreenContent = styled.View(() => ({
  flex: 1,
}));

export const StyledScreenFooter = styled.View(() => ({
  marginTop: "auto",
}));
