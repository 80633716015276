import { addDays, differenceInCalendarDays, isValid } from "date-fns";

const GRACE_PERIOD_IN_DAYS = 25;

/**
 *
 * @param {string} rentDueDate format: "YYYY-MM-DDT00:00:00"
 * @returns {number}
 * @example
 *
 *   calculateDaysToConfirmRent("2022-10-01T00:00:00")
 */
export const calculateDaysToConfirmRent = (rentDueDate = "") => {
  const date = new Date(rentDueDate);

  if (date && isValid(date)) {
    const dateWithGracePeriod = addDays(date, GRACE_PERIOD_IN_DAYS);

    return differenceInCalendarDays(dateWithGracePeriod, Date.now());
  }

  return 0;
};
