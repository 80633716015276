import {
  PRODUCT_VERTICAL_BANK_ACCOUNTS,
  PRODUCT_VERTICAL_CAR_LOANS,
  PRODUCT_VERTICAL_CREDIT_CARDS,
  PRODUCT_VERTICAL_INSURANCE,
  PRODUCT_VERTICAL_INVESTING,
  PRODUCT_VERTICAL_LOANS,
  PRODUCT_VERTICAL_MORTGAGES,
} from "@bwll/bw-utils";

export const productVerticals = {
  v3Title: "Explore all your offers",
  [PRODUCT_VERTICAL_BANK_ACCOUNTS]: "Bank Accounts",
  [PRODUCT_VERTICAL_CREDIT_CARDS]: "Credit Cards",
  [PRODUCT_VERTICAL_INSURANCE]: "Insurance",
  [PRODUCT_VERTICAL_INVESTING]: "Investing",
  [PRODUCT_VERTICAL_LOANS]: "Loans",
  [PRODUCT_VERTICAL_MORTGAGES]: "Mortgages",
  [PRODUCT_VERTICAL_CAR_LOANS]: "Car Loans",
  v3Cta: "EXPLORE NOW",
};
