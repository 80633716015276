interface ISetCookieProps {
  name: string;
  value: string;
  expiresDate: Date;
  domain: string;
  path: string;
}

export const setCookie = ({ name, value, expiresDate, domain, path }: ISetCookieProps) => {
  document.cookie = `${name}=${value}; expires=${expiresDate.toUTCString()}; domain=${domain}; path=${path}`;
};
