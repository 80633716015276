// configs
import DEVELOP_CONFIG from "../env/develop.json";
import HOTFIX_CONFIG from "../env/hotfix.json";
import LOCAL_CONFIG from "../env/local.json";
import PROD_CONFIG from "../env/prod.json";
import RELEASE_CONFIG from "../env/release.json";
import TEST_CONFIG from "../env/test.json";

export enum ENV_TYPE {
  LOCAL = "local",
  DEVELOP = "develop",
  TEST = "test",
  RELEASE = "release",
  HOTFIX = "hotfix",
  PROD = "prod",
}

// mapping of configs to env name
const ENV_CONFIG_MAP = {
  [ENV_TYPE.LOCAL]: LOCAL_CONFIG,
  [ENV_TYPE.DEVELOP]: DEVELOP_CONFIG,
  [ENV_TYPE.TEST]: TEST_CONFIG,
  [ENV_TYPE.RELEASE]: RELEASE_CONFIG,
  [ENV_TYPE.PROD]: PROD_CONFIG,
  [ENV_TYPE.HOTFIX]: HOTFIX_CONFIG,
};

export const ENV_CONFIG_DEFAULT = ENV_CONFIG_MAP[ENV_TYPE.PROD];

export const getConfigEnvironment = (environment: ENV_TYPE) => ({
  ...((environment && ENV_CONFIG_MAP[environment]) || ENV_CONFIG_DEFAULT),
});
