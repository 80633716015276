import { BaseArg } from "facepaint";
import LibraryMarkdown from "markdown-to-jsx";
import React from "react";

import { Heading, List, ListElement, Paragraph } from "..";
import { Link } from "../../Link";
import { Superscript } from "../Superscript";

export interface IProps {
  customStyles?: {
    "heading-1"?: BaseArg;
    "heading-2"?: BaseArg;
    "heading-3"?: BaseArg;
    "heading-4"?: BaseArg;
    "heading-5"?: BaseArg;
    link?: BaseArg;
    paragraph?: BaseArg;
    list?: BaseArg;
    listElement?: BaseArg;
  };
  customProps?: {
    link?: {
      href?: string;
      onClick?: () => void;
      "data-cy"?: string;
    };
  };
  withSuperscript?: boolean;
  children: string;
}

interface ILinkRenderer {
  href?: string;
}

const LinkRenderer: React.FC<ILinkRenderer> = ({ href, ...props }) => {
  return <Link to={href ?? "#"} {...props} />;
};

const componentWithSuperscript = <P,>(Component: React.ComponentType<P>): React.FC<P> => {
  const component = (props: P & { children?: React.ReactNode }) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component {...props}>
      <Superscript>{props.children}</Superscript>
    </Component>
  );
  component.displayName = `${Component.displayName ?? Component.name ?? "Component"}WithSuperscript`;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return component;
};

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Markdown: React.FC<IProps> = ({ customStyles, children, withSuperscript, customProps }) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <LibraryMarkdown
      options={{
        forceBlock: true,
        overrides: {
          h1: {
            component: withSuperscript ? componentWithSuperscript(Heading) : Heading,
            props: {
              as: "h1",
              styleOverrides: customStyles?.["heading-1"],
            },
          },
          h2: {
            component: withSuperscript ? componentWithSuperscript(Heading) : Heading,
            props: {
              as: "h2",
              styleOverrides: customStyles?.["heading-2"],
            },
          },
          h3: {
            component: withSuperscript ? componentWithSuperscript(Heading) : Heading,
            props: {
              as: "h3",
              styleOverrides: customStyles?.["heading-3"],
            },
          },
          h4: {
            component: withSuperscript ? componentWithSuperscript(Heading) : Heading,
            props: {
              as: "h4",
              styleOverrides: customStyles?.["heading-4"],
            },
          },
          h5: {
            component: withSuperscript ? componentWithSuperscript(Heading) : Heading,
            props: {
              as: "h5",
              styleOverrides: customStyles?.["heading-5"],
            },
          },
          a: {
            component: withSuperscript ? componentWithSuperscript(LinkRenderer) : LinkRenderer,
            props: {
              styleOverrides: customStyles?.link,
              ...customProps?.link,
            },
          },
          p: {
            component: withSuperscript ? componentWithSuperscript(Paragraph) : Paragraph,
            props: {
              styleOverrides: customStyles?.paragraph,
            },
          },
          ol: {
            component: List,
            props: {
              styleOverrides: customStyles?.list,
              ordered: true,
            },
          },
          ul: {
            component: List,
            props: {
              styleOverrides: customStyles?.list,
              ordered: false,
            },
          },
          li: {
            component: withSuperscript ? componentWithSuperscript(ListElement) : ListElement,
            props: {
              styleOverrides: customStyles?.listElement,
            },
          },
        },
      }}
    >
      {children}
    </LibraryMarkdown>
  );
};
