import styled, { css } from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { ProgressBarProps } from "./ProgressBar.types";

const commonStyles = css`
  height: ${styledValue(spacing.xs)};
  border-radius: 30px;
`;

export const Outer = styled.View`
  ${commonStyles};
  width: 100%;
  position: relative;
  background-color: ${COLORS.NEUTRAL.COOL["100"]};
  overflow: hidden;
`;

export const Inner = styled.View<Omit<ProgressBarProps, "max">>`
  ${commonStyles};
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ value }) => `${value}%`};
  background-color: ${({ color }) => color};
`;
