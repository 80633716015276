export const newLogin = {
  "changeUserAlert": {
    "title": "Êtes-vous sûr?",
    "body": "Vous serez alors déconnecté de votre compte et il vous sera demandé d'entrer votre adresse courriel et votre mot de passe.",
    "buttons": {
      "confirm": "Modifier le compte",
      "cancel": "Annuler"
    }
  },
  "button": {
    "login": "SE CONNECTER",
    "forgotMyPassword": "J'AI OUBLIÉ MON MOT DE PASSE"
  },
  "title": {
    "welcomeBack": "Bienvenue à nouveau chez Borrowell",
    "welcomeBackWithName": "Bienvenue à nouveau, {{name}}"
  },
  "subtitle": {
    "createAccount": "Vous n'êtes pas membre? [__Créer un compte__]()",
    "changeAccount": "Ce n'est pas vous? [__Changer de compte__]()"
  },
  "biometry": {
    "title": "Bienvenue à nouveau, {{firstName}}",
    "useButton": {
      "title": "UTILISER {{biometryType}}"
    }
  },
  "loginForm": {
    "emailInput": "Courriel",
    "passwordInput": "Mot de passe",
    "mfaInput": "Code"
  }
}
