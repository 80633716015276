import React from "react";
import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Button, Icon } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";

import { CardLayout } from "../CardLayout/CardLayout.component";
import { BUTTON_TEST_ID, CARD_TEST_ID, DESCRIPTION_TEST_ID, TITLE_TEST_ID } from "./ViewAllCard.constants";
import * as Styled from "./ViewAllCard.styles";
import type { ViewAllCardProps } from "./ViewAllCard.types";

const noop = () => undefined;

const defaultIconProps = {
  color: COLORS.PRIMARY["500"],
  size: 40,
} as const;

const defaultButtonProps = {
  onPress: noop,
  title: "",
  type: BUTTON_TYPE.PRIMARY,
  testID: BUTTON_TEST_ID,
} as const;

export const ViewAllCard = ({
  title = "",
  description = "",
  iconProps,
  buttonProps,
  testID = CARD_TEST_ID,
}: ViewAllCardProps) => {
  const i18next = useTranslation();

  const buttonTitle = buttonProps.title || i18next.t("productCatalog:buttons:viewAll");

  return (
    <CardLayout testID={testID}>
      <Styled.ContentContainer>
        <Styled.TextContainer>
          <Styled.IconContainer>
            <Icon {...defaultIconProps} {...iconProps} />
          </Styled.IconContainer>
          <Styled.Heading testID={TITLE_TEST_ID}>{title}</Styled.Heading>
          <Styled.Body testID={DESCRIPTION_TEST_ID}>{description}</Styled.Body>
        </Styled.TextContainer>
        <Styled.ButtonContainer>
          <Button {...defaultButtonProps} {...buttonProps} title={buttonTitle} />
        </Styled.ButtonContainer>
      </Styled.ContentContainer>
    </CardLayout>
  );
};
