export const formErrorMessages = {
  "usernameAlreadyExists": "Ce courriel est déjà associé à un compte. Retournez à la page précédente pour essayer de vous connecter.",
  "emailsDontMatch": "Les courriels ne concordent pas. Veuillez réessayer.",
  "invalidName": "Format de nom non valide. Exemple, John Appleseed",
  "invalidEmail": "Format de courriel non valide. Exemple, nom@domain.com",
  "invalidPassword": "Doit comporter entre 8 et 100 caractères et contenir 1 chiffre et 1 lettre.",
  "invalidPostalCode": "Format non valide. Exemple, A1A 1A1",
  "invalidStreetAddress": "Format non valide. Exemple, 1 boucle infinie",
  "phoneNumberFormat": "Format non valide. Le numéro de téléphone doit comporter 10 chiffres.",
  "phone": "$t(errors:formErrorMessages:required:base) téléphone",
  "mustBe18YearsOld": "Vous devez avoir au moins 18 ans pour utiliser Borrowell.",
  "required": {
    "base": "Entrez votre",
    "phone": "$t(errors:formErrorMessages:required:base) numéro de téléphone",
    "verificationCode": "$t(errors:formErrorMessages:length:base)"
  },
  "length": {
    "base": "Entrez un {{fieldName}} de {{length}} chiffre(s)"
  }
}
