import styled, { css } from "@emotion/native";
import { Animated } from "react-native";

import { Body1, Link1 } from "@bwll/bw-components/next";
import { COLORS, WEB_SCREEN_MAX_WIDTH_WITH_PADDING, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { HamburgerMenuProps } from "./UniversalNavigationBar.types";

const navBarHeightStyles = (navBarHeight: number) => css`
  height: ${styledValue(navBarHeight)};
`;

// This component is used to take up the same amount of height as NavBarContainer.
// This is used to prevent content from being hidden behind the NavBar,
// as the NavBarContainer has position: fixed.
export const NavBarHeightSpacer = styled.View<{ navBarHeight: number }>`
  ${({ navBarHeight }) => navBarHeightStyles(navBarHeight)}
`;

// TODO: Centralize z-index values
export const NavBarContainer = styled.View<{ navBarHeight: number }>`
  ${({ navBarHeight }) => navBarHeightStyles(navBarHeight)}
  width: 100%;
  padding-horizontal: ${styledValue(spacing.xxs)};
  padding-vertical: ${styledValue(spacing.xxxs)};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  flex-direction: row;
  align-items: center;
  background-color: ${COLORS.WHITE};
  z-index: 200;
`;

export const Content = styled.View`
  flex: 1;
  max-width: ${styledValue(WEB_SCREEN_MAX_WIDTH_WITH_PADDING)};
  margin: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.Image`
  align-self: flex-start;
`;

export const Links = styled.View`
  flex-direction: row;
  min-width: 50%;
`;

export const Menu = styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

export const TagLink = styled(Body1)`
  font-weight: normal;
  font-size: ${styledValue(fontSize.xs)};
  color: ${COLORS.TURQUOISE["900"]};
  margin-left: ${styledValue(spacing.xxs)};
`;

export const HamburgerMenu = styled(Link1)`
  margin-top: ${styledValue(spacing.xxs)};
`;

export const HamburgerMenuOverlay = styled.Pressable(
  ({ opacity = 0 }: HamburgerMenuProps) => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  opacity: ${opacity};
  pointer-events: ${opacity > 0 ? "auto" : "none"};
`,
);

export const HamburgerMenuContainer = styled(Animated.ScrollView)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: ${styledValue(spacing.m)};
  width: 300px;
  height: 100vh;
  background-color: ${COLORS.WHITE};
  z-index: 10;
`;

export const HamburgerMenuLink = styled.Pressable`
  color: ${COLORS.NEUTRAL.COOL["700"]};
  font-weight: normal;
  font-size: ${styledValue(fontSize.l)};
`;

export const CloseLink = styled(Link1)`
  margin-left: 230px;
`;

export const Subtitle = styled(Body1)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${styledValue(fontSize.xs)};
  color: ${COLORS.NEUTRAL.COOL["600"]};
  margin-vertical: ${styledValue(spacing.xxs)};
`;

export const HamburgerLinkContainer = styled.Pressable(
  ({ hovered }: { hovered: boolean }) => `
  flex-direction: row;
  align-items: center;
  margin-vertical: ${styledValue(spacing.xxs)};
  color: ${hovered ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL["700"]};
`,
);

export const HamburgerLinkLabel = styled(Body1)(
  ({ hovered, color }: { hovered: boolean; color?: string }) => `
  font-size: ${styledValue(fontSize.m)};
  margin-left: ${styledValue(spacing.xxs)};
  color: ${hovered ? COLORS.PRIMARY.DEFAULT : color ?? COLORS.NEUTRAL.COOL["700"]};
`,
);
export const HamburgerMenuPromo = styled.View`
  background-color: #eefafb;
  border-radius: ${styledValue(5)};
  width: 100%;
  padding-horizontal: ${styledValue(spacing.xs)};
  margin-bottom: ${styledValue(spacing.xxs)};
`;
