import styled, { css } from "@emotion/native";

import { COLORS, borderRadius, mq, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  background-color: ${COLORS.RED["050"]};
  padding: ${styledValue(spacing.xs)};
  border-radius: ${styledValue(borderRadius.s)};
  border: 1px solid ${COLORS.RED["300"]};
`;

export const CardTitleContainer = styled.View`
  margin-bottom: ${styledValue(spacing.xs)};
`;

export const CardDescriptionContainer = styled.View`
  margin-top: ${styledValue(spacing.xs)};
  margin-bottom: ${styledValue(spacing.xs)};
  display: flex;
  flex-direction: column;
  gap: ${styledValue(spacing.xxs)};
`;

export const paragraphStyle = css`
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const ButtonContainer = styled.View`
  width: 100%;
  max-width: 360px;
`;
