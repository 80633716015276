export const security = {
  "title": "Connexion et sécurité",
  "prompt": {
    "title": "Êtes-vous sûr?",
    "buttons": {
      "turnOff": "Désactiver",
      "cancel": "Annuler"
    }
  },
  "options": {
    "rememberLastUser": {
      "title": "Se souvenir de mon adresse courriel",
      "subtitle": "Se souvenir de mon adresse courriel lorsque je me connecte à Borrowell.",
      "prompt": "On vous demandera de vous connecter avec votre adresse courriel et votre mot de passe lors de votre prochaine visite sur l'application."
    },
    "biometry": {
      "title": "{{biometryType}}",
      "subtitle": "Utilisez votre {{biometryType}} stocké sur cet appareil pour vous connecter à votre compte Borrowell.",
      "prompt": "On vous demandera de vous connecter avec votre mot de passe lors de votre prochaine visite sur l'application."
    },
    "twoStepVerification": {
      "title": "Vérification en deux étapes",
      "subtitle": "Utilisez la vérification par SMS ou une application d'authentification pour ajouter une sécurité supplémentaire à votre compte."
    }
  },
  "mfa": {
    "smsTitle": "Vérification par SMS",
    "totpTitle": "Application d'authentification",
    "smsStatus": "Activé pour {{phoneNumber}}",
    "totpStatus": "Activé",
    "switchMethod": "Changer de méthode",
    "recoveryTitle": "Code de recouvrement",
    "recoveryDescription": "Utilisez ce code au cas où vous ne pouvez pas recevoir votre code de vérification.",
    "recoveryDescription2": "Générer un nouveau code entraînera l'expiration de votre code de récupération existant et il ne sera affiché qu'une seule fois.",
    "generateNewCode": "Générer un nouveau code"
  }
}
