export const profile = {
  "title": "Modifier votre profil",
  "subtitle": "Gardez vos informations à jour afin que nous puissions vous offrir la meilleure expérience possible.",
  "accountInformation": "Informations sur le profil",
  "email": "Courriel",
  "passwordCopy": "Mot de passe",
  "changePassword": "Modifier votre mot de passe actuel",
  "personalInformation": "Informations personnelles",
  "manageAccount": "Gérer le compte",
  "address": "Adresse résidentielle",
  "phoneNumber": "Numéro de téléphone",
  "fullName": "Nom complet",
  "dob": "Date de naissance",
  "income": "Revenu",
  "incomeRanges": [
    "0 $ à 19 999 $",
    "20 000 $ à 39 999 $",
    "40 000 $ à 59 999 $",
    "60 000 $ à 79 999 $",
    "80 000 $ à 99 999 $",
    "100 000 $ ou plus"
  ],
  "updateEmail": {
    "title": "Modifier votre courriel",
    "subtitle": "Votre courriel actuel est ",
    "newEmail": "Nouveau courriel",
    "confirmEmail": "Confirmer le courriel",
    "disclaimer": "En cliquant sur mettre à jour le courriel, vous acceptez les",
    "and": " et ",
    "termsOfUse": "Conditions d'utilisation",
    "privacyPolicy": "Politique de confidentialité",
    "updateEmail": "MISE À JOUR DU COURRIEL",
    "confirmationTitle": "Votre adresse courriel a été modifiée",
    "confirmationSubtitle": "Vous pouvez maintenant utiliser votre nouvelle adresse courriel pour vous connecter à votre compte Borrowell.",
    "confirmationSubtitle2": "Vous recevrez un courriel de notre part confirmant que votre adresse courriel a été modifiée.",
    "sameError": "Veuillez utiliser une adresse courriel différente de l'adresse actuelle",
    "matchError": "Votre courriel ne correspond pas. Veuillez réessayer",
    "buttons": {
      "done": "FAIT",
      "backToEditProfile": "RETOUR À LA MODIFICATION DU PROFIL"
    },
    "error": {
      "title": "Impossible de modifier votre adresse courriel",
      "message": "Un problème s'est produit ou ce courriel existe déjà. Veuillez réessayer plus tard."
    }
  },
  "updateAddress": {
    "title": "Mettez à jour votre adresse résidentielle",
    "subtitle": "Modifier l'adresse associée à votre compte Borrowell",
    "streetAddress": "Adresse (rue)",
    "streetAddress2": "Suite/unité (facultatif)",
    "city": "Ville",
    "province": "Province",
    "postalCode": "Code postal",
    "updateAddress": "METTRE À JOUR L'ADRESSE",
    "confirmationTitle": "Votre adresse résidentielle a été mise à jour",
    "confirmationSubtitle": "L'adresse associée à votre compte Borrowell a été mise à jour.",
    "buttons": {
      "done": "FAIT",
      "backToEditProfile": "RETOUR À LA MODIFICATION DU PROFIL"
    },
    "error": {
      "title": "Impossibilité de changer d'adresse résidentielle",
      "message": "Un problème s'est produit ou l'adresse n'est pas valide. Veuillez réessayer plus tard."
    }
  },
  "updatePhoneNumber": {
    "title": "Mettre à jour votre numéro de téléphone",
    "subtitle": "Modifier le numéro de téléphone associé à votre compte Borrowell",
    "phoneNumber": "Numéro de téléphone",
    "updatePhoneNumber": "METTRE À JOUR LE NUMÉRO DE TÉLÉPHONE",
    "confirmationTitle": "Votre numéro de téléphone a été mis à jour",
    "confirmationSubtitle": "Le numéro de téléphone associé à votre compte Borrowell a été mis à jour.",
    "buttons": {
      "done": "FAIT",
      "backToEditProfile": "RETOUR À LA MODIFICATION DU PROFIL"
    },
    "error": {
      "title": "Impossible de changer de numéro de téléphone",
      "message": "Un problème s'est produit ou ce numéro de téléphone n'est pas valide. Veuillez réessayer plus tard."
    }
  },
  "updateIncome": {
    "title": "Mettre à jour vos revenus",
    "subtitle": "La mise à jour de ces informations nous aidera à vous fournir des recommandations personnalisées.",
    "updateIncome": "MISE À JOUR DES REVENUS",
    "confirmationTitle": "Votre revenu a été mis à jour",
    "confirmationSubtitle": "Votre revenu a été mis à jour. Vos recommandations seront mises à jour lors de votre prochaine connexion.",
    "backToEditProfile": "RETOUR À LA MODIFICATION DU PROFIL",
    "error": {
      "title": "Impossible de modifier votre revenu",
      "message": "Un problème s'est produit ou ce revenu n'est pas valide. Veuillez réessayer plus tard."
    }
  },
  "updatePassword": {
    "title": "Modifier votre mot de passe",
    "subtitle": "Choisissez un mot de passe sûr et ne le réutilisez pas pour d'autres comptes.",
    "currentPassword": "Mot de passe actuel",
    "newPassword": "Nouveau mot de passe",
    "confirmPassword": "Confirmer le mot de passe",
    "recoverPassword": "MOT DE PASSE OUBLIÉ",
    "updatePassword": "METTRE À JOUR LE MOT DE PASSE",
    "confirmationTitle": "Votre mot de passe a été modifié",
    "confirmationSubtitle": "Votre nouveau mot de passe est prêt à être utilisé. Vous recevrez un courriel de notre part pour confirmer que votre mot de passe a été modifié.",
    "recoverTitle": "Envoi du courriel de récupération du mot de passe",
    "recoverSubtitle": "Un courriel de récupération du mot de passe a été envoyé à l'adresse courriel associée à votre compte Borrowell.",
    "backToEditProfile": "RETOUR À LA MODIFICATION DU PROFIL",
    "matchError": "Vos mots de passe ne correspondent pas. Veuillez réessayer",
    "invalidPassword": "Votre mot de passe est incorrect. Veuillez réessayer",
    "invalidPassword2": "Votre mot de passe est incorrect. Essayez de réinitialiser votre mot de passe"
  },
  "updateDob": {
    "title": "Mettre à jour votre date de naissance",
    "contactSupport": "Vous ne pouvez pas modifier votre date de naissance. Pour modifier ces informations, veuillez **[contact support]()**."
  },
  "updateFullName": {
    "title": "Mettre à jour votre nom complet",
    "contactSupport": "Vous ne pouvez pas modifier votre nom complet. Pour modifier ces informations, veuillez **[contacter l'assistance]()**.",
    "fieldLabel": "Nom complet"
  }
}
