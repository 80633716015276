export const mfa = {
  "protect": {
    "title": "Protégez votre compte grâce à la vérification en 2 étapes",
    "subtitle": "Ajoutez un niveau de sécurité supplémentaire à votre compte en utilisant un code à usage unique en plus de votre mot de passe lorsque vous vous connectez.",
    "buttons": {
      "next": "ACTIVER LA VÉRIFICATION EN 2 ÉTAPES",
      "cancel": "PEUT-ÊTRE PLUS TARD"
    }
  },
  "method": {
    "title": "Sélectionner une méthode de vérification",
    "errorMessage": "Veuillez sélectionner une méthode pour continuer.",
    "options": {
      "smsTitle": "SMS message texte",
      "smsText": "Recevez un SMS contenant un code à usage unique lorsque vous vous connectez.",
      "totpTitle": "Application d'authentification",
      "totpText": "Utilisez une application pour générer un code à usage unique lorsque vous vous connectez."
    },
    "buttons": {
      "next": "CONTINUER"
    }
  },
  "password": {
    "title": "Entrez votre mot de passe pour passer à la méthode de vérification en 2 étapes",
    "disableTitle": "Entrez votre mot de passe pour désactiver la vérification en 2 étapes",
    "button": "CHANGER DE MÉTHODE",
    "disable": "DÉSACTIVER LA VÉRIFICATION EN 2 ÉTAPES",
    "options": {
      "forgotPassword": "J'ai oublié mon mot de passe"
    },
    "errors": {
      "emptyErrorMessage": "Veuillez entrer votre mot de passe",
      "invalidPassword": "Votre mot de passe est incorrect"
    }
  },
  "methodSetup": {
    "sms": {
      "title": "Vérification par SMS",
      "subtitle": "Entrez le numéro de téléphone où nous devons envoyer votre code de vérification.",
      "resendError": "Veuillez attendre {{seconds}} secondes avant de demander un nouveau code."
    },
    "totp": {
      "title": "Application d'authentification",
      "subtitle": "Pour relier votre application d'authentification à votre compte Borrowell, copiez et collez cette clé dans votre application d'authentification.",
      "markdown": "Vous pouvez utiliser n'importe quelle application d'authentification comme [Google Authenticator]() ou [Microsoft Authenticator]()."
    },
    "inputs": {
      "phoneNumber": "Numéro de téléphone"
    },
    "buttons": {
      "next": "CONTINUER"
    },
    "errorMessage": "Veuillez entrer un numéro de téléphone valide."
  },
  "confirmSetup": {
    "sms": {
      "title": "Vérifiez vos messages texte",
      "subtitle": "Entrez le code unique que nous avons envoyé à **{{phoneNumber}}**.",
      "subtitleLogin": "Entrez le code à usage unique que nous vous avons envoyé à votre numéro de téléphone.",
      "description": "Les messages texte peuvent mettre 30 secondes à arriver.",
      "changeNumber": "Numéro de modification"
    },
    "totp": {
      "title": "Vérifiez votre application d'authentification",
      "subtitle": "Entrez le code à six chiffres généré par votre application d'authentification."
    },
    "caption": "Vous n'arrivez pas à obtenir votre code de vérification?",
    "inputs": {
      "verificationCode": "Code de vérification"
    },
    "buttons": {
      "next": "CONTINUER",
      "pasteFromClipboard": "COLLER À PARTIR DU PRESSE-PAPIERS",
      "resend": "RENVOYER LE CODE",
      "recoveryCode": "Utiliser le code de recouvrement",
      "help": "Aide"
    },
    "errorMessage": "Le code que vous avez entré est incorrect ou expiré."
  },
  "confirmation": {
    "title": "Vous avez activé avec succès la vérification en 2 étapes",
    "buttons": {
      "next": "FAIT"
    }
  },
  "recovery": {
    "title": "Entrez votre code de récupération",
    "generateTitle": "Nous avons généré un nouveau code de récupération pour vous",
    "description": "Vous pouvez utiliser votre code de récupération si vous perdez l'accès à votre appareil et que vous ne pouvez pas recevoir votre code par SMS ou par l'intermédiaire de votre application d'authentification. Chaque code ne peut être utilisé qu'une seule fois.",
    "card": {
      "title": "L'utilisation de votre code de récupération désactivera la vérification en 2 étapes.",
      "subtitle": "Nous vous recommandons vivement de l'activer à nouveau une fois que vous êtes connecté."
    },
    "inputs": {
      "recoveryCode": "Code de récupération"
    },
    "caption": "Vous ne trouvez pas votre code de récupération?",
    "buttons": {
      "next": "SOUMETTRE",
      "done": "FAIT",
      "help": "Visitez notre centre d'aide"
    },
    "accordion": {
      "title": "Où dois-je enregistrer mon code de récupération?",
      "description": [
        "Assurez-vous que votre code de récupération soit accessible même si vous perdez l'accès à votre appareil.",
        "Vous pouvez enregistrer votre code de récupération dans un emplacement de stockage nuagique comme Dropbox, iCloud ou Google Drive.",
        "Vous pouvez également utiliser un gestionnaire de mots de passe comme LastPass ou 1Password."
      ]
    },
    "errorMessage": "Le code que vous avez entré est incorrect ou expiré.",
    "emptyErrorMessage": "Veuillez entrer un code de récupération"
  }
}
