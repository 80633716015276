import { useEffect } from "react";

import { usePlatformContext } from "@bwll/bw-hooks";
import { COOKIE_EXPIRE, COOKIE_NAME, PLATFORM } from "@bwll/bw-types";
import { setCookie } from "@bwll/bw-utils";

export const BORROWELL_MEMBER_ROLE = "Borrowell Member";

interface IProps {
  cookiesDomain: string;
}
export const useSetVisitUrlCookie = ({ cookiesDomain }: IProps) => {
  const { platform } = usePlatformContext();

  useEffect(() => {
    if (platform !== PLATFORM.WEB_APP) {
      return;
    }
    // set visit_url in cookie
    // .includes hack to not break sign-up
    if (window.location.href.includes("utm")) {
      const currentDate = new Date();
      const expiresDate = new Date(currentDate.getTime() + COOKIE_EXPIRE.ONE_DAY);

      setCookie({
        name: COOKIE_NAME.VISIT_URL,
        value: window.location.href,
        expiresDate,
        domain: cookiesDomain,
        path: "/",
      });
    }
  }, [platform, cookiesDomain]);
};
