import styled from "@emotion/native";

import { Body1 } from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import { fontSize } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const HighlightsContainer = styled.View<{ centered: boolean }>`
  flex-direction: row;
  align-items: ${({ centered }) => (centered ? "center" : "flex-start")};
  align-self: baseline;
  justify-content: center;
`;

export const IconContainer = styled.View<{ centered: boolean }>`
  margin-top: ${({ centered }) => styledValue(!centered && isWeb ? -1 : 0)};
`;

export const ResponsiveText = styled(Body1)<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => styledValue(isMobile ? fontSize.xs : fontSize.s)};
`;
