export const signupFlow = {
  "preMember": {
    "primaryCopy": "Bienvenue chez Borrowell",
    "secondaryCopy": "Commencez à surveiller votre cote de crédit gratuitement",
    "emailLabel": "Adresse courriel",
    "confirmEmailLabel": "Confirmez votre adresse courriel",
    "passwordLabel": "Mot de passe",
    "passwordCaption": "Doit contenir au moins 8 caractères et 1 chiffre",
    "tertiaryCopy": "\nEn cliquant sur Créer un compte, vous acceptez les\n[__Conditions d’utilisation__](https://borrowell.com/terms) et la [__Politique de confidentialité__](https://borrowell.com/privacy-policy).",
    "primaryButton": "CRÉER UN COMPTE",
    "login": "SE CONNECTER",
    "isEmailEmptyError": "Veuillez entrer une adresse courriel",
    "isEmailInvalidError": "Veuillez entrer une adresse courriel valide",
    "isPasswordEmptyError": "Veuillez entrer un mot de passe",
    "isPasswordInvalidError": "Veuillez entrer un mot de passe qui contient au moins 8 caractères et 1 chiffre",
    "emailExistsError": "Cette adresse courriel est déjà associée à un compte. Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser [ici](navigation://).",
    "emailsNotMatchError": "Les adresses courriel que vous avez entrées ne concordent pas",
    "matchEmailError": "Les courriels que vous avez entrés ne concordent pas. Veuillez les vérifier à nouveau et réessayer.",
    "accountCreationError": "Une erreur s'est produite lors de la création du compte. Veuillez revérifier et réessayer."
  },
  "name": {
    "primaryCopy": "Quel est votre nom?",
    "secondaryCopy": "Nous en avons besoin pour trouver votre profil de crédit Equifax.",
    "firstNameLabel": "Prénom ",
    "lastNameLabel": "Nom de famille",
    "primaryButton": "CONTINUER",
    "isFirstNameEmptyError": "Veuillez entrer votre prénom",
    "isLastNameEmptyError": "Veuillez entrer votre nom de famille"
  },
  "birthDate": {
    "primaryCopy": "Quelle est votre date de naissance?",
    "secondaryCopy": "Nous en avons besoin pour trouver une correspondance avec votre profil de crédit Equifax.",
    "primaryButton": "CONTINUER",
    "isBirthDateEmptyError": "Veuillez entrer votre date de naissance",
    "isBirthDateValidError": "Veuillez entrer une date de naissance valide"
  },
  "address": {
    "primaryCopy": "Quelle est votre adresse?",
    "primaryCopyConfirm": "Confirmez votre adresse",
    "secondaryCopy": "Nous en avons besoin pour trouver une correspondance avec votre profil de crédit Equifax.",
    "addressLabel": "Adresse",
    "unitLabel": "Numéro de l'unité / de l'appartement (facultatif)",
    "cityLabel": "Ville",
    "provinceLabel": "Province",
    "postalCodeLabel": "Code postal",
    "primaryButton": "CONTINUER",
    "isAddressEmptyError": "Veuillez entrer votre adresse",
    "isCityEmptyError": "Veuillez entrer votre ville",
    "isProvinceEmptyError": "Veuillez entrer votre province",
    "isPostalCodeEmptyError": "Veuillez entrer votre code postal",
    "isPostalCodeValidError": "Veuillez entrer un code postal valide",
    "enterAddressManually": "SAISIR L'ADRESSE MANUELLEMENT"
  },
  "phoneNumber": {
    "primaryCopy": "Quel est votre numéro de téléphone?",
    "secondaryCopy": "Nous en avons besoin pour trouver une correspondance avec votre profil de crédit Equifax.",
    "primaryButton": "CONTINUER",
    "phoneNumberLabel": "Numéro de téléphone",
    "isPhoneNumberEmptyError": "Veuillez entrer votre numéro de téléphone",
    "isPhoneNumberInvalidError": "Veuillez entrer un numéro de téléphone valide"
  },
  "financialGoals": {
    "primaryCopy": "Quels sont vos objectifs financiers cette année?",
    "secondaryCopy": "Nous utilisons ces informations pour personnaliser votre expérience. Sélectionnez toutes les réponses qui s'appliquent.",
    "primaryButton": "CONTINUER",
    "goals": [
      "Surveiller et améliorer ma cote de crédit",
      "Rembourser mes cartes de crédit ou d'autres dettes",
      "Acheter une propriété ou renouveler un prêt hypothécaire",
      "Investir mon argent"
    ],
    "errorMessage": "Veuillez sélectionner un objectif financier pour continuer."
  },
  "income": {
    "primaryCopy": "Quel est votre revenu?",
    "secondaryCopy": "Nous utilisons ceci pour personnaliser votre expérience.",
    "ranges": [
      "0 $ à 19 999 $",
      "20 000 $ à 39 999 $",
      "40 000 $ à 59 999 $",
      "60 000 $ à 79 999 $",
      "80 000 $ à 99 999 $",
      "100 000 $ ou plus"
    ],
    "errorMessage": "Veuillez sélectionner votre revenu pour continuer."
  },
  "equifax": {
    "primaryCopy": "Vous avez presque terminé!",
    "secondaryCopy": "Avant de pouvoir obtenir votre cote de crédit gratuite, vous devez consentir ci-dessous à autoriser l'accès à votre dossier de crédit et répondre à des questions pour vérifier votre identité.",
    "consent": "Je consens à ce que Borrowell obtienne ma cote de crédit et mon dossier de crédit auprès d'agences d'évaluation du crédit tant que j'ai un compte. Borrowell obtiendra et actualisera périodiquement ma cote et mon dossier de crédit afin de me présenter des informations financières sur mesure, des recommandations et des offres de tiers.",
    "terms": "J'ai lu et j'accepte les [__Conditions d'utilisation__](https://borrowell.com/terms) et\n[__Politique de confidentialité__](https://borrowell.com/privacy-policy).",
    "poweredBy": "Propulsé par",
    "primaryButton": "PROCÉDER À LA VÉRIFICATION",
    "errorMessage": "Vous devez donner votre consentement ci-dessous avant de pouvoir accéder à votre cote et dossier de crédit.",
    "alertTitle": "Erreur",
    "alertMessage": {
      "generic": "Une erreur s'est produite lors de la création de votre profil. Veuillez réessayer dans quelques minutes.",
      "duplicateAccount": "Il semble que vous ayez déjà un compte. Veuillez vous connecter en utilisant les bonnes informations d'identification. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter le support."
    }
  },
  "equifaxConsent": {
    "primaryCopy": "Commençons!",
    "secondaryCopy": "Pour trouver votre dossier de crédit Equifax, nous devons vous demander quelques informations.\n\nUne fois inscrit, vous aurez accès gratuitement à votre cote et à votre dossier de crédit, ainsi qu'à des recommandations de produits uniques basées sur votre profil de crédit.",
    "contentTitle": "Voici ce que vous devrez fournir :",
    "bulletPoints": [
      {
        "title": "Vos informations personnelles",
        "description": "Votre nom complet - pas de surnoms."
      },
      {
        "title": "Votre adresse résidentielle",
        "description": "Votre adresse actuelle."
      },
      {
        "title": "Vos objectifs financiers ",
        "description": "Personnalisez vos recommandations pour vous aider à atteindre vos objectifs."
      },
      {
        "description": "Plus de trois millions de Canadiens font confiance à Borrowell. Pour assurer la sécurité de leurs informations. Nous utilisons un système de cryptage 256 bits de qualité bancaire et nous ne partageons pas vos données sans votre consentement."
      }
    ],
    "terms": "Je comprends que je donne instruction à Borrowell de demander et de recevoir périodiquement des copies de rapports de consommation, de cotes et d'informations connexes me concernant de la part d'Equifax. Je comprends que ces informations seront obtenues dans le but de fournir des services à Borrowell et que ce consentement sera effectif aussi longtemps que j'aurai un compte.",
    "primaryButton": "DÉMARRER",
    "errorMessage": "Vous devez donner votre consentement ci-dessous avant de pouvoir accéder à votre cote et à votre dossier de crédit.",
    "alertTitle": "Erreur"
  },
  "nonEngaged": {
    "cta": "DÉCONNEXION"
  }
}
