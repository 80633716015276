import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { ANALYTICS_ATTRIBUTES, ImpressionedProductVerticalCreditCard, TrackEventFunc } from "@bwll/bw-types";
import { mapCreditCardToProductEventProps, noop } from "@bwll/bw-utils";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

const pageEventProps = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: "product_details_page",
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: 1,
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "vertical",
} as const;

const undefinedCreditCardEvents = {
  trackProductViewed: noop,
  trackProductClicked: noop,
  trackProductExpanded: noop,
};

/**
 * Gets an object with event functions for the Credit Card Product Details page.
 * @param trackEvent The {@link TrackEventFunc} to send events to.
 * @param creditCard The credit card loaded into the Product Details page.
 * @returns An object with event functions. The functions will do nothing if the provided credit card is undefined.
 */
export const useCreditCardProductDetailsAnalytics = (
  trackEvent: TrackEventFunc,
  creditCard?: ImpressionedProductVerticalCreditCard,
) => {
  const { productViewed, productClicked, productExpanded } = useMarketplaceAnalyticsEvents(trackEvent);

  return useMemo(() => {
    if (!creditCard) return undefinedCreditCardEvents;

    const productEventProps = mapCreditCardToProductEventProps(creditCard);

    return {
      trackProductViewed: () =>
        productViewed({
          ...productEventProps,
          ...pageEventProps,
        }),
      trackProductClicked: () =>
        productClicked({
          ...productEventProps,
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuidv4(),
        }),
      trackProductExpanded: (areLegalDetailsExpanded: boolean) => {
        productExpanded({
          [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: productEventProps.product_id,
          [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: productEventProps.impression_id,
          [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: productEventProps.tracking_tag,
          [ANALYTICS_ATTRIBUTES.PLACEMENT]: pageEventProps.placement,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: pageEventProps.display_order,
          [ANALYTICS_ATTRIBUTES.CTA_TEXT]: productEventProps.call_to_action,
          [ANALYTICS_ATTRIBUTES.FEATURES_SHOWN]: true,
          [ANALYTICS_ATTRIBUTES.LEGAL_DETAILS_EXPANDED]: areLegalDetailsExpanded,
        });
      },
    };
  }, [creditCard, productClicked, productExpanded, productViewed]);
};
