import styled from "@emotion/styled";

import { IThemeDefinition, borderWidth, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { COLORS, mq } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BANNER_TYPE } from "./banner";

const BANNER_COLORS = (theme: IThemeDefinition) => ({
  ALERT: {
    color: theme.COLORS.YELLOW[900],
    border: theme.COLORS.YELLOW[700],
    background: theme.COLORS.YELLOW["050"],
  },
  INFO: {
    color: theme.COLORS.NEUTRAL.WARM[800],
    border: theme.COLORS.PRIMARY[100],
    // HEX 33 = opacity 20%
    background: `${theme.COLORS.PRIMARY["050"]}33`,
  },
  ERROR: {
    color: theme.COLORS.RED[700],
    border: theme.COLORS.RED[200],
    // HEX 99 = opacity 60%
    background: `${theme.COLORS.RED["050"]}99`,
  },
});

export const Container = styled.div<{ type: BANNER_TYPE }>(({ theme, type }) => {
  const { border, background } = BANNER_COLORS(theme)[type as BANNER_TYPE];

  return mq({
    padding: 12,
    display: "flex",
    background: background,
    border: `${borderWidth.small}px solid ${border}`,
    borderRadius: 4,
  });
});

export const LeftIcon = styled.div<{ type: BANNER_TYPE }>(({ theme, type }) =>
  mq({
    color: BANNER_COLORS(theme)[type as BANNER_TYPE].color,
  }),
);

const baseContent = (theme: IThemeDefinition) => ({
  fontFamily: "Lato",
  fontWeight: 400,
  fontSize: fontSize.xs,
  lineHeight: `${lineHeight.m}px`,
  letterSpacing: "0.24px",
  color: theme.COLORS.NEUTRAL.WARM[800],
});

export const MessageContent = styled.div(({ theme }) =>
  mq({
    ...baseContent(theme),
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
  }),
);

export const NotificationContent = styled.div(({ theme }) =>
  mq({
    marginLeft: 12,
    display: "flex",
    flexDirection: "column",
    fontFamily: "Lato",
    color: theme.COLORS.NEUTRAL.WARM[800],
    button: {
      textTransform: "uppercase",
    },
  }),
);

export const NotificationTitle = styled.h4(() =>
  mq({
    margin: 0,
    fontWeight: 700,
    fontSize: fontSize.m,
    lineHeight: `${lineHeight.xl}px`,
    letterSpacing: "0.16px",
  }),
);

export const notificationMessageStyles = (theme: IThemeDefinition) => ({
  paragraph: {
    ...baseContent(theme),
    margin: `${spacing.xxxs}px ${spacing.none}px ${spacing.xxs}px`,
    a: {
      fontSize: fontSize.xs,
      lineHeight: styledValue(lineHeight.m),
      letterSpacing: "0.32px",
      color: COLORS.PRIMARY.DEFAULT,
      textDecoration: "underline",
      textDecorationColor: COLORS.PRIMARY.DEFAULT,
      ":hover": {
        color: COLORS.PRIMARY[300],
        textDecorationColor: COLORS.PRIMARY[300],
      },
    },
  },
});
