export const provinces = {
  ab: {
    abbr: "AB",
    name: "Alberta",
  },
  bc: {
    abbr: "BC",
    name: "British Columbia",
  },
  mb: {
    abbr: "MB",
    name: "Manitoba",
  },
  nb: {
    abbr: "NB",
    name: "New Brunswick",
  },
  nl: {
    abbr: "NL",
    name: "Newfoundland and Labrador",
  },
  nt: {
    abbr: "NT",
    name: "Northwest Territories",
  },
  ns: {
    abbr: "NS",
    name: "Nova Scotia",
  },
  nu: {
    abbr: "NU",
    name: "Nunavut",
  },
  on: {
    abbr: "ON",
    name: "Ontario",
  },
  pe: {
    abbr: "PE",
    name: "Prince Edward Island",
  },
  qc: {
    abbr: "QC",
    name: "Quebec",
  },
  sk: {
    abbr: "SK",
    name: "Saskatchewan",
  },
  yt: {
    abbr: "YT",
    name: "Yukon",
  },
};
