import styled from "@emotion/styled";

import { COLORS, borderRadius, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Icon as _Icon } from "../../atoms/Icon";
import { DEFAULT_WIDTH, WEB_TOOLTIP_CLASSNAME } from "./Tooltip.constants";

type LayoutConfig = { $width?: number };

const tooltipBackgroundColor = COLORS.WHITE;
const transparentGrayShade = "rgba(0, 0, 0, 0.1)";
const tooltipBorderStyle = `0.5px solid ${transparentGrayShade}`;
const handleTooltipWidth = ({ $width }: LayoutConfig) => styledValue($width ?? DEFAULT_WIDTH);

export const triggerElementStyles: React.CSSProperties = {
  cursor: "pointer",
};

export const Container = styled.div<LayoutConfig>`
  display: inline-block;
  position: relative;
  cursor: pointer;
  z-index: 99;

  & .${WEB_TOOLTIP_CLASSNAME} {
    max-width: ${handleTooltipWidth};
    min-width: ${handleTooltipWidth};
    border-radius: ${borderRadius.xs}px;
    box-shadow: 5px 5px 10px ${transparentGrayShade}, -5px -5px 10px ${transparentGrayShade};
    padding: ${spacing.xs}px;
    color: ${COLORS.NEUTRAL.COOL["600"]};
    background: ${tooltipBackgroundColor};
    font-size: ${styledValue(fontSize.xs)};
    border: ${tooltipBorderStyle};
  }
`;

export const Header = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: `${spacing.xxs}px`,
});

export const Icon = styled(_Icon)({
  color: COLORS.NEUTRAL.COOL["600"],
  cursor: "pointer",
});

export const TooltipContent = styled.div`
  line-height: 1.4;
`;
