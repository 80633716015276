import styled from "@emotion/styled";

import { Icon as _Icon } from "@bwll/bw-components/next";
import { borderWidth, fontSize, lineHeight, mq, spacing } from "@bwll/bw-styles";

export const Header = styled.div(() =>
  mq({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: `${spacing.xxs}px`,
  }),
);

export const Title = styled.h2(({ theme }) =>
  mq({
    ...theme.h3,
  }),
);

export const Icon = styled(_Icon)(({ theme }) =>
  mq({
    color: theme.COLORS.NEUTRAL.COOL["600"],
    cursor: "pointer",
  }),
);

export const ButtonContainer = styled.div(
  mq({
    width: "100%",
  }),
);

export const ContentContainer = styled.div(
  mq({
    padding: spacing.l,
    "h1, h2, h3, h4, h5, h6": {
      textAlign: "left",
    },
  }),
);

export const Footer = styled.div(
  mq({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: spacing.s,
  }),
);

export const SecondaryButton = styled.button(({ theme }) =>
  mq({
    marginTop: spacing.m,
    border: borderWidth.none,
    background: "transparent",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: fontSize.xs,
    lineHeight: `${lineHeight.xs}px`,
    letterSpacing: "1.12px",
    textTransform: "uppercase",
    cursor: "pointer",
    color: theme.COLORS.PRIMARY.DEFAULT,
    "&:hover": {
      color: theme.COLORS.PRIMARY["300"],
    },
  }),
);
