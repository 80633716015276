import React, { ReactNode, createContext, useState } from "react";

import { PLATFORM } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

export interface PlatformContextConfig {
  platform: PLATFORM;
  setPlatform: (value: PLATFORM) => void;
}
export const PlatformContext = createContext<PlatformContextConfig>({} as PlatformContextConfig);

interface IProps {
  children: ReactNode;
  platform: PLATFORM;
}

export const PlatformContextProvider: React.FunctionComponent<IProps> = ({ children, platform }) => {
  const [currPlatform, setCurrPlatform] = useState<PLATFORM>(platform);

  return (
    <PlatformContext.Provider
      value={{
        platform: currPlatform,
        setPlatform: setCurrPlatform,
      }}
    >
      {children}
    </PlatformContext.Provider>
  );
};

export const usePlatformContext = () => useContextWrapper(PlatformContext, ContextName.PlatformContext);
