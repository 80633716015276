// import { isBefore, isAfter, isValid, format, parse } from "date-fns";
import { DateTime } from "luxon";

interface IBaseValidator {
  errorMessage: string;
}

interface ILengthValidator extends IBaseValidator {
  value: number;
}

interface IRegexValidator extends IBaseValidator {
  regex: RegExp;
}

interface IEnumValidator extends IBaseValidator {
  enum: string[];
}

interface IDateValidator extends IBaseValidator {
  date: Date;
}

export interface ICustomValidator extends IBaseValidator {
  validate: (value: string) => boolean;
}

interface IValidators {
  minLength?: ILengthValidator;
  maxLength?: ILengthValidator;
  regexValidator?: IRegexValidator;
  enumValidator?: IEnumValidator;
  dateIsBefore?: IDateValidator;
  dateIsAfter?: IDateValidator;
  isValidDate?: IBaseValidator;
  customValidator?: ICustomValidator | ICustomValidator[];
}

type ValidatorResponse = { valid: boolean; errorMessage?: string };

function validateCustomValidator(value: string, config: ICustomValidator): ValidatorResponse {
  if (config.validate(value)) return { valid: true };
  return { valid: false, errorMessage: config.errorMessage };
}

export class Guard {
  minLenth?: ILengthValidator;

  maxLength?: ILengthValidator;

  regex?: IRegexValidator;

  enum?: IEnumValidator;

  dateIsBefore?: IDateValidator;

  dateIsAfter?: IDateValidator;

  isValidDate?: IBaseValidator;

  customValidator?: ICustomValidator | ICustomValidator[];

  constructor({
    minLength,
    maxLength,
    regexValidator,
    enumValidator,
    dateIsAfter,
    dateIsBefore,
    isValidDate,
    customValidator,
  }: IValidators) {
    this.minLenth = minLength;
    this.maxLength = maxLength;
    this.regex = regexValidator;
    this.enum = enumValidator;
    this.dateIsBefore = dateIsBefore;
    this.dateIsAfter = dateIsAfter;
    this.isValidDate = isValidDate;
    this.customValidator = customValidator;
  }

  validateMinLength(value: string): ValidatorResponse {
    if (!this.minLenth) throw new Error("Min length validator not configured on guard");
    const { value: minLength, errorMessage } = this.minLenth;

    if (value?.trim().length >= minLength) {
      return { valid: true };
    }
    return { valid: false, errorMessage };
  }

  validateMaxLength(value: string): ValidatorResponse {
    if (!this.maxLength) throw new Error("Max length validator not configure on guard");
    const { value: maxLength, errorMessage } = this.maxLength;
    if (value.length <= maxLength) return { valid: true };
    return { valid: false, errorMessage };
  }

  validateRegex(value: string): ValidatorResponse {
    if (!this.regex) throw new Error("Regex validator not configure on guard");
    const { regex, errorMessage } = this.regex;
    if (regex.test(value)) return { valid: true };
    return { valid: false, errorMessage };
  }

  validateEnum(value: string): ValidatorResponse {
    if (!this.enum) throw new Error("Enum validator not configured on guard");
    const { enum: enumValidator, errorMessage } = this.enum;
    if (enumValidator.includes(value)) return { valid: true };
    return { valid: false, errorMessage };
  }

  validateIsValidDate(value: string | Date): ValidatorResponse {
    const errorMessage = this.isValidDate?.errorMessage ?? "Invalid date";

    if (typeof value === "string") {
      const [month, year] = value.toString().split("/");
      const date = DateTime.local(parseInt(year), parseInt(month));

      if (date.isValid) return { valid: true };
      return { valid: false, errorMessage };
    }

    const date = DateTime.fromJSDate(value);

    if (date.isValid) return { valid: true };
    return { valid: false, errorMessage };
  }

  validateIsDateBefore(value: string): ValidatorResponse {
    if (!this.dateIsBefore) throw new Error("dateIsBefore validator not defined on guard");
    const { date, errorMessage } = this.dateIsBefore;

    if (typeof value === "string") {
      const [month, year] = value.toString().split("/");
      if (!month || !year) return { valid: false, errorMessage };

      const valueDate = DateTime.local(parseInt(year), parseInt(month));

      if (valueDate.isValid && valueDate < DateTime.fromJSDate(date)) return { valid: true };
      return { valid: false, errorMessage };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.validateIsValidDate(value).valid && value < date) return { valid: true };
    return { valid: false, errorMessage };
  }

  validateIsDateAfter(value: string): ValidatorResponse {
    if (!this.dateIsAfter) throw new Error("dateIsBefore validator not defined on guard");
    const { date, errorMessage } = this.dateIsAfter;

    if (typeof value === "string") {
      const [month, year] = value.toString().split("/");
      if (!month || !year) return { valid: false, errorMessage };

      const valueDate = DateTime.local(parseInt(year), parseInt(month));

      if (valueDate.isValid && valueDate > DateTime.fromJSDate(date)) return { valid: true };
      return { valid: false, errorMessage };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.validateIsValidDate(value).valid && value > date) return { valid: true };
    return { valid: false, errorMessage };
  }

  validateCustomValidators(value: string): ValidatorResponse {
    if (!this.customValidator) throw new Error("No custom validators defined on guard");
    if (Array.isArray(this.customValidator)) {
      return this.customValidator.reduce(
        (acc, curr) => {
          if (!acc.valid) return acc;
          return validateCustomValidator(value, curr);
        },
        { valid: true } as ValidatorResponse,
      );
    }
    return validateCustomValidator(value, this.customValidator);
  }

  validate(value: string): ValidatorResponse {
    let response: ValidatorResponse = { valid: true };
    if (this.minLenth) {
      response = this.validateMinLength(value);
      if (!response.valid) return response;
    }

    if (this.maxLength) {
      response = this.validateMaxLength(value);
      if (!response.valid) return response;
    }

    if (this.regex) {
      response = this.validateRegex(value);
      if (!response.valid) return response;
    }

    if (this.enum) {
      response = this.validateEnum(value);
      if (!response.valid) return response;
    }

    if (this.isValidDate) {
      response = this.validateIsValidDate(value);
      if (!response.valid) return response;
    }

    if (this.dateIsBefore) {
      response = this.validateIsDateBefore(value);
      if (!response.valid) return response;
    }

    if (this.dateIsAfter) {
      response = this.validateIsDateAfter(value);
      if (!response.valid) return response;
    }

    if (this.customValidator) {
      response = this.validateCustomValidators(value);
      if (!response.valid) return response;
    }

    return response;
  }
}
