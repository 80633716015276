export const subscriptionStatus = {
  canceled: {
    title: "Subscription cancelled",
    message:
      "Your subscription has been cancelled. Please reach out to us if you want to resubscribe to Rent Advantage.",
    status: "Subscription cancelled",
    tooltip:
      "Your subscription has been cancelled. Please reach out to us if you want to resubscribe to Rent Advantage.",
    buttonText: "CONTACT US",
  },
  inactive: {
    title: "Subscription inactive",
    message: "Please reach out to us if you want to resubscribe to Rent Advantage.",
    status: "Subscription inactive",
    tooltip: "Please reach out to us if you want to resubscribe to Rent Advantage.",
    buttonText: "CONTACT US",
  },
  pastDue: {
    title: "Subscription payment due",
    message: "To continue reporting your rent, please pay your Rent Advantage subscription. ",
    status: "Subscription payment due",
    tooltip: "To continue reporting your rent, please pay your Rent Advantage subscription. ",
    buttonText: "PAY SUBSCRIPTION",
  },
};
