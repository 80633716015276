export const selectRentPayment = {
  "title": "Sélectionnez votre paiement de loyer",
  "description": "Sélectionnez jusqu'à {{ maxTransactions }} transactions pour le loyer de ce mois-ci. Si vous payez votre loyer en plus de {{ maxTransactions }} transactions, veuillez [nous contacter]().",
  "manualOnboardingBanner": "Can’t find your rent transaction? Simply [upload a proof of your rent payment]()",
  "notSeeingRentChangeAccount": "Vous ne voyez pas votre loyer? [Changer de compte bancaire]()",
  "viewMore": "Vous ne voyez pas votre loyer? [Voir plus de transactions]()",
  "changeAccount": "Utilisé un autre compte? [Modifier le compte]()",
  "transaction": "{{count}} transaction",
  "transaction_plural": "{{count}} transactions",
  "selectTransaction": "SÉLECTIONNER UNE TRANSACTION",
  "loadingTitle": "Chargement des transactions...",
  "loadingDescription": "Ne fermez pas cette page, sinon vous perdrez vos progrès et devrez recommencer.",
  "footNote": "En sélectionnant cette transaction, vous confirmez qu'il s'agit bien du paiement de votre loyer.",
  "errorTitle": "Nous n'avons trouvé aucune transaction",
  "errorMessage": "Veuillez contacter le service d'assistance pour vous aider à résoudre ce problème.",
  "contactSupport": "CONTACTER L'ASSISTANCE",
  "noTransactionErrorMessage": "Veuillez réessayer plus tard ou téléverser une preuve de votre paiement de loyer.",
  "uploadProof": "TÉLÉVERSER",
  "notSeeingRentContactSupport": "Si vous ne voyez pas vos transactions de loyer, veuillez [nous contacter]()",
  "rentNotFound": {
    "title": "Que faire si vous ne trouvez pas votre paiement de loyer",
    "description": "Si vous avez payé votre loyer récemment, veuillez noter qu'il peut s'écouler jusqu'à 4 jours avant qu'il n'apparaisse dans vos transactions."
  },
  "rentNotFoundOptions": {
    "changeAccount": {
      "title": "**Changer de compte bancaire**",
      "titleNoMD": "Changer de compte bancaire",
      "description": "Changez de compte bancaire si vous avez payé votre loyer à partir d'un autre compte."
    },
    "manualUpload": {
      "title": "**Téléverser manuel**",
      "titleNoMD": "Téléverser manuellement",
      "description": "Téléversez la preuve de paiement de votre loyer, afin que nous puissions mettre à jour manuellement le paiement de votre loyer."
    },
    "contactSupport": {
      "title": "**Contacter le support**",
      "titleNoMD": "Contacter le support",
      "description": "Si votre paiement de loyer n'apparaît pas sur Borrowell, contactez le support."
    },
    "confirmLater": {
      "title": "**Confirmer votre loyer plus tard**",
      "titleNoMD": "Confirmer votre loyer plus tard",
      "description": "Reporter la confirmation de votre paiement de loyer."
    }
  },
  "cantFindRent": "[Je ne trouve pas le paiement de mon loyer]()",
  "cantFindRentNoMD": "Je ne trouve pas le paiement de mon loyer"
}
