export const bankingAccountSelectionPage = {
  title: "Select the account that you use to pay rent",
  button: "SELECT ACCOUNT",
  change: "Change",
  bankAccounts: {
    title: "Bank accounts",
  },
  creditCards: {
    title: "Credit cards",
  },
  any: {
    title: "Other",
  },
  loadingTitle: "Loading accounts...",
  loadingDescription:
    "Please don’t close this page otherwise you’ll lose your progress and will have to start again.",
  noAccountsTitle: "We're sorry, it looks like something went wrong",
  noAccountsMessage: "Please try again later or upload a proof of your rent payment.",
  noAccountsButton: "UPLOAD PROOF OF PAYMENT",
};
