import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { CreditReport, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const fetchLatestCreditReport = async (accessToken: string, baseUrl: string) => {
  const response = await axios.get<CreditReport>(`${baseUrl}/api/credit-reports/v1/reports/latest`, {
    headers: generateApiHeaders(accessToken),
  });

  return response.data;
};

export const useLatestCreditReport = () => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_LATEST_CREDIT_REPORT),
    () => fetchLatestCreditReport(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        enabled: !!accessToken,
      }),
    },
  );
};
