import { css } from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const thumbStyle = css`
  width: ${styledValue(spacing.m)};
  height: ${styledValue(spacing.m)};
  border-radius: ${styledValue(Math.floor(spacing.m / 2))};
`;

export const trackStyle = css`
  height: ${styledValue(2)};
`;
