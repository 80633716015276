import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ANALYTICS_ATTRIBUTES,
  ImpressionedProductVerticalCreditCard,
  TabView,
  TrackEventFunc,
} from "@bwll/bw-types";
import { mapCreditCardToProductEventProps } from "@bwll/bw-utils";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

const pageEventProps = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: "product_catalog",
} as const;

export const useCreditCardProductListAnalytics = (trackEvent: TrackEventFunc) => {
  const { productViewed, productClicked } = useMarketplaceAnalyticsEvents(trackEvent);

  const trackProductViewed = useCallback(
    (
      card: ImpressionedProductVerticalCreditCard,
      index: number,
      currentSortOption: string,
      tabViewed: TabView,
    ) => {
      productViewed({
        ...mapCreditCardToProductEventProps(card),
        ...pageEventProps,
        [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
        [ANALYTICS_ATTRIBUTES.SORT_BY_VALUE]: currentSortOption,
        [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "vertical",
        [ANALYTICS_ATTRIBUTES.TAB_VIEWED]: tabViewed,
      });
    },
    [productViewed],
  );

  const trackProductClicked = useCallback(
    (
      card: ImpressionedProductVerticalCreditCard,
      index: number,
      currentSortOption: string,
      tabViewed: TabView,
    ) => {
      productClicked({
        ...mapCreditCardToProductEventProps(card),
        ...pageEventProps,
        [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuidv4(),
        [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
        [ANALYTICS_ATTRIBUTES.SORT_BY_VALUE]: currentSortOption,
        [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "vertical",
        [ANALYTICS_ATTRIBUTES.TAB_VIEWED]: tabViewed,
      });
    },
    [productClicked],
  );

  return { trackProductViewed, trackProductClicked };
};
