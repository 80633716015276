export const tooltips = {
  "dashboardTitle": "Obtenez des recommandations de produits personnalisées en fonction de votre profil.",
  "sectionTrades": "Vous y trouverez tous les comptes ouverts ou fermés qui figurent dans votre dossier de crédit, comme les cartes de crédit, les prêts à tempérament, les hypothèques et les comptes de téléphonie mobile.",
  "sectionInquiries": "Les enquêtes de crédit sont des demandes de vérification de votre crédit. On parle d'enquête approfondie lorsqu'un prêteur potentiel examine votre dossier de crédit parce que vous avez fait une demande de crédit auprès de celui-ci, ce qui peut avoir une incidence sur votre cote de crédit. On parle d'enquêtes sans impact lorsque vous vérifiez votre propre dossier de crédit et/ou votre cote (par exemple en vérifiant votre cote avec Borrowell), lorsque des entreprises vérifient votre solvabilité pour vous proposer une soumission, ou lorsque des entreprises vous demandent des renseignements alors que vous avez déjà un compte. Les enquêtes sans impact n'affectent pas votre cote de crédit.",
  "sectionCollections": "Tous les comptes qui ont été envoyés en recouvrement, que le(s) solde(s) ait(aient) été payé(s) ou non. Attention : l'envoi d'un compte  en recouvrement peut avoir un impact important sur votre cote de crédit.",
  "sectionLegal": "Tout document public figurant à votre dossier de crédit. Il peut s'agir d'éléments comme un jugement du tribunal.",
  "sectionBankruptcies": "Si vous avez déjà déposé une demande de mise en faillite ou une proposition de consommateur, elle apparaîtra ici.",
  "reportTitle": "Votre dossier de crédit contient des informations importantes sur vos antécédents financiers. Les prêteurs, comme les banques et autres créanciers, examinent les dossiers de crédit (ainsi que d'autres informations) pour déterminer le montant du crédit qu'ils sont en mesure de vous accorder.\n\nVotre dossier de crédit comprend des informations sur vos comptes de crédit actifs, vos enquêtes de crédit (lorsqu'une institution financière, comme une banque, vérifie votre crédit), ainsi que les paiements manqués ou les litiges juridiques que vous pouvez avoir. Il ne contient pas d'informations sur votre compte bancaire, vos factures d'électricité ou votre casier judiciaire.",
  "reportCategoryRevolving": "Avec un crédit renouvelable, vous disposez généralement d'une limite de crédit fixe et vous pouvez emprunter jusqu'à cette limite. Le montant du crédit disponible diminue et augmente au fur et à mesure que les fonds sont empruntés puis remboursés. Les cartes de crédit et les marges de crédit en sont des exemples.",
  "reportCategoryInstallment": "Les prêts à tempérament prévoient des paiements échelonnés sur une période déterminée. Il s'agit par exemple de prêts automobiles, personnels ou étudiants.",
  "reportCategoryOpen": "Les comptes ouverts comprennent les comptes de téléphonie mobile et les cartes de paiement.",
  "reportCategoryMortgage": "Un prêt hypothécaire est un prêt utilisé pour acheter une maison ou un autre bien immobilier.",
  "reportCategoryForeignInquiries": "Enquêtes de crédit à l’étranger. Cela ne comprend que les enquêtes approfondies, c'est-à-dire lorsque vous avez fait une demande de crédit.",
  "reportCategoryLocalInquiries": "Demandes de crédit au Canada. Cela ne comprend que les enquêtes approfondies, c'est-à-dire lorsque vous avez fait une demande de crédit.",
  "cashbackExperiment": {
    "title": "Receive a gift card on us!",
    "body": "Upon being approved for this card, Borrowell will send you a reward of this cash back value.",
    "link": "Learn more"
  }
}
