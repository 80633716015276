import React, { Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";

import { ZendeskChat } from "@bwll/bw-components";

import { ContextProviders } from "../Context";

import PublicRouter from "@app/PublicRouter";
import { PUBLIC_ROUTES } from "@app/PublicRouter/constants";
import { GlobalLoading } from "@app/components";

const LoadTenant = lazy(() => import("../components/LoadTenant"));
const Router = lazy(() => import("../Router"));

const publicRoutes: string[] = Object.values(PUBLIC_ROUTES);

export const NavigationResolver = () => {
  const location = useLocation();
  const isPublic = publicRoutes.includes(location.pathname);

  if (isPublic) {
    return <PublicRouter />;
  }

  return (
    <ContextProviders>
      <Suspense fallback={<GlobalLoading isLoading />}>
        <ZendeskChat>
          <LoadTenant>
            <Router />
          </LoadTenant>
        </ZendeskChat>
      </Suspense>
    </ContextProviders>
  );
};
