import styled from "@emotion/styled";

import { mq } from "@bwll/bw-styles";

import { Divider } from "../../atoms/Divider";
import { RadioInputOption } from "../../atoms/Inputs/RadioInput";
import { ErrorHint, RadioInputGroup } from "../../atoms/Inputs/RadioInputGroup";

export type MultiSectionRadioInputOption = {
  name?: string;
  options: RadioInputOption[];
};

export interface MultiSectionRadioGroupProps {
  multiSectionRadioInputOptions: MultiSectionRadioInputOption[];
  selectedOption?: RadioInputOption;
  onChange: (option: RadioInputOption) => void;
  groupName: string;
  id: string;
  errorHint?: string;
  hasError?: boolean;
  isRequired?: boolean;
  caption?: string;
  withBorder?: boolean;
  labelIsScreenReaderonly?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
`;

const Section = styled.div`
  width: 100%;
`;

const SectionHeader = styled.h2(({ theme }) =>
  mq({
    ...theme.h3,
    textAlign: "left",
  }),
);

const DividerWrapper = styled.div`
  padding: 4px 0 20px 0;
`;

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const MultiSectionRadioGroup = ({
  multiSectionRadioInputOptions,
  selectedOption,
  onChange,
  groupName,
  id,
  errorHint,
  hasError,
  isRequired,
  caption,
  withBorder,
  labelIsScreenReaderonly,
}: MultiSectionRadioGroupProps) => {
  return (
    <Wrapper>
      {multiSectionRadioInputOptions.map((section, i) => {
        return section.options.length > 0 ? (
          <Section key={i}>
            {!labelIsScreenReaderonly && <SectionHeader>{section.name}</SectionHeader>}
            <RadioInputGroup
              id={`${id}-${i}`}
              options={section.options}
              selectedOption={selectedOption}
              groupName={groupName}
              onChange={onChange}
              isRequired={isRequired}
              caption={caption}
              label={section.name ?? ""}
              labelIsScreenReaderonly
              withBorder={withBorder}
            />
            {withBorder === true ? (
              i !== multiSectionRadioInputOptions.length - 1 ? (
                <DividerWrapper>
                  <Divider />
                </DividerWrapper>
              ) : null
            ) : null}
          </Section>
        ) : null;
      })}
      {!!hasError && !!errorHint && (
        <ErrorHint id={`${id}-error`} aria-live="assertive">
          {errorHint}
        </ErrorHint>
      )}
    </Wrapper>
  );
};
