export const reportOptions = {
  reportBankruptcies: {
    title: "Bankruptcies",
    subtitle: "If you've ever filed for bankruptcy or a consumer proposal it would appear here.",
  },
  reportLegal: {
    title: "Legal Items",
    subtitle: "Any public records on your credit report. This may include items such as a court judgment.",
  },
  reportTrades: {
    title: "Trades and Accounts",
    subtitle:
      "Here you'll find any open or closed accounts that appear on your credit report such as credit cards, instalment loans, mortgages and mobile phone accounts.",
    deferred: "Deferred",
    helpCenterTitle: "Have questions about your credit report?",
    helpCenterLink: "Visit Help Center",
  },
  reportInquiries: {
    title: "Inquiries",
    subtitle:
      "Credit inquiries are requests to check your credit. Hard inquiries occur when a potential lender is reviewing your credit because you've applied for credit with them, and may affect your credit score.",
  },
  reportCollections: {
    title: "Collections",
    subtitle:
      "Any accounts that have been sent to collections, whether the balance(s) have been paid or unpaid. Be careful – when an account gets sent to collections it can have a big impact on your credit score.",
  },
};
