import styled from "@emotion/styled";
import React from "react";

import { ImageProps } from "./Image.types";

export type { ImageProps };

const calculateWidthAndHeight = (
  width: number,
  height: number,
  responsive?: boolean,
): React.CSSProperties => {
  return responsive
    ? { width: "100%", height: "auto" }
    : {
        width: width,
        height: height,
      };
};

const ImagePlaceholder = styled.div<ImageProps>(({ width, height, responsive, theme, style = {} }) => ({
  borderRadius: "50%",
  background: theme.COLORS.NEUTRAL.COOL["500"],
  ...calculateWidthAndHeight(width, height, responsive),
  ...style,
}));

const _Image = styled.img<ImageProps>(({ width, height, responsive }) => ({
  objectFit: "contain",
  ...calculateWidthAndHeight(width, height, responsive),
}));

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Image = ({ width, height, src, alt, style, responsive }: ImageProps) => {
  return !src ? (
    <ImagePlaceholder width={width} height={height} style={style} responsive={responsive} />
  ) : (
    <_Image src={src} alt={alt ?? ""} width={width} height={height} style={style} responsive={responsive} />
  );
};
