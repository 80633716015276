import styled from "@emotion/native";

import { Image } from "../../atoms/Image";

export const CardImage = styled(Image)`
  width: 200px;
  height: 125px;
  align-self: center;
  resize-mode: contain;
`;
