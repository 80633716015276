export const servicingDashboard = {
  "title": "Le Bienfait du Loyer",
  "badges": {
    "paid": {
      "icon": "success",
      "text": "Déclaré comme payé"
    },
    "missed": {
      "icon": "close",
      "text": "Signalé comme manqué"
    },
    "willBePaid": {
      "icon": "success",
      "text": "Sera déclarée comme payé"
    },
    "willBeMissed": {
      "icon": "close",
      "text": "Sera déclarée comme manqué"
    }
  },
  "reportingDetails": {
    "title": "Détails du rapport",
    "receiptTitle": "Montant déclaré",
    "amount": "Montant",
    "dateReported": "Date du rapport",
    "leaseStart": "Début du bail :",
    "appearsAs": "Apparaît comme : \"{{ creditorName }}\" au dossier de crédit",
    "reportsTo": "Rapports à Equifax Canada"
  },
  "paymentProfile": {
    "paid": "Payé",
    "missed": "Manqué",
    "noInfo": "Aucune information",
    "deferred": "Paiement différé"
  },
  "aboutPaymentHistory": {
    "title": "Historique des paiements",
    "info1": "L'historique des paiements est le facteur le plus important qui influence votre cote de crédit",
    "info2": "Chaque paiement effectué à temps contribue à établir vos antécédents en matière de crédit",
    "info3": "Un paiement manqué peut entraîner une baisse de votre cote de crédit; veillez donc à effectuer vos paiements à temps"
  },
  "somethingDoesntLookRight": {
    "title": "Quelque chose ne va pas?",
    "description": "Si vous voyez quelque chose qui vous semble incorrect, veuillez consulter la FAQ pour plus d'informations.",
    "ctaButtonCopy": "VISITER LA FAQ"
  },
  "contactSupportCta": "CONTACTER L'ASSISTANCE",
  "badge": {
    "inReview": "En révision",
    "rejected": "Preuve rejetée"
  },
  "card": {
    "inReview": {
      "title": "La preuve est révision...",
      "description": "La preuve de paiement que vous avez fournie pour la période de location {{ date }} est en révision."
    },
    "rejected": {
      "title": "La preuve a été rejetée",
      "description": "La preuve de paiement que vous avez fournie pour le paiement du loyer dû le {{ date }} n'a pas été approuvée.",
      "supportMessage": "Si vous pensez qu'il y a eu un problème, veuillez contacter l'assistance ou soumettre une nouvelle preuve de paiement du loyer.",
      "reSubmitButtonText": "SOUMETTRE PREUVE"
    }
  },
  "reportedTransactionsList": {
    "title": "Montant déclaré"
  }
}
