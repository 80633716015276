import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FullScreenContainer = styled.View<{ backgroundColor: string }>`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const SpinnerContainer = styled.View`
  margin-vertical: ${styledValue(spacing.xxxl)};
`;
