import { PressableStateCallbackType, StyleProp, ViewStyle } from "react-native";
import type { PressableProps } from "react-native";

import { TFeatureAnalyticsAttributes, TRAOnboardingAnalyticsAttributes } from "@bwll/bw-hooks";

import { IconNames } from "../Icon/Icon.types";

export enum BUTTON_TYPE {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
  ACCENT = "ACCENT",
}

export type ButtonProps = {
  testID?: string;
  accessibilityLabel?: string;
  type: BUTTON_TYPE;
  title: string;
  onPress: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  iconName?: IconNames;
  iconAlignment?: "left" | "right";
  // As per product design - https://www.figma.com/file/kvDYbi6ik50av5BwsuPqz4/Components---Product-Design
  state?: "dark" | "light";
  analyticsAttributes?: TFeatureAnalyticsAttributes;
  /**
   * This controls wether we should send analytics events with the Shared Analytics Provider.
   */
  enableAnalyticsEvent?: boolean;
  style?: StyleProp<ViewStyle>;
};

// DEVNOTE - `dataSet` doesn't exist in the `react-native` interface (only in `react-native-web`)
// and therefore we need to define our own here
// See https://bitbucket.org/bwell/%7B24404a30-bc53-42ac-8ba1-74e98f1b8ea8%7D/pull-requests/2014#comment-477585526 for context
export interface ButtonPressableProps extends PressableProps {
  dataSet?: HTMLElement["dataset"];
}

export type CombinedStylesCallback = (state: PressableStateCallbackType) => StyleProp<ViewStyle>;
