import styled from "@emotion/styled";
import React from "react";

import { Section } from "@bwll/bw-components";
import { Heading } from "@bwll/bw-components";
import { mq } from "@bwll/bw-styles";

interface IHeroSection {
  title?: string;
  children: React.ReactNode;
}

const ContentWrapper = styled.div<{ hasMarginTop: boolean }>(({ hasMarginTop }) =>
  mq({
    width: "100%",
    display: "flex",
    flexDirection: ["column", "column", "row"],
    marginTop: hasMarginTop ? ["24px", "24px", "40px"] : "0px",
    "& > *": {
      marginRight: "24px",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const MultiblockSection = ({ children, title }: IHeroSection) => {
  return (
    <Section>
      {!!title && <Heading as="h2">{title}</Heading>}
      <ContentWrapper hasMarginTop={!!title}>{children}</ContentWrapper>
    </Section>
  );
};
