export const scoreAndReportUpdates = {
  title: "Score and report updates",
  subtitle: "Control how you want to be notified about changes to your credit score and report.",
  options: {
    weekly: "Notify me weekly when my score and report are updated.",
    whenScoreChanges: "Only notify me when there’s a change to my score or report.",
  },
  cardNotificationText: {
    weekly: "You’ll be notified about your credit score and report once per week.",
    whenScoreChanges:
      "You’ll only be notified whenever we detect changes such as an increase or decrease in your score, or a new item on your credit report.",
  },
};
