import styled from "@emotion/styled";

import { COLORS, fontSize, lineHeight, mq, spacing } from "@bwll/bw-styles";

export const ListItem = styled.li(
  mq({
    color: COLORS.NEUTRAL.WARM["700"],
    minHeight: spacing.xl,
    fontSize: [fontSize.s, fontSize.l, fontSize.l],
    lineHeight: [`${lineHeight.xl}px`, `${lineHeight.xxl}px`, `${lineHeight.xxl}px`],
    letterSpacing: "0.16px",
  }),
);

export const NumberedList = styled.ol(
  mq({
    paddingLeft: spacing.m,
    marginTop: spacing.xl,
    marginBottom: spacing.none,
  }),
);
