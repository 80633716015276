import { Loading } from "@bwll/bw-components";
import { useLoadingContext } from "@bwll/bw-hooks";

import { Background, Container, LoadingContainer } from "./styles";

interface IGlobalLoadingProps {
  isLoading?: boolean;
  opacity?: number;
}

export const GlobalLoading = ({ isLoading, opacity }: IGlobalLoadingProps) => {
  const {
    loading: { isLoading: isContextLoading, icon, title, description },
  } = useLoadingContext();

  return (
    <Container isLoading={isLoading || isContextLoading}>
      <Background opacity={opacity} />

      <LoadingContainer>
        <Loading icon={icon} title={title} description={description} />
      </LoadingContainer>
    </Container>
  );
};
