export const transactionSelectionMethodPage = {
  title: "Choose your method of rent transaction selection",
  bankConnection: {
    description:
      "<b>Securely connecting your bank account</b> will automatically import your transactions, which means less work for you. You'll just need to check in each month to verify the transaction.",
    descriptionMD:
      "**Securely connecting your bank account** will automatically import your transactions, which means less work for you. You'll just need to check in each month to verify the transaction.",
    link: "CONNECT MY BANK ACCOUNT",
  },
  manualUpload: {
    description:
      "<b>Uploading and filling in details</b> will require you to input your transaction details manually and upload proof of each transaction. It requires a little more effort, but it's a good option if you're having trouble connecting your bank account.",
    descriptionMD:
      "**Uploading and filling in details** will require you to input your transaction details manually and upload proof of each transaction. It requires a little more effort, but it's a good option if you're having trouble connecting your bank account.",
    link: "ADD MANUALLY",
  },
};
