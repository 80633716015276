import { buttons } from "./buttons";
import { creditScoreScreen } from "./creditScoreScreen";
import { screenName } from "./screenName";
import { text } from "./text";

// available under "accessibility:{key}"
export const accessibility = {
  screenName,
  buttons,
  text,
  creditScoreScreen,
};
