export const recommended = {
  "browseByCategory": "Browse By Category",
  "highApprovalChance": "High approval chance",
  "recommendedOffers": "Recommended offers",
  "categories": {
    "personalLoans": "Personal loans",
    "creditCards": "Credit cards",
    "autoLoans": "Auto loans",
    "mortgages": "Mortgages",
    "bankAccounts": "Bank accounts",
    "build": "Build",
    "insurance": "Insurance"
  }
}
