import styled from "@emotion/native";
import { Platform } from "react-native";

import { COLORS, borderWidth, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Body2 } from "../../atoms/Typography";

export const Wrapper = styled.View`
  overflow: hidden;
  align-self: flex-start;
`;

export const BorderedView = styled.View<{ hasUnderline?: boolean }>`
  border-width: 1px;
  border-style: ${Platform.OS === "android" ? "solid" : "dashed"};
  border-color: ${({ hasUnderline }) => (hasUnderline ? COLORS.NEUTRAL.COOL["600"] : "transparent")};
  margin: -2px;
  margin-bottom: 0;
`;

export const StyledRow = styled.View<{ hasSeparator: boolean; height?: string; hasPadding?: boolean }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: ${({ height }) => height ?? styledValue(48)};

  border-color: ${COLORS.NEUTRAL.WARM["100"]};
  border-bottom-width: ${({ hasSeparator }) =>
    styledValue(hasSeparator ? borderWidth.small : borderWidth.none)};

  padding: ${({ hasPadding }) =>
    hasPadding ? `${styledValue(spacing.xxxs)} ${styledValue(spacing.xxs)}` : `${styledValue(spacing.none)}`};
`;

export const StyledDescriptionTerm = styled(Body2)<{ hasUnderline?: boolean }>`
  line-height: ${styledValue(fontSize.xs)};
`;
