import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { mq } from "@bwll/bw-styles";

import { Icon } from "../..";
import {
  StepLabelProps,
  StepLabelWrapperProps,
  StepProps,
  StepperProps,
  StepperWrapperProps,
} from "./Stepper.types";

export type { StepperProps, StepProps };

const StepperWrapper = styled.ol<StepperWrapperProps>`
  display: flex;
  gap: ${({ gap }) => gap}px;
  list-style: none;
  margin: ${({ theme }) => theme.stepper.margin}px;
  padding: ${({ theme }) => theme.stepper.padding}px;
  width: 100%;
`;

const StepListItem = styled.li`
  width: 100%;
`;

const StepLabelWrapper = ({ name, icon, color, fontWeight }: StepLabelWrapperProps) => {
  return (
    <StepLabel color={color} fontWeight={fontWeight}>
      {icon && <Icon icon={icon} size={14} />}
      {name && <span>{name}</span>}
    </StepLabel>
  );
};

const StepLabel = styled.div<StepLabelProps>(({ color, theme, fontWeight }) =>
  mq({
    display: ["none", "flex", "flex"],
    alignItems: "center",
    color: color || theme.stepper.label.color,
    fontWeight: fontWeight || theme.stepper.label.defaultStepFontWeight,
    fontSize: "12px",
  }),
);

const Step = styled.div<StepProps>(({ theme, completed, color }) =>
  mq({
    borderRadius: `${theme.stepper.step.borderRadius}px`,
    marginBottom: `${theme.stepper.step.marginBottom}px`,
    width: "100%",
    height: `${theme.stepper.step.height}px`,
    backgroundColor: completed && color ? color : theme.stepper.step.incompleteBackgroundColor,
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Stepper = ({ steps, showLabels, color, gap }: StepperProps) => {
  const steperTheme = useTheme().stepper;
  const defaultBackgroundColor = color || steperTheme.step.color;
  const defaultGap = gap || steperTheme.gap;

  return (
    <StepperWrapper gap={defaultGap} role="list" aria-label="progress">
      {steps.map((step, index) => (
        <StepListItem key={index} aria-current={step.current ? "step" : "false"}>
          <Step completed={step.completed} current={step.current} color={defaultBackgroundColor} />
          {showLabels && step.label?.show !== false && (
            <>
              <StepLabelWrapper
                name={step.label?.name}
                fontWeight={
                  step.current
                    ? step.label?.fontWeight || steperTheme.label.currentStepFontWeight
                    : steperTheme.label.defaultStepFontWeight
                }
                color={step.current ? step.color || color : undefined}
                icon={step.current ? "dot" : step.completed ? "success" : ""}
              />
            </>
          )}
        </StepListItem>
      ))}
    </StepperWrapper>
  );
};
