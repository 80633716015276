const noHit = {
  title: "We’re having trouble finding your credit score...",
  description: "We couldn’t match the information you provided with a profile in Equifax Canada’s records.",
  whatYouCanDo: "What you can do:",
  contactEquifaxCanada: "1. Contact Equifax Canada",
  contactEquifaxCanadaDescription:
    "Contact Equifax Canada at __[1-866-828-5961](https://borrowell.com/blog/how-to-rebuild-your-credit-in-canada)__ to confirm the information that you entered through Borrowell matches the information that Equifax Canada currently has on file for you.",
  informationCard: {
    title: "Information that you entered:",
    fullName: "Full Name",
    DOB: "Date of Birth",
    phoneNumber: "Phone Number",
    residentialAddress: "Residential Address",
  },
  contactBorrowell: "2. Contact Borrowell",
  contactBorrowellDescription:
    "Once all information is correct and matches your Equifax Canada file, please contact us at __[info@borrowell.com](mailto:info@borrowell.com)__ to have your identity verification questions reset.",
};

export const preMemberDashboard = {
  title: "Welcome to Borrowell",
  whatIsYourCreditScoreCard: {
    title: "Do you know your credit score?",
    titleContinue: "Get your credit score",
    description: "Get your free credit score and report from Equifax Canada",
    descriptionContinue: "Continue where you left off to get your free credit score from Equifax Canada.",
    buttonCopy: "GET MY FREE REPORT",
    buttonCopyContinue: "CONTINUE SIGN UP",
    footnote: "Your free report won't hurt your score, and signing up takes less than 3 minutes.",
  },
  blogCards: {
    title: "Learn about credit",
    whatIsCreditScore: {
      title: "What is a credit score and why does it matter",
      subtitle:
        "A credit score is a three-digit number that financial institutions use to assess how likely you are to pay back debt.",
      linkText: "READ MORE",
    },
    introductionToLoans: {
      title: "Introduction to loans and lines of credit",
      subtitle:
        "Personal loans are a fixed amount of money that you borrow and pay back with interest in monthly instalments.",
      linkText: "READ MORE",
    },
  },
  marketplaceCards: {
    title: "Explore all your offers",
    loans: {
      title: "Loans",
      linkText: "EXPLORE",
    },
    creditCards: {
      title: "Credit Cards",
      linkText: "EXPLORE",
    },
    autoLoans: {
      title: "Car Loans",
      linkText: "EXPLORE",
    },
    mortgages: {
      title: "Mortgages",
      linkText: "EXPLORE",
    },
    bankAccounts: {
      title: "Bank Accounts",
      linkText: "EXPLORE",
    },
    insurance: {
      title: "Insurance",
      linkText: "EXPLORE",
    },
  },
  noHit,
  guest: "Guest",
};
