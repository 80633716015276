import styled from "@emotion/native";
import { Platform, TextStyle } from "react-native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Cell } from "../../atoms/Cell";
import { Label } from "../../atoms/Typography";
import { IconTagProps } from "./IconTag.types";

type IconTagContainerProps = Pick<IconTagProps, "backgroundColor" | "borderColor">;
export const IconTagContainer = styled(Cell)<IconTagContainerProps>`
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  background: ${({ backgroundColor }) => backgroundColor};
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : undefined)};
`;

type IconTagLabelProps = {
  textDecoration: Extract<TextStyle["textDecorationLine"], "underline" | "none">;
};

export const IconTagLabel = styled(Label)<IconTagLabelProps>`
  margin-left: ${styledValue(spacing.xxxs)};
  text-decoration-line: ${({ textDecoration }) => textDecoration};
  text-decoration-style: ${Platform.OS === "android" ? "solid" : "dotted"};
`;
