import styled from "@emotion/styled";

import { Label, Paragraph } from "@bwll/bw-components/next";
import { borderWidth, fontSize, mq, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { captionStyles, headlineStyles } from "./styles";

interface ITransactionCard {
  transactionName?: string;
  transactionDate: string;
  amount: string;
  id?: string;
}

const ContentWrapper = styled.div<{ isCompact?: boolean }>(({ theme, isCompact }) =>
  mq({
    display: "flex",
    justifyContent: "space-between",

    "div:last-of-type": {
      display: "flex",
      height: "fit-content",
      alignItems: "center",
    },

    "& > div > *:first-of-type": {
      fontWeight: 700,
    },

    "& > div > p, & > div > span": {
      ...theme.paragraph,
      margin: isCompact ? spacing.none : `${styledValue(spacing.xxxs)} ${styledValue(spacing.none)}`,

      "&:not(:first-of-type)": {
        fontSize: fontSize.xxs,
      },
    },

    "& > div > input": {
      width: 18,
      height: 18,
    },
  }),
);

export const TransactionInfo = ({ transactionName, transactionDate, amount }: ITransactionCard) => {
  return (
    <ContentWrapper>
      <div>
        <p data-cy="transactionName">{transactionName}</p>
        <p data-cy="transactionDate">{transactionDate}</p>
      </div>
      <div>
        <span data-cy="transactionAmount">{amount}</span>
      </div>
    </ContentWrapper>
  );
};

export const CompactTransactionInfo = ({ transactionName, transactionDate, amount }: ITransactionCard) => {
  return (
    <ContentWrapper isCompact={true}>
      <div>
        <Paragraph style={headlineStyles} testID="transactionName">
          {transactionName ?? "-"}
        </Paragraph>
        <Label style={captionStyles as any} testID="transactionDate">
          {transactionDate}
        </Label>
      </div>
      <div>
        <Paragraph style={headlineStyles} testID="transactionAmount">
          {amount}
        </Paragraph>
      </div>
    </ContentWrapper>
  );
};

interface ICardStyles {
  borderColor?: string;
  background?: string;
}

interface ICard extends ICardStyles {
  children: React.ReactNode;
}

const CardWrapper = styled.div<ICardStyles>(({ theme, background, borderColor }) =>
  mq({
    padding: "12px",
    border: `${styledValue(borderWidth.small)} solid ${borderColor ?? theme.COLORS.NEUTRAL.COOL["200"]} `,
    borderRadius: spacing.xxs,
    background: background ?? "transparent",
    margin: `${styledValue(spacing.m)} ${styledValue(spacing.none)}`,

    button: {
      margin: [
        `${styledValue(spacing.xs)} auto`,
        `${styledValue(spacing.xs)} ${spacing.none}`,
        `${styledValue(spacing.xs)} ${spacing.none}`,
      ],
    },

    "& > *:not(button)": {
      textAlign: "left",
      margin: `${styledValue(spacing.xxs)} ${styledValue(spacing.none)}`,
      "&:first-of-type": {
        marginTop: spacing.none,
      },
    },

    "& > *": {
      "&:last-child": {
        marginBottom: spacing.none,
      },
    },
  }),
);

export const TransactionCard = ({ children, borderColor, background }: ICard) => {
  return (
    <CardWrapper borderColor={borderColor} background={background}>
      {children}
    </CardWrapper>
  );
};
