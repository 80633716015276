/**
 * Function for calculating the relative size based on the default size in pixels from the design
 *
 * Example:
 * If the element in design has width 200px but on the wide screen it's 350px and its child element depends on that
 * we can use that width as a base and always have the dynamic child size no matter how wide or small the screen is.
 * So if we want to make a component responsive for different screen sizes and we don't have an option to use media queries
 * we can calculate that dynamic size value using the width of the element
 */
export const calculateRelativeSize =
  (baseValueByDesign: number) => (pixelsByDesign: number, baseActualValue: number) => {
    const calculatedRatio = pixelsByDesign / baseValueByDesign;
    return Math.round(baseActualValue * calculatedRatio);
  };
