export const creditFactors = {
  "whatDoesThisMean": "Qu'est-ce que cela signifie?",
  "notApplicable": "s.o.",
  "missedPayments": {
    "increase": {
      "missed": "{{count}} nouveau paiement manqué",
      "missed_plural": "{{count}} nouveaux paiements manqués",
      "deferred": "{{count}} nouveau paiement différé",
      "deferred_plural": "{{count}} nouveaux paiements différés"
    },
    "content": {
      "whatAreTitle": "Qu'est-ce qu'un paiement manqué?",
      "whatAreContent": "Les paiements manqués sont tous les paiements que le prêteur a signalés comme étant en retard d'au moins 30 jours.",
      "howToImproveTitle": "Comment s'améliorer",
      "makePaymentSoonTitle": "Effectuer un paiement dans les plus brefs délais",
      "makePaymentSoonContent": "Effectuez le paiement dès que possible afin d'éviter que le paiement manqué ne se transforme en [marque dérogatoire]()",
      "thinkThereIsErrorTitle": "Si vous pensez qu'il y a une erreur",
      "contactTheCreditorTitle": "Contacter le créancier",
      "contactTheCreditorContent": "L'étape suivante consiste à contacter votre créancier pour lui demander de corriger l'erreur.",
      "contactTheBureauTitle": "Contacter les agences d'évaluation du crédit",
      "contactTheBureauContent": "Si votre créancier met trop de temps à corriger l'erreur ou refuse tout simplement de le faire, vous devrez peut-être vous adresser directement aux agences d'évaluation du crédit pour contester votre dossier de crédit."
    }
  },
  "creditInquiries": {
    "numberOfInquiries": "Nbre de demandes",
    "tooltips": {
      "detailsBody": "Une enquête de crédit est une demande d'examen de votre dossier de crédit par un prêteur ou une entreprise. Elle peut rester dans votre dossier pendant trois ans.\n\nLes enquêtes de crédit que vous voyez ici sont uniquement des enquêtes de crédit approfondies.",
      "equifaxDisputeBody": "Equifax n'a pas pu fournir le nom du prêteur qui a effectué l'enquête approfondie.\n\nVeuillez contacter Equifax à [__{{ phoneNumber }}__](), du lundi au vendredi entre 9 heures et 17 heures (HE) pour plus de détails."
    },
    "noInquiries": "Vous n'avez pas d'enquête de crédit. Continuez comme ça!",
    "new": {
      "title": "{{ count }} nouvelle enquête",
      "title_plural": "{{ count }} nouvelles enquêtes",
      "view_history": "Voir l'historique des enquêtes"
    },
    "improvementTips": {
      "first": "Étalez vos demandes de crédit afin de donner à votre cote de crédit le temps de se rétablir.",
      "second": "Ne demandez que les produits que vous êtes sûr de vouloir.",
      "third": "Si vous demandez un prêt automobile ou un prêt hypothécaire, les agences d'évaluation du crédit combinent les enquêtes effectuées dans un délai de grâce de 14 à 45 jours pour ces types de demandes de prêt et les comptent comme une seule enquête approfondie.",
      "fourth": "Vérifiez vos probabilités d'approbation avant de soumettre votre demande afin d'éviter les enquêtes de crédit inutiles."
    },
    "history": {
      "title": "Historique d'enquêtes approfondies",
      "subtitle": "Voici toutes les enquêtes approfondies qui apparaissent dans votre dossier de crédit."
    },
    "promoCard": {
      "title": "Faites une demande en toute confiance",
      "cta": "Voir les offres"
    },
    "equifaxDisputeCardTitle": "Vous ne reconnaissez pas une enquête?",
    "increase": "{{count}} nouvelle enquête de crédit",
    "increase_plural": "{{count}} nouvelles enquêtes de crédit",
    "content": {
      "whatAreTitle": "Qu'est-ce qu'une enquête de crédit?",
      "whatAreContent": "Il existe deux types d'enquêtes de crédit : les enquêtes de crédit approfondies et les enquêtes de crédit sans impact. Les enquêtes approfondies peuvent avoir un impact sur votre cote de crédit et restent à votre dossier pendant 3 ans, tandis que les enquêtes sans impact n'ont pas d'impact sur votre cote de crédit.\n\nLes enquêtes que vous voyez ici sont uniquement des enquêtes approfondies, car Emprunell n'affiche pas les enquêtes sans impact sur votre dossier de crédit.",
      "howToImproveTitle": "Comment s'améliorer",
      "spreadYourLoanTitle": "Étalez vos demandes de prêt ou de carte de crédit",
      "spreadYourLoanContent": "Échelonnez vos demandes de crédit pour donner à votre cote de crédit le temps de se rétablir. Réfléchissez bien à la nécessité de demander le produit et à l'impact qu'il aura sur votre santé financière globale.",
      "groupCreditInquiriesTitle": "Regrouper les demandes de crédit par type de produit",
      "groupCreditInquiriesContent": "Si le fait de demander une série de produits de crédit sur une courte période est généralement un signal d'alarme, il existe une exception pour les prêts automobiles ou hypothécaires. Les agences d'évaluation du crédit regroupent les enquêtes sur certains prêts effectuées au cours d'une période de grâce et les comptent comme une seule enquête de crédit. \n\nLe délai de grâce pour regrouper ces types d'enquêtes est généralement de 14 à 45 jours.",
      "thinkThereIsErrorTitle": "Si vous pensez qu'il y a une erreur",
      "contactTheCreditorTitle": "Contactez le créancier",
      "contactTheCreditorContent": "L'étape suivante consiste à contacter votre créancier pour lui demander de corriger l'erreur.",
      "contactTheBureauTitle": "Contactez les agences d'évaluation du crédit",
      "contactTheBureauContent": "Si votre créancier met trop de temps à corriger l'erreur ou refuse tout simplement de le faire, vous devrez peut-être vous adresser directement aux agences d'évaluation du crédit pour contester votre dossier de crédit."
    }
  },
  "creditUtilization": {
    "increase": {
      "lessThan30": "Augmentation de {{ count }} %",
      "moreThan30": "Augmentation de {{ count }} % depuis la dernière mise à jour",
      "summaryLessThan30": "L'utilisation a augmenté de {{ count }} %",
      "summaryMoreThan30": "L'utilisation a augmenté de {{ count }} % depuis la dernière mise à jour"
    },
    "chart": {
      "accountsTotal": "Comptes Total",
      "totalBalance": "Solde total : {{ balance }}",
      "totalLimit": "Limite totale : {{ limit }}"
    },
    "tooltips": {
      "detailsBody": "L'utilisation du crédit détermine la proportion de la limite totale de votre carte de crédit et de votre marge de crédit utilisée.",
      "noTradesTitle": "Pourquoi vous voyez cela?",
      "noTradesBody": "Vous voyez s.o. parce que vous n'avez pas de compte de crédit renouvelables actifs comme une carte de crédit ou une marge de crédit."
    },
    "naUtilization": {
      "notice": "Vous n'avez pas d'utilisation de crédit puisqu'il n'y a pas de cartes de crédit ou de marges de crédit dans votre dossier de crédit.",
      "tip": {
        "body": "Envisagez d'obtenir une carte de crédit pour vous aider à établir votre historique de crédit.",
        "link": "__[Explorer les cartes de crédit](https://borrowell.com/blog/how-much-does-my-credit-score-drop-if-I-miss-a-payment)__"
      }
    },
    "zeroUtilization": {
      "notice": "Un taux d'utilisation du crédit de 0 % indique au prêteur que vous n'utilisez pas votre crédit, ce qui peut avoir un impact négatif sur votre cote de crédit.",
      "tip": {
        "body1": "Portez un petit achat récurrent sur vos cartes de crédit, par exemple un abonnement mensuel à Netflix ou Spotify.",
        "body2": "Veillez simplement à ne pas oublier de payer ces frais à chaque date d'échéance!"
      }
    },
    "lowUtilizationNotice": "Excellent travail. Vous avez réussi à maintenir votre taux d'utilisation du crédit inférieur à 30 % et votre solde total inférieur à {{ limit }}.",
    "mediumUtilizationNotice": "Votre taux d'utilisation du crédit est supérieur à 30 %, ce qui signifie que vous utilisez plus que le montant optimal de crédit.",
    "highUtilizationNotice": "Votre taux d'utilisation du crédit est égal ou supérieur à 75 %, ce qui signifie que vous utilisez plus que le montant optimal de crédit.",
    "improvementTip": {
      "title": "Ce qu'il faut savoir",
      "body1": "Essayez de maintenir votre taux d'utilisation en dessous de 30 % ou votre solde total en dessous de {{ limit }}. Cela montre aux prêteurs que vous pouvez utiliser le crédit de manière responsable.",
      "body2": "Demandez à votre prêteur une augmentation de votre limite de crédit afin d'augmenter votre limite totale."
    },
    "overUtilization": {
      "notice": "Votre utilisation de crédit est supérieure à 100 %, ce qui signifie que vous avez dépassé votre limite de crédit totale.",
      "tip": "Payez votre solde dès que possible pour le ramener en dessous de 100 % afin d'éviter des frais ou des pénalités."
    },
    "content": {
      "whatIsTitle": "Qu'est-ce que l'utilisation du crédit?",
      "whatIsContent": "L'utilisation du crédit détermine la proportion de la limite totale de votre carte de crédit et de votre marge de crédit utilisée. \n\nEn général, vous devriez essayer de maintenir votre taux d'utilisation du crédit en dessous de 30 %.",
      "howToImproveTitle": "Comment s'améliorer",
      "minimizeBalanceTitle": "1. Réduisez le solde de vos comptes",
      "minimizeBalanceContent": "Réduisez les soldes de vos cartes de crédit et de vos marges de crédit en payant la totalité du solde chaque mois.",
      "increaseCreditTitle": "2. Augmenter votre limite de crédit",
      "increaseCreditContent": "Un autre moyen d'améliorer votre taux d'utilisation est de demander à votre société de carte de crédit d'augmenter votre limite de crédit. L'objectif est d'augmenter le montant total du crédit dont vous disposez sans augmenter le solde de votre dette actuelle."
    },
    "accountSummary": {
      "title": "Résumé du compte",
      "noUtilization": "Un prêteur peut fermer ce compte s'il détecte plusieurs mois d'inactivité.",
      "overUtilization": "Ramenez ce chiffre en dessous de 100 % dès que possible pour éviter d'encourir des frais ou des pénalités.",
      "balanceUsedOfCredit": "{{balance}} utilisé sur une limite de {{creditLimit}}"
    }
  },
  "derogatoryMarks": {
    "none": "Pas de marques dérogatoires",
    "noChange": "Pas de nouvelles marques dérogatoires",
    "increase": {
      "collection": "{{count}} nouveau recouvrement",
      "collection_plural": "{{count}} nouveaux recouvrements",
      "bankruptcy": "{{count}} nouvelle faillite",
      "bankruptcy_plural": "{{count}} nouvelles faillites",
      "legalItem": "{{count}} nouvel élément juridique",
      "legalItem_plural": "{{count}} nouveaux éléments juridiques",
      "marks": "{{count}} nouvelle marque",
      "marks_plural": "{{count}} nouvelles marques",
      "summaryMarks": "{{count}} nouvelle marque dérogatoire",
      "summaryMarks_plural": "{{count}} nouvelles marques dérogatoires"
    },
    "decrease": {
      "collection": "{{count}} recouvrement clos",
      "collection_plural": "{{count}} recouvrements clos",
      "bankruptcy": "{{count}} faillite close",
      "bankruptcy_plural": "{{count}} faillites closes",
      "legalItem": "{{count}} point juridique clos",
      "legalItem_plural": "{{count}} éléments juridiques clos",
      "marks": "{{count}} marque close",
      "marks_plural": "{{count}} marques closes"
    },
    "content": {
      "whatAreTitle": "Qu'est-ce qu'une marque dérogatoire?",
      "whatAreContent": "Les marques dérogatoires sont des événements comme des recouvrements, des faillites ou des éléments juridiques sur votre dossier de crédit qui indiquent que vous n'avez pas rempli vos obligations en matière de crédit. Ces événements peuvent rester sur votre dossier de crédit pendant 6 à 10 ans et ont un impact négatif sur votre crédit.",
      "howToImproveTitle": "Comment s'améliorer",
      "settleTheMarkTitle": "1. Réglez la marque dérogatoire",
      "settleTheMarkContent": "Réglez le problème dès que possible afin d'éviter qu'il n'affecte encore plus votre crédit. ",
      "startRebuildTitle": "2. Commencez à rétablir votre crédit",
      "startRebuildContent": "Commencez à rétablir votre crédit en consultant nos meilleures [recommandations de produits]() pour vous.",
      "thinkThereIsErrorTitle": "Si vous pensez qu'il y a une erreur",
      "contactTheCreditorTitle": "Contactez le créancier",
      "contactTheCreditorContent": "L'étape suivante consiste à contacter votre créancier pour lui demander de corriger l'erreur.",
      "contactTheBureauTitle": "Contactez les agences d'évaluation du crédit",
      "contactTheBureauContent": "Si votre créancier met trop de temps à corriger l'erreur ou refuse tout simplement de le faire, vous devrez peut-être vous adresser directement aux agences d'évaluation du crédit pour contester votre dossier de crédit."
    }
  },
  "avgCreditAge": {
    "increase": {
      "year": "Augmenté de {{count}} année",
      "year_plural": "Augmenté de {{count}} années",
      "month": "Augmenté de {{count}} mois",
      "month_plural": "Augmenté de {{count}} mois"
    },
    "decrease": {
      "year": "Diminué de {{count}} année",
      "year_plural": "Diminué de {{count}} années",
      "month": "Diminué de {{count}} mois",
      "month_plural": "Diminué de {{count}} mois"
    },
    "summaryUpdate": {
      "increasedBy": "L'ancienneté moyenne du crédit a augmenté de ",
      "decreasedBy": "L'ancienneté moyenne du crédit a diminué de ",
      "month": "{{count}} mois",
      "month_plural": "{{count}} mois",
      "year": "{{count}} année",
      "year_plural": "{{count}} années"
    },
    "content": {
      "whatIsTitle": "Qu'est-ce que l'ancienneté moyenne du crédit?",
      "whatIsContent": "L'ancienneté moyenne du crédit correspond à la durée moyenne pendant laquelle vous avez utilisé le crédit.",
      "howToImproveTitle": "Comment s'améliorer",
      "keepOldestAccountTitle": "Gardez vos comptes les plus anciens ouverts",
      "keepOldestAccountContent": "Au lieu de fermer vos anciens comptes, pensez à les garder actifs en y imputant de petits achats récurrents, comme votre abonnement mensuel à Netflix ou Spotify. Veillez simplement à ne pas oublier de payer ces frais à la date d'échéance."
    }
  },
  "totalAccounts": {
    "revolvingTrade": "{{count}} nouveau crédit renouvelable",
    "revolvingTrade_plural": "{{count}} nouveaux crédits renouvelables",
    "installmentLoan": "{{count}} nouveau prêt à tempérament",
    "installmentLoan_plural": "{{count}} nouveaux prêts à tempérament",
    "mortgage": "{{count}} nouveau prêt hypothécaire",
    "mortgage_plural": "{{count}} nouveaux prêts hypothécaires",
    "accounts": "{{count}} nouveau compte ouvert",
    "accounts_plural": "{{count}} nouveaux comptes ouverts",
    "content": {
      "whatIsTitle": "Qu'est-ce que le total des comptes?",
      "whatIsContent": "Il s'agit du nombre de cartes de crédit, de prêts, d'hypothèques et d'autres comptes ouverts que vous possédez. Les prêteurs cherchent à savoir si vous avez utilisé une combinaison de comptes de crédit de manière responsable.",
      "howToImproveTitle": "Comment s'améliorer",
      "diversifyTitle": "Diversifiez votre crédit",
      "diversifyContent": "Si vous êtes à la recherche d'un crédit, l'ajout de quelques produits de crédit comme une marge de crédit, un prêt hypothécaire, un prêt à tempérament ou une facture de téléphone pour atteindre une combinaison de crédit optimale est simple et peut améliorer votre cote de crédit de plusieurs points.",
      "practiceResponsibleTitle": "Empruntez de façon responsable",
      "practiceResponsibleContent": "Ce qu'il faut retenir de cette stratégie, c'est que vous ne devez contracter un nouveau crédit que si vous en avez besoin. Vous devrez également prouver que vous êtes en mesure de gérer avec succès un ensemble diversifié de produits de crédit et d'effectuer vos paiements à temps. Dans le cas contraire, l'ajout de nouveaux produits pourrait vous faire plus de mal que de bien."
    }
  },
  "equifaxDisputeCard": {
    "title": "Vous ne reconnaissez pas quelque chose?",
    "bodyMarkdown": "Lisez notre [__article du centre d'aide__]() sur la manière de contester des informations incorrectes sur votre dossier de crédit."
  },
  "equifaxDisputeLink": "Contestation du dossier de crédit d'Equifax",
  "transunionDisputeLink": "Contestation du dossier de crédit de TransUnion",
  "backToDashboard": "RETOUR AU TABLEAU DE BORD",
  "backToSummary": "RETOUR AU RÉSUMÉ",
  "needsWork": "À améliorer",
  "fair": "Correct",
  "good": "Bon",
  "lastUpdatedCaption": "Dernière mise à jour le",
  "nextUpdateCaption": "Prochaine mise à jour"
}
