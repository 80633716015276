import { ReactNode } from "react";

import { BannerMessage } from "./bannerMessage";
import { BannerNotification } from "./bannerNotification";

export enum BANNER_VARIANT {
  MESSAGE = "MESSAGE",
  NOTIFICATION = "NOTIFICATION",
}

export enum BANNER_TYPE {
  ALERT = "ALERT",
  INFO = "INFO",
  ERROR = "ERROR",
}

export enum BANNER_ICONS {
  ALERT = "caution",
  INFO = "info_hollow",
  ERROR = "alert_octagon",
}

export interface IBannerNotification {
  title: string;
  message: string;
  buttonText: string;
  url?: string;
  buttonAction: () => void;
}

export interface IBannerProps {
  type: BANNER_TYPE;
  variant?: BANNER_VARIANT;
  children?: ReactNode;
  bannerNotification?: IBannerNotification;
  className?: string;
  dataCy?: string;
  hideLeftIcon?: boolean;
}

const BANNER_COMPONENT = (props: IBannerProps) => ({
  MESSAGE: <BannerMessage {...props} />,
  NOTIFICATION: <BannerNotification {...props} />,
});

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Banner = ({ variant = BANNER_VARIANT.MESSAGE, ...rest }: IBannerProps) => {
  return BANNER_COMPONENT({ variant, ...rest })[variant as BANNER_VARIANT];
};
