import React, { useEffect } from "react";

import { useUserConfigExperiments } from "@bwll/bw-hooks";
import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

import { config } from "@app/config";

interface IProps {
  children: React.ReactNode;
}

export const AppBlock = ({ children }: IProps) => {
  const { isSuccess, data } = useUserConfigExperiments();

  useEffect(() => {
    if (isSuccess) {
      if (
        data[WEB_EXPERIMENT_IDS.ENABLE_RENT_REPORTING]?.treatment !==
        WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.ENABLE_RENT_REPORTING].ON
      ) {
        window.location.href = config.GEMSTONE_BASE_URL;
      }
    }
  }, [isSuccess, data]);

  return <>{children}</>;
};
