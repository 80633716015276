import { StyledComponent } from "@emotion/native";
import { TextProps } from "react-native";

import { StyledBody1Bold, StyledBody1Regular, StyledBody1SemiBold } from "./Body1.styles";
import { BODY1_VARIANT, Body1Props } from "./Body1.types";

export const getBody1ComponentStyleByVariant = (
  variant: BODY1_VARIANT,
): StyledComponent<TextProps & Body1Props> => {
  switch (variant) {
    case "bold":
      return StyledBody1Bold;
    case "semi-bold":
      return StyledBody1SemiBold;
    case "regular":
    default:
      return StyledBody1Regular;
  }
};
