export const accountDeactivation = {
  settingsMenuTitle: "Deactivation",
  settingsMenuSubtitle: "Deactivate your account",
  chooseStep: {
    title: "We're sorry to see you go",
    options: {
      deactivate: {
        label: "Deactivate Account",
        caption: "This pauses credit monitoring",
        text: "You won’t be notified about changes and your previous score history will be saved if you want to reactivate your account.",
      },
      delete: {
        label: "Delete Account",
        caption: "",
        text: "When you request to delete your Borrowell account, you won’t be able to access it in the future.",
      },
    },
    buttons: {
      nextStep: "NEXT STEP",
      cancel: "CANCEL",
    },
  },
  activeLoanStep: {
    deactivate: {
      title: "Your account can’t be deactivated at this time",
      subtitle:
        "You currently have an active Borrowell loan. To deactivate your account, finish paying off your remaining balance. \n\nFor assistance, please contact us through our [help centre]().",
    },
    delete: {
      title: "Your account can’t be deleted at this time",
      subtitle:
        "You currently have an active Borrowell loan. To delete your account, finish paying off your remaining balance. \n\nFor assistance, please contact us through our [help centre]().",
    },
    buttons: {
      goToDashboard: "GO TO DASHBOARD",
    },
  },
  confirmCurrentStep: {
    deactivate: {
      title: "Are you sure you want to pause your account?",
      buttons: {
        nextStep: "I WANT TO DEACTIVATE",
        cancel: "I CHANGED MY MIND",
      },
    },
    delete: {
      title: "Are you sure you want to delete your account?",
      content:
        "You can pause your account instead so you have the option to reopen the account in the future. This allows you to keep your past credit score history on file. Learn more about our retention practices [here](https://helpcentre.borrowell.com/hc/en-us/articles/17573748600340-How-does-Borrowell-retain-my-personal-information-).",
      dangerBanner: "Your Borrowell account will be deleted in accordance with our retention policy",
      buttons: {
        nextStep: "I WANT TO DELETE",
        cancel: "I CHANGED MY MIND",
      },
    },
    subtitle: "You’ll lose access to the following:",
    options: {
      one: "Free credit monitoring",
      two: "Access to your free credit score",
      three: "Personalized recommendations and your approval chances",
      four: "Quick and friendly customer service about your credit score and account questions.",
    },
  },
  reasonStep: {
    defaultReason: "Select a reason",
    deactivate: {
      title: "Why are you deactivating your account?",
      reasonsCaption: "Reason for deactivating",
      otherReasonLabel: "Enter your reason for deactivating",
    },
    delete: {
      title: "Why are you deleting your account?",
      reasonsCaption: "Reason for deleting",
      otherReasonLabel: "Enter your reason for deleting",
    },
    provideFeedback: "Please provide us some feedback (Optional)",
    title: "We're sorry to see you go",
    subtitle: "If you deactivate, you lose access to:",
    options: {
      one: "Free credit monitoring",
      two: "Access to your free credit score",
      three: "Personalized recommendations and viewing approval chances",
    },
    reasons: {
      tooManyEmails: {
        label: "I'm getting too many emails",
        content: {
          copy: "We’re sorry to hear that. Did you know you can control your communication preferences?",
          links: {
            prefs: "Change how often you get score update notifications",
          },
        },
      },
      notUseful: {
        label: "I don't find Borrowell useful",
        content: {
          copy: "We’re sorry to hear that. Here are some benefits that Borrowell offers:",
          links: {
            dashboard: "Staying on top of your credit health",
            report: "View your credit report to spot signs of fraud or reporting mistake",
            coach: "Use our Credit Coach to understand why your score changed",
            recommendations: "Access to financial product recommendations tailored to your credit profile",
          },
        },
      },
      zeroScore: {
        label: "My credit score is always 0",
        content: {
          copy: "A zero credit score means that your credit file is too new to generate a score or there is a mistake on your credit report. You can read more using the link below to learn more about what you can do.",
          links: {
            helpCentre: "Why do I have a 0 score?",
          },
        },
      },
      elsewhere: {
        label: "I monitor my score elsewhere",
        content: {
          copy: "Thanks for letting us know! Many of our members enjoy using our free service because it's easy to use and understand. Here are more benefits that Borrowell offers:",
          links: {
            creditReport: "View your credit report to spot signs of fraud or a reporting mistake",
            creditCoach: "Visit the credit coach to get helpful tips on why your score changed",
            recommendations: "View your product recommendations and see your approval chances",
          },
        },
      },
      issueWithAccount: {
        label: "Issues with my account",
        content: {
          copy: "We're sorry to hear that, can you let us know what issue you’re having?",
          links: {
            incorrectInfo: "My personal information is incorrect",
            staleScore: "My credit score isn’t refreshing",
            noScore: "I don’t have a credit score",
            other: "I have another issue",
          },
        },
      },
      other: {
        label: "Other, please explain further",
        content: {
          copy: "We're sorry to hear that, can you tell us why you want to leave?",
        },
      },
    },
    buttons: {
      nextStep: "NEXT STEP",
      cancel: "I CHANGED MY MIND",
    },
  },
  confirmationStep: {
    deactivate: {
      title: "Enter your password to confirm deactivation",
      content: "Pressing the button below will deactivate your Borrowell account.",
      buttons: {
        action: "DEACTIVATE ACCOUNT",
        cancel: "I CHANGED MY MIND",
      },
    },
    delete: {
      title: "Enter your password to confirm deletion",
      content: "Pressing the button below will submit a request to our team to delete your account",
      buttons: {
        action: "DELETE ACCOUNT",
        cancel: "I CHANGED MY MIND",
      },
    },
    label: "Enter Your Password",
    forgotPassword: "I forgot my password",
    errors: {
      emptyField: "Please enter your password to continue",
      invalidField: "Incorrect password, please try again",
      serverError: "Temporary copy for server error",
    },
  },
  deactivatedSuccessfulStep: {
    deactivate: {
      title: "Your account has been temporarily deactivated",
      subtitle:
        "You’ll receive a confirmation email shortly, no further action is required.\n\nIf you wish to reactivate your account in the future, simply contact our member support team. \n\nWe wish you all the best!\n",
    },
    delete: {
      title: "We’re sorry to see you go!",
      subtitle:
        "You’ll receive a confirmation email shortly, no further action is required. \n\nIf you wish to use our services again in the future, you’ll need to create a new account. \n\nTo learn more about how we collect and store your information, please see our [Privacy Policy](https://borrowell.com/privacy-policy).\n\nWe wish you all the best!\n",
    },
    buttons: {
      goToHomePage: "GO TO HOMEPAGE",
    },
  },
};
