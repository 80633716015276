export const transactionDetails = {
  "title": "Fournir les détails de la transaction pour votre preuve de paiement",
  "description": "Vous trouverez les détails des transactions sur vos relevés bancaires.",
  "descriptionOnboarding": "Vous trouverez les détails des transactions sur vos relevés bancaires. Les champs ci-dessous sont obligatoires.",
  "labels": {
    "file": "Fichier",
    "leaseTerm": "Durée du bail",
    "transactionDate": "Date de la transaction",
    "transactionAmount": "Montant de la transaction",
    "transactionDescription": "Description de la transaction",
    "rentDueDate": "Date d'échéance du loyer",
    "comments": "Commentaires (facultatif)",
    "change": "CHANGER"
  },
  "captions": {
    "transactionDate": "",
    "transactionAmount": "",
    "transactionDescription": "La description de la transaction doit être inférieure à 100 caractères",
    "transactionDescriptionOnboarding": "Vous pouvez trouver la description de la transaction sur vos relevés bancaires. Essayez de décrire cette transaction avec des détails tels que « <b>Virement Interac à Jean Tremblay</b> » ou comme cette transaction apparaît sur votre compte.",
    "transactionDescriptionOnboardingMD": "Vous pouvez trouver la description de la transaction sur vos relevés bancaires. Essayez de décrire cette transaction avec des détails tels que « **Virement Interac à Jean Tremblay** » ou comme cette transaction apparaît sur votre compte.",
    "comments": "Le commentaire doit être inférieur à 500 caractères"
  },
  "button": "CONTINUER",
  "buttonSubmitProof": "SOUMETTRE LA PREUVE DU PAIEMENT",
  "buttonOnboarding": "CONFIRMER",
  "errors": {
    "transactionDate": "La date de la transaction doit être entre {{ date }} et aujourd'hui",
    "descriptionField": "Veuillez entrer une description de moins de {{ maxLength }} caractères.",
    "commentField": "Veuillez entrer un commentaire de moins de {{ maxLength }} caractères.",
    "apiError": "Une erreur s'est produite lors de la soumission de votre transaction."
  },
  "modal": {
    "amountMismatch": {
      "title": "Les montants ne correspondent pas",
      "enteredLabel": "Montant de la transaction",
      "requiredLabel": "Montant du loyer du bail",
      "description": "Si le montant que vous avez entré est incorrect, veuillez mettre à jour le montant de votre transaction. Sinon, donnez des détails dans la section commentaires."
    },
    "dateMismatch": {
      "title": "Transaction périmée",
      "enteredLabel": "Date de la transaction",
      "requiredLabel": "Date d'échéance du loyer",
      "description": "Si la date que vous avez entrée est incorrecte, veuillez mettre à jour la date de votre transaction. Sinon, veuillez fournir des détails dans la section commentaires."
    },
    "buttons": {
      "edit": "MODIFIER LES DÉTAILS",
      "skip": "PROCÉDER DE TOUTE FAÇON"
    }
  }
}
