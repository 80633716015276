export const fontSize = {
  xxxxs: 8,
  xxxs: 10,
  xxs: 12,
  xs: 14,
  s: 16,
  m: 18,
  l: 20,
  xl: 24,
  xxl: 32,
  xxxl: 48,
  xxxxl: 56,
} as const;

export type FontSizeKeys = keyof typeof fontSize;
export type StandardFontSize = (typeof fontSize)[FontSizeKeys];

export const lineHeight = {
  xxxs: 12,
  xxs: 14,
  xs: 16,
  s: 18,
  m: 20,
  l: 22,
  xl: 24,
  xxl: 32,
  xxxl: 40,
} as const;

export type LineHeightKeys = keyof typeof lineHeight;
export type StandardLineHeight = (typeof lineHeight)[LineHeightKeys];

export const letterSpacing = {
  none: 0,
  xs: 0.15,
  s: 0.2,
  m: 0.25,
  l: 0.4,
  xl: 0.5,
  xxl: 1.25,
} as const;

export type LetterSpacingKeys = keyof typeof letterSpacing;
export type StandardLetterSpacing = (typeof letterSpacing)[LetterSpacingKeys];

export const fonts = {
  lato: {
    bold: "Lato-Bold",
    black: "Lato-Black",
    semibold: "Lato-Semibold",
    regular: "Lato-Regular",
  },
  merriweather: {
    black: "Merriweather-Black",
  },
  meowscript: {
    regular: "MeowScript-Regular",
  },
} as const;

export type FontsKeys = keyof typeof fonts;
export type StandardFonts = (typeof fonts)[FontsKeys];

export const fontWeights = {
  regular: "400",
  semiBold: "600",
  bold: "700",
} as const;

export type FontWeightsKeys = keyof typeof fontWeights;
export type StandardFontWeights = (typeof fontWeights)[FontWeightsKeys];
