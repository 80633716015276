import styled from "@emotion/native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { SemiBoldText } from "../Default";
import { StyledDisplay2Props } from "./Display2.types";

export const StyledDisplay2Component = styled(SemiBoldText)<StyledDisplay2Props>`
  font-size: ${styledValue(fontSize.xxl)};
  line-height: ${styledValue(spacing.xl)};
  letter-spacing: ${styledValue(letterSpacing.xs)};
  color: ${({ color }) => color ?? COLORS.NEUTRAL.WARM["700"]};
`;
