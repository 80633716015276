export const eid = {
  "primer": {
    "title": "Vérifions votre identité",
    "subtitle": "Cela ne prendra que quelques minutes.",
    "bulletsTitle": "Veuillez lire ce qui suit avant de commencer :",
    "firstBullet": "Vous devez le faire en une seule fois et votre avancement dans cette partie ne sera pas sauvegardé.",
    "secondBullet": "Quatre questions relatives à votre profil de crédit vous seront posées afin de s'assurer qu'il s'agit bien de vous.",
    "poweredBy": "Vérification effectuée par",
    "primaryButton": "RÉPONDRE AUX QUESTIONS"
  },
  "equifaxMaintenance": {
    "title": "Equifax est en panne pour maintenance",
    "subtitle": "Nous sommes désolés, le système Equifax est actuellement indisponible pour cause d'entretien. En général, cela ne dure environ qu'une demi-heure, veuillez donc réessayer dans 30 minutes.\n\nNous sommes vraiment désolés pour les inconvénients occasionnés. N'hésitez pas à revenir!",
    "primaryButton": "RETOUR À LA PAGE DE CONNEXION",
    "footnote": "Si vous avez des questions, n'hésitez pas à envoyer un courriel à [info@borrowell.com](mailto:info@borrowell.com) ou à consulter notre liste de [questions fréquemment posées](https://helpcentre.borrowell.com/hc/en-us)."
  },
  "questions": {
    "title": "Question {{index}} de 4",
    "nextQuestion": "QUESTION SUIVANTE",
    "submitAnswers": "SOUMETTRE LES RÉPONSES",
    "errorMessage": "Veuillez sélectionner une option pour continuer.",
    "none": "Si aucune des options ne s'applique à vous, veuillez sélectionner « Aucune de ces options »."
  },
  "eidRetry": {
    "title": "Les réponses fournies ne correspondaient pas aux dossiers d'Equifax",
    "subtitle": "Vous pouvez essayer à nouveau de répondre à une nouvelle série de questions pour vérifier votre identité.",
    "buttons": {
      "answerNewQuestions": "RÉPONDRE À DE NOUVELLES QUESTIONS"
    }
  },
  "uberFail": {
    "title": "Désolé {{firstName}}, nous n'avons pas pu vérifier votre identité.",
    "subtitle": "Nous savons que cela peut être frustrant. Vous trouverez ci-dessous les raisons pour lesquelles votre identité n'a pas pu être vérifiée.",
    "firstBullet": "Les réponses que vous avez fournies aux questions de vérification d'identité ne correspondent pas aux dossiers d'Equifax.",
    "secondBullet": "Les informations personnelles que vous avez saisies ne correspondent pas au dossier d'Equifax.",
    "thirdBullet": "Vous avez émis une alerte sur votre compte Equifax.",
    "fourthBullet": "Votre numéro de téléphone n'a pas pu être vérifié.",
    "visitHelpCenter": "Veuillez consulter le centre d'aide pour plus de détails.",
    "primaryButton": "VISITER LE CENTRE D'AIDE"
  },
  "networkError": {
    "title": "Il semble que vous ne soyez pas connecté à l'internet",
    "message": "Veuillez vérifier votre connexion et réessayer de soumettre vos réponses."
  }
}
