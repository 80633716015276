import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const CreditCardsCategoryHeaderWrapper = styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

export const HeaderWrapper = styled.View`
  margin-right: ${styledValue(spacing.xs)};
  flex: 1;
`;

export const LinkWrapper = styled.View`
  margin-left: auto;
`;
