export const biometry = {
  "title": "Se connecter rapidement et en toute sécurité",
  "buttons": {
    "dismiss": "PEUT-ÊTRE PLUS TARD"
  },
  "states": {
    "prompt": {
      "title": "Veuillez vous connecter avec votre empreinte digitale",
      "message": "Touchez le capteur d'empreintes digitales de votre téléphone\n",
      "cancelLabel": "UTILISER LE MOT DE PASSE",
      "variants": {
        "permission": {
          "title": "Veuillez confirmer votre empreinte digitale",
          "cancelLabel": "ANNULER"
        }
      }
    },
    "tryAgain": {
      "message": "Impossible de lire votre empreinte digitale,\nVeuillez réessayer."
    },
    "error": {
      "message": "Empreinte digitale non reconnue,\nveuillez réessayer"
    },
    "lockout": {
      "message": "Trop de tentatives. Veuillez vous connecter \navec votre mot de passe",
      "variants": {
        "permission": {
          "message": "Trop de tentatives. Veuillez réessayer\nplus tard."
        }
      }
    },
    "success": {
      "message": "Empreintes digitales reconnues"
    }
  },
  "options": {
    "TouchID": {
      "subTitle": "Activez l'authentification par empreinte digitale pour faciliter la connexion et ne pas avoir à taper votre mot de passe à chaque fois.",
      "buttonText": "ACTIVER L'AUTHENTIFICATION"
    },
    "Fingerprint": {
      "subTitle": "Activez l'authentification par empreinte digitale pour faciliter la connexion et ne pas avoir à taper votre mot de passe à chaque fois.",
      "buttonText": "ACTIVER L'AUTHENTIFICATION"
    },
    "FaceID": {
      "subTitle": "Activez l'authentification faciale pour faciliter la connexion et ne pas avoir à taper votre mot de passe à chaque fois.",
      "buttonText": "ACTIVER L'AUTHENTIFICATION"
    },
    "Iris Recognition": {
      "subTitle": "Activez l'authentification par l'iris pour faciliter la connexion et ne pas avoir à taper votre mot de passe à chaque fois.",
      "buttonText": "ACTIVER L'AUTHENTIFICATION"
    },
    "Fingerprint or FaceID": {
      "subTitle": "Activez l'authentification par empreinte digitale ou faciale pour faciliter la connexion et ne pas avoir à taper votre mot de passe à chaque fois.",
      "buttonText": "ACTIVER L'AUTHENTIFICATION"
    },
    "Fingerprint or Iris Recognition": {
      "subTitle": "Activez l'authentification par empreinte digitale ou iris pour faciliter la connexion et ne pas avoir à taper votre mot de passe à chaque fois.",
      "buttonText": "ACTIVER L'AUTHENTIFICATION"
    },
    "FaceID or Iris Recognition": {
      "subTitle": "Activez l'authentification faciale ou par l'iris pour faciliter la connexion et ne pas avoir à taper votre mot de passe à chaque fois.",
      "buttonText": "ACTIVER L'AUTHENTIFICATION"
    },
    "Fingerprint, FaceID or Iris Recognition": {
      "subTitle": "Activez l'authentification par empreinte digitale, faciale ou iris pour faciliter la connexion et ne pas avoir à taper votre mot de passe à chaque fois.",
      "buttonText": "ACTIVER L'AUTHENTIFICATION"
    }
  }
}
