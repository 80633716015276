export enum DATE_FORMATS {
  /**
   * date-fns compatible formats
   * official docs: https://date-fns.org/v2.16.1/docs/format
   * format interactive app: - https://date-fns-interactive.netlify.app/
   */
  MMM_d_yyyy = "MMM d, yyyy",
  MMMM_dd = "MMMM dd",
  MMM_dd_YYYY = "MMM dd yyyy",
  MMM_dd_comma_YYYY = "MMM dd, yyyy",
  MMMM_d_yyyy = "MMMM d, yyyy",
  MMMM_dd_yyyy = "MMMM dd, yyyy",
  MMMM_yyyy = "MMMM yyyy",
  yyyy_MM_dd = "yyyy-MM-dd",
  yyyy_MM_dd_Time = "yyyy-MM-dd'T'00:00",
  EEE_MMM_dd = "EEE, MMM dd",
  EEE_MMMM_dd = "EEE, MMMM dd",
  MM_yyyy = "MM/yyyy",
  EEEE_MMMM_dd_yyyy = "EEEE, MMMM dd yyyy",
  EEEE_MMMM_dd = "EEEE, MMMM dd",
  EEEE_MMMM_dd_comma_yyyy = "EEEE, MMMM dd, yyyy",
  MMM_d = "MMM d",
  MMMM = "MMMM",
  /** Ordinal numbers: 1st, 2nd, etc. */
  do = "do",

  /** @deprecated format: only compatible with moment.js */
  dddd_MMMM_DD_YYYY = "dddd, MMMM DD YYYY",
  /** @deprecated format: only compatible with moment.js */
  YYYY_MM_DD = "YYYY-MM-DD",
  /** @deprecated format: only compatible with moment.js */
  MMMM_DD_yyyy = "MMMM DD, yyyy",
}
