import i18next from "i18next";
import { useCallback } from "react";

import {
  ANALYTICS_ATTRIBUTES,
  AnalyticsEventCallback,
  CBL_ANALYTICS_ATTRIBUTES,
  CBL_ANALYTICS_VALUES,
  EVENT_LABEL,
  LAYOUT_TYPE,
  PLACEMENT,
  POPUP_NAMES,
  RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE,
} from "@bwll/bw-types";
import { uuid } from "@bwll/bw-utils";

const rentReportingCommonAttributes = {
  [ANALYTICS_ATTRIBUTES.LAYOUT_TYPE]: LAYOUT_TYPE.MOBILE_APP,
  [ANALYTICS_ATTRIBUTES.POPUP_CATEGORY]: RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.RENT_REPORTING,
  [ANALYTICS_ATTRIBUTES.POPUP_TEXT_BODY]: i18next.t("rentReporting:popupCard:body"),
};

const cblCommonAttributes = {
  [CBL_ANALYTICS_ATTRIBUTES.SCREEN_NAME]: CBL_ANALYTICS_VALUES.CBL_POPUP,
};

const mktPlaceCommonAttributes = {
  [ANALYTICS_ATTRIBUTES.LAYOUT_TYPE]: LAYOUT_TYPE.MOBILE_APP,
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: PLACEMENT.DASHBOARD,
  [ANALYTICS_ATTRIBUTES.NAME]: POPUP_NAMES.MARKETPLACE_POPUP,
  [ANALYTICS_ATTRIBUTES.POPUP_IMPRESSION_ID]: uuid(),
};

export const usePromotionPopupAnalyticEvents = () => {
  const createRAModalViewedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    () => [
      EVENT_LABEL.POPUP_SCREEN_VIEWED,
      {
        ...rentReportingCommonAttributes,
        [ANALYTICS_ATTRIBUTES.NAME]: RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.RENT_REPORTING_POPUP_VIEWED,
      },
    ],
    [],
  );

  const createRAModalClickedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    ({ destination } = { destination: "" }) => [
      EVENT_LABEL.POPUP_BUTTON_CLICKED,
      {
        ...rentReportingCommonAttributes,
        [ANALYTICS_ATTRIBUTES.NAME]: RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.RENT_REPORTING_POPUP_CLICKED,
        [ANALYTICS_ATTRIBUTES.DESTINATION]: destination,
      },
    ],
    [],
  );

  const createRAModalDismissedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    () => [
      EVENT_LABEL.POPUP_BUTTON_CLICKED,
      {
        ...rentReportingCommonAttributes,
        [ANALYTICS_ATTRIBUTES.NAME]: RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.RENT_REPORTING_POPUP_DISMISSED,
      },
    ],
    [],
  );

  const createCblModalViewedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    () => [
      EVENT_LABEL.MODAL_VIEWED,
      {
        ...cblCommonAttributes,
      },
    ],
    [],
  );

  const createCblModalClickedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    () => [
      EVENT_LABEL.BUTTON_CLICKED,
      {
        ...cblCommonAttributes,
        // TODO: fix locale since this locale would only work for mobile - SMA-1768
        [ANALYTICS_ATTRIBUTES.BUTTON_NAME]: i18next.t("cbl:cblPromoModal:cta"),
        [ANALYTICS_ATTRIBUTES.CALL_TO_ACTION]: CBL_ANALYTICS_VALUES.CBL_POPUP_CTA_CLICK,
      },
    ],
    [],
  );

  const createCblModalDismissedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    () => [
      EVENT_LABEL.MODAL_DISMISSED,
      {
        ...cblCommonAttributes,
      },
    ],
    [],
  );

  const createMktPlacePopupViewedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    ({ productId } = { productId: "" }) => [
      EVENT_LABEL.POPUP_BUTTON_VIEWED,
      {
        ...mktPlaceCommonAttributes,
        [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: productId,
      },
    ],
    [],
  );

  const createMktPlacePopupClickedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    ({ productId } = { productId: "" }) => [
      EVENT_LABEL.POPUP_BUTTON_CLICKED,
      {
        ...mktPlaceCommonAttributes,
        [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: productId,
      },
    ],
    [],
  );

  const createMktPlacePopupDismissedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    ({ productId } = { productId: "" }) => [
      EVENT_LABEL.POPUP_DISMISSED,
      {
        ...mktPlaceCommonAttributes,
        [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: productId,
      },
    ],
    [],
  );

  return {
    createRAModalViewedAnalyticEvent,
    createRAModalClickedAnalyticEvent,
    createRAModalDismissedAnalyticEvent,
    createCblModalViewedAnalyticEvent,
    createCblModalClickedAnalyticEvent,
    createCblModalDismissedAnalyticEvent,
    createMktPlacePopupViewedAnalyticEvent,
    createMktPlacePopupClickedAnalyticEvent,
    createMktPlacePopupDismissedAnalyticEvent,
  };
};
