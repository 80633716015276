import styled from "@emotion/native";

import { Label } from "@bwll/bw-components/next/atoms/Typography";
import { COLORS, borderWidth, fontSize, spacing } from "@bwll/bw-styles";

export const StyledCheckboxComponent = styled.View(() => ({
  flex: 1,
}));

export const StyledCheckboxOptionComponent = styled.TouchableOpacity(() => ({
  flexDirection: "row",
  borderBottomWidth: 1,
  borderColor: COLORS.NEUTRAL.WARM[100],
  paddingVertical: spacing.xs,
}));

export const StyledCheckboxSquareComponent = styled.View(({ selected }: { selected: boolean }) => ({
  borderRadius: spacing.xxxs / 2,
  borderWidth: borderWidth.small * 2,
  height: spacing.s,
  width: spacing.s,
  backgroundColor: selected ? COLORS.PRIMARY.DEFAULT : COLORS.WHITE,
  borderColor: selected ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.WARM[400],
  marginRight: spacing.xxs,
  alignSelf: "center",
  justifyContent: "center",
}));

export const StyledCheckboxLabel = styled(Label)(() => ({
  fontSize: fontSize.l,
}));
