export const text = {
  status: "status {{statusName}}",
  creditScoreGraph: "Your current credit score is {{creditScore}}, it is {{pointsText}}, {{nextUpdateText}}",
  eid: "Equifax",
  enstream: "Enstream",
  loading: "Loading, please wait",
  recognition: "Recognition, {{message}}",
  headsUp: "Heads Up, {{message}}",
  errorCard: "Sorry, there is an error: {{message}}",
  infoCard: "Something you need to know: {{message}}",
  disableSecureEntry: "disable secure entry",
  editField: "{{fieldName}} {{caption}}",
  errorField: "there is an error on {{fieldName}}, {{error}}",
  birthDateError: "there is an error, {{error}}",
};
