import styled from "@emotion/native";
import { Platform } from "react-native";

import { COLORS, IColor, fontSize, lineHeight } from "@bwll/bw-styles";
import { Platforms } from "@bwll/bw-types";
import { styledValue } from "@bwll/bw-utils";

import { BoldText } from "../Typography/Default";
import { StyledLinkTextComponentProps } from "./Link.types";

const IS_ANDROID = Platform.OS === Platforms.ANDROID;

export const StyledLinkTextComponent = styled(BoldText)<StyledLinkTextComponentProps>`
  font-style: normal;
  text-align: ${({ textAlign }) => textAlign};
  color: ${COLORS.PRIMARY.DEFAULT};
  text-decoration: ${({ hasUnderline }) => (hasUnderline ? "underline" : "none")};
  text-decoration-color: ${COLORS.PRIMARY.DEFAULT};
`;

export const StyledLink1 = styled(StyledLinkTextComponent)`
  font-size: ${styledValue(fontSize.s)};
  line-height: ${({ isNested }) =>
    // lineHeight.xs + lineHeight.xxs aligns texts from Link1 with Body1
    styledValue(isNested && !IS_ANDROID ? fontSize.s : lineHeight.xs + lineHeight.xxs)};
`;

export const Link2Wrapper = styled.TouchableOpacity<{ hasUnderline?: boolean; color?: string | IColor }>`
  ${({ hasUnderline, color }) =>
    hasUnderline &&
    `border-bottom-width: 1px;
  border-color: ${color ?? COLORS.PRIMARY.DEFAULT};
  border-style: solid;
  height: ${styledValue(!IS_ANDROID ? fontSize.l : lineHeight.l)};`}
`;

export const StyledLink2 = styled(StyledLinkTextComponent)`
  font-size: ${styledValue(fontSize.xs)};
  color: ${({ color = COLORS.PRIMARY.DEFAULT }) => color as string};
  text-decoration: none;
  line-height: ${({ isNested }) => styledValue(isNested && !IS_ANDROID ? fontSize.xs : lineHeight.l)};
`;
