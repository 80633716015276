export const paymentProofComments = {
  "title": "Ajoutez des commentaires à votre preuve de paiement",
  "body1": "Si le montant que vous avez saisi est incorrect, veuillez revenir à l'écran précédent pour mettre à jour le montant de votre transaction.",
  "body2": "Dans le cas contraire, vous pouvez expliquer la différence entre le montant de la transaction et le montant du bail.",
  "transactionAmount": "Montant de la transaction",
  "rentAmountFromLease": "Montant du loyer du bail",
  "labels": {
    "comments": "Commentaire"
  },
  "captions": {
    "comments": "Le commentaire doit être inférieur à 500 caractères.",
    "charactersLeft": " ({{count}} restant(s))"
  },
  "buttonSubmitProof": "SOUMETTRE LA PREUVE DU PAIEMENT"
}
