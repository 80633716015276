export const pushNotificationSettings = {
  title: "Push notification settings",
  subTitle: "Choose which types of notifications you want to receive on your device.",
  options: {
    all: {
      title: "Promotional & Transactional",
      text: "Be the first to receive alerts for exclusive offers, newly launched products, and expiring offers! Staying ahead has never been more rewarding.",
    },
    transactional: {
      title: "Transactional",
      text: "We'll only notify you for updates on your credit score, credit report, and Borrowell product usage. ",
    },
    none: {
      title: "Neither",
      text: "Prefer a break from notifications? While we'll miss updating you, take your time in the quiet.",
    },
  },
};
