import React, { useRef, useState } from "react";
import { Animated, FlatList, ListRenderItem, View } from "react-native";

import { useWindowProperties } from "@bwll/bw-hooks";
import { spacing } from "@bwll/bw-styles";

import { Indicators } from "../../atoms/Indicators";
import { Spacer } from "../../atoms/Spacer";
import { Heading1 } from "../../atoms/Typography";
import { ElementTracker } from "../../molecules/ElementTracker";
import { InfoCard } from "../InfoCard/InfoCard.component";
import { InfoCardProps } from "../InfoCard/InfoCard.types";
import {
  CarouselNavigatorContainer,
  CarouselWrapper,
  Container,
  IndicatorsContainer,
  OffersText,
  styles,
} from "./CarouselCards.styles";
import { ICarouselCardsProps } from "./CarouselCards.types";

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

export const CarouselCards: React.FC<ICarouselCardsProps> = ({
  carouselCards,
  title,
  startIndex,
  onScrollHandler,
  onButtonPressHandler,
  onCardViewed,
}) => {
  const {
    dimensions: {
      window: { width },
    },
  } = useWindowProperties();

  // card width
  // adjusts card width and offset
  const cardWidth = (width / 9) * 8;
  const timelineSnapping = cardWidth;
  const timelinePadding = (width - cardWidth) / 2;

  const { current: scrollPosition } = useRef(new Animated.Value(0));
  const [maxHeight, setMaxHeight] = useState(0);

  const [currentPosition, setCurrentPosition] = useState(0);

  const cardPositionListener = (position: number) => setCurrentPosition(position);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const flatListRef = useRef<FlatList>(null);

  const onScroll = Animated.event([{ nativeEvent: { contentOffset: { x: scrollPosition } } }], {
    useNativeDriver: true,
    listener: ({
      nativeEvent: {
        contentOffset: { x },
      },
    }: {
      nativeEvent: { contentOffset: { x: number } };
    }) => {
      const position = Math.max(0, Math.round(x / cardWidth));

      if (carouselCards && carouselCards.length > position) {
        cardPositionListener(position);
        setSelectedIndex(position);

        if (onScrollHandler) onScrollHandler(position);
      }
    },
  });

  const getCardInputRange = (position: number): number[] => {
    const startInterval = position * cardWidth;
    return [startInterval - cardWidth, startInterval, startInterval + cardWidth];
  };

  const onPressInternalHandler = (index: number) => {
    if (onButtonPressHandler) {
      onButtonPressHandler(index);
    }
  };

  const renderItem = ({ item: card, index }: { item: InfoCardProps; index: number }) => {
    return (
      <ElementTracker
        shouldStartTracking
        trackingHandler={() =>
          onCardViewed?.({
            index,
            card: card as never,
            ...(card?.buttonText && { buttonTextOverride: card.buttonText }),
          })
        }
      >
        <View
          style={{
            width: cardWidth,
            paddingHorizontal: spacing.xxs,
          }}
        >
          <InfoCard {...card} onPressHandler={() => onPressInternalHandler(index)} />
        </View>
      </ElementTracker>
    );
  };

  const onScrollToIndexFailed = (info: {
    index: number;
    highestMeasuredFrameIndex: number;
    averageItemLength: number;
  }) => {
    const wait = new Promise((resolve) => setTimeout(resolve, 500));
    wait.then(() => {
      flatListRef.current?.scrollToIndex({ index: info.index, animated: true });
    });
  };

  return (
    <CarouselWrapper>
      <OffersText>
        <Heading1>{title}</Heading1>
      </OffersText>
      <Spacer height={spacing.xs} />
      <Container>
        <AnimatedFlatList
          ref={flatListRef}
          horizontal
          onContentSizeChange={(_, height) => setMaxHeight(height)}
          data={carouselCards}
          decelerationRate="fast"
          showsHorizontalScrollIndicator={false}
          overScrollMode="never"
          renderItem={renderItem as ListRenderItem<unknown>}
          onScrollToIndexFailed={onScrollToIndexFailed}
          snapToInterval={timelineSnapping}
          contentContainerStyle={{ paddingHorizontal: timelinePadding }}
          onScroll={onScroll}
          initialScrollIndex={startIndex}
        />
      </Container>
      <Spacer height={spacing.xs} />
      <CarouselNavigatorContainer>
        <IndicatorsContainer minWidth={width * 0.1} maxWidth={width * 0.2}>
          <Indicators
            numItems={carouselCards!.length}
            getInputRange={getCardInputRange}
            currentPosition={scrollPosition}
            indicatorStyle={styles.indicatorStyle}
          />
        </IndicatorsContainer>
      </CarouselNavigatorContainer>
    </CarouselWrapper>
  );
};
