export const formErrorMessages = {
  usernameAlreadyExists: "This email is already associated with an account. Go back to try logging in.",
  emailsDontMatch: "Emails don't match. Please try again.",
  invalidName: "Invalid name format. Example, John Appleseed",
  invalidEmail: "Invalid email format. Example, name@domain.com",
  invalidPassword: "Must be between 8 and 100 characters and contain 1 number and 1 letter.",
  invalidPostalCode: "Invalid format. Example, A1A 1A1",
  invalidStreetAddress: "Invalid format. Example, 1 Infinite Loop",
  phoneNumberFormat: "Invalid format. Phone number should be 10-digits in length",
  phone: "$t(errors:formErrorMessages:required:base) phone",
  mustBe18YearsOld: "You must be at least 18 years old to use Borrowell",
  required: {
    base: "Enter your",
    phone: "$t(errors:formErrorMessages:required:base) phone number",
    verificationCode: "$t(errors:formErrorMessages:length:base)",
  },
  length: {
    base: "Enter a {{length}} digit {{fieldName}}",
  },
};