import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Label } from "../../atoms/Typography";

export const BreadcrumbsContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const BreadcrumbSeparator = styled(Label)`
  padding-horizontal: ${styledValue(spacing.xxs)};
`;
