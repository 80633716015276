import MarketplaceEmptyResults from "./assets/marketplace-empty-results.svg";
import MarketplaceLoading from "./assets/marketplace-loading.svg";
import SvgEmpty from "./assets/svg-empty.svg";

type SvgImage = {
  key: string;
  component: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const SvgPlaceholder = SvgEmpty;

// Add new SVG image key and component mapping here
export const SVG_IMAGES = [
  { key: "marketplace-empty-results", component: MarketplaceEmptyResults },
  { key: "marketplace-loading", component: MarketplaceLoading },
  { key: "svg-empty", component: SvgEmpty },
] as const satisfies ReadonlyArray<SvgImage>;

/** List of keys of the SVG images registered in SVG_IMAGES. Sorted in ascending order. */
export const SVG_IMAGE_KEYS = SVG_IMAGES.map(({ key }) => key).sort((a, b) => a.localeCompare(b));
