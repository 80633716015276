export const rentConfirmationPage = {
  selection: {
    title: "Select your rent payment",
    consent: "By selecting this transaction you’re confirming that this is your rent payment.",
    cta: "SELECT TRANSACTION",
    cantFindRentPaymentLink: "I can't find my rent payment",
    amountsDontMatch: "Amounts don't match",
    changeSelection: "CHANGE SELECTION",
    selectedTransactions: "Selected transactions",
    rentAmountFromLease: "Rent amount from lease",
    popupContactSupport: "If you don't see all the transactions, please [__contact support__]()",
    notSeeingRent: "Don’t see your rent?",
    viewMore: "View more transactions",
    contactSupport: "If you don’t see your rent transactions, please [__contact support__]()",
  },
  review: {
    title: "Review rent payment",
    subtitle:
      "Once you confirm your rent, we’ll report it as paid. You can expect it to appear on your credit report in 2 – 3 weeks.",
    consent:
      "By continuing, you're confirming that this transaction is your rent payment. Borrowell may validate this information at any time.",
    cta: "CONFIRM RENT",
    sectionTitle: "Selected transaction",
    sectionTitleMobile: "Amount to be reported",
    tooltipTitle: "What does this mean?",
    tooltipContent: "This is the amount we'll report to Equifax based on the transactions you selected.",
    changeTransactions: "Select different transactions",
    totalAmount: "Total amount",
    changeLink: "Change",
  },
};
