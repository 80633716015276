import styled from "@emotion/styled";

import { COLORS, fontSize, mq, spacing } from "@bwll/bw-styles";

export const SectionHeader = styled.h3(({ theme }) =>
  mq({
    ...theme.h3,
    textAlign: "left",
    fontSize: fontSize.s,
  }),
);

export const CardLinkInner = styled.div(
  mq({
    padding: spacing.xs,
    background: COLORS.NEUTRAL.COOL["050"],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > div:last-of-type": {
      paddingLeft: spacing.xxs,
    },
    cursor: "pointer",
    borderRadius: 8,
  }),
);
