import { ReactNode, createContext, useMemo } from "react";

import { useUserConfigExperiments } from "@bwll/bw-hooks";
import { EXPERIMENT_SOURCE, IExperiments } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

type ExperimentsMap = {
  [key in EXPERIMENT_SOURCE]?: IExperiments;
};

interface IExperimentStoreContext {
  experimentsMap: ExperimentsMap;
}

interface IExperimentStoreProviderProps {
  children: ReactNode;
  loadingComponent?: React.ReactNode;
}

export const ExperimentStoreContext = createContext<IExperimentStoreContext>({
  experimentsMap: {},
});

export const ExperimentStoreProvider = ({ children, loadingComponent }: IExperimentStoreProviderProps) => {
  const { isSuccess: isUserConfigSuccess, isLoading: isUserConfigLoading, data } = useUserConfigExperiments();

  const experimentsMap = useMemo(() => {
    const map = {} as ExperimentsMap;
    if (isUserConfigSuccess) {
      map.userConfig = data;
    }
    return map;
  }, [isUserConfigSuccess, data]);

  if (isUserConfigLoading && loadingComponent) {
    return <>{loadingComponent}</>;
  }

  return (
    <ExperimentStoreContext.Provider value={{ experimentsMap }}>{children}</ExperimentStoreContext.Provider>
  );
};

export const useExperimentStoreContext = () =>
  useContextWrapper(ExperimentStoreContext, ContextName.ExperimentStoreContext);
