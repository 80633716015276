import styled from "@emotion/native";
import { Animated } from "react-native";

import { COLORS } from "@bwll/bw-styles";

export const ExpandableWrapper = styled(Animated.View)`
  flex: 1 1 100%;
  border-bottom-color: ${COLORS.NEUTRAL.COOL["100"]};
  border-bottom-width: 1px;
`;

export const CenteredView = styled.View`
  align-items: center;
`;
