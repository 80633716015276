import styled from "@emotion/styled";
import { ReactNode } from "react";

import { mq } from "@bwll/bw-styles";

export interface IList {
  ordered?: boolean;
  children: ReactNode;
}

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const List = ({ children, ordered }: IList) => {
  const UnorderedListComponent = styled.ul(({ theme }) => mq(theme.list));

  const OrderedListComponent = styled.ol(({ theme }) => mq(theme.list));

  if (ordered)
    return (
      <OrderedListComponent>{Array.isArray(children) && children?.map((li) => li)}</OrderedListComponent>
    );

  return (
    <UnorderedListComponent>{Array.isArray(children) && children?.map((li) => li)}</UnorderedListComponent>
  );
};
