import styled from "@emotion/styled";
import { ReactNode } from "react";

import { COLORS, mq, spacing } from "@bwll/bw-styles";

export interface FormFieldWrapperProps {
  label?: string;
  labelFontSize?: string;
  isRequired?: boolean;
  error?: string;
  isDirty?: boolean;
  caption?: string;
  id?: string;
  labelIsScreenReaderonly?: boolean;
  children: ReactNode;
}

const Label = styled.label`
  ${mq({
    letterSpacing: "0.4px",
    marginTop: "5px",
  })}
`;

const FormFieldContainer = styled.div<{
  hasError?: boolean;
  labelFontSize?: string;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0.4rem 0 2rem 0;
  width: 100%;
  & > label,
  & > span {
    color: ${({ hasError }) => (hasError ? COLORS.RED["700"] : COLORS.NEUTRAL.WARM["700"])};
    ${({ labelFontSize }) => labelFontSize && `font-size: ${labelFontSize};`}
  }
  & > span[id$="-error"] {
    margin-top: ${spacing.xxs}px;
  }
  & > label {
    ${({ labelFontSize }) => labelFontSize && `font-size: ${labelFontSize};`}
  }
  :focus-within,
  :focus,
  :hover {
    color: ${COLORS.PRIMARY.DEFAULT};
    & > label,
    & > span {
      color: ${({ hasError }) => (hasError ? COLORS.RED["700"] : COLORS.NEUTRAL.WARM["700"])};
    }
    input {
      border-color: ${({ hasError }) => (hasError ? COLORS.RED["700"] : COLORS.PRIMARY.DEFAULT)};
    }
    button {
      border-color: ${({ hasError }) => (hasError ? COLORS.RED["700"] : COLORS.PRIMARY.DEFAULT)};
    }
  }
`;

/** NOT MEANT TO BE CONSUMED DIRECTLY,
 * should be used to wrap input components */
export const FormFieldWrapper = ({
  children,
  isRequired,
  error,
  label,
  labelFontSize,
  caption,
  id,
  isDirty,
  labelIsScreenReaderonly,
}: FormFieldWrapperProps) => {
  return (
    <FormFieldContainer hasError={!!error && isDirty} labelFontSize={labelFontSize}>
      {!labelIsScreenReaderonly && (
        <Label htmlFor={id} id={`${id}-label`}>
          {label}
          {isRequired && <span>*</span>}
        </Label>
      )}
      {children}
      {!!caption && <span id={`${id}-caption`}>{caption}</span>}
      {!!error && isDirty && (
        <span id={`${id}-error`} aria-live="assertive">
          {error}
        </span>
      )}
    </FormFieldContainer>
  );
};
