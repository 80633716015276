export enum DEVICE_PLATFORM {
  IOS = "iOS",
  ANDROID = "Android",
  WEB = "Web",
}

export enum LAYOUT_TYPE {
  MOBILE_APP = "Mobile_App",
  WEB = "Web",
  MOBILE_WEB = "MWeb",
}

export enum RENT_REPORTING_VERSION {
  V1 = "v1.0",
  V1_1_0 = "v1.1.0",
}

export type VERSION = RENT_REPORTING_VERSION;

export enum EVENT_LABEL {
  // base
  PAGE = "Page",
  SCREEN_VIEW = "Screen View",
  BUTTON_CLICKED = "Button Clicked",
  NAVIGATION_BUTTON_CLICKED = "Navigation Button Clicked",
  POPUP_SCREEN_VIEWED = "Popup Screen Viewed",
  POPUP_BUTTON_CLICKED = "Popup Button Clicked",
  POPUP_BUTTON_VIEWED = "Popup Button Viewed",
  POPUP_DISMISSED = "Popup Dismissed",
  PROMO_CARD_VIEWED = "Promo Card Viewed",
  PROMO_CARD_CLICKED = "Promo Card Clicked",
  MODAL_VIEWED = "Modal Viewed",
  MODAL_DISMISSED = "Modal Dismissed",
  LINK_CLICKED = "Link Clicked",
  CHECKBOX_CLICKED = "Checkbox Clicked",
}

export const CURRENT_RENT_REPORTING_VERSION = RENT_REPORTING_VERSION.V1;
