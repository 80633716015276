import styled, { css } from "@emotion/native";

import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  background-color: ${COLORS.WHITE};
  padding: ${styledValue(spacing.xs)};
  border-radius: ${styledValue(borderRadius.s)};
  border: 1px solid ${COLORS.NEUTRAL.COOL["200"]};
`;

export const CardTitleContainer = styled.View`
  margin-bottom: ${styledValue(spacing.xs)};
`;

export const CardDescriptionContainer = styled.View`
  margin-top: ${styledValue(spacing.xs)};
  margin-bottom: ${styledValue(spacing.xs)};
`;

export const paragraphStyle = css`
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;
