const updateBooleanStringTypes = (config: string) =>
  config.split(`"false"`).join(`false`).split(`"true"`).join(`true`);

export const parseJsonString = (json: string) => {
  try {
    return JSON.parse(updateBooleanStringTypes(json)) || {};
  } catch (error) {
    return {};
  }
};
