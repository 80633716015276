import styled from "@emotion/styled";

import { fontSize, letterSpacing, lineHeight } from "@bwll/bw-styles";
import { IColors } from "@bwll/bw-styles";

import { Icon } from "../../atoms/Icon";

interface IStepInfo {
  title: string;
  description: string;
  active?: boolean;
  completed?: boolean;
  lastStep?: boolean;
}

interface IVerticalStepInfoProps {
  steps: IStepInfo[];
}

type StepProps = {
  active?: boolean;
  completed?: boolean;
  lastStep?: boolean;
};

const getBorderColor = (active: boolean, colors: IColors) =>
  active ? colors.NEUTRAL.WARM[800] : colors.NEUTRAL.COOL[200];

const Step = styled.li<StepProps>(({ active, completed, lastStep, theme }) => ({
  ...(!lastStep && { minHeight: 144 }),

  listStyle: "none",
  borderLeft: `2px solid ${
    lastStep ? "transparent" : getBorderColor(active || completed || false, theme.COLORS)
  }`,

  padding: "4px 8px 8px 44px",
  position: "relative",

  strong: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: fontSize.l,
    lineHeight: `${lineHeight.xl + 4}px`,
    letterSpacing: letterSpacing.xs,

    color: theme.COLORS.NEUTRAL.WARM[800],
  },

  p: {
    margin: "8px 0",

    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: fontSize.xs,
    lineHeight: `${lineHeight.m}px`,
    letterSpacing: letterSpacing.s,

    color: theme.COLORS.NEUTRAL.WARM[700],
  },
}));

const getNumberColor = (active: boolean, colors: IColors) =>
  active ? colors.NEUTRAL.COOL[900] : colors.NEUTRAL.COOL[500];

const StepNumber = styled.div<StepProps>(({ active, completed, theme }) => ({
  border: `2px solid ${getBorderColor(active || completed || false, theme.COLORS)}`,

  background: completed ? theme.COLORS.NEUTRAL.WARM[800] : theme.COLORS.WHITE,
  color: completed ? theme.COLORS.WHITE : getNumberColor(active || false, theme.COLORS),

  width: 56,
  height: 56,
  borderRadius: "50%",

  position: "absolute",
  top: 0,
  left: -32,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: fontSize.xl + 4,
  lineHeight: `${lineHeight.xxl + 4}px`,
  letterSpacing: letterSpacing.m,
}));

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const VerticalStepperInfo = ({ steps }: IVerticalStepInfoProps) => {
  return (
    <ul>
      {steps &&
        steps.length > 0 &&
        steps.map((step, index) => (
          <Step
            key={step.title}
            active={step.active}
            completed={step.completed}
            lastStep={steps.length - 1 === index}
          >
            <StepNumber active={step.active} completed={step.completed}>
              {step.completed ? <Icon icon="success" size={32} color="white" /> : index + 1}
            </StepNumber>

            <strong>{step.title}</strong>
            <p>{step.description}</p>
          </Step>
        ))}
    </ul>
  );
};
