import { settingOptions } from './settingOptions';
import { accountDeactivation } from './accountDeactivation';
import { referYourFriends } from './referYourFriends';
import { profile } from './profile';
import { security } from './security';
import { language } from './language';
import { updateFrequency } from './updateFrequency';
import { turnOffEmailNotifications } from './turnOffEmailNotifications';
import { scoreAndReportUpdates } from './scoreAndReportUpdates';
import { pushNotificationSettings } from './pushNotificationSettings';
import { pushNotifications } from './pushNotifications';
import { emailNotifications } from './emailNotifications';
import { notificationPreferences } from './notificationPreferences';
import { mfa } from './mfa';
import { linkOptions } from './linkOptions';

export const settings = {
  settingOptions,
  accountDeactivation,
  referYourFriends,
  profile,
  security,
  language,
  updateFrequency,
  turnOffEmailNotifications,
  scoreAndReportUpdates,
  pushNotificationSettings,
  pushNotifications,
  emailNotifications,
  notificationPreferences,
  mfa,
  linkOptions,
}