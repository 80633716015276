export enum ONBOARDING_STEP_COMPLETED {
  RENTAL_ADDRESS = "RentalAddress",
  LEASE_TERMS = "LeaseTerms",
  RENT_METHOD_SELECT_MANUAL = "RentMethodSelectManual",
  RENT_METHOD_SELECT_CONNECT_BANK = "RentMethodSelectConnectBank",
  BANK_CONNECT = "BankConnect",
  SELECT_ACCOUNT = "SelectAccount",
  RENT_TRANSACTION_SELECT = "RentTransactionSelect",
  MANUAL_RENT_UPLOAD = "ManualRentUpload",
  MANUAL_RENT_DETAILS = "ManualRentDetails",
  CHECKOUT_SUMMARY = "CheckoutSummary",
  CHECKOUT_CONFIRMATION = "CheckoutConfirmation",
  // temporary v2 steps
  RENTAL_ADDRESS_V2 = "RentalAddressV2",
  LEASE_TERMS_V2 = "LeaseTermsV2",
  RENT_METHOD_SELECT_MANUAL_V2 = "RentMethodSelectManualV2",
  RENT_METHOD_SELECT_CONNECT_BANK_V2 = "RentMethodSelectConnectBankV2",
  BANK_CONNECT_V2 = "BankConnectV2",
  SELECT_ACCOUNT_V2 = "SelectAccountV2",
  RENT_TRANSACTION_SELECT_V2 = "RentTransactionSelectV2",
  MANUAL_RENT_UPLOAD_V2 = "ManualRentUploadV2",
  MANUAL_RENT_DETAILS_V2 = "ManualRentDetailsV2",
  CHECKOUT_SUMMARY_V2 = "CheckoutSummaryV2",
  CHECKOUT_CONFIRMATION_V2 = "CheckoutConfirmationV2",
}

export const V2OnboardingSteps = new Set<ONBOARDING_STEP_COMPLETED>([
  ONBOARDING_STEP_COMPLETED.RENTAL_ADDRESS_V2,
  ONBOARDING_STEP_COMPLETED.LEASE_TERMS_V2,
  ONBOARDING_STEP_COMPLETED.RENT_METHOD_SELECT_MANUAL_V2,
  ONBOARDING_STEP_COMPLETED.RENT_METHOD_SELECT_CONNECT_BANK_V2,
  ONBOARDING_STEP_COMPLETED.BANK_CONNECT_V2,
  ONBOARDING_STEP_COMPLETED.SELECT_ACCOUNT_V2,
  ONBOARDING_STEP_COMPLETED.RENT_TRANSACTION_SELECT_V2,
  ONBOARDING_STEP_COMPLETED.MANUAL_RENT_UPLOAD_V2,
  ONBOARDING_STEP_COMPLETED.MANUAL_RENT_DETAILS_V2,
  ONBOARDING_STEP_COMPLETED.CHECKOUT_SUMMARY_V2,
  ONBOARDING_STEP_COMPLETED.CHECKOUT_CONFIRMATION_V2,
]);
