export const text = {
  "status": "statut {{statusName}}",
  "creditScoreGraph": "Votre cote de crédit actuelle est {{creditScore}}, elle est {{pointsText}}, {{nextUpdateText}}",
  "eid": "Equifax",
  "enstream": "Enstream",
  "loading": "Chargement, veuillez patienter",
  "recognition": "Reconnaissance, {{message}}",
  "headsUp": "Attention, {{message}}",
  "errorCard": "Désolé, il y a une erreur: {{message}}",
  "infoCard": "Ce qu'il faut savoir: {{message}}",
  "disableSecureEntry": "désactiver l'entrée sécurisée",
  "editField": "{{fieldName}} {{caption}}",
  "errorField": "il y a une erreur sur {{fieldName}}, {{error}}",
  "birthDateError": "il y a une erreur, {{error}}"
}
