import styled from "@emotion/styled";
import { StyleSheet } from "react-native";
import { LinearGradientProps } from "react-native-linear-gradient";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const BannerMainWrapper = styled.div`
  display: flex;
  gap: ${styledValue(spacing.xs)};
  margin-bottom: ${styledValue(spacing.xxs)};
`;

export const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${styledValue(spacing.xxs)};
`;

export const CreditScoreGrowthImgStyles = StyleSheet.create({
  creditGrowthImage: {
    width: 88,
    height: "auto",
    objectFit: "contain",
  },
});

export const CreditScoreGrowthBannerLinearGradient: LinearGradientProps = {
  colors: ["rgba(251, 215, 203, 0.2)", "rgba(209, 68, 21, 0.2)"],
  locations: [0.3, 1.0],
  useAngle: true,
  angle: 115,
};

export const ComponentContainer = styled.div`
  width: 480px;
`;
