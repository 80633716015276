import React from "react";

import { Icon, Markdown, Spacer, Tooltip, TooltipText } from "@bwll/bw-components/next";
import { Body1Props } from "@bwll/bw-components/next/atoms/Typography/Body1/Body1.types";
import { isWeb } from "@bwll/bw-components/next/constants";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, fontSize } from "@bwll/bw-styles";

import * as Styled from "./Highlights.styles";
import { HighlightsProps } from "./Highlights.types";

const ResponsiveText = (props: Body1Props) => {
  const { isMobile } = useBreakpoints();

  return <Styled.ResponsiveText isMobile={isMobile} {...props} />;
};

const defaultColor = COLORS.NEUTRAL.WARM["800"];

export const Highlights = ({
  hasTooltip,
  tooltipTextProps,
  iconProps,
  children,
  testID,
  centeredVertically = true,
}: HighlightsProps) => {
  const { iconName, iconColor = undefined, iconSize = fontSize.s } = iconProps;
  const TypographyComponent = tooltipTextProps?.TypographyComponent ?? ResponsiveText;
  const tooltipCopy = tooltipTextProps?.tooltipText;

  const tooltipProps = isWeb
    ? {
        content: <ResponsiveText>{tooltipCopy}</ResponsiveText>,
      }
    : {
        bodyText: `${tooltipCopy}`,
        tooltipWidth: 300,
      };

  return (
    <Styled.HighlightsContainer testID={testID} centered={centeredVertically}>
      <Styled.IconContainer centered={centeredVertically}>
        <Icon icon={iconName} size={iconSize} color={iconColor} />
      </Styled.IconContainer>
      <Spacer width={6} />
      {hasTooltip && tooltipTextProps ? (
        <Tooltip {...tooltipProps}>
          <TooltipText
            color={tooltipTextProps.color || defaultColor}
            tooltipKey={tooltipTextProps.tooltipKey ?? ""}
            TypographyComponent={TypographyComponent}
            variant={tooltipTextProps.variant}
          >
            {children}
          </TooltipText>
        </Tooltip>
      ) : (
        <Markdown color={defaultColor}>{children}</Markdown>
      )}
    </Styled.HighlightsContainer>
  );
};
