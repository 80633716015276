import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";

import App from "./App";

const tagManagerArgs = { gtmId: "GTM-K5GCC9" };
TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);
