export const profile = {
  title: "Edit your profile",
  subtitle: "Keep your information up to date so we can provide you with the best experience",
  accountInformation: "Profile Information",
  email: "Email address",
  passwordCopy: "Password",
  changePassword: "Change your current password",
  personalInformation: "Personal Information",
  manageAccount: "Manage Account",
  address: "Residential address",
  phoneNumber: "Phone number",
  fullName: "Full Name",
  dob: "Date of birth",
  income: "Income",
  incomeRanges: [
    "$0 to $19,999",
    "$20,000 to $39,999",
    "$40,000 to $59,999",
    "$60,000 to $79,999",
    "$80,000 to $99,999",
    "$100,000 or more",
  ],
  updateEmail: {
    title: "Change your email address",
    subtitle: "Your current email is ",
    newEmail: "New email",
    confirmEmail: "Confirm email",
    disclaimer: "By clicking update email, you agree to the",
    and: " and ",
    termsOfUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    updateEmail: "UPDATE EMAIL",
    confirmationTitle: "Your email address has been changed",
    confirmationSubtitle: "You can now use your new email address to log in to your Borrowell account.",
    confirmationSubtitle2:
      "You'll receive an email from us to confirm that your email address has been changed.",
    sameError: "Please use a different email from the current one",
    matchError: "Your email doesn't match. Please try again",
    buttons: {
      done: "DONE",
      backToEditProfile: "BACK TO EDIT PROFILE",
    },
    error: {
      title: "Unable to change your email address",
      message: "Something went wrong or this email already exists. Please try again later.",
    },
  },
  updateAddress: {
    title: "Update your residential address",
    subtitle: "Change the address associated with your Borrowell account",
    streetAddress: "Street Address",
    streetAddress2: "Suite/Unit (Optional)",
    city: "City",
    province: "Province",
    postalCode: "Postal Code",
    updateAddress: "UPDATE ADDRESS",
    confirmationTitle: "Your residential address has been updated",
    confirmationSubtitle: "The address associated with your Borrowell account has been updated",
    buttons: {
      done: "DONE",
      backToEditProfile: "BACK TO EDIT PROFILE",
    },
    error: {
      title: "Unable to change your residential address",
      message: "Something went wrong or this address is invalid. Please try again later.",
    },
  },
  updatePhoneNumber: {
    title: "Update your phone number",
    subtitle: "Change the phone number associated with your Borrowell account",
    phoneNumber: "Phone Number",
    updatePhoneNumber: "UPDATE PHONE NUMBER",
    confirmationTitle: "Your phone number has been updated",
    confirmationSubtitle: "The phone number associated with your Borrowell account has been updated",
    buttons: {
      done: "DONE",
      backToEditProfile: "BACK TO EDIT PROFILE",
    },
    error: {
      title: "Unable to change your phone number",
      message: "Something went wrong or this phone number is invalid. Please try again later.",
    },
  },
  updateIncome: {
    title: "Update your income",
    subtitle: "Keeping this up to date will help us provide you with personalized recommendations",
    updateIncome: "UPDATE INCOME",
    confirmationTitle: "Your income has been updated",
    confirmationSubtitle:
      "Your income has been updated. Your recommendations will update the next time you log in.",
    backToEditProfile: "BACK TO EDIT PROFILE",
    error: {
      title: "Unable to change your income",
      message: "Something went wrong or this income is invalid. Please try again later.",
    },
  },
  updatePassword: {
    title: "Change your password",
    subtitle: "Choose a strong password and don't reuse it for other accounts",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    recoverPassword: "FORGOT PASSWORD",
    updatePassword: "UPDATE PASSWORD",
    confirmationTitle: "Your password has been changed",
    confirmationSubtitle:
      "Your new password is ready to use. You’ll receive an email from us to confirm that your password has changed",
    recoverTitle: "Password recovery email sent",
    recoverSubtitle:
      "A password recovery email has been sent to the email address associated with your Borrowell account",
    backToEditProfile: "BACK TO EDIT PROFILE",
    matchError: "Your passwords don't match. Please try again",
    invalidPassword: "Your password is incorrect. Please try again",
    invalidPassword2: "Your password is incorrect. Try resetting your password",
  },
  updateDob: {
    title: "Update your date of birth",
    contactSupport:
      "You’re unable to change your date of birth. To change these details, please **[contact support]()**",
  },
  updateFullName: {
    title: "Update your Full Name",
    contactSupport:
      "You’re unable to change your full name. To change these details, please **[contact support]()**",
    fieldLabel: "Full Name",
  },
};