import styled from "@emotion/native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { isWeb } from "../../../constants";
import { BoldText } from "../Default";
import { StyledDisplay1Props } from "./Display1.types";

export const StyledDisplay1Component = styled(BoldText)<StyledDisplay1Props>`
  font-size: ${styledValue(isWeb ? fontSize.xxxxl : fontSize.xxxl)};
  line-height: ${styledValue(spacing.xxl)};
  letter-spacing: ${styledValue(letterSpacing.xs)};
  color: ${({ color }) => color ?? COLORS.NEUTRAL.WARM["700"]};
`;
