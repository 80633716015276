import styled from "@emotion/styled";
import React, { useState } from "react";

import { COLORS, mq, spacing } from "@bwll/bw-styles";

import { Icon } from "../..";

interface ICardStyles {
  borderColor?: string;
  background?: string;
}

interface ICard extends ICardStyles {
  children: React.ReactNode;
  isCloseable?: boolean;
  dataCy?: string;
}

const ContentWrapper = styled.div<ICardStyles>(({ theme, background, borderColor }) =>
  mq({
    position: "relative",
    width: `calc(100% - ${spacing.xxs * 2}px)`,
    padding: `${spacing.xxs}px ${spacing.xxs}px ${spacing.s}px`,
    border: `1px solid ${borderColor ?? theme.COLORS.NEUTRAL.COOL["200"]} `,
    borderRadius: spacing.xxxs,
    background: background ?? "transparent",
    margin: `${spacing.s}px 0px`,

    button: {
      margin: [`${spacing.xxs}px auto`, `${spacing.xxs}px 0`, `${spacing.xxs}px 0`],
    },

    "& > *:not(button)": {
      textAlign: "left",
      margin: `${spacing.xxxs}px 0px`,
      "&:first-of-type": {
        marginTop: 0,
      },
    },

    "& > p": {
      color: COLORS.NEUTRAL.WARM["800"],
    },

    "& > *": {
      "&:last-child": {
        marginBottom: 0,
      },
    },
  }),
);

const CloseButton = styled.div<{ isCloseable?: boolean }>(({ isCloseable }) => ({
  display: isCloseable ? "block" : "none",
  color: COLORS.NEUTRAL.WARM["600"],
  cursor: "pointer",
  position: "absolute",
  right: spacing.s,
  top: spacing.xs,
}));

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Card = ({ children, borderColor, background, isCloseable, dataCy }: ICard) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) return <></>;

  return (
    <ContentWrapper
      borderColor={borderColor}
      background={background}
      data-cy={dataCy ? `${dataCy}-container` : "card-container"}
    >
      <CloseButton
        isCloseable={isCloseable}
        onClick={() => setIsOpen(false)}
        data-cy={dataCy ? `${dataCy}Close-button` : "cardClose-button"}
      >
        <Icon icon="close" size={30} />
      </CloseButton>
      {children}
    </ContentWrapper>
  );
};
