export const notificationPreferences = {
  title: "Notification Preferences",
  subtitle: "Choose how you want to receive communications from Borrowell.",
  options: {
    push: {
      title: "Push notifications",
      customize: {
        title: "Customize push notifications",
        content:
          "Choose what type of notifications you want to receive on your device. You can always change your mind if needed.",
      },
      promotional: {
        title: "Promotional alerts",
        content: "Alerts for exclusive offers, newly launched products and expiring offers.",
      },
    },
    email: {
      title: "Email notifications",
      creditScore: {
        title: "Credit score and report update frequency",
        content: "Choose when you want to be notified about changes to your credit score and report.",
      },
      offers: {
        title: "Promotional Offers",
        content:
          "Product recommendations and exclusive offers that are available for your latest credit profile.",
        link: "How to turn off promotional emails",
      },
    },
    learnMore: {
      title: "Learn More",
      push: "Learn more about Borrowell’s push notifications",
      email: "Learn more about Borrowell’s emails",
    },
    score: {
      title: "Score and report updates",
      subtitle: "Control how you want to be notified about changes to your credit score and report.",
    },
  },
  cardNotificationText: {
    pushAndEmail: "You’ll receive communications from Borrowell via push notifications and email.",
    email: "You’ll receive communications from Borrowell via email.",
    turnOffEmailLink: "Why can’t I turn off emails here?",
  },
  turnOffEmailsModal: {
    markdownContent:
      "## Communications that you receive from Borrowell \n \nBorrowell sends you two types of emails: promotional and emails about updates to your credit score and report. You can currently turn off promotion emails but you can’t turn off credit score & report emails. \n \n### Score and report updates \n \nWhen there are updates to your account, we inform you as a member so you’re always aware of changes to your credit profile. \n \n \nThe emails you receive about your credit score and report are transactional emails. Transactional or functional emails aren't required to have an unsubscribe link under Canada's Anti-Spam Legislation. \n \n \nYou can control how you want to be notified about changes to your credit profile **[__here__]()**. \n \n### Promotional emails \n \nPromotional emails are how we inform you about financial products that fit your credit profile. These financial products could help improve your credit or help you reach your financial goals. \n \nTo turn off these emails, go to the bottom of the email and click the unsubscribe link.",
  },
  notificationTypes: {
    all: "Promotional & Transactional",
    transactional: "Transactional",
    none: "Neither",
  },
  selectedNotificationStatus: "You selected to receive **{{type}}** notifications.",
  emailTypes: {
    weekly: "Email me weekly",
    changes: "Only email me when there’s a change",
  },
  selectedEmailStatus: "You selected **{{type}}.**",
};
