import { StyledComponent } from "@emotion/native";
import { TouchableOpacityProps } from "react-native";

import { PrimarySubtitleComponent, SecondarySubtitleComponent } from "./Subtitle.styles";
import { SUBTITLE_TYPE } from "./Subtitle.types";

export const getSubtitleComponentStyleByType = (
  type: SUBTITLE_TYPE,
): StyledComponent<TouchableOpacityProps> => {
  const subtitleComponentSchema = {
    [SUBTITLE_TYPE.PRIMARY]: PrimarySubtitleComponent,
    [SUBTITLE_TYPE.SECONDARY]: SecondarySubtitleComponent,
  };

  return subtitleComponentSchema[type as SUBTITLE_TYPE];
};
