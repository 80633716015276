import styled from "@emotion/native";

// import LinearGradient from "react-native-linear-gradient";
import { Image, Paragraph } from "@bwll/bw-components/next";
import { COLORS, borderRadius, shadow, spacing } from "@bwll/bw-styles";

export const PressableContainer = styled.View<{
  hasShadow: boolean;
  width?: number;
}>(({ hasShadow }) => ({
  backgroundColor: COLORS.WHITE,
  borderRadius: borderRadius.s,
  width: "100%",
  ...(hasShadow && shadow),
}));

export const ContentWrapper = styled.View<{ paddingTop?: boolean }>(({ paddingTop }) => ({
  padding: spacing.xs,
  paddingTop: paddingTop ? spacing.xs : spacing.none,
  paddingBottom: spacing.none,
  flexDirection: "row",
  borderRadius: borderRadius.s,
}));

export const HeaderImage = styled(Image)<{ height: number }>(({ height }) => ({
  width: "100%",
  height: height,
}));

export const PaddedParagraph = styled(Paragraph)({
  marginVertical: spacing.xxs,
});

export const TextWrapper = styled.View({
  flex: 1,
});

export const LinkWrapper = styled.View({
  paddingHorizontal: spacing.xs,
  paddingBottom: spacing.xxs,
});

export const ImageWrapper = styled.View({ paddingLeft: spacing.xxs });

export const CardImage = styled(Image)<{ height: number; width: number }>(({ height, width }) => ({
  height,
  width,
  aspectRatio: width / height,
}));
