import styled from "@emotion/native";
import { Animated, Pressable } from "react-native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Icon } from "../../atoms/Icon";
import { IconProps } from "../../atoms/Icon/Icon.types";
import { Label } from "../../atoms/Typography";

export const StyledExpandingTiles = styled.View`
  flex-direction: row;
  margin: ${styledValue(-2)};

  height: ${styledValue(72)};
`;

export const StyledExpandingTilesLabel = styled(Label)`
  font-size: ${styledValue(12)};
  line-height: ${styledValue(16)};
  letter-spacing: ${styledValue(0.4)};
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);
export const StyledExpandableTile = styled(AnimatedPressable)<{ isExpanded: boolean; gapPercent: number }>`
  align-items: center;
  flex-direction: ${({ isExpanded }) => (isExpanded ? "row" : "column")};
  padding-vertical: ${styledValue(spacing.xxs)};
  margin-right: ${({ gapPercent }) => `${gapPercent}%`};
  overflow: hidden;
  background-color: ${COLORS.NEUTRAL.COOL["050"]};
  border-radius: ${styledValue(8)};
`;

export const StyledExpandedTileIcon = (props: IconProps) =>
  Icon({
    ...props,
    size: 32,
    color: COLORS.PRIMARY["500"],
    style: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 64,
    },
  });

export const StyledExpandedTileMinimize = (props: Omit<IconProps, "icon">) =>
  Icon({
    ...props,
    icon: "minimize",
    size: 16,
    color: COLORS.PRIMARY["500"],
    style: {
      flex: "0 1 auto",
      alignSelf: "flex-start",
      marginRight: spacing.xxxs,
    },
  });

export const StyledExpandedTileDescription = styled(StyledExpandingTilesLabel)`
  flex: 1;
`;
