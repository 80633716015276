import styled from "@emotion/styled";
import facepaint from "facepaint";

import mq from "@bwll/bw-styles/breakpoints";

interface IProps {
  isOpen: boolean;
  customStyles?: facepaint.Arg;
}

export const ExpandIcon = styled.div<IProps>(({ isOpen, customStyles, theme }) =>
  mq({
    position: "relative",
    alignSelf: "center",
    transition: "transform 0.5s ease",
    width: "20px",
    height: "3px",
    backgroundColor: theme.COLORS.PURPLE["700"],
    transform: isOpen ? "rotate(180deg)" : "",
    borderRadius: "1px",
    "&:before": {
      display: "block",
      content: '""',
      width: "20px",
      height: "3px",
      transform: isOpen ? "rotate(180deg)" : "rotate(90deg)",
      transition: "all 0.5s ease",
      backgroundColor: theme.COLORS.PURPLE["700"],
      borderRadius: "1px",
    },
    ...customStyles,
  }),
);
