import styled from "@emotion/native";

import { fonts } from "@bwll/bw-styles";

import { styledOS } from "../../../utils";

export const RegularText = styled.Text`
  font-family: "${styledOS([`${fonts.lato.regular}, Lato`, fonts.lato.regular])}";
`;

export const SemiBoldText = styled.Text`
  font-family: "${styledOS([`${fonts.lato.semibold}, Lato`, fonts.lato.semibold])}";
  font-weight: ${styledOS(["600", ""])};
`;

export const BoldText = styled.Text`
  font-family: "${styledOS([`${fonts.lato.bold}, Lato`, fonts.lato.bold])}";
  font-weight: ${styledOS(["700", ""])};
`;
