export const confirmRentTransaction = {
  "title": "Confirmez votre loyer",
  "message": "<br/>Vous avez **{{count}} jour** pour confirmer votre loyer.<br/>Si vous avez déjà contacté notre équipe, ne vous inquiétez pas! Nous veillerons à ce que votre loyer soit correctement déclaré à Equifax Canada.",
  "message_plural": "<br/>Vous avez **{{count}} jours** pour confirmer votre loyer.<br/>Si vous avez déjà contacté notre équipe, ne vous inquiétez pas! Nous veillerons à ce que votre loyer soit correctement déclaré à Equifax Canada.",
  "messageLastDay": "<br/>Vous avez **jusqu'à aujourd'hui** pour confirmer votre loyer.<br/> Si vous avez déjà contacté notre équipe, ne vous inquiétez pas! Nous veillerons à ce que votre loyer soit correctement déclaré à Equifax Canada.",
  "messageMobile": "Vous avez **{{count}} jour** pour confirmer votre loyer.",
  "messageMobile_plural": "Vous avez **{{count}} jours** pour confirmer votre loyer.",
  "messageMobileLastDay": "Vous avez **jusqu'à aujourd'hui** pour confirmer votre loyer.",
  "messageMobileReported": "Si vous avez déjà contacté notre équipe, ne vous inquiétez pas! Nous veillerons à ce que votre loyer soit correctement déclaré à Equifax Canada.",
  "buttonText": "CONFIRMER LE LOYER"
}
