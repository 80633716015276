import React from "react";

import { StyledSubheading1 } from "./Subheading1.styles";
import { Subheading1Props } from "./Subheading1.types";

/**
 * Component for showing the Subheading1.
 *
 * @component
 * @example
 * return (
 *   <Subheading1 color={COLORS.NEUTRAL.WARM["700"]} addMargin>Hello World</Subheading1>
 * )
 */
export const Subheading1 = ({
  accessibilityLabel,
  style,
  testID,
  children,
  color,
  onPress,
}: Subheading1Props) => {
  return (
    <StyledSubheading1
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
      color={color}
      onPress={onPress}
    >
      {children}
    </StyledSubheading1>
  );
};
