import styled from "@emotion/native";

import { Display2, Heading1 } from "@bwll/bw-components/next/atoms/Typography";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const CreditCardsCategoryHeaderContainer = styled.View`
  align-items: flex-start;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const IconContainer = styled.View<{ isMobile: boolean }>`
  margin-right: ${({ isMobile }) => (isMobile ? styledValue(spacing.xxxs) : styledValue(spacing.xxs))};
`;

export const TitleLarge = styled(Display2)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const TitleDefault = styled(Heading1)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const TitleDefaultMobile = styled(Heading1)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-size: ${styledValue(fontSize.s)};
`;
