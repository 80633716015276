import styled from "@emotion/native";

import { Label } from "@bwll/bw-components/next/atoms/Typography";
import { COLORS, borderWidth, fontSize, spacing } from "@bwll/bw-styles";

const RADIUS = spacing.s / 2;

export const StyledRadioComponent = styled.View(() => ({
  flex: 1,
}));

export const StyledRadioOptionComponent = styled.TouchableOpacity(() => ({
  flexDirection: "row",
  borderBottomWidth: 1,
  borderColor: COLORS.NEUTRAL.WARM[100],
  paddingVertical: spacing.xs,
}));

export const StyledRadioCircleComponent = styled.View(({ selected }: { selected: boolean }) => ({
  borderWidth: borderWidth.small * 2,
  borderRadius: spacing.l,
  height: spacing.s,
  width: spacing.s,
  backgroundColor: COLORS.WHITE,
  borderColor: selected ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.WARM[400],
  marginRight: spacing.xxs,
  alignSelf: "center",
  justifyContent: "center",
}));

export const StyledRadioInnerCircleComponent = styled.View(() => ({
  borderWidth: borderWidth.small * 2,
  borderRadius: spacing.l,
  height: RADIUS,
  width: RADIUS,
  backgroundColor: COLORS.PRIMARY.DEFAULT,
  borderColor: COLORS.PRIMARY.DEFAULT,
  alignSelf: "center",
}));

export const StyledRadioLabel = styled(Label)(() => ({
  fontSize: fontSize.l,
}));
