import styled from "@emotion/native";
import { Picker, PickerProps } from "@react-native-picker/picker";

import { Caption, Label } from "@bwll/bw-components/next/atoms/Typography";
import { COLORS, borderRadius, borderWidth, fontSize, fonts, spacing } from "@bwll/bw-styles";

import { isWeb } from "../../../constants";

export const StyledDropdownContainer = styled.View(() => ({
  flex: 1,
}));

const StringPicker = (args: PickerProps<string>) => <Picker<string> {...args} />;

export const StyledDropdownComponent = styled(StringPicker, {
  // Picker writes all props to HTML, causing a warning
  shouldForwardProp: (prop) => prop !== "errored",
})(({ disabled, errored }: { disabled: boolean; errored: boolean }) => ({
  padding: spacing.xxs,
  minHeight: spacing.xl + spacing.xs,
  fontFamily: isWeb ? `${fonts.lato.regular}, Lato` : fonts.lato.regular,
  fontSize: fontSize.xs,
  borderWidth: borderWidth.small,
  borderRadius: borderRadius.s,
  borderColor: errored ? COLORS.RED[700] : COLORS.NEUTRAL.COOL[600],
  backgroundColor: disabled ? COLORS.NEUTRAL.COOL[200] : COLORS.WHITE,
  marginVertical: spacing.xxxs,
  color: disabled ? COLORS.NEUTRAL.COOL[600] : undefined,
}));

export const StyledLabelComponent = styled(Label)(({ errored }: { errored: boolean }) => ({
  color: errored ? COLORS.RED[700] : COLORS.NEUTRAL.WARM["700"],
}));

export const StyledCaptionComponent = styled(Caption)(
  ({ errored, color }: { errored: boolean; color?: string }) => ({
    color: errored ? COLORS.RED[700] : color ?? COLORS.NEUTRAL.WARM["700"],
  }),
);
