export const editAddressPage = {
  "continueButton": "CONFIRMER LA NOUVELLE ADRESSE",
  "title": "Modifier l'adresse de location",
  "subTitle": "Mettez à jour les informations relatives à votre loyer si vous avez récemment déménagé. La mise à jour de vos données locatives ici n'aura pas d'incidence sur votre compte de crédit.",
  "street": "Adresse (rue)",
  "streetCaption": "Commencez à taper votre adresse pour le remplissage automatique",
  "unitApt": "No d'unité/app.",
  "city": "Ville",
  "province": "Province",
  "postalCode": "Code postal"
}
