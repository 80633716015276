export const successfulOnboardingPage = {
  button: "DONE",
  title: "You’re one step closer to building your credit history!",
  whatsNext: {
    title: "What’s next?",
    content: [
      "You’ll have to login every month to confirm your rent payment.",
      "Continue to make your rent payments on time and watch your credit history grow.",
    ],
    contentBankFlow: [
      "Your rent payment information will be sent to Equifax Canada. Once they receive the information, it can take up to 4 weeks for the tradeline “BRWL SELF-RPT RENT” to appear on your credit report.",
    ],
    contentManualFlow: [
      "Once your proof of payment is approved, your rent payment information will be sent to Equifax Canada. Once they receive the information, it can take up to 4 weeks for the tradeline “BRWL SELF - RPT RENT” to appear on your credit report.",
    ],
  },
};
