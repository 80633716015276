import { useTheme } from "@emotion/react";

import { Button, Icon, Markdown } from "@bwll/bw-components";

import { BANNER_ICONS, BANNER_TYPE, IBannerProps } from "./banner";
import {
  Container,
  LeftIcon,
  NotificationContent,
  NotificationTitle,
  notificationMessageStyles,
} from "./styles";

export const BannerNotification = ({ type, bannerNotification, dataCy, className }: IBannerProps) => {
  const theme = useTheme();

  return (
    <Container {...{ type, className }}>
      <LeftIcon type={type}>
        <Icon icon={BANNER_ICONS[type as BANNER_TYPE]} size={24} />
      </LeftIcon>

      <NotificationContent data-cy={dataCy || "bannerContent"}>
        <NotificationTitle data-cy={dataCy ? `${dataCy}Title` : "bannerTitle"}>
          {bannerNotification?.title}
        </NotificationTitle>

        <Markdown
          customStyles={notificationMessageStyles(theme)}
          data-cy={dataCy ? `${dataCy}Message` : "bannerMessage"}
          customProps={bannerNotification?.url ? { link: { href: bannerNotification.url } } : undefined}
        >
          {bannerNotification?.message || ""}
        </Markdown>

        <Button
          data-cy={dataCy ? `${dataCy}Button` : "bannerButton"}
          onClick={bannerNotification?.buttonAction}
        >
          {bannerNotification?.buttonText}
        </Button>
      </NotificationContent>
    </Container>
  );
};
