import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import {
  BaseResponse,
  IApiHookOptions,
  ProofSubmissionStatus,
  QUERY_KEYS,
  RentProofSubmission,
} from "@bwll/bw-types";
import { generateApiHeaders, queryBehavior } from "@bwll/bw-utils";
import { generateQueryKey } from "@bwll/bw-utils";

const fetchRentProofSubmission = async (
  accessToken: string,
  baseUrl: string,
  rentPeriod?: string,
  proofSubmissionStatus?: ProofSubmissionStatus,
) => {
  const response = await axios.get<BaseResponse<RentProofSubmission[]>>(
    `${baseUrl}/api/rentProofSubmissions?rentPeriod=${rentPeriod}&proofSubmissionStatus=${
      proofSubmissionStatus != null
        ? ProofSubmissionStatus[proofSubmissionStatus as ProofSubmissionStatus]
        : ""
    }`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data.data as RentProofSubmission[];
};

export interface UseRentProofSubmisionProps {
  rentPeriod?: string;
  proofSubmissionStatus?: ProofSubmissionStatus;
  customApiOptions?: IApiHookOptions<RentProofSubmission[]>;
}

export const useRentProofSubmission = ({
  rentPeriod = "",
  proofSubmissionStatus,
  customApiOptions,
}: UseRentProofSubmisionProps) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;
  const queryKey =
    proofSubmissionStatus !== undefined
      ? generateQueryKey(QUERY_KEYS.GET_RENT_PROOF_SUBMISSION, accessToken, {
          proofSubmissionStatus: proofSubmissionStatus,
        })
      : generateQueryKey(QUERY_KEYS.GET_RENT_PROOF_SUBMISSION, accessToken);

  return useQuery(
    queryKey,
    () =>
      fetchRentProofSubmission(
        accessToken,
        envConfigs.API_RENT_REPORTING_URL,
        rentPeriod,
        proofSubmissionStatus,
      ),
    {
      ...queryBehavior({
        expiresIn,
        ...customApiOptions,
        enabled: !!accessToken && (customApiOptions?.enabled ?? true),
      }),
    },
  );
};
