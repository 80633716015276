import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { PaddingProps } from "./Padding.types";

const styledValueSafe = (value?: number): string => styledValue(value ?? spacing.xs);

export const StyledPadding = styled.View<PaddingProps>`
  padding: ${({ all }) => styledValueSafe(all)};
  ${({ left, horizontal }) =>
    (horizontal !== undefined || left !== undefined) &&
    `padding-left: ${styledValueSafe(left ?? horizontal)}`};
  ${({ right, horizontal }) =>
    (horizontal !== undefined || right !== undefined) &&
    `padding-right: ${styledValueSafe(right ?? horizontal)}`};
  ${({ top, vertical }) =>
    (top !== undefined || vertical !== undefined) && `padding-top: ${styledValueSafe(top ?? vertical)}`};
  ${({ bottom, vertical }) =>
    (bottom !== undefined || vertical !== undefined) &&
    `padding-bottom: ${styledValueSafe(bottom ?? vertical)}`};
`;
