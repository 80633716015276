import { useCallback } from "react";

import {
  IMPRESSION_EVENT_NAMES,
  ON_LINK_CLICK_EVENT_NAMES,
  TButtonAnalyticsAttributes,
  TFeatureAnalyticsAttributes,
  TLinkAnalyticsAttributes,
  TSharedAnalyticsAttributes,
} from "./analyticsContext.types";
import { TImpressionAnalyticsAttributes } from "./featuresAttributes";

/**
 *
 * All shared Analytics/Segment events should be placed on this hook.
 * @returns
 */

/**
 * List of available events. This should only contain core event names, not feature related.
 */
const enum ANALYTICS_EVENT_NAMES {
  BUTTON_CLICK = "Button Click",
  // COMING SOON: View, Navigate, On Error | Future Events
}

export const useSharedAnalytics = ({ commonAttributes, trackEvent }: TSharedAnalyticsAttributes) => {
  const onButtonClick = useCallback(
    (eventAttributes: TButtonAnalyticsAttributes & TFeatureAnalyticsAttributes) =>
      trackEvent(
        `${ANALYTICS_EVENT_NAMES.BUTTON_CLICK}${
          eventAttributes?.flowName ? `: ${eventAttributes.flowName}` : ""
        }`,
        {
          ...commonAttributes,
          ...eventAttributes,
        },
      ),
    [commonAttributes, trackEvent],
  );

  const impressionView = useCallback(
    <TCustomComponentAttributes = TImpressionAnalyticsAttributes>({
      eventName = IMPRESSION_EVENT_NAMES.IMPRESSION_VIEW,
      eventAttributes,
    }: {
      eventName?: IMPRESSION_EVENT_NAMES;
      eventAttributes: TFeatureAnalyticsAttributes<TCustomComponentAttributes>;
    }) =>
      trackEvent(`${eventName}${eventAttributes?.flowName ? `: ${eventAttributes.flowName}` : ""}`, {
        ...commonAttributes,
        ...eventAttributes,
      }),
    [commonAttributes, trackEvent],
  );

  const onLinkClick = useCallback(
    <TCustomComponentAttributes = TLinkAnalyticsAttributes>({
      eventName = ON_LINK_CLICK_EVENT_NAMES.LINK_CLICK,
      eventAttributes,
    }: {
      eventName?: ON_LINK_CLICK_EVENT_NAMES;
      eventAttributes: TCustomComponentAttributes & TFeatureAnalyticsAttributes;
    }) =>
      trackEvent(`${eventName}${eventAttributes?.flowName ? `: ${eventAttributes.flowName}` : ""}`, {
        ...commonAttributes,
        ...eventAttributes,
      }),
    [commonAttributes, trackEvent],
  );

  return { onButtonClick, impressionView, onLinkClick };
};
