import styled from "@emotion/native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { RegularText } from "../Default";
import { StyledLabelProps } from "./Label.types";

export const StyledLabel = styled(RegularText)<StyledLabelProps>`
  font-size: ${styledValue(fontSize.xxs)};
  line-height: ${styledValue(spacing.xs)};
  letter-spacing: ${styledValue(letterSpacing.l)};
  color: ${({ color }) => color ?? COLORS.NEUTRAL.WARM["700"]};
`;
