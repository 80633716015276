import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ChipWrapper = styled.Pressable<{ backgroundColor?: string }>`
  flex-direction: row;
  border-radius: ${styledValue(spacing.xs)};
  background: ${({ backgroundColor }) => backgroundColor ?? COLORS.NEUTRAL.COOL["100"]};
  padding-horizontal: ${styledValue(spacing.xxs)};
  padding-vertical: ${styledValue(spacing.xxxs)};
  align-self: flex-start;
  align-items: center;
  justify-content: center;
`;

export const ChipIconWrapper = styled.View`
  margin-right: ${styledValue(spacing.xxxs)};
`;
