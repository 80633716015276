import styled from "@emotion/styled";

import { mq } from "@bwll/bw-styles";

import { RadioInput, RadioInputOption } from "./RadioInput";

export interface RadioInputGroupProps {
  options: RadioInputOption[];
  selectedOption?: RadioInputOption;
  groupName: string;
  id: string;
  onChange: (option: RadioInputOption) => void;
  errorHint?: string;
  hasError?: boolean;
  shouldInitDirty?: boolean;
  label: string;
  labelIsScreenReaderonly?: boolean;
  isRequired?: boolean;
  caption?: string;
  withBorder?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
`;

const Label = styled.label`
  ${({ theme }) =>
    mq({
      display: "block",
      letterSpacing: "0.4px",
      marginTop: "5px",
      marginBottom: "20px",
      color: theme.COLORS.NEUTRAL.WARM["700"],
    })}
`;

export const ErrorHint = styled.span`
  ${({ theme }) =>
    mq({
      display: "block",
      letterSpacing: "0.4px",
      marginTop: "5px",
      color: theme.COLORS.RED["800"],
    })}
`;
const Caption = styled.span`
  ${({ theme }) =>
    mq({
      display: "block",
      letterSpacing: "0.4px",
      marginTop: "5px",
      color: theme.COLORS.NEUTRAL.WARM["700"],
    })}
`;

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const RadioInputGroup = ({
  options,
  selectedOption,
  groupName,
  label,
  labelIsScreenReaderonly,
  id,
  hasError,
  errorHint,
  isRequired,
  caption,
  onChange,
  withBorder = false,
}: RadioInputGroupProps) => {
  return (
    <Wrapper id={id} role="radiogroup" aria-labelledby={`${id}-label`} data-testid={id} data-cy={id}>
      {!labelIsScreenReaderonly && (
        <Label htmlFor={id} id={`${id}-label`}>
          {label}
          {isRequired && <span> *</span>}
        </Label>
      )}
      {options.map((option) => {
        return (
          <RadioInput
            key={option.value}
            option={option}
            name={groupName}
            onChange={(opt) => {
              onChange(opt);
            }}
            idPrefix={id}
            selected={option.value === selectedOption?.value}
            withBorder={withBorder}
          />
        );
      })}
      {!!caption && <Caption id={`${id}-caption`}>{caption}</Caption>}
      {!!hasError && !!errorHint && (
        <ErrorHint id={`${id}-error`} aria-live="assertive">
          {errorHint}
        </ErrorHint>
      )}
    </Wrapper>
  );
};
