import React, { createContext, useCallback, useMemo, useState } from "react";

import { QuickApplyPreferredLanguage } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

export interface IQuickApplyAddressFormFields {
  streetAddress: string;
  streetAddress2: string;
  city: string;
  regionId: string;
  postalCode: string;
}

export interface IQuickApplyContactFormFields {
  phoneNumber: string;
  email: string;
}

export interface IQuickApplyProfileFormFields {
  birthdate: Date;
  firstName: string;
  lastName: string;
  language: QuickApplyPreferredLanguage;
}

export interface IQuickApplyCustomFormFields {
  [key: string]: string | number | boolean;
}

interface IAddressFormContext {
  fields: IQuickApplyAddressFormFields;
  updateAddressField: (key: keyof IQuickApplyAddressFormFields, value: string) => void;
  updateAddress: (value: IQuickApplyAddressFormFields) => void;
}

interface IContactFormContext {
  fields: IQuickApplyContactFormFields;
  updateContactField: (key: keyof IQuickApplyContactFormFields, value: string) => void;
  updateContact: (value: IQuickApplyContactFormFields) => void;
}

interface IProfileFormContext {
  fields: IQuickApplyProfileFormFields;
  updateProfileField: (key: keyof IQuickApplyProfileFormFields, value: string | Date) => void;
  updateProfile: (value: IQuickApplyProfileFormFields) => void;
}

// Generic Form Attributes Context
interface ICustomFormContext {
  fields: IQuickApplyCustomFormFields;
  updateCustomField: (key: string, value: string) => void;
  updateCustomFields: (value: IQuickApplyCustomFormFields) => void;
}

export interface IQuickApplyContextFields {
  address: IQuickApplyAddressFormFields;
  contact: IQuickApplyContactFormFields;
  profile: IQuickApplyProfileFormFields;
  custom?: IQuickApplyCustomFormFields;
}

export interface IQuickApplyContext {
  address: IAddressFormContext;
  contact: IContactFormContext;
  profile: IProfileFormContext;
  custom?: ICustomFormContext;
}

const QuickApplyContext = createContext<IQuickApplyContext>({} as IQuickApplyContext);

export type QuickApplyInitialState = {
  address: IQuickApplyAddressFormFields;
  contact: IQuickApplyContactFormFields;
  profile: IQuickApplyProfileFormFields;
  customFields?: IQuickApplyCustomFormFields;
};

interface IQuickApplyProvider {
  children?: React.ReactNode;
  initialState: QuickApplyInitialState;
}

export const QuickApplyProvider = ({
  children,
  initialState: { address, contact, profile, customFields },
}: IQuickApplyProvider) => {
  const [addressFormFields, setAddressFormFields] = useState<IQuickApplyAddressFormFields>(address);
  const [contactFormFields, setContactFormFields] = useState<IQuickApplyContactFormFields>(contact);
  const [profileFormFields, setProfileFormFields] = useState<IQuickApplyProfileFormFields>(profile);
  const [customFormFields, setCustomFormFields] = useState<IQuickApplyCustomFormFields>(customFields ?? {});

  const updateAddressField = useCallback((key: string, value: string) => {
    setAddressFormFields((curr) => ({ ...curr, [key]: value }));
  }, []);

  const addressValue: IAddressFormContext = useMemo(
    () => ({
      fields: addressFormFields,
      updateAddressField,
      updateAddress: (value) => {
        setAddressFormFields(value);
      },
    }),
    [addressFormFields, updateAddressField],
  );

  const updateContactField = useCallback((key: string, value: string) => {
    setContactFormFields((curr) => ({ ...curr, [key]: value }));
  }, []);

  const contactValue: IContactFormContext = useMemo(
    () => ({
      fields: contactFormFields,
      updateContactField,
      updateContact: (value) => {
        setContactFormFields(value);
      },
    }),
    [contactFormFields, updateContactField],
  );

  const updateProfileField = useCallback((key: string, value: string | Date) => {
    setProfileFormFields((curr) => ({ ...curr, [key]: value }));
  }, []);

  const profileValue: IProfileFormContext = useMemo(
    () => ({
      fields: profileFormFields,
      updateProfileField,
      updateProfile: (value) => {
        setProfileFormFields(value);
      },
    }),
    [profileFormFields, updateProfileField],
  );

  const updateCustomField = useCallback((key: string, value: string | number | boolean) => {
    setCustomFormFields((curr) => ({ ...curr, [key]: value }));
  }, []);

  const customValue: ICustomFormContext = useMemo(
    () => ({
      fields: customFormFields,
      updateCustomField,
      updateCustomFields: (value) => {
        setCustomFormFields(value);
      },
    }),
    [customFormFields, updateCustomField],
  );

  const formValue: IQuickApplyContext = useMemo(
    () => ({
      address: addressValue,
      contact: contactValue,
      profile: profileValue,
      custom: customValue,
    }),
    [addressValue, contactValue, profileValue, customValue],
  );

  return <QuickApplyContext.Provider value={formValue}>{children}</QuickApplyContext.Provider>;
};

export const useQuickApplyContext = () => useContextWrapper(QuickApplyContext, ContextName.QuickApplyContext);
