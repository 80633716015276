import React from "react";

import { StyledCaption } from "./Caption.styles";
import { CaptionProps } from "./Caption.types";

export const Caption = ({ children, style, accessibilityLabel, color, testID, onPress }: CaptionProps) => {
  return (
    <StyledCaption
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
      color={color}
      onPress={onPress}
    >
      {children}
    </StyledCaption>
  );
};
