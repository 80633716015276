import styled from "@emotion/styled";
import { ReactNode } from "react";

import { mq } from "@bwll/bw-styles";

type SupportedHeaders = "h1" | "h2" | "h3" | "h4" | "h5";

export interface HeadingProps {
  children: ReactNode;
  as?: SupportedHeaders;
  styledAs?: SupportedHeaders;
}

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Heading = ({ as = "h1", styledAs, children }: HeadingProps) => {
  const Component = styled[as ?? "h1"](({ theme }) => {
    return mq({ ...theme[styledAs ?? as] });
  });
  return <Component>{children}</Component>;
};
