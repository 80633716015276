export const emailNotifications = {
  title: "Email communications that you receive from Borrowell",
  description: "Borrowell sends you two types of emails: promotional and transactional (such as emails about updates to your credit score and report, or about products you have signed up for). You can currently turn off promotional emails but you can’t turn off transactional emails.",
  transactionalEmails: {
    title: "Transactional emails",
    description1: "When there are updates to your account or products, we inform you as a member so you’re always aware of changes to your credit profile and important updates.",
    description2: "The emails you receive about your credit score and report are transactional emails. Transactional emails aren’t required to have an unsubscribe link under Canada’s Anti-Spam Legislation.",
    description3: "You can control how you want to be notified about changes to your credit profile [here]().",
  },
  promotionalEmails: {
    title: "Promotional emails",
    description1: "Promotional emails are how we inform you about financial products that fit your credit profile. These financial products could help you build your credit or help you reach your financial goals.",
    description2: "To turn off these emails, go to the bottom of the email and click the unsubscribe link.",
  },
};

