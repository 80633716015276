// @ts-nocheck

/* eslint-disable @typescript-eslint/ban-ts-ignore */
import styled from "@emotion/styled";
import i18next from "i18next";

import { Icon } from "@bwll/bw-components/next";
import { IconNames } from "@bwll/bw-components/next/atoms/Icon/Icon.types";
import { COLORS, spacing } from "@bwll/bw-styles";
import { PaymentEntry, PaymentProfile, months } from "@bwll/bw-types";

const CalendarRow = styled.div<{ addMargin?: boolean }>(({ addMargin }) => ({
  display: "flex",
  margin: "16px 0px",
  marginLeft: addMargin ? "calc(100% / 12)" : 0,
  fontSize: 12,
  "&:first-of-type": {
    margin: `0px 0px 0px ${addMargin ? "calc(100% / 12)" : 0}`,
  },
  "&:last-of-type": {
    "&>div:last-of-type": {
      paddingBottom: spacing.xs,
      borderBottom: `1px solid ${COLORS.NEUTRAL.COOL["200"]}`,
    },
  },
}));

const CaptionRow = styled.div<{ addMargin?: boolean }>({
  display: "flex",
  flexWrap: "wrap",
  marginLeft: "calc(100% / 12)",

  "&>div": {
    display: "flex",
    alignItems: "center",
    marginRight: spacing.s,
    span: {
      fontSize: 12,
      marginLeft: spacing.xxxs,
    },
  },
});

const IconsRow = styled.div({
  width: "100%",
  display: "flex",
});

const CalendarHeading = styled.div({
  width: "calc(100% / 12)",
  height: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const CalendarCell = styled.div({
  width: "calc(100% / 12)",
  height: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

interface ReportingCalendarProps {
  paymentProfileList: PaymentProfile[];
}

export const ReportingCalendar = ({ paymentProfileList = [] }: ReportingCalendarProps) => {
  const monthStatus = (months: PaymentEntry[], index: number) => {
    const status = months.filter((pMonth) => pMonth.month === index)[0];
    return status?.paymentDue ?? 4;
  };

  const icons = [
    { icon: "no_info_small", color: COLORS.NEUTRAL.COOL["500"] },
    { icon: "success", color: COLORS.TURQUOISE["700"] },
    { icon: "close", color: COLORS.RED["700"] },
    { icon: "deferred", color: COLORS.NEUTRAL.WARM["700"] },
    {},
  ];

  return (
    <div>
      <div>
        <div>
          <CalendarRow addMargin>
            {months.map((month) => (
              <CalendarCell key={month}>
                <span>{month[0].toUpperCase()}</span>
              </CalendarCell>
            ))}
          </CalendarRow>
          {paymentProfileList.map((profile) => (
            <CalendarRow key={profile.year}>
              <CalendarHeading>{`'${profile.year - 2000}`}</CalendarHeading>
              <IconsRow>
                {months.map((month, index) => {
                  const { icon, color } = icons[monthStatus(profile.months, index + 1)];
                  return (
                    <CalendarCell key={`profile-${profile.year}-${month}`}>
                      {icon && <Icon icon={icon as IconNames} size={16} color={color} />}
                    </CalendarCell>
                  );
                })}
              </IconsRow>
            </CalendarRow>
          ))}
        </div>
        <CaptionRow>
          <div>
            <Icon icon="success" size={16} color={COLORS.TURQUOISE["700"]} />
            <span>{i18next.t("rentReporting:servicingDashboard:paymentProfile:paid")}</span>
          </div>
          <div>
            <Icon icon="close" size={16} color={COLORS.RED["700"]} />
            <span>{i18next.t("rentReporting:servicingDashboard:paymentProfile:missed")}</span>
          </div>
          <div>
            <Icon icon="no_info_small" size={12} color={COLORS.NEUTRAL.COOL["500"]} />
            <span>{i18next.t("rentReporting:servicingDashboard:paymentProfile:noInfo")}</span>
          </div>
          <div>
            <Icon icon="deferred" size={12} color={COLORS.NEUTRAL.WARM["700"]} />
            <span>{i18next.t("rentReporting:servicingDashboard:paymentProfile:deferred")}</span>
          </div>
        </CaptionRow>
      </div>
    </div>
  );
};
