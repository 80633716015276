import React, { PropsWithChildren, createContext, useCallback, useMemo, useState } from "react";

import { BankTransaction } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

export type SelectedTransactionsMap = {
  [key: string]: BankTransaction;
};

export type RentTransactionsContextType = {
  transactionsSource: BankTransaction[];
  selectedTransactions: SelectedTransactionsMap;
  selectedTransactionsList: BankTransaction[];
  totalAmount: number;
  addTransaction: (id: string) => void;
  removeTransaction: (id: string) => void;
  replaceTransaction: (id: string) => void;
  selectMultipleTransactions: (ids: string[]) => void;
  resetSelectedTransactions: () => void;
  setTransactionsSource: (transactions: BankTransaction[]) => void;
};

type ProviderProps = Record<string, unknown>;

const RentTransactionsContext = createContext<RentTransactionsContextType>({} as RentTransactionsContextType);

export const useRentTransactionsContext = () =>
  useContextWrapper<RentTransactionsContextType>(
    RentTransactionsContext,
    ContextName.RentTransactionsContext,
  );

export const RentTransactionsProvider = ({ children }: PropsWithChildren<ProviderProps>) => {
  const [transactionsSource, setTransactionsSource] = useState<BankTransaction[]>([]);

  /**
   * The map for faster access without iterations
   */
  const [selectedTransactions, setSelectedTransactions] = useState<SelectedTransactionsMap>({});

  const transactionsMap = useMemo(() => {
    const transactionsList = transactionsSource;
    const allTransactions = transactionsList.reduce((map, transaction) => {
      map[transaction.guid] = transaction;

      return map;
    }, {} as SelectedTransactionsMap);

    return allTransactions;
  }, [transactionsSource]);

  /**
   * Extends the map of transactions (split payment usage)
   */
  const addTransaction = useCallback(
    (id: string) => {
      setSelectedTransactions((selectedIds) => ({ ...selectedIds, [id]: transactionsMap[id] }));
    },
    [transactionsMap],
  );

  const removeTransaction = useCallback((id: string) => {
    setSelectedTransactions((selectedIds) => {
      const updatedTransactionsMap = { ...selectedIds };
      delete updatedTransactionsMap[id];

      return updatedTransactionsMap;
    });
  }, []);

  /**
   * Override the map of transaction (single transaction selection).
   * Should be removed when the single transaction flow is not supported.
   */
  const replaceTransaction = useCallback(
    (id: string) => {
      setSelectedTransactions({ [id]: transactionsMap[id] });
    },
    [transactionsMap],
  );

  const selectMultipleTransactions = useCallback(
    (ids: string[]) => {
      const selectedMap = ids.reduce((map, id) => {
        if (transactionsMap[id]) {
          map[id] = transactionsMap[id];
        }

        return map;
      }, {} as SelectedTransactionsMap);

      setSelectedTransactions(selectedMap);
    },
    [transactionsMap],
  );

  const resetSelectedTransactions = useCallback(() => {
    setSelectedTransactions({});
  }, []);

  const selectedTransactionsList = useMemo(() => Object.values(selectedTransactions), [selectedTransactions]);

  const totalAmount = useMemo(
    () => selectedTransactionsList.reduce((sum, transaction) => sum + transaction?.amount ?? 0, 0),
    [selectedTransactionsList],
  );

  const value = useMemo(
    () => ({
      transactionsSource,
      selectedTransactions,
      selectedTransactionsList,
      totalAmount,
      addTransaction,
      removeTransaction,
      replaceTransaction,
      selectMultipleTransactions,
      resetSelectedTransactions,
      setTransactionsSource,
    }),
    [
      transactionsSource,
      totalAmount,
      selectedTransactions,
      selectedTransactionsList,
      addTransaction,
      removeTransaction,
      replaceTransaction,
      selectMultipleTransactions,
      resetSelectedTransactions,
    ],
  );

  return <RentTransactionsContext.Provider value={value}>{children}</RentTransactionsContext.Provider>;
};
