import styled from "@emotion/native";

import { COLORS } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FullScreenScreenContainer = styled.View`
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.NEUTRAL.WARM["700"]};
  justify-content: center;
  align-items: stretch;
  pointer-events: auto;
`;

export const ContentContainer = styled.View<{ screenHeight: number }>`
  width: 100%;
  height: 100%;
  padding-top: ${({ screenHeight }) => styledValue(screenHeight * 0.2)};
  padding-horizontal: ${styledValue(10)};
  align-items: center;
`;

export const CenteredImage = styled.Image`
  width: 100%;
  max-width: ${styledValue(200)};
  max-height: 60vh;
  aspect-ratio: 2;
`;
