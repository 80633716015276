import styled from "@emotion/native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { isWeb } from "../../../constants";
import { BoldText } from "../Default";
import { StyledHeading2Props } from "./Heading2.types";

export const StyledHeading2 = styled(BoldText)<StyledHeading2Props>`
  font-size: ${styledValue(isWeb ? fontSize.l : fontSize.m)};
  line-height: ${styledValue(isWeb ? spacing.xl : spacing.m)};
  letter-spacing: ${styledValue(letterSpacing.s)};
  color: ${({ color }) => color ?? COLORS.NEUTRAL.WARM["700"]};
`;
