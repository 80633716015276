export const tooltips = {
  dashboardTitle: "Get personalized product recommendations based on your profile.",
  sectionTrades:
    "Here you'll find any open or closed accounts that appear on your credit report such as credit cards, instalment loans, mortgages and mobile phone accounts.",
  sectionInquiries:
    "Credit inquiries are requests to check your credit. “Hard inquiries” occur when a potential lender is reviewing your credit because you’ve applied for credit with them, and may affect your credit score. “Soft inquiries” occur when you’re checking your own credit file and/or score (such as checking your score with Borrowell), credit checks made by businesses to offer you a quote, or inquiries made by businesses where you already have an account. “Soft inquiries” do not affect your credit score.",
  sectionCollections:
    "Any accounts that have been sent to collections, whether the balance(s) have been paid or unpaid. Be careful – when an account gets sent to collections it can have a big impact on your credit score.",
  sectionLegal:
    "Any public records on your credit report. This may include items such as a court judgment.",
  sectionBankruptcies: "If you've ever filed for bankruptcy or a consumer proposal it would appear here.",
  reportTitle:
    "Your credit report reveals important information about your financial history. Credit lenders, such as banks and other creditors, look at credit reports (along with other information) to see how much credit they are able to give you.\n\nYour credit report includes information about your active credit accounts, your inquiries (when a financial institution, such as a bank, checks your credit), and any missed payments or legal items you may have. It does not include any information about your bank account, electrical bills, or your criminal record.",
  reportCategoryRevolving:
    "With revolving credit, you generally have a set credit limit and you can borrow up to that amount. The amount of credit available to you decreases and increases as funds are borrowed and then repaid. Examples include credit cards and lines of credit.",
  reportCategoryInstallment:
    "Instalment loans have scheduled payments over a set period of time. Examples include car, personal and student loans.",
  reportCategoryOpen: "Open accounts include mobile phone accounts and charge cards.",
  reportCategoryMortgage: "A mortgage is a loan used to purchase a home or other real estate.",
  reportCategoryForeignInquiries:
    "Applications for credit outside of Canada. This only includes “hard inquiries” - when you’ve applied for credit.",
  reportCategoryLocalInquiries:
    "Applications for credit in Canada. This only includes “hard inquiries” - when you’ve applied for credit.",
  cashbackExperiment: {
    title: "Receive a gift card on us!",
    body: "Upon being approved for this card, Borrowell will send you a reward of this cash back value.",
    link: "Learn more",
  }
};
