import { createContext, useMemo } from "react";

import { ContextName, useContextWrapper } from "../contextWrapper";
import { TAnalyticsContext, TAnalyticsProviderProps } from "./analyticsContext.types";
import { useSharedAnalytics } from "./useSharedAnalytics";

export const AnalyticsContext = createContext<TAnalyticsContext>({} as TAnalyticsContext);

export const AnalyticsProvider = ({ children, commonAttributes, trackEvent }: TAnalyticsProviderProps) => {
  const { onButtonClick, impressionView, onLinkClick } = useSharedAnalytics({ commonAttributes, trackEvent });

  const value = useMemo<TAnalyticsContext>(
    () => ({
      onButtonClick,
      impressionView,
      onLinkClick,
      // COMING SOON: onView, onNavigate, onError | Future events
    }),
    [onButtonClick, impressionView, onLinkClick],
  );

  /**
   * Do not wrap this with SessionProvider, it will impact react-query authentication on the mobile app.
   */
  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};
export const useAnalyticsContext = () => useContextWrapper(AnalyticsContext, ContextName.AnalyticsContext);
