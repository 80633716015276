import styled from "@emotion/native";

import { COLORS, shadow, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { FullWidthViewProps } from "./FullWidthView.types";

export const FullWidthBackground = styled.View<FullWidthViewProps>`
  background: ${({ color }) => color ?? COLORS.WHITE};
  margin: 0px -${({ marginHorizontal }) => styledValue(marginHorizontal ?? spacing.xs)};
  padding-vertical: ${({ paddingVertical }) => styledValue(paddingVertical ?? spacing.xs)};
  padding-horizontal: ${({ paddingHorizontal }) => styledValue(paddingHorizontal ?? spacing.xs)};
  ${({ hasShadow }) => hasShadow && shadow};
`;
