import styled from "@emotion/native";

import { styledValue } from "@bwll/bw-utils";

import { StyledSpacerProps } from "./Spacer.types";

export const StyledSpacer = styled.View<StyledSpacerProps>`
  width: ${({ width }) => styledValue(width || 1)};
  height: ${({ height }) => styledValue(height || 1)};
`;
