export const updateFrequency = {
  title: "Credit score and report updates",
  description: "Choose when you want to be notified about changes to your credit score and report.",
  emailOptions: {
    weekly: {
      title: "Email me weekly",
      description: "You’ll be notified each week with credit score and report updates.",
    },
    onChange: {
      title: "Only email me when there’s a change",
      description: "You’ll only be notified whenever we detect changes such as an increase or decrease in your score, or a change to a credit factor on your credit report.",
    },
  },
  link: "[__Learn more about Borrowell’s emails__]()",
};
