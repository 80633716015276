import styled from "@emotion/native";

import { Body2 } from "@bwll/bw-components/next";

export const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Value = styled(Body2)<{ isDecorated: boolean }>`
  text-decoration-line: ${({ isDecorated }) => isDecorated && "line-through"};
`;
