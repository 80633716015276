export const reportItem = {
  "statusOpen": "OUVERT",
  "statusClosed": "FERMÉ",
  "categoryTypeRevolving": "Renouvelable",
  "categoryTypeMortgage": "Hypothèque",
  "categoryTypeInstallment": "Versement",
  "categoryTypeOpen": "Ouvert",
  "categoryTypeForeignInquiries": "Enquêtes de crédit à l’étranger",
  "categoryTypeLocalInquiries": "Enquêtes de crédit locales",
  "categoryTypeCollections": "Recouvrements",
  "categoryTypeLegalItems": "Éléments juridiques",
  "categoryTypeBankruptcies": "Faillites"
}
