export const autoLoans = {
  title: "Auto Loans",
  listHeader: "Offers for you",
  mobile: {
    tabBarLabel: "Autos",
  },
  amountPerMonth: "{{ amount }} / month",
  apr: {
    from: "from",
  },
  termLength: "months",
  monthlyPaymentEstimate: "Monthly payment estimate",
  interestRatePlaceholder: "-",
  tags: {
    preselectedPrefix: "You're ",
    preselectedSuffix: "pre-selected!",
    preferredPartner: "Preferred Partner",
    autoLoan: "Auto Loan",
    secured: "Secured",
  },
  approvalChanceNotAvailable: "Not Available",
  tooltips: {
    preselectedTitle: "You’re pre-selected to apply!",
    preselected:
      "Based on your credit report, you’ve been pre-selected to apply for this Auto Loan. Conditions and fees apply.",
    preselectedLink: "Learn More",
    secured:
      "A lender can request collateral for large loans for which the money is being used to purchase a specific asset or in cases where the credit score isn’t sufficient to qualify for an unsecured loan.",
    monthlyPaymentEstimate:
      "This is the estimated monthly cost paid to the lender over the term of contract. This number is calculated as the maximum loan amount divided by the largest term amount.",
    apr: "Annual interest rate applied on a loan.",
  },
  buttons: {
    details: "DETAILS",
    apply: "APPLY",
  },
  whyCompanyTitle: "Why {{companyName}}?",
  whatToExpectTitle: "What you can expect once you’ve applied.",
  specialOfferCard: {
    title: "Special offer",
    description: "Check out our exclusive offer",
    specialOfferBadge: "Special offer",
    "Auto loan": "Auto loan",
  },
};
