export const address = {
  "streetAddressLabel": "Adresse",
  "unitLabel": "N° de l'unité ou de l'appartement (facultatif)",
  "cityLabel": "Ville",
  "provinceLabel": "Province",
  "postalCodeLabel": "Code postal",
  "buttons": {
    "continue": "CONTINUER",
    "enterAddressManually": "ENTRER L'ADRESSE MANUELLEMENT"
  },
  "errors": {
    "isAddressEmpty": "Veuillez entrer votre adresse",
    "isCityEmpty": "Veuillez entrer votre ville",
    "isProvinceEmpty": "Veuillez entrer votre province",
    "isPostalCodeEmpty": "Veuillez entrer votre code postal",
    "isPostalCodeValid": "Veuillez entrer un code postal valide",
    "isProvinceUnavailable": "Cette offre n'est actuellement pas disponible pour les résidents de {{province}}"
  }
}
