import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { COLORS, mq, spacing } from "@bwll/bw-styles";

import { Icon } from "../..";

interface InfoItemProps {
  image?: string;
  icon?: string;
  index?: number;
  gap?: number;
  paddingTop?: string;
  children: React.ReactNode;
}

const SectionWrapper = styled.div(
  mq({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "fit-content",
    justifyContent: "start",
    "& > div:last-of-type": {
      marginBottom: 0,
    },
  }),
);

const RowWrapper = styled.div<{ gap?: number }>(({ gap }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: gap ?? 0,
}));

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0px;
  }
`;

const ImageContainer = styled.div<{ imageSize?: string; paddingTop?: string }>(
  ({ imageSize, paddingTop }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: paddingTop || "1.2rem",
    height: imageSize ?? "3.2rem",
    minHeight: "2.6rem",

    "&>*:first-of-type": {
      marginRight: spacing.xxs,
      color: COLORS.NEUTRAL.WARM["700"],
    },
  }),
);

const Image = styled.img<{ imageSize?: string }>(({ imageSize }) => {
  return mq({
    height: imageSize ?? "3rem",
    objectFit: "contain",
    marginRight: "1rem",
  });
});

const IndexIcon = styled.div<{ imageSize?: string }>(({ imageSize }) => ({
  width: imageSize ?? "3.2rem",
  height: imageSize ?? "3.2rem",
  marginRight: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  background: COLORS.NEUTRAL.COOL["100"],

  span: {
    fontSize: 24,
    fontWeight: 700,
    textAlign: "center",
    color: COLORS.NEUTRAL.WARM["800"],
  },
}));

const InfoContext = React.createContext({ imageSize: "2rem" });

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const InfoItem: React.FC<InfoItemProps> = ({ children, image, icon, index, gap, paddingTop }) => {
  const { imageSize } = React.useContext(InfoContext);

  return (
    <RowWrapper gap={gap}>
      <ImageContainer imageSize={imageSize} paddingTop={paddingTop}>
        {image && <Image src={image} imageSize={imageSize} />}
        {!image && icon && <Icon size={imageSize} icon={icon} />}
        {!image && !icon && index && (
          <IndexIcon imageSize={imageSize}>
            <span>{index}</span>
          </IndexIcon>
        )}
      </ImageContainer>
      <ContentWrapper>{children}</ContentWrapper>
    </RowWrapper>
  );
};

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const InfoSection: React.FC<{ imageSize?: string; children: React.ReactNode }> = ({
  children,
  imageSize,
}) => {
  const value = useMemo(
    () => ({
      imageSize: imageSize ?? "2rem",
    }),
    [imageSize],
  );

  return (
    <InfoContext.Provider value={value}>
      <SectionWrapper>{children}</SectionWrapper>
    </InfoContext.Provider>
  );
};
