import { COLORS } from "../src/colors";

export const subscriptionStatusBadge = {
  canceled: {
    badgeBackgroundColor: COLORS.YELLOW["050"],
    badgeTextColor: COLORS.NEUTRAL.WARM["800"],
    badgeIconColor: COLORS.YELLOW["900"],
  },
  inactive: {
    badgeBackgroundColor: COLORS.NEUTRAL.COOL["050"],
    badgeTextColor: COLORS.NEUTRAL.WARM["800"],
    badgeIconColor: COLORS.NEUTRAL.WARM["800"],
  },
  pastDue: {
    badgeBackgroundColor: COLORS.RED["050"],
    badgeTextColor: COLORS.NEUTRAL.WARM["800"],
    badgeIconColor: COLORS.RED["700"],
  },
  empty: {
    badgeBackgroundColor: COLORS.WHITE,
    badgeTextColor:COLORS.WHITE,
    badgeIconColor: COLORS.WHITE,
  },
};
