import { WEB_EXPERIMENT_IDS } from "@bwll/bw-types";

import { useUserConfigExperiments } from "../userConfiguration/useUserConfigExperiments";

export const DEFAULT_MIN_MONTHLY_RENT_AMOUNT = 200;

export const useMinRentAmount = (fallbackValue?: string): number => {
  const { data: experiments } = useUserConfigExperiments();
  const remoteValue = experiments?.[WEB_EXPERIMENT_IDS.ENABLE_RENT_REPORTING]?.config?.minRentAmount;
  const localValue = fallbackValue;
  const defaultValue = DEFAULT_MIN_MONTHLY_RENT_AMOUNT;

  if (remoteValue && typeof remoteValue === "string") {
    const parsed = parseFloat(remoteValue);
    return isNaN(parsed) ? defaultValue : parsed;
  }

  if (localValue && typeof localValue === "string") {
    const parsed = parseFloat(localValue);
    return isNaN(parsed) ? defaultValue : parsed;
  }

  return defaultValue;
};
