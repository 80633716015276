import styled from "@emotion/styled";

import { mq } from "@bwll/bw-styles";
import { borderWidth, fontSize, lineHeight, spacing } from "@bwll/bw-styles";

import { GENERIC_ERROR_TYPE } from "./genericError";

enum GENERIC_ERROR_TYPE_IMAGE_SPACE {
  FULL_SCREEN = spacing.xxxl,
  EMBEDDED = spacing.l,
}

enum GENERIC_ERROR_TYPE_TITLE_SPACE {
  FULL_SCREEN = spacing.xxxl,
  EMBEDDED = spacing.s,
}

enum GENERIC_ERROR_TYPE_TEXT_ALIGN {
  FULL_SCREEN = "left",
  EMBEDDED = "center",
}

interface IStyleProps {
  type: GENERIC_ERROR_TYPE;
}

export const Container = styled.div(() =>
  mq({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  }),
);

export const TitleContainer = styled.div<IStyleProps>(({ type }) =>
  mq({
    marginBottom: GENERIC_ERROR_TYPE_TITLE_SPACE[type as GENERIC_ERROR_TYPE],
    "> h1, p": {
      textAlign: GENERIC_ERROR_TYPE_TEXT_ALIGN[type as GENERIC_ERROR_TYPE],
    },
  }),
);

export const Title = styled.h1(({ theme }) =>
  mq({
    ...theme.h2,
  }),
);

export const ImageContainer = styled.div<IStyleProps>(({ type }) =>
  mq({
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: GENERIC_ERROR_TYPE_IMAGE_SPACE[type as GENERIC_ERROR_TYPE],
  }),
);

export const ButtonContainer = styled.div(() =>
  mq({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
);

export const SecondaryButton = styled.button(({ theme }) =>
  mq({
    marginTop: spacing.m,
    border: borderWidth.none,
    background: "transparent",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: fontSize.xs,
    lineHeight: `${lineHeight.xs}px`,
    letterSpacing: "1.12px",
    textTransform: "uppercase",
    cursor: "pointer",
    color: theme.COLORS.PRIMARY.DEFAULT,
    "&:hover": {
      color: theme.COLORS.PRIMARY["300"],
    },
  }),
);
