import styled from "@emotion/styled";
import { BaseArg } from "facepaint";
import { ReactNode } from "react";

import { mq, spacing } from "@bwll/bw-styles";

interface IProp {
  children: ReactNode;
  backgroundColor?: string;
  maxWidth?: number;
  showBottomPadding?: boolean;
  customStyles?: BaseArg;
}

const ContentWrapper = styled.div<{ backgroundColor?: string }>(({ backgroundColor }) =>
  mq({
    background: backgroundColor ?? "transparent",
    width: "100%",
    maxWidth: "100%",
    height: ["100%", "auto", "auto"],
  }),
);

const DisplayWrapper = styled.div<{
  maxWidth?: number;
  showBottomPadding?: boolean;
  customStyles?: BaseArg;
}>(({ maxWidth, showBottomPadding, customStyles }) =>
  mq({
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: ["100%", maxWidth ?? "864px", maxWidth ?? "864px"],
    maxWidth: [
      `calc(100% - ${spacing.m * 2}px)`,
      `calc(100% - ${spacing.xl * 2}px)`,
      `calc(100% - ${spacing.xl * 2}px)`,
    ],
    height: ["100%", "auto", "auto"],
    padding: [
      `${spacing.m}px ${spacing.m}px ${showBottomPadding ? spacing.m : spacing.none}px`,
      `${spacing.xl}px ${spacing.xl}px ${showBottomPadding ? spacing.m : spacing.none}px`,
      `40px ${spacing.xl}px ${showBottomPadding ? spacing.m : spacing.none}px`,
    ],
    ...customStyles,
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Section = ({
  children,
  backgroundColor,
  maxWidth,
  showBottomPadding = true,
  customStyles,
}: IProp) => {
  return (
    <ContentWrapper backgroundColor={backgroundColor}>
      <DisplayWrapper maxWidth={maxWidth} showBottomPadding={showBottomPadding} customStyles={customStyles}>
        {children}
      </DisplayWrapper>
    </ContentWrapper>
  );
};
