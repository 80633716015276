import { StyleSheet } from "react-native";

export const customStyles = StyleSheet.create({
  flatList: {
    overflow: "visible",
    // Web carousel must have both values 'x' and 'y' declared.
    "overflow-y": "visible",
    "overflow-x": "visible",
  },
});
