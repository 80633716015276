import { ImageSourcePropType, useWindowDimensions } from "react-native";

import { CenteredImage, ContentContainer, FullScreenScreenContainer } from "./SplashScreen.styles";
import { SplashScreenProps } from "./SplashScreen.types";

// TODO: PF-206: Move image to a shared location with better typing.
// Fix type of image imports to be compatible with ImageSourcePropType,
// and replace string prop type and casting to ImageSourcePropType.
export const SplashScreen = ({ image }: SplashScreenProps) => {
  const { height: screenHeight } = useWindowDimensions();

  return (
    <FullScreenScreenContainer>
      <ContentContainer screenHeight={screenHeight}>
        <CenteredImage source={image as ImageSourcePropType} alt="splash-screen-image" resizeMode="contain" />
      </ContentContainer>
    </FullScreenScreenContainer>
  );
};
