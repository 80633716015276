export const mxConnectBankingPage = {
  bannerInfoMessage:
    "Connecting your bank account may take up to 5 minutes. Please keep this page open so you don’t lose your progress.",
  bannerInfoMessageManualRent:
    "Connecting your bank account may take up to 10 minutes. Please keep this page open so you don’t lose your progress.  \n  \n  \nTaking too long? [Upload a proof of your rent payment]()",
  addManualBankAccountBanner: "Can’t link your bank account? [Simply upload a proof of your rent payment]()",
  connectBankAccount: {
    title: "Securely sign-in to your bank",
    subtitle: "Borrowell uses MX to connect your account",
    buttons: {
      connect: "CONNECT BANK",
    },
    browserTitle: "Connect Bank",
  },
  reconnectBankAccount: {
    title: "Reconnect your bank account",
    subtitle:
      "Your bank account was disconnected. Please reconnect your bank account so we can find your rent transaction.",
    buttons: {
      connect: "RECONNECT BANK",
      connectLater: "CONNECT LATER",
    },
  },
};
