import styled from "@emotion/styled";

import { FlexProps } from "./Flex.types";

export type { FlexProps } from "./Flex.types";

const FlexStyled = styled.div<FlexProps>(({ justifyContent, alignItems, flexDirection, fullWidth, gap }) => ({
  display: "flex",
  width: fullWidth ? "100%" : "auto",
  justifyContent,
  alignItems,
  flexDirection,
  gap,
}));

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Flex = ({ children, ...props }: FlexProps) => {
  return <FlexStyled {...props}>{children}</FlexStyled>;
};
