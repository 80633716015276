export enum SETTINGS_FIELDS {
  FULL_NAME = "full-name",
  DOB = "dob",
  ADDRESS = "address",
  PHONE = "phone-number",
  EMAIL = "email",
}

export interface IProfile {
  value: string;
  label: string;
  settingsField: string;
  showEditButton?: boolean;
}
