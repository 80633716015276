import { NativeModules, Platform, useWindowDimensions } from "react-native";

const NAVIGATION_HEADER_HEIGHT = Platform.select({ android: 30, ios: 48, default: 48 });

interface IDimensions {
  width: number;
  height: number;
}

interface IDimensionsViews {
  window: IDimensions;
  scale: number;
}

export interface IWindowProperties {
  statusBarHeight: number;
  navigationHeaderHeight: number;
  dimensions: IDimensionsViews;
}

export const useWindowProperties = (): IWindowProperties => {
  const statusBarHeight = NativeModules?.StatusBarManager?.HEIGHT;

  const { width, height, scale } = useWindowDimensions();

  return {
    statusBarHeight,
    navigationHeaderHeight: statusBarHeight + NAVIGATION_HEADER_HEIGHT,
    dimensions: {
      window: {
        width,
        height,
      },
      scale,
    },
  };
};
