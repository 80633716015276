export const forgotMyPassword = {
  request: {
    forgotMyPasswordNavigation: "I FORGOT MY PASSWORD",
    title: "Forgot your password?",
    subtitle: "Simply reset your password by providing your email below to receive instructions.",
    emailLabel: "Email",
    emailAccessibilityLabel: "Enter your email",
    resetPassword: "RESET PASSWORD",
    cancel: "CANCEL",
    isEmailEmptyError: "Please enter an email address",
    isEmailInvalidError: "Please enter a valid email address",
  },
  success: {
    title: "Password reset sent",
    subtitle:
      "If this email is associated with a Borrowell account, you will receive an email with instructions to reset your password.\n\nDidn't receive an email? Be sure to check your spam or junk folder.",
    goBack: "BACK TO LOGIN",
  },
};
