import styled from "@emotion/styled";
import { Arg } from "facepaint";
import React, { ReactNode } from "react";

import { mq } from "@bwll/bw-styles";
import { IThemeDefinition } from "@bwll/bw-styles";

type ButtonStyles = "PRIMARY" | "SECONDARY" | "TERTIARY";
type ButtonSizes = "SMALL" | "MEDIUM" | "LARGE" | "FULL";

export interface IButtonProps {
  name?: string;
  variant?: ButtonStyles;
  size?: ButtonSizes;
  onClick?: (e: React.MouseEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent<Element>) => void;
  styleOverrides?: Arg;
  id?: string;
  disabled?: boolean;
  ariaLabel?: string;
  children: ReactNode;
  "data-cy"?: string | undefined;
}

interface IWithColorTheme {
  variant: ButtonStyles;
  size: ButtonSizes;
}

const USE_THEME_LOOKUP: Record<ButtonStyles, keyof IThemeDefinition> = {
  PRIMARY: "button",
  SECONDARY: "secondaryButton",
  TERTIARY: "tertiaryButton",
};

const sizeVariants = {
  SMALL: {
    width: "160px",
    height: "48px",
    fontSize: "14px",
    borderRadius: "22px",
  },
  LARGE: {
    width: ["100%", "360px", "360px"],
    height: "48px",
    fontSize: ["14px", "16px", "16px"],
    borderRadius: ["29px", "", "48px"],
  },
  FULL: {
    width: ["100%"],
    height: "48px",
    fontSize: ["14px", "16px", "16px"],
    borderRadius: ["29px", "", "48px"],
  },
};

const DefaultButton = styled.button<IButtonProps>(({ variant, size, theme, styleOverrides }) => {
  return mq({
    ...theme[USE_THEME_LOOKUP[variant ?? "PRIMARY"]],
    ...sizeVariants[size && size !== "MEDIUM" ? size : "SMALL"],
    ...styleOverrides,
  });
});

const ButtonComponent: React.FC<
  IWithColorTheme &
    Pick<IButtonProps, "id" | "name" | "onClick" | "onKeyDown" | "disabled" | "children" | "styleOverrides">
> = ({
  id,
  name,
  variant = "PRIMARY",
  size = "MEDIUM",
  onClick,
  onKeyDown,
  disabled,
  children,
  styleOverrides,
  ...otherButtonProps
}) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      onClick?.(e);
    },
    [onClick],
  );

  return (
    <DefaultButton
      id={id}
      name={name}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      variant={variant}
      size={size}
      disabled={disabled}
      styleOverrides={styleOverrides}
      {...otherButtonProps}
    >
      {children}
    </DefaultButton>
  );
};

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 * @description This is the external component, which decides between rendering
 * a Button or a Link, depending on the type of props passed to it.
 * For the pure Button component, please @see ButtonComponent
 */
export const Button: React.FC<IButtonProps> = ({
  id,
  name,
  variant = "PRIMARY",
  size = "MEDIUM",
  onClick,
  onKeyDown,
  children,
  ariaLabel = "",
  styleOverrides,
  "data-cy": _dataCy,
  ...otherButtonProps
}) => {
  const dataCy = _dataCy || (id || name ? `${id || name}-button` : undefined);

  return (
    <ButtonComponent
      id={id}
      name={name}
      onClick={onClick}
      onKeyDown={onKeyDown}
      variant={variant}
      size={size}
      aria-label={ariaLabel}
      styleOverrides={styleOverrides}
      {...(dataCy && { "data-cy": dataCy })}
      {...otherButtonProps}
    >
      {children}
    </ButtonComponent>
  );
};
