// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";

import { mq } from "@bwll/bw-styles";

import { ILinkButtonProps, ILinkProps } from "./Link.types";

const LinkButton = styled("a", {
  shouldForwardProp: (prop) => isPropValid(prop),
})<ILinkButtonProps>(({ theme, styleOverrides }) =>
  mq({
    ...theme.link,
    textDecoration: "none",
    ...styleOverrides,
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Link = ({
  to,
  name,
  children,
  alt,
  rel,
  text,
  ariaLabel,
  target,
  styleOverrides,
  ...otherLinkProps
}: ILinkProps) => {
  return (
    <LinkButton
      href={to}
      name={name}
      {...otherLinkProps}
      alt={alt}
      rel={rel}
      aria-label={ariaLabel}
      target={target}
      styleOverrides={styleOverrides}
    >
      {children || text}
    </LinkButton>
  );
};
