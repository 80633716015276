import React, { useEffect } from "react";

import { appInsightsService } from "@bwll/bw-utils";

import { AppInsightsProviderProps } from "./AppInsightsProvider.types";

export const AppInsightsProvider = ({ children, connectionString }: AppInsightsProviderProps) => {
  useEffect(() => {
    if (connectionString) {
      appInsightsService.init(connectionString);
    }
  }, [connectionString]);

  return <>{children}</>;
};
