export const cbAgreement = {
  "title": "Review your Credit Builder agreement",
  "subtitle1": "To proceed, please review the entire agreement until the end by scrolling to its bottom.",
  "subtitle2": "Then, sign your name and check the box to confirm that you have read and agree.",
  "subtitle3": "This agreement outlines the terms and conditions of your loan.",
  "agreementTitle": "Borrowell Credit Builder Agreement",
  "body1": "This Agreement outlines the terms and conditions of your Borrowell Credit Builder and is your promise to pay to Refresh Solutions Inc., a subsidiary of Borrowell Inc. all amounts owing to us under this Agreement. The defined terms used in this Agreement are found in the “Definitions” section of this Agreement. Please save a copy of this Agreement and keep it in a secure place so it is accessible and usable for future reference.",
  "body2": "**By entering into this Agreement, you agree and acknowledge that the Borrowell Credit Builder is a loan.** It is this loan that creates the installment loan tradeline that may help build your credit. The terms and conditions in this Agreement, which includes the Disclosure Statement, apply to the loan that we provide to you.",
  "disclosureStatement": {
    "title": "Disclosure Statement",
    "labels": {
      "lender": "Lender:",
      "borrower": "Borrower:",
      "address": "Address:",
      "email": "Email:",
      "effectiveDate": "Effective Date:",
      "loanAmount": "Loan Amount:",
      "term": "Term:",
      "borrowerCashSecurity": "Borrower Cash Security:",
      "annualInterestRate": "Annual Interest Rate:",
      "fees": "Fees:",
      "payments": "Payments:",
      "paymentSchedule": "Payment Schedule:",
      "prepaymentPrivilege": "Prepayment Privilege:",
      "costOfBorrowing": "Cost of Borrowing:",
      "totalOfAllPayments": "Total of all Payments:",
      "annualPercentageRate": "Annual Percentage Rate:"
    },
    "values": {
      "lender": "Refresh Solutions Inc., 1 University Ave, 3rd Floor, Toronto, ON M5J 2P1",
      "loanAmount": "Approved Loan Amount ${{loanAmount}}",
      "term": "36 months",
      "borrowerCashSecurity": "Cash Security received from Borrower on the Effective Date: $0",
      "annualInterestRate": "0% per annum is the Annual Interest Rate",
      "fees": "Program Fee is ${{programFee}} per month (for the first 24 months) and $0 per month (for the final 12 months)",
      "feesBiWeekly": "Program Fee is ${{programFee}} biweekly (for the first 24 months of the Term) and $0 biweekly (for the final 12 months of the Term)",
      "payments": "Your monthly Payment is ${{monthlyPaymentAmount}}",
      "paymentsBiWeekly": "Your biweekly Payment is ${{paymentAmount}}",
      "paymentSchedule": "Each Payment is due on the {{recurringDate}} day of each month (or the last day of the month if there is no such date). The first Payment Date is {{firstPaymentdate}}. The outstanding balance at the end of the Term, if all scheduled Payments are made on a timely basis, is $0.",
      "paymentScheduleBiWeekly": "The first Payment Date is {{firstPaymentDate}} and your Payments will continue on a biweekly schedule thereafter. The outstanding balance at the end of the Term, if all scheduled Payments are made on a timely basis, is $0.",
      "prepaymentPrivilege": "You are entitled to pay the full amount of your Loan Amount under this Agreement at anytime without any prepayment charge or penalty.",
      "costOfBorrowing": "${{borrowingCost}}",
      "totalOfAllPayments": "${{paymentTotal}}",
      "annualPercentageRate": "{{annualPercentageRate}}% is the Annual Percentage Rate\nThe Annual Percentage Rate is the cost of borrowing/total cost of credit expressed as an annual percentage rate."
    }
  },
  "termsAndConditions": {
    "title": "Terms and Conditions",
    "section1Body": "**Definitions** – In this Agreement:",
    "section1SubSectionA": "“**Agreement**” means this Agreement, as it may be amended, supplemented, replaced, extended, renewed, restated or otherwise modified from time to time.",
    "section1SubSectionB": "“**Borrower Cash Security**” means the security for the Loan, which will be held as collateral for payment of the amounts that you owe pursuant to the terms of this Agreement.",
    "section1SubSectionC": "“**Event of Default**” means any of the following events:",
    "section1SubSectionCSubClauseI": "You do not make, when due, any Payment required under this Agreement;",
    "section1SubSectionCSubClauseII": "You breach, fail to comply with or perform any other obligation to us under this Agreement or any other agreement with us or with any of our affiliates;",
    "section1SubSectionCSubClauseIII": "You become insolvent or bankrupt or other creditor proceedings are threatened or initiated against you, or you die;",
    "section1SubSectionCSubClauseIV": "If we have any reason to believe that you will be unable to make Payments or you will otherwise not be able to comply with this Agreement; or",
    "section1SubSectionCSubClauseV": "If any representation, warranty, statement or information which you have made or provided to us under or in connection with this Agreement or the application, is or becomes false or untrue.",
    "section1SubSectionD": "“**Lender**”, “**we**”, “**us**” and “**our**” means Refresh Solutions Inc.",
    "section1SubSectionE": "“**Loan**” means the fixed interest rate loan for the Loan Amount which we provide to you.",
    "section1SubSectionF": "“**PAD**” means a pre-authorized debit from your chequing or savings account at a Canadian financial institution pursuant to which you have agreed to make payments due to us under this Agreement.",
    "section1SubSectionG": "“**Payment Date**” means each date shown in the Disclosure Statement on which you agree to pay us a Payment.",
    "section1SubSectionH": "“**Security**” has the meaning in this Agreement and includes the Borrower Cash Security.",
    "section1SubSectionI": "“**You**” and “**your**” mean the person shown at the top of this Agreement and is the borrower under this Agreement.",
    "section2Body": "**Loan** – Pursuant to this Agreement, you are approved for a loan of up to the Loan Amount, which is available to you in accordance with the terms and conditions set out in this Agreement. The Borrower hereby authorizes and directs the Lender to advance the Loan Amount, pursuant to the terms and conditions of this Agreement. Please note that no cash will be advanced to you at the commencement of this Loan. You will receive the Borrower Cash Security at the end of the Term or upon termination of the Loan minus Fees owed and can drawdown the Loan during the Term in accordance with Section 7 of this Agreement.",
    "section3Body": "**Fees** – The Disclosure Statement sets out the Fees that apply to you. You agree to pay the Fees set out in the Disclosure Statement and you authorize us to charge them to you.",
    "section4Body": "**Termination** – You may terminate this Agreement at any time without any cancellation fee or penalty. If you request the termination of the Loan prior to the first Payment Date, you must provide five (5) business days' written notice to the Lender. If the termination of the Agreement comes into effect before the first Payment is due then the Borrower will not be subject to Fees. If the Borrower requests to terminate the Agreement after the first Payment Date, the Borrower will be liable for any and all Fees owed.",
    "section5BodyPart1": "**Payments** – You hereby promise to make regular Payments to the Lender as set out in the Payment Schedule in the Disclosure Statement and pay any additional charges and fees owing by you to the Lender. Payments will be made by you by way of PAD. If any Payment Date falls on a weekend or holiday, the Lender will withdraw the Payment from your bank account on the first business day subsequent to that date (for example, if the Payment is due on Saturday, the Lender will withdraw the Payment on the following Monday).",
    "section5BodyPart2": "Payments will be applied to satisfy the following in order:",
    "section5BodyPart2SubSectionA": "Fees, as outlined above; and",
    "section5BodyPart2SubSectionB": "any excess towards the Loan Amount.",
    "section5BodyPart3": "For clarity, for the first twenty-four (24) months of the Loan, each Payment will be applied first to the Program Fee and the remainder will be applied to the Loan Amount. From month twenty-five (25) of the Loan to the end of the Term, each Payment will be applied to the Loan Amount. This assumes that no prepayments have been made.",
    "section6Body": "**Prepayment** – You can prepay the Loan in full or in part at any time without any prepayment charge or penalty. Prepayments may result in a change to the Term, including a shorter Term, and a final payment that is less than the final payment amount.",
    "section7BodyPart1": "**Borrower Cash Security Account** – As of the date hereof, you have provided the Lender cash security equal to the Borrower Cash Security amount outlined in the Disclosure Statement. As you make Payments, the portion of the Payment applied to the Loan Amount will be put in the Borrower Cash Security Account. You can draw under the Loan at any time by way of submitting a drawdown request form available upon request. Drawdowns under the Loan at any time during the Term shall be subject to a maximum amount equal to the Borrower Cash Security balance, less the aggregate amount of any drawdowns made up to and outstanding on such date.\nYou authorize and direct the Lender to hold all Borrower Cash Security in the Borrower Cash Security Account as security for the Loan, pursuant to the terms of this Agreement.\nAt the end of the Term, or, if this Agreement is terminated before the end of the Term, at the time of termination, you will have access to your Borrower Cash Security balance outstanding at such time, subject to the section titled “Right of Set-Off” in this Agreement.\nIn return for the Lender agreeing to lend to you the Loan Amount, you agree as follows:",
    "section7SubSectionA": "**Grant of Security Interest** – You acknowledge and agree that in order to secure the due and punctual payment and performance of the Loan to you under this Agreement, you, as debtor, pledge, hypothecate, assign, transfers set over unto the Lender, as secured party, and direct the Lender to hold for your  benefit , and grant to the Lender, a security interest in the Borrower Cash Security, and all of your rights, title and interest therein, if any, and all such property received, receivable or otherwise distributed in respect of, in substitution or in exchange for, or in replacement of the foregoing, and all proceeds of the foregoing (to such extent, collectively the “Security”). The Lender will have the right to administer its interest in the Security, including without limitation any interest granted to it above, and to exercise any rights or remedies available to it at law upon default by you under this Agreement, provided that if the default and any costs of the Lender associated with the default and the enforcement of its security are satisfied by less than the full value of the Security then, subject to the terms of this Agreement, such excess will be returned to you by the Lender.",
    "section7SubSectionB": "**No Other Charges** – You promise to the Lender that the Security is free and clear of all other charges and security interests and you will keep the Security free and clear of all other charges and security interests.",
    "section7SubSectionC": "**Will Not Sell** – You will not sell, lease or otherwise transfer or dispose of, or give possession of, the Security (or any part of the Security) or any of your interest in the Security, to anyone else without the Lender’s written consent.",
    "section7SubSectionD": "**Pay Costs of Enforcement** – You will pay all of the Lender’s reasonable costs and expenses (including legal fees and expenses) in connection with enforcing the Security.",
    "section7BodyPart2": "The Lender shall hold any Borrower Cash Security received from you in an account in the Lender’s name with a bank or other regulated financial institution (the “**Borrower Cash Security Account**”). The Lender combines your Borrower Cash Security with the cash security of other borrowers. These pooled amounts are held separate from the Lender’s corporate funds, and the Lender will not use these funds for its operating expenses or any other corporate purposes except as set out in this Agreement. You will not receive any interest on the Borrower Cash Security.",
    "section8Body": "**Default** – In the Event of Default, the Borrower Cash Security may be applied by the Lender to the following amounts, unless otherwise required by applicable law or the terms of this Agreement: (a) our reasonable legal costs incurred in collecting or attempting to collect amounts you owe us; and (b) outstanding Fees. If after such application of the Borrower Cash Security, there is any Borrower Cash Security remaining, it shall be returned to you pursuant to the terms of this Agreement.",
    "section9Body": "**Demand by Lender** – If you Default under this Agreement, the Lender may demand that you make your Payments and repay any other amounts owing by you under this Agreement, subject to any notice or other requirements of applicable law. Any notice will be sent to the latest address shown in our records.",
    "section10Body": "**Right of Set-Off** – Notwithstanding any other provision in this Agreement, we are authorized at any time, including in respect of any legal proceedings with respect to the Loan, to set-off and apply any amount or portion of Borrower Cash Security held by the Lender or any amounts we may owe you from time to time to any amounts or indebtedness owing by you to the Lender. The rights of the Lender under this section are in addition to any other rights and remedies (including, without limitation, other rights of set-off) which the Lender may have.",
    "section11Body": "**Reporting Payment History** – On a monthly basis, the Lender will make your payment history available on your credit profile. It may take up to ninety (90) days from the Effective Date of this Agreement for the Loan to be reflected on your credit profile. Failure to meet repayment obligations may result in a negative report to the credit bureaus, which would reduce your credit score. Please borrow responsibly.",
    "section12BodyPart1": "**Privacy** – You hereby consent to Refresh Solutions Inc. and its affiliates, and their agents and representatives, collecting using and disclosing your personal information for the purposes of:",
    "section12SubSectionA": "Assessing your application and determining your eligibility for a loan;",
    "section12SubSectionB": "Approving, opening, administering, servicing and enforcing your Loan (including collecting outstanding debts and realizing on the Security held by the Lender);",
    "section12SubSectionC": "Determining your eligibility and suitability for, and providing you with, other products and services you request;",
    "section12SubSectionD": "Verifying your identity and other information you have provided to us (including your bank account);",
    "section12SubSectionE": "Conducting market research and data analytics (we will generally use your information in an aggregated format or with direct personal identifiers removed);",
    "section12SubSectionF": "Developing and enhancing our products and services;",
    "section12SubSectionG": "Meeting legal and regulatory requirements;",
    "section12SubSectionH": "Detecting and preventing fraud and other unauthorized or illegal activities;",
    "section12SubSectionI": "Notifying you with transactional messages and promotional messages via text message, phone call and email in accordance with applicable law; and",
    "section12SubSectionJ": "As otherwise permitted or required by applicable law.",
    "section12BodyPart2": "We may share your personal information with our affiliates or with our service providers (companies operating on our behalf) and other third parties (companies with which we provide programs, products or services) for the purposes described in this Section and in accordance with applicable law.",
    "section13Body": "**Assignment** – You may not assign this Agreement without the consent of the Lender.",
    "section14Body": "**Notices** – Subject to applicable law, all notices under this Agreement will be in writing and shall be delivered by e-mail to your address or the Lender’s address, as set out in the Disclosure Statement. Notice is deemed to have been given on the date it is sent by email (with confirmation of transmission) if sent during normal business hours of the recipient, or on the next business day, if sent after normal business hours of the recipient.",
    "section15Body": "**Waiver** – If we fail to exercise, or delay in exercising, any of the rights that we have under this Agreement or if, on any occasion, we waive the rights that we have under this Agreement, it shall not be considered a waiver of any of our rights at any other time or on any other occasion.",
    "section16Body": "**Severability** – If any portion of this Agreement is declared or deemed, for any reason, to be invalid or unenforceable, then the remaining portions of this Agreement shall not be affected and shall remain in full force and effect.",
    "section17Body": "**Changes to this Agreement** – You agree that the Lender may change, modify, add or remove any provisions of this Agreement at any time. The Lender will notify you of any such amendments by sending notice to you in accordance with Section 14 and applicable law. Your continued performance under the Agreement after receiving the notice means that you agree to and accept any such amendment and the Agreement as amended immediately applies to you and the Lender.",
    "section18Body": "**Electronic Signature** – This Agreement may be agreed to, executed or delivered by electronic means and all such counterparts or other electronic means shall together constitute one and the same agreement. You acknowledge receiving a copy of this Agreement and agree that the electronically agreed to or signed version of this Agreement we received from you has the same force and effect as manual signatures, will be considered the original executed agreement and be binding and enforceable against both parties of the date first written above.",
    "section19Body": "**Applicable Law** – This Agreement is governed by the laws of Canada and the laws of the province in which you resided when you obtained the Loan.",
    "section20Body": "**Currency** – All references to dollar amounts in this Agreement mean Canadian dollars.",
    "section21Body": "**Contact Information** – You may make inquiries about your Loan, including payment, balance or if you have any complaints about your Loan or questions about this Agreement, you can access the Help Centre on the Borrowell website or contact us at Refresh Solutions Inc., 1 University Ave, 3rd Floor, Toronto, ON M5J 2P1, by toll free phone at: 1-888-408-0480, or by email at: buildcredit@borrowell.com.",
    "section22Body": "**Consent to Disclose Credit Information** – You consent to the Lender disclosing information about your credit history and other personal information to credit reporting agencies in order to update your credit information."
  },
  "agreementConsent": {
    "label": "Borrower's Signature",
    "caption": "Type your name here to sign",
    "checkBoxLabel": "I confirm that I have read and agree to the terms of my Credit Builder agreement",
    "buttonText": "CONFIRM",
    "signatureError": "Please use {{fullName}} as your signature."
  }
}
