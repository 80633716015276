import React from "react";

import { StyledHeading2 } from "./Heading2.styles";
import { Heading2Props } from "./Heading2.types";

/**
 * Component for showing the Heading1.
 *
 * @component
 * @example
 * return (
 *   <Heading1 color={COLORS.NEUTRAL.WARM["700"]} addMargin>Hello World</Heading1>
 * )
 */
export const Heading2 = ({
  accessibilityLabel,
  style,
  testID,
  children,
  color,
  numberOfLines,
  onPress,
}: Heading2Props) => {
  return (
    <StyledHeading2
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
      color={color}
      numberOfLines={numberOfLines}
      onPress={onPress}
    >
      {children}
    </StyledHeading2>
  );
};
