import React from "react";

import { StyledExpressiveLabel } from "./ExpressiveLabel.styles";
import { ExpressiveLabelProps } from "./ExpressiveLabel.types";

export const ExpressiveLabel = ({ children, style, accessibilityLabel, testID }: ExpressiveLabelProps) => {
  return (
    <StyledExpressiveLabel
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
    >
      {children?.toString().toUpperCase()}
    </StyledExpressiveLabel>
  );
};
