import styled from "@emotion/styled";
import React, { ReactNode, useState } from "react";

import { Section } from "@bwll/bw-components";
import { Heading, Markdown } from "@bwll/bw-components";
import { spacing } from "@bwll/bw-styles";

export interface IFAQItem {
  title: string;
  description: string;
  isOpen?: boolean;
}

export interface IFAQ {
  title: string;
  ctaButton?: ReactNode;
  content: IFAQItem[];
}

const FAQList = styled.div({
  marginTop: "32px",
});

const FAQHeader = styled.div({
  width: "100%",
  height: "60px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  span: {
    fontSize: "20px",
    fontWeight: "700",
    fontFamily: "Lato",
    lineHeight: "28px",
  },
});

const ExpandIcon = styled.div<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: "relative",
  width: spacing.xs,
  height: 1,
  transition: "transform 0.5s ease",
  background: theme.COLORS.PRIMARY["500"],
  transform: isOpen ? "rotate(180deg)" : "",

  marginTop: spacing.xs,
  marginLeft: spacing.xs,
  "&:before": {
    transition: "transform 0.5s ease",
    background: theme.COLORS.PRIMARY["500"],
    height: 1,
    width: spacing.xs,
    display: "block",
    transform: isOpen ? "rotate(180deg)" : "rotate(90deg)",
    content: '""',
  },
}));

const FAQItemExpandable = styled.div<{ isOpen?: boolean }>(({ theme, isOpen = false }) => ({
  transition: "all 0.4s ease",
  overflow: "hidden",
  maxHeight: isOpen ? "200vh" : "0vh",
  paddingBottom: isOpen ? spacing.xs : "0px",
  borderBottom: `1px solid ${theme.COLORS.NEUTRAL.COOL["100"]}`,
}));

const FAQItem: React.FC<{ item: IFAQItem }> = ({ item }) => {
  const [isOpen, toggleOpen] = useState(false);

  return (
    <>
      <FAQHeader
        onClick={() => {
          toggleOpen(!isOpen);
        }}
      >
        <span>{item.title}</span>
        <ExpandIcon isOpen={isOpen} />
      </FAQHeader>
      <FAQItemExpandable isOpen={isOpen}>
        <Markdown>{item.description}</Markdown>
      </FAQItemExpandable>
    </>
  );
};

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const FaqSection = ({ title, ctaButton, content }: IFAQ) => {
  return (
    <Section>
      <Heading as="h2">{title}</Heading>
      <FAQList>
        {content.map((item, i) => (
          <FAQItem item={item} key={item.title + i} />
        ))}
      </FAQList>
      {ctaButton && ctaButton}
    </Section>
  );
};
