export const proofOfPayment = {
  title: "Upload your proof of payment",
  // Use with tooltip
  titlePart1: "Upload your",
  titlePart2: "proof of payment",

  titleTooltipTitle: "Proof of payment",
  titleTooltipDescription:
    "Proof of payment includes a copy of your rent cheque, confirmation of your credit card or debit card payment, confirmation of your e-transfer and any other information we require to demonstrate that your rent payment has been made.",

  description: "Please upload your proof of payment for due date **{{ date }}**.",
  descriptionNoMD: "Please upload your proof of payment for due date ",

  requirementsDescription: "The proof of payment you upload should include the following information:",
  requirementsList: {
    yourName: "Your name",
    transactionDate: "Transaction date",
    transactionAmount: "Transaction amount",
    transactionDescription: "Transaction description",
    accountNumber: "Account number",
  },

  notesDescription: "When uploading your proof of payment, please note the following:",
  notesList: {
    notProtected: "Please ensure the file is not password-protected.",
    eStatement:
      "Visit your online banking website and download an electronic statement for this transaction in any of these file types: **png, jpg, jpeg, pdf**.",
    eStatementNoMD:
      "Visit your online banking website and download an electronic statement for this transaction in any of these file types: ",
  },

  fileLabel: "File",
  filesUploadingSectionTitle: "Uploading",
  filesReadySectionTitle: "Files",
  fileChangeButton: "CHANGE",
  fileMaxSize: "Max. file size: {{ mb }}MB",
  confirmation: "I confirm that the proof of payment I have uploaded is authentic and accurate.",

  uploadFileButton: "UPLOAD FILE",
  chooseFileButton: "CHOOSE FILE",

  actionSheet: {
    browseFiles: "Browse Files",
    browsePhotos: "Browse Photos",
    cancel: "Cancel",
  },

  errors: {
    fileSize: "Your file exceeds the max size of {{ mb }}MB",
    fileFormat: "File type must be either ",
    or: " or ",
    fileFormatMD: "File type must be either **png**, **jpg**, **jpeg**, or **pdf**.",
    uploadError: "Something went wrong uploading your file.",
  },

  loading: {
    title: "Uploading proof of payment...",
    description:
      "Please don’t close this page otherwise you’ll lose your progress and will have to start again.",
  },
};
