import React from "react";
import { Image as NativeImage } from "react-native";

import { ImageProps } from "./Image.types";

export const Image = ({ source, style, testID, resizeMode, accessibilityLabel }: ImageProps) => {
  return (
    <NativeImage
      source={source}
      style={style}
      resizeMode={resizeMode}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="image"
      testID={testID}
    />
  );
};
