export const REGION_KEYS = {
  ALBERTA: "ALBERTA",
  BRITISH_COLUMBIA: "BRITISH_COLUMBIA",
  MANITOBA: "MANITOBA",
  NEW_BRUNSWICK: "NEW_BRUNSWICK",
  NEWFOUNDLAND_AND_LABRADOR: "NEWFOUNDLAND_AND_LABRADOR",
  NORTHWEST_TERRITORIES: "NORTHWEST_TERRITORIES",
  NOVA_SCOTIA: "NOVA_SCOTIA",
  NUNAVUT: "NUNAVUT",
  ONTARIO: "ONTARIO",
  PRINCE_EDWARD_ISLAND: "PRINCE_EDWARD_ISLAND",
  QUEBEC: "QUEBEC",
  SASKATCHEWAN: "SASKATCHEWAN",
  YUKON: "YUKON",
} as const;
export type RegionKey = (typeof REGION_KEYS)[keyof typeof REGION_KEYS];

export const REGION_IDS = {
  [REGION_KEYS.ALBERTA]: "00000000-0000-0000-0000-000000000004",
  [REGION_KEYS.BRITISH_COLUMBIA]: "00000000-0000-0000-0000-000000000003",
  [REGION_KEYS.MANITOBA]: "00000000-0000-0000-0000-000000000007",
  [REGION_KEYS.NEW_BRUNSWICK]: "00000000-0000-0000-0000-000000000008",
  [REGION_KEYS.NEWFOUNDLAND_AND_LABRADOR]: "00000000-0000-0000-0000-000000000010",
  [REGION_KEYS.NORTHWEST_TERRITORIES]: "00000000-0000-0000-0000-000000000011",
  [REGION_KEYS.NOVA_SCOTIA]: "00000000-0000-0000-0000-000000000005",
  [REGION_KEYS.NUNAVUT]: "00000000-0000-0000-0000-000000000012",
  [REGION_KEYS.ONTARIO]: "00000000-0000-0000-0000-000000000002",
  [REGION_KEYS.PRINCE_EDWARD_ISLAND]: "00000000-0000-0000-0000-000000000009",
  [REGION_KEYS.QUEBEC]: "00000000-0000-0000-0000-000000000001",
  [REGION_KEYS.SASKATCHEWAN]: "00000000-0000-0000-0000-000000000006",
  [REGION_KEYS.YUKON]: "00000000-0000-0000-0000-000000000013",
} as const;
export type RegionId = (typeof REGION_IDS)[keyof typeof REGION_IDS];

export const REGION_LABELS = {
  [REGION_KEYS.ALBERTA]: "Alberta",
  [REGION_KEYS.BRITISH_COLUMBIA]: "British Columbia",
  [REGION_KEYS.MANITOBA]: "Manitoba",
  [REGION_KEYS.NEW_BRUNSWICK]: "New Brunswick",
  [REGION_KEYS.NEWFOUNDLAND_AND_LABRADOR]: "Newfoundland and Labrador",
  [REGION_KEYS.NORTHWEST_TERRITORIES]: "Northwest Territories",
  [REGION_KEYS.NOVA_SCOTIA]: "Nova Scotia",
  [REGION_KEYS.NUNAVUT]: "Nunavut",
  [REGION_KEYS.ONTARIO]: "Ontario",
  [REGION_KEYS.PRINCE_EDWARD_ISLAND]: "Prince Edward Island",
  // Using "Quebec" instead of "Québec" to match the Google autocomplete API response
  [REGION_KEYS.QUEBEC]: "Quebec",
  [REGION_KEYS.SASKATCHEWAN]: "Saskatchewan",
  [REGION_KEYS.YUKON]: "Yukon",
} as const;
export type RegionLabel = (typeof REGION_LABELS)[keyof typeof REGION_LABELS];

export const REGION_ABBREVIATIONS = {
  [REGION_KEYS.ALBERTA]: "AB",
  [REGION_KEYS.BRITISH_COLUMBIA]: "BC",
  [REGION_KEYS.MANITOBA]: "MB",
  [REGION_KEYS.NEW_BRUNSWICK]: "NB",
  [REGION_KEYS.NEWFOUNDLAND_AND_LABRADOR]: "NL",
  [REGION_KEYS.NORTHWEST_TERRITORIES]: "NT",
  [REGION_KEYS.NOVA_SCOTIA]: "NS",
  [REGION_KEYS.NUNAVUT]: "NU",
  [REGION_KEYS.ONTARIO]: "ON",
  [REGION_KEYS.PRINCE_EDWARD_ISLAND]: "PE",
  [REGION_KEYS.QUEBEC]: "QC",
  [REGION_KEYS.SASKATCHEWAN]: "SK",
  [REGION_KEYS.YUKON]: "YT",
} as const;
export type RegionAbbreviation = (typeof REGION_ABBREVIATIONS)[keyof typeof REGION_ABBREVIATIONS];

export interface RegionConfig {
  id: RegionId;
  abbreviation: RegionAbbreviation;
  name: RegionLabel;
}

export const REGIONS = {
  [REGION_KEYS.ALBERTA]: {
    id: REGION_IDS.ALBERTA,
    abbreviation: REGION_ABBREVIATIONS.ALBERTA,
    name: REGION_LABELS.ALBERTA,
  },
  [REGION_KEYS.BRITISH_COLUMBIA]: {
    id: REGION_IDS.BRITISH_COLUMBIA,
    abbreviation: REGION_ABBREVIATIONS.BRITISH_COLUMBIA,
    name: REGION_LABELS.BRITISH_COLUMBIA,
  },
  [REGION_KEYS.MANITOBA]: {
    id: REGION_IDS.MANITOBA,
    abbreviation: REGION_ABBREVIATIONS.MANITOBA,
    name: REGION_LABELS.MANITOBA,
  },
  [REGION_KEYS.NEW_BRUNSWICK]: {
    id: REGION_IDS.NEW_BRUNSWICK,
    abbreviation: REGION_ABBREVIATIONS.NEW_BRUNSWICK,
    name: REGION_LABELS.NEW_BRUNSWICK,
  },
  [REGION_KEYS.NEWFOUNDLAND_AND_LABRADOR]: {
    id: REGION_IDS.NEWFOUNDLAND_AND_LABRADOR,
    abbreviation: REGION_ABBREVIATIONS.NEWFOUNDLAND_AND_LABRADOR,
    name: REGION_LABELS.NEWFOUNDLAND_AND_LABRADOR,
  },
  [REGION_KEYS.NORTHWEST_TERRITORIES]: {
    id: REGION_IDS.NORTHWEST_TERRITORIES,
    abbreviation: REGION_ABBREVIATIONS.NORTHWEST_TERRITORIES,
    name: REGION_LABELS.NORTHWEST_TERRITORIES,
  },
  [REGION_KEYS.NOVA_SCOTIA]: {
    id: REGION_IDS.NOVA_SCOTIA,
    abbreviation: REGION_ABBREVIATIONS.NOVA_SCOTIA,
    name: REGION_LABELS.NOVA_SCOTIA,
  },
  [REGION_KEYS.NUNAVUT]: {
    id: REGION_IDS.NUNAVUT,
    abbreviation: REGION_ABBREVIATIONS.NUNAVUT,
    name: REGION_LABELS.NUNAVUT,
  },
  [REGION_KEYS.ONTARIO]: {
    id: REGION_IDS.ONTARIO,
    abbreviation: REGION_ABBREVIATIONS.ONTARIO,
    name: REGION_LABELS.ONTARIO,
  },
  [REGION_KEYS.PRINCE_EDWARD_ISLAND]: {
    id: REGION_IDS.PRINCE_EDWARD_ISLAND,
    abbreviation: REGION_ABBREVIATIONS.PRINCE_EDWARD_ISLAND,
    name: REGION_LABELS.PRINCE_EDWARD_ISLAND,
  },
  [REGION_KEYS.QUEBEC]: {
    id: REGION_IDS.QUEBEC,
    abbreviation: REGION_ABBREVIATIONS.QUEBEC,
    name: REGION_LABELS.QUEBEC,
  },
  [REGION_KEYS.SASKATCHEWAN]: {
    id: REGION_IDS.SASKATCHEWAN,
    abbreviation: REGION_ABBREVIATIONS.SASKATCHEWAN,
    name: REGION_LABELS.SASKATCHEWAN,
  },
  [REGION_KEYS.YUKON]: {
    id: REGION_IDS.YUKON,
    abbreviation: REGION_ABBREVIATIONS.YUKON,
    name: REGION_LABELS.YUKON,
  },
} as const;

// REGIONS ordered in alphabetical order
export const REGIONS_ORDERED = [
  REGIONS.ALBERTA,
  REGIONS.BRITISH_COLUMBIA,
  REGIONS.MANITOBA,
  REGIONS.NEW_BRUNSWICK,
  REGIONS.NEWFOUNDLAND_AND_LABRADOR,
  REGIONS.NORTHWEST_TERRITORIES,
  REGIONS.NOVA_SCOTIA,
  REGIONS.NUNAVUT,
  REGIONS.ONTARIO,
  REGIONS.PRINCE_EDWARD_ISLAND,
  REGIONS.QUEBEC,
  REGIONS.SASKATCHEWAN,
  REGIONS.YUKON,
] as const;
