import styled from "@emotion/native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { isWeb } from "../../../constants";
import { BoldText } from "../Default";
import { StyledHeading1Props } from "./Heading1.types";

export const StyledHeading1 = styled(BoldText)<StyledHeading1Props>`
  font-size: ${styledValue(isWeb ? fontSize.xl + 2 : fontSize.xl)};
  line-height: ${styledValue(spacing.m)};
  letter-spacing: ${styledValue(letterSpacing.m)};
  color: ${({ color }) => color ?? COLORS.NEUTRAL.WARM["700"]};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
`;
