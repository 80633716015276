export const ENGLISH_LANGUAGE = "en";
export const FRENCH_LANGUAGE = "fr";
export const DEFAULT_LANGUAGE = ENGLISH_LANGUAGE;

export type AvailableLocale = typeof ENGLISH_LANGUAGE | typeof FRENCH_LANGUAGE;

/**
 * We have to use regional locale standards with vendors like Stripe.
 */
export const RegionalLocale = {
  [ENGLISH_LANGUAGE]: "en-CA",
  [FRENCH_LANGUAGE]: "fr-CA",
} as const;

type RegionalLocaleKey = keyof typeof RegionalLocale;
export type AvailableRegionalLocale = (typeof RegionalLocale)[RegionalLocaleKey];
