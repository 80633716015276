import styled from "@emotion/styled";
import React, { useState } from "react";

import { COLORS, mq } from "@bwll/bw-styles";

import { FormFieldWrapper } from "./FormField";

type IconPosition = "LEFT" | "RIGHT";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  caption?: string;
  errorHint?: string;
  forceError?: boolean;
  hasError?: boolean;
  icon?: {
    iconSymbol: string;
    iconPosition: IconPosition;
  };
  id?: string;
  isDarkTheme?: boolean;
  isRequired?: boolean;
  label: string;
  labelIsScreenReaderonly?: boolean;
  labelFontSize?: string;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
  "data-cy"?: string | undefined;
  "data-testid"?: string | undefined;
}
const _Input = styled.input<{
  isDarkTheme?: boolean;
  iconPosition?: IconPosition;
  hasError?: boolean;
}>(({ theme, isDarkTheme, iconPosition, hasError }) => {
  const iconPadding = iconPosition
    ? iconPosition === "LEFT"
      ? { paddingLeft: "30px" }
      : { paddingRight: "45px" }
    : undefined;
  return mq({
    ...theme[isDarkTheme ? "inputDark" : "input"],
    ...iconPadding,
    borderColor: hasError ? COLORS.RED["700"] : COLORS.NEUTRAL.COOL["600"],
  });
});

const InputContainer = styled.div`
  ${mq({
    display: "flex",
    flex: "1",
    position: "relative",
    alignItems: "center",
    width: "100%",
  })}
`;

const Icon = styled.span<{ iconPosition: `${IconPosition}` }>`
  position: absolute;
  ${({ iconPosition }) => iconPosition === "LEFT" && `left: 16px;`}
  ${({ iconPosition }) => iconPosition === "RIGHT" && `right: 20px;`}
`;

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Input = ({
  id,
  inputRef,
  value,
  onBlur,
  label,
  isRequired = false,
  caption,
  errorHint,
  hasError,
  isDarkTheme = false,
  forceError,
  labelIsScreenReaderonly,
  labelFontSize,
  "aria-label": ariaLabel,
  icon,
  name,
  "data-cy": _dataCy,
  "data-testid": _dataTestId,
  ...props
}: InputProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const shouldShowError = forceError || isDirty;
  const dataCy = _dataCy || (id || name ? `${id || name}-input` : undefined);
  return (
    <FormFieldWrapper
      id={id}
      label={label}
      labelFontSize={labelFontSize}
      isRequired={isRequired}
      isDirty={shouldShowError}
      caption={caption}
      error={hasError ? errorHint : undefined}
      labelIsScreenReaderonly={labelIsScreenReaderonly}
    >
      <InputContainer>
        {!!icon && <Icon iconPosition={icon.iconPosition}>{icon.iconSymbol}</Icon>}
        <_Input
          {...(props as any)}
          id={id}
          ref={inputRef}
          value={value}
          iconPosition={icon?.iconPosition}
          onBlur={(event) => {
            if (!isDirty) {
              setIsDirty(true);
            }
            onBlur && onBlur(event);
          }}
          hasError={isDirty && hasError}
          isDarkTheme={isDarkTheme}
          aria-required={isRequired}
          aria-invalid={shouldShowError}
          aria-describedby={caption ? `${id}-caption` : undefined}
          aria-label={ariaLabel || labelIsScreenReaderonly ? label : undefined}
          {...(dataCy && { "data-cy": dataCy })}
          {...((_dataTestId || dataCy) && { "data-testid": _dataTestId || dataCy })}
        />
      </InputContainer>
    </FormFieldWrapper>
  );
};
