import React, { createContext, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { ContextName, useContextWrapper } from "../contextWrapper";
import { AndroidActionContextProps, AndroidActionProviderProps } from "./androidActionContext.types";

const AndroidActionContext = createContext<AndroidActionContextProps>({} as AndroidActionContextProps);

export const AndroidActionProvider = ({
  children,
  setIsAndroidTooltipRenderedAction,
}: AndroidActionProviderProps) => {
  const dispatch = useDispatch();

  const handlePress = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsAndroidTooltipRenderedAction(isOpen));
    },
    [dispatch, setIsAndroidTooltipRenderedAction],
  );

  const value = useMemo(() => ({ handlePress }), [handlePress]);

  return <AndroidActionContext.Provider value={value}>{children}</AndroidActionContext.Provider>;
};

export const useAndroidAction = () => {
  return useContextWrapper(AndroidActionContext, ContextName.AndroidActionContext);
};
