import i18next from "i18next";
import { DateTime } from "luxon";

import {
  IAddressFormFields,
  ILeaseFormFields,
  useEnvironmentContext,
  useLeaseContext,
  useMinRentAmount,
  useProfile,
} from "@bwll/bw-hooks";
import { stripNonNumbers } from "@bwll/bw-utils";

import { Guard, ICustomValidator } from "./guard";

import { getMinTransactionDate, getMinTransactionDateFormatted } from "@app/utils";

const oneDayAgo = DateTime.now().minus({ days: 1 });
const maxRentAmount = 10000;

export const useValidations = () => {
  const { data: profileData } = useProfile();
  const minTransactionDate = getMinTransactionDate(profileData);
  const minTransactionDateFormatted = getMinTransactionDateFormatted(minTransactionDate);
  const { envConfigs } = useEnvironmentContext();

  const minRentAmount = useMinRentAmount(envConfigs.MIN_RENT_AMOUNT);

  const { monthlyRentAmount } = useLeaseContext()?.fields ?? {};

  const {
    monthlyRentAmountErrorHint,
    rentPaymentDueDateErrorHint,
    leaseStartDateErrorHint,
    required,
    invalidProvince,
    enterValidDate,
    quebecNotAvailable,
    noSpecialCharacters,
    invalidPostalCode,
  }: Record<string, string> = i18next.t("translations:rentReporting:formPage:errorMessages", {
    returnObjects: true,
    minRentAmount,
  });

  const manualUploadValidators = {
    leaseTermValidator: new Guard({
      minLength: {
        value: 1,
        errorMessage: required,
      },
    }),
    transactionDateValidator: new Guard({
      minLength: {
        value: 1,
        errorMessage: required,
      },
      customValidator: {
        validate: (transactionDate: string) => {
          return (
            DateTime.fromISO(transactionDate) >= minTransactionDate &&
            DateTime.fromISO(transactionDate) <= DateTime.now()
          );
        },
        errorMessage: i18next.t("rentReporting:transactionDetails:errors:transactionDate", {
          date: minTransactionDateFormatted,
        }),
      },
    }),
    transactionAmountValidator: new Guard({
      minLength: {
        value: 1,
        errorMessage: required,
      },
      customValidator: {
        validate: (transactionAmount: string) => {
          const rent = parseFloat(stripNonNumbers(transactionAmount));
          return rent > 0 && rent < maxRentAmount;
        },
        errorMessage: i18next.t(
          "translations:rentReporting:formPage:errorMessages:monthlyRentAmountErrorHint",
          {
            minRentAmount: 0,
          },
        ),
      },
    }),
    transactionAmountThresholdValidator: new Guard({
      customValidator: {
        validate: (amount: string) => {
          const rent = parseFloat(stripNonNumbers(monthlyRentAmount));
          const transAmt = parseFloat(stripNonNumbers(amount));
          return transAmt >= rent * 0.9 && transAmt <= rent * 2;
        },
        errorMessage: i18next.t("rentReporting:transactionDetails:modal:amountMismatch:title"),
      } as ICustomValidator,
    }),
    transactionDescriptionValidator: new Guard({
      minLength: {
        value: 1,
        errorMessage: required,
      },
      maxLength: {
        value: 100,
        errorMessage: i18next.t("rentReporting:transactionDetails:errors:descriptionField", {
          maxLength: 100,
        }),
      },
    }),
    transactionCommentsValidator: new Guard({
      maxLength: {
        value: 500,
        errorMessage: i18next.t("rentReporting:transactionDetails:errors:commentField", {
          maxLength: 500,
        }),
      },
    }),
  };

  const textValidator = new Guard({
    minLength: {
      value: 1,
      errorMessage: required,
    },
    maxLength: {
      value: 50,
      errorMessage: i18next.t("translations:rentReporting:formPage:errorMessages:maximumCharactersLimit", {
        maximum: 50,
      }),
    },
    regexValidator: {
      regex: /^[0-9a-zàâçéèêëîïôûùüÿñæœ ./-]*$/i,
      errorMessage: noSpecialCharacters,
    },
  });

  const provinceValidator = new Guard({
    enumValidator: {
      enum: ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"],
      errorMessage: invalidProvince,
    },
  });

  const dateValidator = new Guard({
    isValidDate: {
      errorMessage: enterValidDate,
    },
    dateIsAfter: {
      date: new Date(1989, 11, 31),
      errorMessage: leaseStartDateErrorHint,
    },
    dateIsBefore: {
      date: oneDayAgo.toJSDate(),
      errorMessage: i18next.t("translations:rentReporting:formPage:errorMessages:dateBefore", {
        date: oneDayAgo.toFormat("DD"),
      }),
    },
  });

  const rentValidator: ICustomValidator = {
    validate: (amount: string) => {
      const parsedRentAmount = parseFloat(stripNonNumbers(amount));
      return parsedRentAmount >= minRentAmount && parsedRentAmount <= maxRentAmount;
    },
    errorMessage: monthlyRentAmountErrorHint,
  };

  const customDayValidator: ICustomValidator = {
    validate: (amount: string) => {
      return parseFloat(stripNonNumbers(amount)) > 0 && parseFloat(stripNonNumbers(amount)) <= 31;
    },
    errorMessage: rentPaymentDueDateErrorHint,
  };

  const minNumberValidator = new Guard({
    customValidator: rentValidator,
  });

  const dayValidator = new Guard({
    customValidator: customDayValidator,
  });

  const postalCodeValidator = new Guard({
    regexValidator: {
      regex:
        // eslint-disable-next-line security/detect-unsafe-regex
        /(^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][\s-]?\d[ABCEGHJ-NPRSTV-Z]\d$)|(^\d{5}(-\d{4})?$)/,
      errorMessage: invalidPostalCode,
    },
  });

  const validateAddress = (address?: IAddressFormFields) => {
    if (!address) return false;

    const validate = () => {
      if (!textValidator.validate(address.street).valid) return false;
      if (!textValidator.validate(address.city).valid) return false;
      if (!postalCodeValidator.validate(address.postalCode).valid) return false;
      if (!provinceValidator.validate(address.province).valid) return false;

      return true;
    };
    return validate();
  };

  const validateLease = (lease?: ILeaseFormFields) => {
    if (!lease) return false;

    const validate = () => {
      if (!minNumberValidator.validate(lease.monthlyRentAmount).valid) return false;
      if (!dateValidator.validate(lease.leaseStartDate).valid) return false;
      if (!dayValidator.validate(lease.rentPaymentDueDate).valid) return false;

      return true;
    };
    return validate();
  };

  return {
    textValidator,
    provinceValidator,
    dateValidator,
    rentValidator,
    customDayValidator,
    minNumberValidator,
    dayValidator,
    postalCodeValidator,
    validateAddress,
    validateLease,
    manualUploadValidators,
  };
};
