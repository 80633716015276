import styled from "@emotion/styled";
import { BaseArg } from "facepaint";

import { COLORS, mq, toRGBAString } from "@bwll/bw-styles";

const highlightButtonStyles = {
  backgroundColor: toRGBAString(COLORS.PRIMARY["050"], 0.5),
};

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const OptionButton = styled.button(
  ({ isHighlighted, dropdownOptionStyles }: { isHighlighted?: boolean; dropdownOptionStyles?: BaseArg }) => {
    const buttonStyle = isHighlighted
      ? { ...dropdownOptionStyles, ...highlightButtonStyles }
      : { ...dropdownOptionStyles, fontWeight: "normal" };

    return mq(buttonStyle);
  },
);
