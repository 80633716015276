import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, QUERY_KEYS, RentReportingProfile } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const fetchRentReportingProfile = async (accessToken: string, baseUrl: string) => {
  // TODO: Calling /rent-reporting directly while we don't have a firewall for gateway
  const response = await axios.get<RentReportingProfile>(`${baseUrl}/api/tenants`, {
    headers: generateApiHeaders(accessToken),
  });

  return response.data;
};

export const useProfile = <TReturn = RentReportingProfile>(
  customApiOptions?: IApiHookOptions<RentReportingProfile, TReturn>,
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_RENT_REPORTING_PROFILE, accessToken),
    () => fetchRentReportingProfile(accessToken, envConfigs.API_RENT_REPORTING_URL),
    {
      ...queryBehavior<RentReportingProfile, TReturn>({
        expiresIn,
        ...customApiOptions,
        enabled: !!accessToken && (customApiOptions?.enabled ?? true),
      }),
    },
  );
};
