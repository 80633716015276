import styled from "@emotion/native";
import styledWeb from "@emotion/styled";
import { Link } from "react-router-dom";

import { COLORS, fontSize } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Link2 } from "../../../../atoms/Link";
import { Label } from "../../../../atoms/Typography";

export const BreadcrumbItemLink = styledWeb(Link)`
  font-family: Lato-Bold, Lato, sans-serif;
  font-weight: 400;
  font-size: ${styledValue(fontSize.xxs)};
  color: ${COLORS.PRIMARY.DEFAULT};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${COLORS.PRIMARY.DEFAULT};
  }
`;

export const BreadcrumbItemButton = styled(Link2)`
  font-weight: 400;
  font-size: ${styledValue(fontSize.xxs)};
  color: ${COLORS.PRIMARY.DEFAULT};
`;

export const BreadcrumbItemLabel = styled(Label)``;
