import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, LatestRentPeriodTransactions, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const fetchLatestRentPeriodTransactions = async (accessToken: string, baseUrl: string) => {
  // TODO: Calling /rent-reporting directly while we don't have a firewall for gateway
  const response = await axios.get<LatestRentPeriodTransactions>(
    `${baseUrl}/api/rentTransactions/latestRentPeriod`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const useLatestRentPeriodTransactions = (
  customApiOptions?: IApiHookOptions<LatestRentPeriodTransactions>,
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_RENT_REPORTING_LATEST_RENT_PERIOD_TRANSACTIONS, accessToken),
    () => fetchLatestRentPeriodTransactions(accessToken, envConfigs.API_RENT_REPORTING_URL),
    {
      ...queryBehavior({
        expiresIn,
        ...customApiOptions,
        enabled: !!accessToken && (customApiOptions?.enabled ?? true),
      }),
    },
  );
};
