export const enstream = {
  start: {
    title: "Verify your identity using your phone number",
    subtitle: "We couldn't verify your identity based on your answers to the security questions.",
    body: "Next we'll try to verify your identity by matching your mobile subscriber information with a verification code.",
    poweredBy: "Phone verification by",
    primaryButton: "VERIFY VIA TEXT MESSAGE",
  },
  incorrectCode: {
    title: "You entered an incorrect code too many times",
    subtitle: "You entered an incorrect 6-digit verification code which caused it to expire.",
    body: "To continue, send a new code to {{phoneNumber}}. [Change phone number](navigation://)",
    primaryButton: "SEND NEW CODE",
  },
  expired: {
    title: "Your verification code expired",
    changePhone: "To continue, send a new code to\n{{phoneNumber}}. [Change phone number](navigation://)",
    primaryButton: "SEND NEW CODE",
  },
  failed: {
    title: "We couldn't verify your phone number",
    subtitle:
      "Unfortunately, our phone identity verification system doesn't support your mobile phone provider.",
    body: "You can try a different phone number or click below to find out why your phone number couldn’t be verified.\n\n[Why couldn't my phone number be verified?](navigation://)",
    primaryButton: "TRY A DIFFERENT NUMBER",
  },
  verify: {
    primaryCopy: "Verify via text message",
    secondaryCopy: "Enter your phone number so we can send you a verification code.",
    primaryButton: "SEND VERIFICATION CODE",
    phoneNumberLabel: "Phone Number",
    phoneNumberError: "Please enter a mobile phone number",
    generalPhoneNumberError:
      "It looks like you've entered a landline. Please enter your mobile phone number.",
    errorMessage: "Something went wrong or this mobile phone number is invalid. Please try again later.",
  },
  confirm: {
    primaryCopy: "Enter verification code",
    secondaryCopy:
      "Enter the 6-digit code that was sent to\n{{phoneNumber}}. [Change phone number](navigation://)",
    resendButton: "RESEND VERIFICATION CODE",
    primaryButton: "SUBMIT CODE",
    confirmationCodeError: "Please enter your verification code",
    verificationLabel: "Verification Code",
    verificationError:
      "The verification code you entered is incorrect. You have {{attempts}} attempts remaining.",
    errorMessage: "Please enter the correct verification code",
  },
};
