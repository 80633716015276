/**
 * Function placeholder
 *
 * @example
 *
 * const Context = React.createContext({
 *   onPress: noop, // <<< using `noop` as a placeholder so the `onPress` returns actual function instead of undefined
 * })
 */
export const noop = () => undefined;
