import axios from "axios";
import { useQuery } from "react-query";

import { useLatestCreditReport } from "@bwll/bw-hooks";
import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { PaymentProfileList, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const fetchRentReportingTradeline = async (accessToken: string, baseUrl: string, tradeId: string) => {
  const response = await axios.get<PaymentProfileList>(
    `${baseUrl}/api/credit-reports/v2/trades/${tradeId}/payment-profiles`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const isRentReportingTradelineCreditorName = (creditorName: string): boolean =>
  creditorName === "BRWL SELF-RPT RENT" || creditorName === "BRWL SELF RPT RENT";

export const useRentReportingPaymentProfile = () => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  const { data } = useLatestCreditReport();

  const hasRentReportingTradeline = !!data?.openAccounts?.find((item) =>
    isRentReportingTradelineCreditorName(item.creditorName),
  );
  const rentReportingTradelineIndex = data?.openAccounts?.findIndex((item) =>
    isRentReportingTradelineCreditorName(item.creditorName),
  );

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_PAYMENT_PROFILES_BY_TRADE_ID),
    () =>
      fetchRentReportingTradeline(
        accessToken,
        envConfigs.API_GATEWAY_URL,
        data?.openAccounts?.[rentReportingTradelineIndex ?? 0].tradeId ?? "",
      ),
    {
      ...queryBehavior({
        expiresIn,
        enabled: !!accessToken && hasRentReportingTradeline,
      }),
    },
  );
};
