export const quickApply = {
  "headers": {
    "view": "Quick apply",
    "edit": "Edit details"
  },
  "view": {
    "infoBanner": {
      "title": "Quick Apply for your {{vertical}}",
      "description": "We’ve pre-filled the form below. Please confirm your information below to ensure the highest chance of approval."
    },
    "heading": "Review your personal details",
    "buttons": {
      "redirect": "QUICK APPLY",
      "edit": "EDIT"
    },
    "consent": {
      "messages": {
        "carsfast": "I agree by clicking 'Quick Apply', I consent to the disclosure of my information listed above to Carsfast by Borrowell Inc. for the purpose of filling my application. I also agree that I have read the privacy policy and terms & conditions of Carsfast and understand that a hard inquiry may be placed on my credit file.",
        "default": "I agree by clicking “Quick Apply”, I consent to the disclosure of my information (name, address, date of birth, phone number, address and email) to {{companyName}} by Borrowell Inc. for the purpose of pre-populating the required fields on the {{companyName}} application."
      },
      "links": {
        "carsfastPrivacyPolicy": "Privacy policy",
        "carsfastTermsAndConditions": "Terms & conditions"
      },
      "confirmation": "Review all details to proceed"
    },
    "verticalNames": {
      "creditCard": "credit card",
      "loan": "loan",
      "product": "product"
    },
    "additionalInformation": {
      "heading": "Additional Information",
      "description": "You’re one step closer to completing your {{companyName}} application. We only need a few more details!"
    },
    "fixErrorsCaption": "Please complete all required fields for your quote."
  },
  "edit": {
    "heading": "Edit your personal details",
    "fieldGroupLabels": {
      "identity": "Identity",
      "contact": "Contact",
      "address": "Home address"
    },
    "buttons": {
      "save": "Save"
    },
    "fixErrorsCaption": "Fix all errors to proceed",
    "accessibility": {
      "formLabel": "Quick Apply form",
      "fixErrorsCaptionLabel": "Fix errors caption"
    },
    "validationErrors": {
      "invalidAge": "You must be 18 years or older to apply."
    }
  },
  "fieldlabels": {
    "fullName": "Full name",
    "firstName": "First name",
    "lastName": "Last name",
    "address1": "Street address",
    "address2": "Unit number (Optional)",
    "city": "City",
    "region": "Province/Territory",
    "postalCode": "Postal code",
    "dateOfBirth": "Date of birth",
    "phoneNumber": "Phone number",
    "emailAddress": "Email address",
    "address": "Address",
    "housingMonthlyCost": "What is your monthly mortgage/rent payment?",
    "housingStatus": {
      "title": "Accommodation Details",
      "label": "Do you rent or own your place?"
    },
    "timeAtAddress": "When did you start living at your current address?",
    "loanAmount": "Desired loan amount",
    "preferredLanguage": "Language Preference",
    "employmentStatus": {
      "title": "Employment Details",
      "label": "What is your current employment status?"
    },
    "monthlyIncome": "What is your gross monthly income?",
    "employmentDuration": "How long have you worked at your current company?",
    "housingDuration": "How long have you stayed at your current address?",
    "companyName": "What is the name of your current company?",
    "desiredVehicle": "What type of vehicle do you plan to get?"
  },
  "fieldCaptions": {
    "income": "Total yearly income before taxes"
  },
  "success": {
    "title": "Application submitted",
    "description": {
      "autoloans": "Thank you for contacting {{partnerName}}, a pre-qualification specialist will be contacting you very soon to discuss your auto loan. For additional inquiries you can reach us at +1 (800) 957-5394.",
      "carsfast": "Thank you for trusting Carsfast to help find an affordable vehicle for you. We have matched you with one of our dealership partners and you should be getting a call shortly to discuss your available options. To be sure to get the best possible service from us please keep your phone nearby. Also, check your email and SMS regularly over the next few days for any updates."
    },
    "buttons": {
      "backToRecommendations": "BACK TO RECOMMENDATIONS"
    }
  },
  "error": {
    "title": "There was an issue submitting the application",
    "description": "We had an issue submitting your application with {{partnerName}}. You can try to apply directly on {{partnerName}}’s website or try again later.",
    "buttons": {
      "applyOnPartnerWebsite": "APPLY ON PARTNER WEBSITE",
      "backToRecommendations": "BACK TO RECOMMENDATIONS"
    }
  },
  "housingStatus": {
    "rentLabel": "Rent",
    "ownLabel": "Own",
    "otherLabel": "Other"
  },
  "preferredLanguage": {
    "englishLabel": "English",
    "frenchLabel": "Français"
  },
  "employmentStatus": {
    "employedLabel": "Employed",
    "selfemployedLabel": "Self-employed",
    "retiredLabel": "Retired",
    "disabilityLabel": "Disability",
    "otherLabel": "Other",
    "unemployedLabel": "Unemployed"
  },
  "lendingArchDuration": {
    "lessThanThreeMonthsLabel": "Less than 3 months",
    "threeToSixMonthsLabel": "3 to less than 6 months",
    "sixToTwelveMonthsLabel": "6 to less than 12 months",
    "twelveToTwentyFourMonthsLabel": "12 months to less than 2 years",
    "twentyFourMonthsPlusLabel": "2 years+"
  },
  "desiredVehicle": {
    "carLabel": "Car",
    "suvLabel": "SUV",
    "truckLabel": "Truck",
    "vanLabel": "Van"
  }
}
