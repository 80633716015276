import styled from "@emotion/native";
import { ViewProps } from "react-native";

import { COLORS, borderRadius, boxShadow, spacing, toRGBAString } from "@bwll/bw-styles";

import { ModalDefaultLayoutProps } from "./Default.layout.types";

export const Background = styled.View(() => ({
  backgroundColor: toRGBAString(COLORS.NEUTRAL.WARM["800"], 0.9),
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}));

const baseContainerStyles = {
  backgroundColor: COLORS.WHITE,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const modalContainerStyles = ({ width, height, margin = spacing.m }: ModalDefaultLayoutProps) => ({
  ...baseContainerStyles,
  boxShadow: boxShadow,
  borderRadius: borderRadius.s,
  margin,
  ...(width && { width }),
  ...(height && { height }),
});

const overlayContainerStyles = {
  ...baseContainerStyles,
  width: "100%",
  height: "100%",
};

const getContainerStyles = ({ width, height, margin, presentationType }: ModalDefaultLayoutProps) => {
  return presentationType === "modal"
    ? { ...modalContainerStyles({ width, height, margin }) }
    : { ...overlayContainerStyles };
};

// eslint-disable-next-line
// @ts-ignore
export const Container = styled.View<ModalDefaultLayoutProps>(({ width, height, presentationType }) => ({
  ...(getContainerStyles({ width, height, presentationType }) as ViewProps),
}));
