export const servicingDashboard = {
  title: "Rent Advantage",
  badges: {
    paid: {
      icon: "success",
      text: "Reported as paid",
    },
    missed: {
      icon: "close",
      text: "Reported as missed",
    },
    willBePaid: {
      icon: "success",
      text: "Will be reported as paid",
    },
    willBeMissed: {
      icon: "close",
      text: "Will be reported as missed",
    },
  },
  reportingDetails: {
    title: "Reporting details",
    receiptTitle: "Reported Amount",
    amount: "Amount",
    dateReported: "Date reported",
    leaseStart: "Lease start:",
    appearsAs: "Appears as: “{{ creditorName }}” on credit report",
    reportsTo: "Reports to Equifax Canada",
  },
  paymentProfile: {
    paid: "Paid",
    missed: "Missed",
    noInfo: "No Information",
    deferred: "Deferred Payment",
  },
  aboutPaymentHistory: {
    title: "About payment history",
    info1: "Payment history is the most important factor that influences your credit score",
    info2: "Every on-time payment helps to gradually build your credit history",
    info3:
      "A missed payment can cause a drop in your credit score so make sure you make your payments on time",
  },
  somethingDoesntLookRight: {
    title: "Something doesn’t look right?",
    description: "If you see something that doesn’t look right, please visit the FAQs for more information.",
    ctaButtonCopy: "VISIT FAQS",
  },
  contactSupportCta: "CONTACT SUPPORT",

  badge: {
    inReview: "In review",
    rejected: "Proof rejected",
  },

  card: {
    inReview: {
      title: "Proof is being reviewed...",
      description: "The proof of payment you provided for the rent period {{ date }} is being reviewed.",
    },
    rejected: {
      title: "Proof was rejected",
      description: "The proof of payment you provided was not approved.",
      supportMessage:
        "If you think there’s been a problem, please reach out to support or submit a new proof of rent payment.",
      reSubmitButtonText: "SUBMIT NEW RENT PROOF",
    },
  },

  reportedTransactionsList: {
    title: "Reported Amount",
  },
};
