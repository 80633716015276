export const customerPortal = {
  backButton: "BACK TO RENT ADVANTAGE",
  title: "Rent Advantage Settings",
  subscriptionPaymentMethod: "Subscription and Payment Method",
  managedThroughStripe: "Managed through Stripe",
  infoCard: {
    title: "Unsubscribing will close the tradeline associated with your current rent payment.",
    titleStripeSettings: "Unsubscribing will close the tradeline associated with your current rent payment.",
    titleLeaseDetailsSettings: "Keep taking advantage of your subscription",
    description: "By keeping your subscription, you will be taking advantage of a great credit building opportunity.",
    helpCentreLink: "[__View help centre article__]()",
    learnMoreLink: "Learn more",
    exploreBenefitsLink: "Explore Benefits",
  },
  subscriptionSettingsHeader: "Subscription and payment",
  leaseDetailsSettingsHeader: "Lease information",
  rentalAddress: "Rental Address",
  monthlyRentAmount: "Monthly Rent Amount",
};
