export const report = {
  titleLine1: "Your Equifax",
  titleLine2: "Credit Report",
  registeredTrademark: "®",
  fileCreationDate: "File creation date",
  lastPulled: "Report last pulled",
  reportItemsNone: "No report items",
  reportItem: "item",
  reportItems: "items",
  expandLess: "less",
  expandMore: "more",
};