export interface RentProofSubmissionAddModel {
  rentPeriod: string;
  attachments: RentProofAttachmentAddModel[];
  manualRentUploadType: ManualRentUploadType;
}

export interface RentProofAttachmentAddModel {
  filename?: string | undefined;
  file?: File | undefined;
}

export interface RentProofAttachment {
  attachmentId: string;
  filename: string;
  transactionDate?: string;
  description?: string;
  memberComment?: string;
  amount?: number;
}

export interface RentProofAttachmentUpdateModel {
  transactionDate?: string;
  description?: string;
  memberComment?: string;
  amount?: number;
  rentPeriod?: string;
  manualRentUploadType?: ManualRentUploadType;
}

export interface RentProofAttachmentUpdateModelV3 {
  transactionDate?: string;
  description?: string;
  memberComment?: string;
  amount?: number;
  rentPeriod?: string;
  manualRentUploadType?: ManualRentUploadType;
  attachments?: RentProofAttachmentAddModel[];
}

export interface RentProofAttachmentUpdateRequest extends RentProofAttachmentUpdateModel {
  rentProofSubmissionId: string;
  rentProofAttachmentId: string;
}

export interface RentProofAttachmentUpdateRequestV3 extends RentProofAttachmentUpdateModelV3 {
  rentProofSubmissionId: string;
  rentProofAttachmentId: string;
}

export interface RentProofSubmission {
  rentProofSubmissionId: string;
  proofSubmissionStatus: ProofSubmissionStatus;
  rentPeriod: string;
  attachments: RentProofAttachment[];
  dateCreated: string;
}

export enum ProofSubmissionStatus {
  Draft,
  Pending,
  Approved,
  Rejected,
}

export enum ManualRentUploadType {
  RentConfirmation,
  ManualOnboarding,
}
