export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MonthNumberToShorthandMap = {
  1: "J",
  2: "F",
  3: "M",
  4: "A",
  5: "M",
  6: "J",
  7: "J",
  8: "A",
  9: "S",
  10: "O",
  11: "N",
  12: "D",
};

export enum MONTH {
  JAN = 1,
  FEB = 2,
  MAR = 3,
  APR = 4,
  MAY = 5,
  JUN = 6,
  JLY = 7,
  AUG = 8,
  SEP = 9,
  OCT = 10,
  NOV = 11,
  DEC = 12,
}
