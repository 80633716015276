export const adhocPayments = {
  creditBuilderTitle: "Credit Builder payment",
  checkboxLabel:
    "I confirm that I have read and agree to the terms of this **[one-time payment agreement]()**",
  getBackOnTrack:
    "Let's get you back on track! Missed payments have a negative impact on your credit score. Review your payment details and choose a date for the payment to be withdrawn from your linked bank account.",
  missedPayment: "Missed payment amount",
  paymentDateMissed: "Payment date missed",
  totalAmount: "Total Amount",
  confirmButton: "CONFIRM",
  datePickerLabel: "One-time payment date",
  datePickerError: "Please select a valid date",
  schedulePaymentDate:
    "You can schedule a payment from **{{earliestDate}}** to **{{latestDate}}** **not including weekends or holidays.**",
  aboutPayments: {
    aboutPaymentsPoint1: {
      title: "About payments",
      description:
        "Payments must be scheduled 1 business day before your next regular payment (due **{{paymentDate}}**) to bring you back to good standing.",
    },
    aboutPaymentsPoint2: {
      title: "About payment status",
      description: "Please note that it may take up to 5-8 business days for the payment to appear as paid.",
    },
    aboutPaymentsPoint3: {
      title: "About banking",
      description:
        "This payment will be withdrawn from the bank account you connected for your regular Credit Builder payments. If you need to update your banking, please submit a help request.",
    },
    aboutPaymentsPoint4: {
      title: "Need additional help?",
      description: "Contact us at **[buildcredit@borrowell.com]()**",
    },
  },
};
