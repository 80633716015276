export const checkoutConfirmationPage = {
  title: "You’re one step closer to building your credit history!",
  infoSection: {
    title: "What’s next?",
    info: [
      "Once your proof of payment is approved, your rent payment information will be sent to Equifax Canada. Once they receive the information, it can take up to 4 weeks for the tradeline “BRWL SELF-RPT RENT” to appear on your credit report.",
      "You’ll have to log in every month to confirm your rent payment.",
      "Continue to make your rent payments on time and watch your credit history grow.",
    ],
  },
  checkout: "Checkout",
};
