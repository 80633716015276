import { ReactNode, createContext, useMemo, useState } from "react";

import { EXPERIMENT_SOURCE } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

interface IExperimentContext {
  experimentId?: string;
  experimentSource: EXPERIMENT_SOURCE;
  shouldShowControl: boolean;
  setShouldShowControlFalse: () => void;
}

interface IExperimentProviderProps {
  experimentId: string;
  experimentSource?: EXPERIMENT_SOURCE;
  children: ReactNode;
}

export const ExperimentContext = createContext<IExperimentContext>({
  experimentSource: EXPERIMENT_SOURCE.USER_CONFIG,
  shouldShowControl: true,
  setShouldShowControlFalse: () => {
    return;
  },
});

export const ExperimentProvider = ({
  experimentId,
  experimentSource = EXPERIMENT_SOURCE.USER_CONFIG,
  children,
}: IExperimentProviderProps) => {
  const [shouldShowControl, setShouldShowControl] = useState(true);

  const value = useMemo(
    () => ({
      experimentId,
      experimentSource,
      shouldShowControl,
      setShouldShowControlFalse: () => {
        setShouldShowControl(false);
      },
    }),
    [experimentId, shouldShowControl, experimentSource],
  );
  return <ExperimentContext.Provider value={value}>{children}</ExperimentContext.Provider>;
};

export const useExperimentContext = () => useContextWrapper(ExperimentContext, ContextName.ExperimentContext);
