import styled from "@emotion/styled";

import { COLORS } from "@bwll/bw-styles";

const _Divider = styled.hr(() => ({
  backgroundColor: COLORS.NEUTRAL.COOL["100"],
  flex: 1,
  height: "1px",
  borderWidth: 0,
  margin: 0,
}));

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Divider = () => {
  return <_Divider />;
};
