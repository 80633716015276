import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useMemo } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";

import { SplashScreen } from "@bwll/bw-components/next";
import borrowellLogoWhite from "@bwll/bw-components/src/assets/borrowellLogoWhite.png";
import { useSessionContext } from "@bwll/bw-hooks";
import { appInsightsService, determineSessionId } from "@bwll/bw-utils";

export const BORROWELL_MEMBER_ROLE = "Borrowell Member";

interface IProps {
  children: React.ReactNode;
  redirectUrl: string;
  loadingComponent?: React.ReactNode;
  errorComponent?: React.ReactNode;
  isSilentAuth?: boolean;
}
export const AuthCheck = ({
  children,
  redirectUrl,
  loadingComponent,
  errorComponent,
  isSilentAuth = false,
}: IProps) => {
  const {
    removeUser,
    signinRedirect,
    signinSilent,
    user,
    isAuthenticated,
    activeNavigator,
    isLoading,
    events,
    error,
  } = useAuth();
  const { userData, setUserData } = useSessionContext();
  const location = useLocation();

  const signInUserState = useMemo(() => {
    // Using location hook instead of window.location to respect BrowserRouter's
    // basename configuration.
    return { originLocation: location };
  }, [location]);

  // Trigger the sign-in flow.
  useEffect(() => {
    if (hasAuthParams() || isAuthenticated || activeNavigator || isLoading) {
      // Waiting for oidc-client to be ready for sign-in calls.
      return;
    }

    if (isSilentAuth) {
      // Attempt to signinSilent for silent apps to reconcile the session.
      signinSilent({ state: signInUserState });
    } else {
      // Attempt to signinRedirect for the main app to reconcile the session or
      // redirect to the login page.
      signinRedirect({ state: signInUserState });
    }
  }, [
    isSilentAuth,
    activeNavigator,
    isAuthenticated,
    isLoading,
    signinSilent,
    signinRedirect,
    signInUserState,
  ]);

  const handleSignOut = useCallback(() => {
    removeUser();
    signinRedirect({ state: signInUserState });
  }, [removeUser, signinRedirect, signInUserState]);

  useEffect(() => {
    if (isSilentAuth) {
      return;
    }

    // events.* returns a cleanup function
    const cleanupFunctions: (() => void)[] = [];
    cleanupFunctions.push(events.addAccessTokenExpired(handleSignOut));
    cleanupFunctions.push(events.addUserSignedOut(handleSignOut));

    return () => {
      cleanupFunctions.forEach((removeCallback) => removeCallback());
    };
  }, [isSilentAuth, events, handleSignOut]);

  useEffect(() => {
    if (!user) {
      return;
    }

    // redirect Pre-Members with role = "Pre Member" to the redirectUrl.
    if (user.profile.role !== BORROWELL_MEMBER_ROLE) {
      removeUser();
      window.location.replace(redirectUrl);
      return;
    }

    // Store user data in session context to allow other components to access it
    // through useSessionContext hook.
    if (user.access_token) {
      const { access_token: accessToken, expires_in: expiresIn, state } = user;
      const { individualClientId } = jwtDecode(accessToken);
      setUserData({
        accessToken,
        expiresIn,
        sessionId: determineSessionId(accessToken),
        state,
      });
      // Set individualClientId for App Insights events.
      appInsightsService.setIndividualClientId(individualClientId);
    }
  }, [redirectUrl, user, setUserData, removeUser]);

  useEffect(() => {
    if (!error) {
      return;
    }
    if (activeNavigator !== "signinSilent") {
      // Safeguard #1: If there's an error that's not related to signinSilent,
      // attempt to signinSilent for all apps to reconcile the session.
      signinSilent({ state: signInUserState });
      return;
    }
    if (!isSilentAuth) {
      // Safeguard #2: If there's an error with signinSilent, attempt to
      // signinRedirect on the main app.
      signinRedirect({ state: signInUserState });
      return;
    }
  }, [error, isSilentAuth, activeNavigator, signinSilent, signinRedirect, signInUserState]);

  if (isSilentAuth) {
    return <>{children}</>;
  }

  if (isLoading || !userData?.accessToken) {
    return loadingComponent ? <>{loadingComponent}</> : <SplashScreen image={borrowellLogoWhite} />;
  }

  if (user?.expired || error) {
    return errorComponent ? <>{errorComponent}</> : <SplashScreen image={borrowellLogoWhite} />;
  }

  return <>{children}</>;
};
