export const months = {
  "January": "janvier",
  "February": "février",
  "March": "mars",
  "April": "avril",
  "May": "mai",
  "June": "juin",
  "July": "juillet",
  "August": "aout",
  "September": "septembre",
  "October": "octobre",
  "November": "novembre",
  "December": "décembre"
}
