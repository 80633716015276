export const tabBar = {
  dashboard: "Dashboard",
  recommendations: "Recommend",
  recommendationsExperiment: "Discover",
  score: "Score",
  report: "Report",
  creditCoach: "Coach",
  cbl: "Build",
  cblHeader: "Build Credit",
  rentReporting: "Rent",
  verticalLoans: "Loans",
  verticalCarLoans: "Autos",
  verticalCards: "Cards",
};