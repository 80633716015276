export const errorMessages = {
  "network": {
    "server": "Nous avons des difficultés à nous connecter à nos serveurs, veuillez réessayer plus tard.",
    "lockedAccount": "Oups, vous avez trop de tentatives de connexion échouées. Pour votre protection, votre compte sera verrouillé pendant 15 minutes.",
    "unknown": "Un problème s'est produit, veuillez réessayer."
  },
  "login": {
    "password": "Oups, votre adresse courriel ou mot de passe est incorrect.",
    "mfa": "Veuillez entrer votre code d'authentification à deux facteurs.",
    "biometry": "Trop de tentatives. Veuillez vous connecter avec votre mot de passe.",
    "biometryLockout": "Pour votre sécurité, la connexion via {{type}} est temporairement désactivée. Veuillez réessayer dans 30 secondes ou vous connecter avec votre mot de passe.",
    "biometryDisabledByOS": "Pour votre sécurité, la connexion via {{type}} est actuellement désactivée. Veuillez vous connecter avec votre mot de passe.",
    "biometryCancelled": "La connexion biométrique a été annulée.",
    "biometryServerError": "Veuillez entrer votre mot de passe pour sécuriser votre compte.",
    "untrusted": "Nous ne reconnaissons pas cet appareil associé à votre compte. Veuillez consulter vos courriels pour plus de détails.",
    "accountDisabled": "Nous avons des difficultés à vous connecter. Veuillez nous contacter pour obtenir de l'aide."
  }
}
