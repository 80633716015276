import { ReactNode, createContext, useCallback, useState } from "react";

import { BankTransaction, RentTransaction } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

export interface IBankContext {
  bankName: string | undefined;
  setBankName: (value: string) => void;
  memberGuid: string | null;
  setMemberGuid: (value: string) => void;
  accountGuid: string | null;
  setAccountGuid: (value: string) => void;
  transactions?: BankTransaction[];
  setTransactions: (value: BankTransaction[] | undefined) => void;
  latestTransactions?: RentTransaction[];
  setLatestTransactions: (value: RentTransaction[] | undefined) => void;
  latestRentPeriod: string | null;
  setLatestRentPeriod: (value: string) => void;
  clearMxState: () => void;
}

interface IBankProviderProps {
  children: ReactNode;
}

export const BankContext = createContext<IBankContext>({} as IBankContext);

export const BankProvider = ({ children }: IBankProviderProps) => {
  const [bankName, setBankName] = useState<string | undefined>();
  const [memberGuid, setMemberGuid] = useState<string | null>(null);
  const [accountGuid, setAccountGuid] = useState<string | null>(null);
  const [transactions, setTransactions] = useState<BankTransaction[] | undefined>();
  const [latestTransactions, setLatestTransactions] = useState<RentTransaction[] | undefined>();
  const [latestRentPeriod, setLatestRentPeriod] = useState<string | null>(null);

  const clearMxState = useCallback(() => {
    setMemberGuid("");
    setAccountGuid("");
  }, []);

  return (
    <BankContext.Provider
      value={{
        bankName,
        setBankName,
        memberGuid,
        setMemberGuid,
        accountGuid,
        setAccountGuid,
        transactions,
        setTransactions,
        latestTransactions,
        setLatestTransactions,
        latestRentPeriod,
        setLatestRentPeriod,
        clearMxState,
      }}
    >
      {children}
    </BankContext.Provider>
  );
};

export const useBankContext = () => useContextWrapper(BankContext, ContextName.BankContext);
