import { ThemeProvider } from "@emotion/react";
import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";

import { SplashScreen } from "@bwll/bw-components/next";
import { AuthCheck, AuthProvider } from "@bwll/bw-components/next/contexts/authorization";
import borrowellLogoWhite from "@bwll/bw-components/src/assets/borrowellLogoWhite.png";
import {
  BankProvider,
  ENV_TYPE,
  EnvironmentProvider,
  ExperimentStoreProvider,
  FormProvider,
  LoadingProvider,
  ManualUploadProvider,
  NavigationProvider,
  PlatformContextProvider,
  RentAdvantageSessionProvider,
} from "@bwll/bw-hooks";
import { baseTheme } from "@bwll/bw-styles";
import { PLATFORM } from "@bwll/bw-types";

import { AppBlock } from "@app/Context/appBlock";
import { GlobalLoading } from "@app/components";

import { config } from "@config";

const environment = config.ENVIRONMENT as ENV_TYPE;

const authConfig = {
  authority: config.IDENTITY_AUTHORITY,
  clientId: config.OIDC_CLIENT,
  redirectUri: config.REDIRECT_URI,
};

export const ContextProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <PlatformContextProvider platform={PLATFORM.WEB_APP}>
      <EnvironmentProvider environment={environment}>
        <AuthProvider authConfig={authConfig} cookiesDomain={config.COOKIES_DOMAIN}>
          <ThemeProvider theme={baseTheme}>
            <Suspense fallback={<SplashScreen image={borrowellLogoWhite} />}>
              <LoadingProvider>
                <NavigationProvider>
                  <RentAdvantageSessionProvider>
                    <AuthCheck
                      redirectUrl={config.GEMSTONE_BASE_URL}
                      loadingComponent={<GlobalLoading isLoading />}
                    >
                      <ExperimentStoreProvider>
                        <AppBlock>
                          <FormProvider>
                            <BankProvider>
                              <ManualUploadProvider>
                                <HelmetProvider>{children}</HelmetProvider>
                              </ManualUploadProvider>
                            </BankProvider>
                          </FormProvider>
                        </AppBlock>
                      </ExperimentStoreProvider>
                    </AuthCheck>
                  </RentAdvantageSessionProvider>
                </NavigationProvider>
              </LoadingProvider>
            </Suspense>
          </ThemeProvider>
        </AuthProvider>
      </EnvironmentProvider>
    </PlatformContextProvider>
  );
};
