import styled from "@emotion/styled";
import { BaseArg } from "facepaint";
import { useRef } from "react";

import { fontSize, mq, toRGBAString } from "@bwll/bw-styles";

export type RadioInputOption = {
  label: string;
  subLabel?: string;
  subText?: string;
  type?: string;
  value: string | number;
};

type RadioInputProps = {
  option: RadioInputOption;
  selected: boolean;
  idPrefix: string;
  name: string;
  onChange: (value: RadioInputOption) => void;
  withBorder?: boolean;
  active?: boolean;
};

type LabelStyles = "label" | "subLabel" | "subText";

const Label = styled.label<{ type: LabelStyles; isBold?: boolean }>(({ type, isBold = false, theme }) => {
  const common = {
    cursor: "pointer",
  };

  const labelStyles: Record<LabelStyles, BaseArg> = {
    label: {
      fontSize: fontSize.xs,
      fontWeight: isBold ? 600 : 400,
      color: theme.COLORS.NEUTRAL.WARM["800"],
      margin: "auto 0",
    },
    subLabel: {
      fontSize: fontSize.xs,
      fontWeight: isBold ? 600 : 400,
      color: theme.COLORS.NEUTRAL.WARM["800"],
      margin: "auto 0",
    },
    subText: {
      fontSize: fontSize.xxs,
      color: theme.COLORS.NEUTRAL.WARM["700"],
      marginTop: "-7px",
    },
  };
  return mq({
    ...common,
    ...labelStyles[type],
  });
});

const Wrapper = styled.label<{ withBorder?: boolean; selected: boolean; subText?: string }>(
  ({ withBorder = false, selected, subText, theme }) => {
    const common = `
      margin: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      alignitems: center;
      border-bottom: 1px solid ${theme.COLORS.NEUTRAL.COOL["050"]};
      cursor: pointer;
    `;

    const withBorderTheme = `
      border: 1px solid ${theme.COLORS.NEUTRAL.COOL["600"]};
      margin: 16px 0;
      border-radius: 8px;
      padding: ${subText ? "3px 3px 12px 12px" : "12px 3px 12px 12px"};
      :hover {
        border: 1px solid ${theme.COLORS.PRIMARY["500"]};
        background: ${toRGBAString(theme.COLORS.PRIMARY["050"], 0.2)};
      }
    `;

    const selectedTheme = `
      border: 1px solid ${theme.COLORS.PRIMARY["500"]};
      background: ${toRGBAString(theme.COLORS.PRIMARY["050"], 0.2)};
    `;
    const finalWithBorderTheme = selected ? withBorderTheme + selectedTheme : withBorderTheme;

    return withBorder ? common + finalWithBorderTheme : common;
  },
);

const ContentWrapper = styled.div<{ withBorder?: boolean }>(({ withBorder = false }) => {
  const common = `
    display: flex;
    flex: 1;
    :focus-within > div#input-radio {
      animation: backgroun-fade-in 500ms forwards;
    }
  `;
  const withBorderTheme = `
    flex-direction: row-reverse;
  `;
  return withBorder ? common + withBorderTheme : common;
});

const LabelWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const HiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
`;

const ShadowInputFocusEffect = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => toRGBAString(theme.COLORS.PRIMARY["500"], 0)};
  transition: background-color, transform 2000ms cubic-bezier(0, 0, 0.2, 1);
  border-radius: 100%;

  @keyframes backgroun-fade-in {
    0% {
      background-color: ${({ theme }) => toRGBAString(theme.COLORS.PRIMARY["500"], 0)};
    }
    100% {
      background-color: ${({ theme }) => toRGBAString(theme.COLORS.PRIMARY["500"], 0.1)};
    }
  }
`;

const ShadowInput = styled.span<{ checked: boolean }>`
  display: flex;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: ${({ theme }) => theme.COLORS.WHITE};
  border: 2px solid
    ${({ checked, theme }) => (checked ? theme.COLORS.PRIMARY["600"] : theme.COLORS.NEUTRAL.COOL["600"])};
`;

const InnerShadowInput = styled.span<{ checked: boolean }>`
  display: flex;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${({ checked, theme }) => (checked ? theme.COLORS.PRIMARY["600"] : "transparent")};
  transition: transform ease 280ms, background-color ease 280ms;
  transform: ${({ checked }) => (checked ? "scale(1)" : "scale(0.001)")};
`;

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const RadioInput = ({
  option,
  selected,
  idPrefix,
  onChange,
  name,
  withBorder = false,
}: RadioInputProps) => {
  const { label, subLabel, subText, value } = option;
  const id = `${idPrefix}-${value}`;
  const inputRef = useRef<HTMLInputElement>(null);
  const focusInput = () => {
    inputRef.current?.focus();
  };
  return (
    <>
      <Wrapper
        withBorder={withBorder}
        data-testid={`${id}-wrapper`}
        data-cy={`${id}-wrapper`}
        selected={selected}
        htmlFor={id}
        subText={subText}
      >
        <ContentWrapper withBorder={withBorder}>
          <HiddenInput
            type="radio"
            value={value}
            id={id}
            checked={selected}
            name={name}
            onChange={() => {
              onChange(option);
            }}
            aria-checked={selected}
            data-testid={`${id}-input`}
            data-cy={`${id}-input`}
            ref={inputRef}
          />
          <ShadowInputFocusEffect
            onClick={() => {
              focusInput();
              onChange(option);
            }}
            data-testid={`${id}-shadow-input`}
            data-cy={`${id}-shadow-input`}
            id={`${id}-input-radio`}
          >
            <ShadowInput checked={selected}>
              <InnerShadowInput checked={selected} />
            </ShadowInput>
          </ShadowInputFocusEffect>
          <LabelWrapper>
            <Label
              htmlFor={id}
              data-testid={`${id}-label`}
              data-cy={`${id}-label`}
              type="label"
              isBold={withBorder}
            >
              {label}
            </Label>
            {subLabel ? (
              <Label
                htmlFor={id}
                data-testid={`${id}-subLabel`}
                data-cy={`${id}-subLabel`}
                type="subLabel"
                isBold={withBorder}
              >
                {subLabel}
              </Label>
            ) : null}
          </LabelWrapper>
        </ContentWrapper>
        {subText ? (
          <Label htmlFor={id} type="subText">
            {subText}
          </Label>
        ) : null}
      </Wrapper>
    </>
  );
};
