import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Button = styled.Pressable<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: ${styledValue(spacing.xl)};
  background-color: ${({ isActive }) => (isActive ? COLORS.WHITE : COLORS.NEUTRAL.COOL["050"])};
  padding-vertical: ${styledValue(spacing.xxxs)};
`;
