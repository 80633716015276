export const settingOptions = {
  "title": "Paramètres du compte",
  "logOut": "Déconnexion",
  "contactUs": "Contacter Borrowell",
  "updatePassword": "Mise à jour du mot de passe",
  "allowNotifications": "Autoriser les notifications",
  "notificationPreferences": "Préférences de notification",
  "editProfile": "Modifier votre profil",
  "clearStorage": "Effacer le stockage",
  "helpCenter": "Centre d'aide",
  "closeIcon": "Fermer les paramètres",
  "version": "Version",
  "language": "Langue",
  "rentReporting": "Le Bienfait du Loyer"
}
