import styled from "@emotion/native";

import { styledValue } from "@bwll/bw-utils";

import { ImageSize } from "./CreditCardImage.types";

export const DEFAULT_IMAGE_WIDTH_PX = 160;
export const DEFAULT_IMAGE_HEIGHT_PX = 100;

export const HorizontalImage = styled.Image<ImageSize>`
  height: ${({ height }) => styledValue(height)};
  width: ${({ width }) => styledValue(width)};
`;

export const VerticalImageContainer = styled.View<ImageSize>`
  position: relative;
  width: ${({ width }) => styledValue(width)};
  height: ${({ height }) => styledValue(height)};
`;

export const VerticalImage = styled.Image<ImageSize>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => styledValue(width)};
  height: ${({ height }) => styledValue(height)};
`;
