export const settingOptions = {
  title: "Account Settings",
  logOut: "Logout",
  contactUs: "Contact Borrowell",
  updatePassword: "Update Your Password",
  allowNotifications: "Allow notifications",
  notificationPreferences: "Notification Preferences",
  editProfile: "Edit Your Profile",
  clearStorage: "Clear Storage",
  helpCenter: "Help Center",
  closeIcon: "Close settings",
  version: "Version",
  language: "Language",
  rentReporting: "Rent Advantage",
};
