import React, { ReactNode, createContext, useState } from "react";

import { IUserData } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

export interface ISessionContext {
  userData: IUserData;
  setUserData: (value: IUserData) => void;
}

interface ISessionProviderProps {
  children: ReactNode;
  initialUserData?: IUserData;
}

export const SessionContext = createContext<ISessionContext>({} as ISessionContext);

export const SessionProvider = ({ children, initialUserData }: ISessionProviderProps) => {
  const [userData, setUserData] = useState<IUserData>({
    accessToken: initialUserData?.accessToken ?? "",
    expiresIn: initialUserData?.expiresIn ?? 0,
    sessionId: initialUserData?.sessionId ?? "",
    state: initialUserData?.state ?? "",
  });

  return <SessionContext.Provider value={{ userData, setUserData }}>{children}</SessionContext.Provider>;
};

export const useSessionContext = () => useContextWrapper(SessionContext, ContextName.SessionContext);
