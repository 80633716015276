export const dashboard = {
  lastUpdated: "Your score was updated",
  scrollTitle: "Borrowell",
  subTitle: "Here’s your overview as of {{lastUpdated}}",
  title: "Welcome, {{firstName}}",
  applyButton: "APPLY",
  illustrativePromo: {
    v1: {
      title: "Your Recommendations",
      body: "{{username}}, we have recommendations based on your credit profile.",
      cta: "View Recommendations",
      ctaBadgeContent: "6",
    },
    v2: {
      title: "Your Recommendations",
      body: "{{username}}, we have recommendations based on your credit profile",
      cta: "EXPLORE NOW",
      ctaBadgeContent: "6",
    },
    v3: {
      title: "Credit cards, loans & much more!",
      body: "Explore a range of financial products",
      cta: "Explore Now",
      ctaBadgeContent: "6",
    },
    v4: {
      title: "Credit cards, loans & much more!",
      body: "Explore a range of financial products",
      cta: "EXPLORE NOW",
      ctaBadgeContent: "6",
    },
  },
  cblPromoCardV2: {
    title: "Build your credit history",
    body: "Build your credit history and mix, which can unlock more loans, credit cards & more!",
    cta: "LEARN MORE",
  },
  rentReportingPromoCard: {
    default: {
      title: "Build your credit history",
      body: "Report your rent on your Equifax Canada credit report and start building your credit history!",
      cta: "EXPLORE NOW",
    },
    v1: {
      title: "Build credit to achieve your financial goals",
      body: "Report your rent on your Equifax Canada credit report and start building your credit history!",
      cta: "EXPLORE NOW",
    },
    v2: {
      title: "Build credit to achieve your financial goals",
      body: "Build your credit history with rent payments you’re already making!",
      cta: "EXPLORE NOW",
    },
  },
  poweredByBorrowell: "Powered by Borrowell",
  loading: "Loading your dashboard...",
  creditCoachTitle: "I'm Molly, your Credit Coach. I can help you understand your credit score.",
  creditCoachLink: "Visit my Credit Coach",
  cards: {
    appReview: {
      title: "Are you enjoying the app?",
      yes: "I am!",
      no: "Not really",
    },
    appRate: {
      title: "We’re glad you’re enjoying it 😃",
      prompt: "We’d appreciate it if you left us a rating on the {{storeName}}!",
      confirmButton: "RATE APP",
      notNowButton: "NOT NOW",
    },
    feedback: {
      title: "Help us improve 🙂",
      prompt: "Would you mind giving us feedback on what we could improve?",
      confirmButton: "SURE",
      notNowButton: "NOT NOW",
    },
    notificationsPrompt: {
      titlePrefix: "Your score updates ",
      titleSuffix: ".\nGet notified when it does!",
      prompt: "GET NOTIFICATIONS",
    },
    creditCoachTips: {
      moreUpdate: "SEE 1 MORE UPDATE",
      moreUpdates: "SEE {{numberOfUpdates}} MORE UPDATES",
    },
    promotedProduct: {
      sponsored: "Sponsored",
      applyNow: "APPLY NOW",
      approvalChance: "Approval Chance",
      annualFee: "Annual Fee",
      purchaseRate: "Purchase Rate",
      cashAdvance: "Cash Advance",
      productDisclosure: "Sponsored Product Disclosure",
      disclosureDescription:
        "The offers that appear on this app are from third party advertisers from which Borrowell receives compensation. This compensation may impact how and where products appear on this site (including, for example, the order in which they appear). It is this compensation that enables Borrowell to provide you with services like free access to your credit score and report. Borrowell strives to provide a wide array of offers for our members, but our offers do not represent all financial services companies or products.",
    },
    scoreTracker: {
      noChangeMarkdown: "**No change**",
      upMarkdown: "Up **{{count}} point**",
      downMarkdown: "Down **{{count}} point**",
      upMarkdown_plural: "Up **{{count}} points**",
      downMarkdown_plural: "Down **{{count}} points**",
      zeroMarkdown: "**Zero score**",
      noChange: "No change",
      up: "Up",
      down: "Down",
      zero: "Zero score",
      pointsDifference: "{{ count }} point",
      pointsDifference_plural: "{{ count }} points",
      whatDoesScoreMean: "What does my credit score mean?",
      whatDoesZeroScoreMean: "What does zero credit score mean?",
      ers: {
        shortTitle: "Calculated using ERS 2.0",
        title: "Score calculated using ERS 2.0",
        tooltip: {
          title: "About ERS 2.0",
          content:
            "ERS 2.0 (the Equifax Risk Score) is based on Equifax's proprietary model and may not be the same score used by third parties to assess your creditworthiness. The provision of this score to you is intended for your own educational use. Third parties will take into consideration other information in addition to a credit score when evaluating your creditworthiness.",
          link: "Learn more about credit scores",
        },
      },
      today: "Today",
      nextUpdateIs: "Next update is",
      learnMore: "Learn More",
      inNumDays: "in {{count}} days",
      inNumDaysToday: "today",
      inNumDaysTomorrow: "tomorrow",
      help: {
        title: "What does my credit score mean?",
        zeroScore: "What does zero credit score mean?",
        paragraph1: "Your credit score is ",
        excellent:
          "With an excellent score, you may qualify for a wide variety of financial products with the best possible rates. Keep up the great work.",
        good: "With a good credit score, you may qualify for a variety of credit options with some of the lowest interest rates available.",
        fair: "With a fair credit score, you may qualify for credit. However, your options may be somewhat limited and you may not get the best rate.",
        below:
          "With a below average credit score, you may qualify for credit. However, your options may be limited and you may not get the best rate.",
        poor: "With a poor credit score, you may have difficulty accessing credit.",
      },
      toDoNext: {
        title: "What you could do next",
        creditCoach: "View the Credit Coach to see how you could improve",
        recommendations: "Check out financial products you qualify for",
      },
    },
    sponsoredCarousel: {
      yourOffers: "Your offers",
      cardShowing: "Showing {{curCard}} of {{numCards}}",
      percentageTransform: "{{value}}%",
      dollarSignTransform: "${{value}}",
      sponsoredHeaderSuffix: " | Sponsored",
      sponsoredCards: {
        approvalChance: "Approval Chance:",
        headerToolTip:
          "Borrowell receives additional compensation for products with the label “Sponsored”. This compensation helps Borrowell provide you with free tools like your weekly credit score.",
        preSelectedBanner: "PRE-SELECTED",
        cta: "VIEW PRODUCT",
        loans: {
          header: "Loan",
          payment: "Payment",
          loanAmount: "Loan Amount",
          apr: "APR",
          term: "Term",
        },
        creditCards: {
          header: "Credit Card",
          preSelectedBanner: "Pre-Selected",
          annualFee: "Annual Fee",
          interest: "Interest",
          cashAdvance: "Cash Advance",
          balanceTransfer: "Balance Transfer",
        },
        autoLoans: {
          header: "Car Loan",
        },
        figLoan: {
          cta: {
            preApproved: "INSTANT APPLY",
            notStarted: "GET MY INSTANT QUOTE",
          },
          title: "Borrowell Personal Loan",
          preSelectedBanner: "You’re pre-approved!",
          titleTooltip:
            "Based on your credit report and Borrowell account profile information, you’ve been pre-approved for this loan from Fig Financial. You meet the qualification criteria and have an excellent chance of being approved for this loan!",
          loanAmount: "Loan Amount",
          monthlyPayment: "Monthly Payment",
          monthlyPaymentAmount: "{{amount}}/mo",
          apr: "APR",
          term: "Term",
          termDuration: "{{term}} months",
          daysLeft: "Act fast! Your offer will expire in {{count}} day",
          daysLeft_plural: "Act fast! Your offer will expire in {{count}} days",
        },
        insurance: {
          header: "Insurance",
        },
        bankAccounts: {
          header: "Bank Accounts",
        },
        mortgages: {
          header: "Mortgage",
        },
        investments: {
          header: "Investing",
        },
      },
    },
    firstLoginCarousel: {
      title: "How we can help",
    },
    preSelected: {
      approvalChance: "Approval Chance",
      loans: {
        payment: "Payment",
        amount: "Amount",
        apr: "APR",
        term: "Term",
      },
      creditCards: {
        header: "Credit Card | Sponsored",
        headerToolTip:
          "Borrowell receives additional compensation for products with the label “Sponsored”. This compensation helps Borrowell provide you with free tools like your weekly credit score.",
        preSelectedBanner: "Pre-Selected",
        annualFee: "Annual Fee",
        interest: "Interest",
        cashAdvance: "Cash Advance",
        balanceTransfer: "Balance Transfer",
        cta: "APPLY NOW",
      },
      fairstone: {
        quickApply: "Quick Apply",
        v1: {
          title: "You've been pre-selected for a loan from Fairstone!",
          description:
            "Use this to consolidate debt, cover unexpected expenses or give your budget some flexibility.",
          cta: "VIEW OFFER",
        },
        v2: {
          title: "Fairstone",
          description: "Use this loan to consolidate debt.",
          quickApplyCta: "APPLY NOW",
          topBanner: "YOU'RE PRE-SELECTED",
          cta: "VIEW FULL DETAILS",
        },
        v3: {
          title: "You're pre-selected!",
          description: "Use this loan to consolidate debt.",
          cta: "VIEW OFFER",
        },
      },
      ccc: {
        title: "You’ve been pre-selected for a loan from Consumer Capital Canada",
        description:
          "Start your online loan application today and borrow up to {{amount}} at competitive rates.",
        cta: "VIEW OFFER",
      },
      lendful: {
        title: "You’ve been pre-selected for a loan from Lendful",
        description:
          "Use this to cover any of your needs, such as paying off debt or dealing with surprise expenses.",
        cta: "VIEW OFFER",
      },
      mbna: {
        title: "You’ve been pre-selected to apply for a credit card with MBNA!",
        description:
          "You could get a 0% promotional annual interest rate (“AIR”)† for 12 months on balance transfers✪ completed within 90 days of account opening. Conditions and fees apply.",
        cta: "VIEW OFFER",
      },
      amex: {
        title: "You are pre-qualified for the {{ cardName }}",
        description: "Enjoy benefits and perks of becoming an American Express® Cardmember. T&Cs Apply.",
        cta: "VIEW DETAILS",
        dynamicDescription: {
          cobalt:
            "Earn up to 50,000 Membership Rewards® points in your first year. Spend and other conditions apply.",
          aeroplanReserve: "Elevate every step of your journey.",
          aeroplan: "Find your happy places everywhere you go.",
          platinum:
            "Discover rewards that can take you places, with access to benefits that elevate every experience.",
          gold: "Earn points on your travel and everyday purchases so you can stop dreaming and start experiencing your perfect vacation.",
          green:
            "Earn a Welcome Bonus of 10,000 Membership Rewards® points. Spend and other conditions apply.",
          simplyCashPreferred:
            "Earn up to $400 cash back in the first 4 months. Spend and other conditions apply.",
          simplyCash:
            "Earn 4% cash back in your first 6 months (up to $200 cash back). Spend and other conditions apply.",
          marriott:
            "Whether you're close to home or away, earn points wherever you go and redeem whenever you're ready.",
          ram: "Experience exceptional travel benefits that make every escape one to remember – while earning more Miles along the way.",
          pam: "Earn a Welcome Bonus of 2,000 AIR MILES®* Bonus Miles. Spend and other conditions apply.",
          sam: "Earn a Welcome Bonus of 500 AIR MILES®* Bonus Miles. Spend and other conditions apply.",
        },
      },
      bmo: {
        title: "You are pre-qualified for {{ cardName }}",
        description: "Enjoy benefits and perks of becoming a BMO card holder.",
        cta: "VIEW OFFER",
        dynamicDescription: {
          noFeeCashBack: "Enjoy the most cash back on groceries in Canada without paying an annual fee.*",
          noFeeAmex: "Fly farther faster when you get AIR MILES Reward Miles on every purchase.",
          eclipseVisaRoc: "One card. Infinite ways to be rewarded.",
          eclipseVisaQc: "One card. Infinite ways to be rewarded.",
          cashBackWorldElite: "The only card that gives you 5% cash back on groceries.*",
          airMilesWorldElite: "Maximize the Miles! Enjoy your rewards and travel perks sooner.",
          worldElite:
            "Experience a world of extra rewards at home and away with a great earn rate and travel perks.",
          bmoRewards: "Earn points every time you spend and see the possibilities add up.",
          preferred: "Give yourself a little breathing room with our lowest interest rate.",
        },
      },
      skycap: {
        title: "SkyCap Financial has pre-selected you for a loan!",
        description:
          "Are you looking for assistance to get through some tough times? At SkyCap we are here to help. We say yes when banks say no.",
        cta: "VIEW OFFER",
      },
      sympleloans: {
        title: "Great news! You’ve been pre-qualified for a loan from Symple Loans",
        description:
          "You can use this loan to consolidate high-interest debt, pay off credit card bills, fund upcoming expenses, and more.",
        cta: "VIEW OFFER",
      },
      lenddirect: {
        title:
          "Congrats! You’ve been pre-selected for a personalized loan offer just for you from LendDirect!",
        description:
          "LendDirect offers personal loans built with you in mind. Whatever your financial goals or needs might be, LendDirect can help!",
        cta: "VIEW OFFER",
      },
    },
    creditFactors: {
      title: "What's impacting your score",
      missedPaymentsTitle: "Missed payments",
      creditInquiriesTitle: "Credit inquiries",
      creditInquiriesV2Title: "Hard inquiries",
      creditUtilizationTitle: "Credit utilization",
      derogatoryMarksTitle: "Derogatory marks",
      avgCreditAgeTitle: "Avg. credit age",
      totalAccountsTitle: "Total accounts",
      viewDetails: "VIEW DETAILS",
      viewMoreDetails: "VIEW MORE DETAILS",
      updates: {
        placeholder: "{{amount}} item(s) changed",
        missedPayment: "{{amount}} missed payment",
        missedPayment_plural: "{{amount}} missed payments",
        creditInquiriesNew: "{{amount}} new credit inquiry",
      },
      tooltips: {
        missedPayments:
          "Missed payments are any bill payments that the lender reported as not being paid on time.",
        missedPaymentsImpact:
          "Missed payments have a high impact on your credit score and accounts for approximately 35% of how your score is calculated.",
        creditInquiries:
          "A credit inquiry is when a lender or company makes a request to review your credit reports and can stay on your report for 3 years. The inquiries that you see here are only hard inquiries.",
        creditInquiriesImpact:
          "Credit inquiries have a low impact on your credit score and accounts for approximately 10% of how your score is calculated.",
        creditUtilization:
          "Credit utilization measures how much of your total credit card and line of credit limits you are using.",
        creditUtilizationImpact:
          "Credit utilization has a high impact on your credit score and accounts for approximately 30% of how your score is calculated.",
        derogatoryMarks:
          "Derogatory marks are events such as, collections, bankruptcies, or legal items on your credit report that indicates you didn’t fulfill your credit obligations. These events can stay on your credit report for 6 - 10 years and negatively impacts your credit.",
        derogatoryMarksImpact:
          "Derogatory marks have a high impact on your credit score and the presence of just one derogatory mark could have a significant negative impact on your score.",
        avgCreditAge:
          "Average credit age refers to the average length of time you've had credit accounts open.",
        avgCreditAgeImpact:
          "Average credit age has a medium impact on your credit score and accounts for approximately 15% of how your score is calculated.",
        totalAccounts:
          "This refers to the number of open accounts you have. Lenders like to see that you have used a mix of credit accounts responsibly.",
        totalAccountsImpact:
          "The total number of accounts you have has low impact on your credit score and accounts for approximately 10% of how your score is calculated.",
      },
      month: "{{count}} month",
      month_plural: "{{count}} months",
      year: "{{count}} year",
      year_plural: "{{count}} years",
      yr: "{{count}} yr",
      yr_plural: "{{count}} yrs",
      mo: "{{count}} mo",
      mo_plural: "{{count}} mos",
      impact: {
        low: "Low",
        medium: "Medium",
        high: "High",
      },
      scoreImpactTitle: "SCORE IMPACT",
    },
  },
  reengagingMemberBannerMessage:
    "We’re currently updating your credit score and this might take some time. Here’s your credit score from {{date}}",
  systemIssueMessage: "Due to the Equifax outage, there may be a delay in the accuracy of your credit score.",
  topPicks: {
    title: "Top picks for Prime Credit Score",
  },
  bestValueCreditCards: {
    headerSuffix: " FIRST-YEAR VALUE",
  },
};
