export const productList = {
  "sortAndFilters": {
    "sort": {
      "prefix": "Sort by:",
      "approvalChance": "Approval chance (high to low)",
      "fees": "Fees (low to high)",
      "recommended": "Recommended",
      "firstYearValue": "First year value (high to low)"
    }
  },
  "adDisclosureLink": "Ad disclosure"
}
