export const noHit = {
  "supportCard": {
    "haveQuestions": "Vous avez d'autres questions?",
    "happyToHelp": "Nous sommes heureux de vous aider!",
    "button": {
      "contactSupport": "VISITER LE CENTRE D'AIDE"
    }
  },
  "formLabel": {
    "fullName": "Nom complet",
    "dob": "Date de naissance",
    "phoneNumber": "Numéro de téléphone",
    "address": "Adresse résidentielle"
  },
  "firstNoHit": {
    "title": "Désolé, nous n'avons pas trouvé votre profil de crédit Equifax.",
    "subtitle": "Veuillez revoir les informations que vous avez soumises et réessayer.",
    "button": {
      "resubmitInformation": "SOUMETTRE À NOUVEAU LES INFORMATIONS"
    }
  },
  "secondNoHit": {
    "title": "Vos informations ne correspondent pas au dossier d'Equifax",
    "subtitle": "Voici quelques mesures que vous pouvez prendre.",
    "accordion": {
      "contactEquifax": {
        "title": "Contacter Equifax",
        "markdownContent": "Contactez Equifax au [__1-866-828-5961__](tel:18668285961) pour confirmer que les informations que vous avez entrées par l'intermédiaire de Borrowell correspondent aux informations qu'Equifax possède actuellement sur vous. \n \nInformations que vous avez entrées :"
      },
      "contactBorrowell": {
        "title": "Contacter Borrowell",
        "markdownContent": "Une fois que vous avez contacté Equifax et confirmé vos informations, contactez-nous [__here__]() et nous activerons votre profil pour que vous puissiez obtenir votre cote de crédit gratuite!"
      }
    }
  },
  "updateName": {
    "title": "Mettez à jour votre nom complet",
    "subtitle": "Veuillez vérifier ces informations pour vous assurer qu'elles sont correctes.",
    "button": {
      "updateInformation": "METTRE À JOUR LES INFORMATIONS"
    }
  },
  "updateDob": {
    "title": "Mettre à jour votre date de naissance",
    "subtitle": "Veuillez vérifier ces informations pour vous assurer qu'elles sont correctes.",
    "button": {
      "updateInformation": "METTRE À JOUR LES INFORMATIONS"
    }
  },
  "updatePhoneNumber": {
    "title": "Mettre à jour votre numéro de téléphone",
    "subtitle": "Veuillez vérifier ces informations pour vous assurer qu'elles sont correctes.",
    "button": {
      "updateInformation": "METTRE À JOUR LES INFORMATIONS"
    }
  },
  "updateAddress": {
    "title": "Mettre à jour votre adresse",
    "subtitle": "Veuillez vérifier ces informations pour vous assurer qu'elles sont correctes.",
    "button": {
      "updateInformation": "METTRE À JOUR LES INFORMATIONS"
    }
  }
}
