import styled from "@emotion/styled";

import { Flex } from "@bwll/bw-components";
import { mq, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const RentAmountsContainer = styled(Flex)(() =>
  mq({
    margin: `${styledValue(spacing.xs)} 0`,

    "> div + div": { marginTop: spacing.xxs },
  }),
);

export const RentAmountLabel = styled.strong(({ theme }) =>
  mq({
    ...theme.paragraph,
    fontWeight: 600,
    color: theme.COLORS.NEUTRAL.WARM[700],
    margin: 0,
  }),
);

export const RentAmountValue = styled.span(({ theme }) =>
  mq({
    ...theme.paragraph,
    color: theme.COLORS.NEUTRAL.WARM[700],
    margin: 0,
  }),
);
