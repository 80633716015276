import styled from "@emotion/native";

import { COLORS, borderRadius } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { CardLayoutProps } from "./CardLayout.types";

export const StyledCard = styled.View<CardLayoutProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 444px;
  border-radius: ${styledValue(borderRadius.s)};
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.WHITE};
`;
