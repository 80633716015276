import styled from "@emotion/native";

import { COLORS, fontSize, fonts, letterSpacing, lineHeight } from "@bwll/bw-styles";

import { RegularText } from "../Default";

const BaseStyledComponent = styled(RegularText)(() => ({
  fontWeight: "700",
  fontSize: fontSize.s,
  lineHeight: lineHeight.m,
  letterSpacing: letterSpacing.m,
  color: COLORS.NEUTRAL.WARM["800"],
}));

export const PrimaryTitleComponent = styled(BaseStyledComponent)(() => ({
  fontSize: fontSize.xl,
  lineHeight: lineHeight.xxl,
}));

export const SecondaryTitleComponent = styled(BaseStyledComponent)(() => ({
  fontSize: fontSize.l,
  lineHeight: lineHeight.xl,
}));
