export const creditFactors = {
  whatDoesThisMean: "What does this mean?",
  notApplicable: "N/A",
  missedPayments: {
    increase: {
      missed: "{{count}} new missed payment",
      missed_plural: "{{count}} new missed payments",
      deferred: "{{count}} new deferred payment",
      deferred_plural: "{{count}} new deferred payments",
    },
    content: {
      whatAreTitle: "What are missed payments?",
      whatAreContent:
        "Missed payments are any payments that the lender has reported as being at least 30 days past due.",
      howToImproveTitle: "How to improve",
      makePaymentSoonTitle: "Make a payment as soon as possible",
      makePaymentSoonContent:
        "Make the payment as soon as possible to avoid the  missed payment turning into a [derogatory mark.]()",
      thinkThereIsErrorTitle: "If you think there’s an error",
      contactTheCreditorTitle: "Contact the creditor",
      contactTheCreditorContent:
        "The next step is to reach out to your creditor to ask them to fix the error.",
      contactTheBureauTitle: "Contact the credit bureaus",
      contactTheBureauContent:
        "If your creditor is taking too long to fix the error or is simply refusing to correct the mistake, you may need to reach out directly to the credit bureaus to dispute your credit report.",
    },
  },
  creditInquiries: {
    numberOfInquiries: "# of inquiries",
    tooltips: {
      detailsBody:
        "A credit inquiry is when a lender or company makes a request to review your credit reports and can stay on your report for 3 years.\n\nThe inquiries that you see here are only hard inquiries.",
      equifaxDisputeBody:
        "Equifax couldn’t provide the name of the lender that made the hard inquiry.\n\nPlease contact Equifax at [__{{ phoneNumber }}__](), Monday through Friday between 9 AM to 5 PM (EST) for more details.",
    },
    noInquiries: "You have no credit inquiries. Keep up the great work!",
    new: {
      title: "{{ count }} new inquiry",
      title_plural: "{{ count }} new inquiries",
      view_history: "View inquiry history",
    },
    improvementTips: {
      first: "Spread out your credit applications to give your credit score time to recover.",
      second: "Only apply for products that you’re sure you want",
      third:
        "If you’re applying for an auto loan or mortgage, credit bureaus will combine hard pulls made within a 14 - 45 day grace period for these types of loan inquiries and count them as a single hard check.",
      fourth: "Check out your approval chances before you apply to avoid unecessary inquiries",
    },
    history: {
      title: "Hard inquiry history",
      subtitle: "Here are all your hard inquiries that appear on your credit report.",
    },
    promoCard: {
      title: "Apply with confidence",
      cta: "View offers",
    },
    equifaxDisputeCardTitle: "Don’t recognize an inquiry?",
    increase: "{{count}} new credit inquiry",
    increase_plural: "{{count}} new credit inquiries",
    content: {
      whatAreTitle: "What are credit inquiries?",
      whatAreContent:
        "There are two types of credit inquiries: hard and soft credit inquiries. Hard inquiries can impact your credit score and stay on your report for 3 years while soft inquiries don’t impact your credit score.\n\nThe inquiries that you see here are only hard inquiries as Borrowell doesn’t show soft inquiries on your credit report.",
      howToImproveTitle: "How to improve",
      spreadYourLoanTitle: "Spread out your loan or credit card applications",
      spreadYourLoanContent:
        "Spread out your credit applications to give your credit score time to recover. Carefully consider whether you need to apply for the product and how that product will affect your overall financial health.",
      groupCreditInquiriesTitle: "Group credit inquiries by product type",
      groupCreditInquiriesContent:
        "While applying for a series of credit products in quick succession is typically a red flag, there is an exception if you are applying for an auto loan or mortgage. Credit bureaus will combine hard pulls made within a grace period for certain loan inquiries and count them as a single hard check. \n\nThe grace period to group these types of hard inquiries is usually 14 - 45 days.",
      thinkThereIsErrorTitle: "If you think there’s an error",
      contactTheCreditorTitle: "Contact the creditor",
      contactTheCreditorContent:
        "The next step is to reach out to your creditor to ask them to fix the error.",
      contactTheBureauTitle: "Contact the credit bureaus",
      contactTheBureauContent:
        "If your creditor is taking too long to fix the error or is simply refusing to correct the mistake, you may need to reach out directly to the credit bureaus to dispute your credit report.",
    },
  },
  creditUtilization: {
    increase: {
      lessThan30: "Increased by {{ count }}%",
      moreThan30: "Increased by {{ count }}% since last update",
      summaryLessThan30: "Utilization increased by {{ count }}%",
      summaryMoreThan30: "Utilization increased by {{ count }}% since last update",
    },
    chart: {
      accountsTotal: "Accounts Total",
      totalBalance: "Total balance: {{ balance }}",
      totalLimit: "Total limit: {{ limit }}",
    },
    tooltips: {
      detailsBody:
        "Credit utilization measures how much of your total credit card and line of credit limits you are using.",
      noTradesTitle: "Why you’re seeing this",
      noTradesBody:
        "You’re seeing N/A because you have no active revolving trades such as a credit card or line of credit.",
    },
    naUtilization: {
      notice:
        "You don’t have any credit utilization since there are no credit cards or lines of credit on your credit report.",
      tip: {
        body: "Consider getting a credit card to help you start building your credit history.",
        link: "__[Explore credit cards](https://borrowell.com/blog/how-much-does-my-credit-score-drop-if-I-miss-a-payment)__",
      },
    },
    zeroUtilization: {
      notice:
        "0% credit utilization shows lender that you’re not using your credit which may have a negative impact on your credit score.",
      tip: {
        body1:
          "Charge a small recurring purchase to your credit cards such as a monthly subscription to Netflix or Spotify.",
        body2: "Just make sure you don’t forget to pay off these charges by each payment due date!",
      },
    },
    lowUtilizationNotice:
      "Great work keeping your credit utilization under 30% and your total balance under {{ limit }}.",

    mediumUtilizationNotice:
      "Your credit utilization is above 30% which means you’re using more than the optimal amount of credit.",
    highUtilizationNotice:
      "Your credit utilization is 75% or greater which means you’re using more than the optimal amount of credit.",
    improvementTip: {
      title: "What to know",
      body1:
        "Try to keep your utilization below 30% or your total balance under {{ limit }}. This shows lenders that you can use credit responsibly",
      body2: "Ask your lender for a credit limit increase to increase your total limit",
    },
    overUtilization: {
      notice: "Your credit utilization is above 100% which means you’re over your total credit limit.",
      tip: "Pay your balance as soon as possible to bring it below 100% to avoid avoid incurring fees or penalties.",
    },
    content: {
      whatIsTitle: "What is credit utilization?",
      whatIsContent:
        "Credit utilization measures how much of your total credit card and lines of credit limits you are using. \n\nIn general, you should try to keep your credit utilization under 30%.",
      howToImproveTitle: "How to improve",
      minimizeBalanceTitle: "1. Minimize your account balances",
      minimizeBalanceContent:
        "Reduce the balances you owe on your credit cards and lines of credit by paying off the balance in full each month",
      increaseCreditTitle: "2. Increase your credit limit",
      increaseCreditContent:
        "Another way to improve your utilization rate is to ask your credit card company to increase your credit limit. The goal is to increase the total amount of credit available to you without adding to your current debt balance.",
    },
    accountSummary: {
      title: "Account summary",
      noUtilization: "A lender may close this account if they detect several months of inactivity.",
      overUtilization: "Bring this below 100% as soon as possible to avoid incurring fees or penalties.",
      balanceUsedOfCredit: "{{balance}} used of {{creditLimit}} limit",
    },
  },
  derogatoryMarks: {
    none: "No derogatory marks",
    noChange: "No new derogatory marks",
    increase: {
      collection: "{{count}} new collection",
      collection_plural: "{{count}} new collections",
      bankruptcy: "{{count}} new bankruptcy",
      bankruptcy_plural: "{{count}} new bankruptcies",
      legalItem: "{{count}} new legal item",
      legalItem_plural: "{{count}} new legal items",
      marks: "{{count}} new mark",
      marks_plural: "{{count}} new marks",
      summaryMarks: "{{count}} new derogatory mark",
      summaryMarks_plural: "{{count}} new derogatory marks",
    },
    decrease: {
      collection: "{{count}} collection closed",
      collection_plural: "{{count}} collections closed",
      bankruptcy: "{{count}} bankruptcy closed",
      bankruptcy_plural: "{{count}} bankruptcies closed",
      legalItem: "{{count}} legal item closed",
      legalItem_plural: "{{count}} legal items closed",
      marks: "{{count}} mark closed",
      marks_plural: "{{count}} marks closed",
    },
    content: {
      whatAreTitle: "What are derogatory marks?",
      whatAreContent:
        "Derogatory marks are events such as collections, bankruptcies, or legal items on your credit report that indicates you didn’t fulfill your credit obligations. These events can stay on your credit report for 6 - 10 years and negatively impacts your credit.",
      howToImproveTitle: "How to improve",
      settleTheMarkTitle: "1. Settle the derogatory mark",
      settleTheMarkContent:
        "Address the event as soon as possible to avoid having it affect your credit even further. ",
      startRebuildTitle: "2. Start rebuilding your credit",
      startRebuildContent:
        "Start rebuilding your credit by exploring our best [product recommendations]() for you.",
      thinkThereIsErrorTitle: "If you think there’s an error",
      contactTheCreditorTitle: "Contact the creditor",
      contactTheCreditorContent:
        "The next step is to reach out to your creditor to ask them to fix the error.",
      contactTheBureauTitle: "Contact the credit bureaus",
      contactTheBureauContent:
        "If your creditor is taking too long to fix the error or is simply refusing to correct the mistake, you may need to reach out directly to the credit bureaus to dispute your credit report.",
    },
  },
  avgCreditAge: {
    increase: {
      year: "Increased by {{count}} year",
      year_plural: "Increased by {{count}} years",
      month: "Increased by {{count}} month",
      month_plural: "Increased by {{count}} months",
    },
    decrease: {
      year: "Decreased by {{count}} year",
      year_plural: "Decreased by {{count}} years",
      month: "Decreased by {{count}} month",
      month_plural: "Decreased by {{count}} months",
    },
    summaryUpdate: {
      increasedBy: "Avg. credit age increased by ",
      decreasedBy: "Avg. credit age decreased by ",
      month: "{{count}} month",
      month_plural: "{{count}} months",
      year: "{{count}} year",
      year_plural: "{{count}} years",
    },
    content: {
      whatIsTitle: "What is average credit age?",
      whatIsContent: "Average credit age refers to the average length of time you’ve been using credit.",
      howToImproveTitle: "How to improve",
      keepOldestAccountTitle: "Keep your oldest accounts open",
      keepOldestAccountContent:
        "Instead of closing your older accounts, consider keeping them active by charging small recurring purchases to them — such as your monthly Netflix or Spotify bill. Just make sure you don’t forget to pay off these charges by each payment due date.",
    },
  },
  totalAccounts: {
    revolvingTrade: "{{count}} new revolving trade",
    revolvingTrade_plural: "{{count}} new revolving trades",
    installmentLoan: "{{count}} new instalment loan",
    installmentLoan_plural: "{{count}} new instalment loans",
    mortgage: "{{count}} new mortgage",
    mortgage_plural: "{{count}} new mortgages",
    accounts: "{{count}} new open account",
    accounts_plural: "{{count}} new open accounts",
    content: {
      whatIsTitle: "What is total accounts?",
      whatIsContent:
        "This refers to the number of open credit cards, loans, mortgages and other accounts you have. Lenders like to see that you have used a mix of accounts on your credit responsibly.",
      howToImproveTitle: "How to improve",
      diversifyTitle: "Diversify your credit",
      diversifyContent:
        "If you're looking for credit, adding a few credit products such as a line of credit, mortgage, instalment loan, or a phone bill to reach an optimal credit mix can be an easy win and improve your credit score by several points.",
      practiceResponsibleTitle: "Practice responsible borrowing",
      practiceResponsibleContent:
        "The critical thing to remember with this strategy is that you should only take on new credit if you need it. You'll also need to prove you can successfully manage a diverse mix of credit products and make your payments on time. Otherwise, adding new products may do more harm than good.",
    },
  },
  equifaxDisputeCard: {
    title: "Don’t recognize something?",
    bodyMarkdown:
      "Read our [__help center article__]() on how to dispute incorrect information on your credit report.",
  },
  equifaxDisputeLink: "Equifax credit report dispute",
  transunionDisputeLink: "TransUnion credit report dispute",
  backToDashboard: "BACK TO DASHBOARD",
  backToSummary: "BACK TO SUMMARY",
  needsWork: "Needs work",
  fair: "Fair",
  good: "Good",
  lastUpdatedCaption: "Last updated",
  nextUpdateCaption: "Next update",
};
