import { useState } from "react";

import {
  CheckboxContainer,
  Container,
  HiddenCheckbox,
  Icon,
  Label,
  LabelWrapper,
  SpanText,
  StyledCheckbox,
  SubLabel,
  SubText,
  Wrapper,
} from "./styles";

export interface CheckboxProps<T> {
  value?: T;
  label?: string;
  subLabel?: string;
  subText?: string;
  initialChecked?: boolean;
  onCheckedChange?: (value: T, checked: boolean) => void;
  "data-cy"?: string | undefined;
  withBorder?: boolean;
  disabled?: boolean;
}

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Checkbox = ({
  value,
  label,
  subLabel,
  subText,
  initialChecked,
  onCheckedChange,
  "data-cy": dataCy,
  withBorder = false,
  disabled = false,
  ...props
}: CheckboxProps<string>) => {
  const [toggle, setToggle] = useState<boolean>(initialChecked ?? false);

  const handleCheck = () => {
    const newCheckedState = !toggle;
    setToggle(newCheckedState);

    if (onCheckedChange) {
      onCheckedChange(value || "", newCheckedState);
    }
  };

  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        !disabled && handleCheck();
      }}
      withBorder={withBorder}
      checked={toggle}
      disabled={disabled}
    >
      <Wrapper withBorder={withBorder}>
        <CheckboxContainer
          checked={toggle}
          data-cy={`${dataCy || "checkbox"}-container`}
          withBorder={withBorder}
        >
          <HiddenCheckbox
            checked={toggle}
            onChange={(e) => {
              e.preventDefault();
              handleCheck();
            }}
            data-cy={dataCy}
            {...props}
          />
          <StyledCheckbox checked={toggle} disabled={disabled} data-cy={`${dataCy || "checkbox"}-styled`}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
        {label ? (
          <LabelWrapper>
            <Label disabled={disabled}>{label}</Label>
            <SubLabel disabled={disabled}>{subLabel}</SubLabel>
          </LabelWrapper>
        ) : (
          value && <SpanText>{value}</SpanText>
        )}
      </Wrapper>
      {subText && <SubText disabled={disabled}>{subText}</SubText>}
    </Container>
  );
};
