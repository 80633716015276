import styled from "@emotion/styled";

import { COLORS, spacing } from "@bwll/bw-styles";

import { Icon } from "../Icon";

type Theme = "dark" | "light";

interface IBadgeStyles {
  borderColor?: string;
  backgroundColor?: string;
  colorTheme?: Theme;
  iconColor?: string;
  textColor?: string;
}

interface IBadge extends IBadgeStyles {
  icon: string;
  text: string;
  theme?: Theme;
  dataCy?: string;
}

const getDefaultColor = (colorTheme?: Theme) =>
  colorTheme === "dark" ? COLORS.WHITE : COLORS.NEUTRAL.WARM["800"];

const ContentWrapper = styled.div<IBadgeStyles>(
  ({ borderColor, backgroundColor, colorTheme = "light", iconColor, textColor }) => ({
    border: `1px solid ${borderColor ? borderColor : COLORS.NEUTRAL.COOL["100"]}`,
    background: backgroundColor ?? "transparent",
    height: 28,
    width: "fit-content",
    borderRadius: 4,
    padding: "4px 8px",
    display: "flex",
    alignItems: "center",
    color: textColor || getDefaultColor(colorTheme),
    svg: {
      marginRight: spacing.xxxs,
      color: iconColor ? iconColor : colorTheme === "dark" ? COLORS.WHITE : COLORS.NEUTRAL.COOL["600"],
    },
    span: {
      fontSize: 14,
      fontFamily: "Lato",
    },
  }),
);

export const BadgeText = styled.span<{ textColor?: string; colorTheme?: Theme }>(
  ({ textColor, colorTheme }) => ({
    color: textColor || getDefaultColor(colorTheme),
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Badge = ({
  icon,
  text,
  borderColor,
  backgroundColor,
  theme,
  iconColor,
  textColor,
  dataCy,
}: IBadge) => {
  return (
    <ContentWrapper
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      colorTheme={theme}
      iconColor={iconColor}
    >
      <Icon size={16} icon={icon} />
      <BadgeText data-cy={dataCy} textColor={textColor} colorTheme={theme}>
        {text}
      </BadgeText>
    </ContentWrapper>
  );
};
