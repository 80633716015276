import { StyledComponent } from "@emotion/native";
import { TextProps } from "react-native";

import { StyledBody2Bold, StyledBody2Regular, StyledBody2SemiBold } from "./Body2.styles";
import { BODY2_VARIANT, Body2Props } from "./Body2.types";

export const getBody2ComponentStyleByVariant = (
  variant: BODY2_VARIANT,
): StyledComponent<TextProps & Body2Props> => {
  switch (variant) {
    case "regular":
    default:
      return StyledBody2Regular;
    case "bold":
      return StyledBody2Bold;
    case "semi-bold":
      return StyledBody2SemiBold;
  }
};
