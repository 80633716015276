import React, { useCallback, useMemo, useRef, useState } from "react";
import { Animated, FlatList, LayoutChangeEvent, ListRenderItem, View } from "react-native";

import { BREAKPOINT_NAMES, useBreakpoints, useWindowProperties } from "@bwll/bw-hooks";
import { BREAKPOINTS, COLORS, spacing } from "@bwll/bw-styles";

import { Icon } from "../..";
import { Indicators } from "../../atoms/Indicators";
import { Spacer } from "../../atoms/Spacer";
import { Heading2 } from "../../atoms/Typography";
import {
  Arrow,
  CarouselNavigatorContainer,
  CarouselWrapper,
  Container,
  Content,
  IndicatorsContainer,
  ItemWrapper,
  OffersText,
  styles,
} from "./GenericCarousel.styles";
import { IGenericCarouselData, IGenericCarouselProps } from "./GenericCarousel.types";

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const MAX_CARD_WIDTH = {
  [BREAKPOINT_NAMES.MOBILE]: 280,
  [BREAKPOINT_NAMES.TABLET]: 304,
  [BREAKPOINT_NAMES.DESKTOP]: 304,
};

const NEXT_CARD_PEAK_WIDTH = spacing.xs;
const numberOfItemsInView = 1;
const itemSeparatorWidth = spacing.xs;

export const GenericCarousel = <TParams,>({
  data,
  title,
  startIndex,
  hideControls,
  minHeight = 150,
}: IGenericCarouselProps<TParams>) => {
  const dataLength = data?.length ?? 0;

  const carouselWrapper = useRef(null);
  const [carouselWrapperWidth, setCarouselWrapperWidth] = useState(0);

  const setCarouselWidth = useCallback((props: LayoutChangeEvent) => {
    setCarouselWrapperWidth(props.nativeEvent.layout.width);
  }, []);

  const {
    dimensions: {
      window: { width },
    },
  } = useWindowProperties();

  const { isDesktop, isMobile } = useBreakpoints();
  const [isAnimating, setIsAnimating] = useState(false);

  const [isLastCard, setIsLastItem] = useState(false);
  const [currPosition, setPosition] = useState(0);
  const shouldShowArrows = useMemo(() => width >= BREAKPOINTS.TABLET_MINIMUM_WIDTH, [width]);
  const flatListRef = useRef<FlatList>(null);

  const calculatedWidth = useMemo(() => {
    if (width > BREAKPOINTS.DESKTOP_MINIMUM_WIDTH) return MAX_CARD_WIDTH[BREAKPOINT_NAMES.DESKTOP];
    if (width > BREAKPOINTS.TABLET_MINIMUM_WIDTH) return MAX_CARD_WIDTH[BREAKPOINT_NAMES.TABLET];

    return carouselWrapperWidth - spacing.xs - NEXT_CARD_PEAK_WIDTH;
  }, [carouselWrapperWidth, width]);

  /**
   * Card Width: width - offset
   */
  const cardWidth = calculatedWidth;
  const timelineSnapping = cardWidth + itemSeparatorWidth * 2;

  const { current: scrollPosition } = useRef(new Animated.Value(0));

  const onScroll = Animated.event([{ nativeEvent: { contentOffset: { x: scrollPosition } } }], {
    useNativeDriver: true,
    listener: ({
      nativeEvent: {
        contentOffset: { x },
      },
    }: {
      nativeEvent: { contentOffset: { x: number } };
    }) => {
      setIsLastItem(false);

      // Prevent duplicated scroll events when clicking the navigation arrows.
      if (isAnimating) return;
      const position = Math.max(
        0,
        isDesktop ? Math.ceil(x / timelineSnapping) : Math.round(x / timelineSnapping),
      );

      setPosition(position);
    },
  });

  const carouselHorizontalMargin = useMemo(
    () => (width - carouselWrapperWidth) / 2,
    [width, carouselWrapperWidth],
  );

  const calculateOffset = useCallback(
    (position: number) =>
      position === 0
        ? 0
        : position === dataLength - numberOfItemsInView
        ? cardWidth * (position + 1)
        : cardWidth * position + (position - 2) * itemSeparatorWidth + itemSeparatorWidth * 2,
    [cardWidth, dataLength],
  );

  // Snap position for mobile web scrolling
  const snapPosition = useCallback(
    ({ position }: { position?: number }) => {
      flatListRef.current?.scrollToOffset({
        animated: true,
        offset: calculateOffset(typeof position !== "undefined" ? position : currPosition),
      });
    },
    [calculateOffset, currPosition],
  );

  const getCardInputRange = (position: number): number[] => {
    const startInterval = position * cardWidth;
    return [startInterval - cardWidth, startInterval, startInterval + cardWidth];
  };

  interface IGenericRenderItem {
    item: IGenericCarouselData<TParams>;
    index: number;
  }

  const renderItem = ({ item: { ItemComponent, params }, index }: IGenericRenderItem) => {
    const position = index === 0 ? "left" : index === dataLength - 1 ? "right" : "middle";

    return (
      <ItemWrapper
        width={cardWidth}
        cardPosition={position}
        {...(position === "left" && isMobile && { marginLeft: carouselHorizontalMargin })}
        {...(position === "right" && isMobile && { marginRight: carouselHorizontalMargin })}
      >
        {ItemComponent && <ItemComponent {...params} index={index} />}
      </ItemWrapper>
    );
  };

  const ANIMATION_TIME = 500;

  const nextItem = useCallback(() => {
    if (currPosition < dataLength - numberOfItemsInView || !isLastCard) {
      setIsAnimating(true);
      const newPos = currPosition + 1;
      setPosition(newPos);

      snapPosition({ position: newPos });
    }
    setTimeout(() => setIsAnimating(false), ANIMATION_TIME);
  }, [currPosition, dataLength, snapPosition, isLastCard]);

  const previousItem = useCallback(() => {
    if (currPosition > 0) {
      setIsAnimating(true);
      const newPos = currPosition - 1;
      setPosition(newPos);
      snapPosition({ position: newPos });
    }
    setTimeout(() => setIsAnimating(false), ANIMATION_TIME);
  }, [currPosition, snapPosition]);

  const onLastCardReached = useCallback(() => {
    // Prevents race condition with onScroll
    setTimeout(() => {
      setIsLastItem(true);
    }, 150);
  }, []);

  return (
    <CarouselWrapper ref={carouselWrapper}>
      <View onLayout={setCarouselWidth}>
        <Content>
          {typeof title !== "string" ? (
            title
          ) : (
            <OffersText>
              <Heading2>{title}</Heading2>
            </OffersText>
          )}
          <Spacer height={spacing.xs} />

          <Container
            minHeight={minHeight}
            carouselWidth={isMobile ? width : undefined}
            horizontalMargin={isMobile ? carouselHorizontalMargin : 0}
          >
            <AnimatedFlatList
              ref={flatListRef}
              horizontal
              data={data}
              renderItem={renderItem as unknown as ListRenderItem<unknown>}
              showsHorizontalScrollIndicator={false}
              decelerationRate="fast"
              overScrollMode="never"
              initialScrollIndex={startIndex}
              onEndReached={onLastCardReached}
              onScroll={onScroll}
            />
          </Container>
          {!hideControls && (
            <>
              <Spacer height={spacing.xs} />
              <CarouselNavigatorContainer>
                <IndicatorsContainer minWidth={width * 0.1} maxWidth={width * 0.2}>
                  <Indicators
                    numItems={data?.length ?? 0}
                    getInputRange={getCardInputRange}
                    currentPosition={scrollPosition}
                    indicatorStyle={styles.indicatorStyle}
                  />
                </IndicatorsContainer>
              </CarouselNavigatorContainer>
            </>
          )}
        </Content>
        <Arrow
          position="left"
          isEnabled={shouldShowArrows}
          onPress={previousItem}
          color={currPosition > 0 ? COLORS.PRIMARY["500"] : COLORS.NEUTRAL.COOL["400"]}
          disabled={currPosition <= 0}
        >
          <Icon
            icon="back_arrow_thin"
            color={currPosition > 0 ? COLORS.PRIMARY["500"] : COLORS.NEUTRAL.COOL["400"]}
          />
        </Arrow>
        <Arrow
          position="right"
          isEnabled={shouldShowArrows}
          onPress={nextItem}
          color={isLastCard ? COLORS.NEUTRAL.COOL["400"] : COLORS.PRIMARY["500"]}
          disabled={isLastCard}
        >
          <Icon
            icon="forward_arrow_thin"
            color={isLastCard ? COLORS.NEUTRAL.COOL["400"] : COLORS.PRIMARY["500"]}
          />
        </Arrow>
      </View>
    </CarouselWrapper>
  );
};
