export const selectRentPayment = {
  title: "Select your rent payment",
  description:
    "Select up to {{ maxTransactions }} transactions for this month’s rent. If you pay your rent in more than {{ maxTransactions }} transactions, please [contact us]().",
  manualOnboardingBanner: "Can’t find your rent transaction? Simply [upload a proof of your rent payment]()",
  notSeeingRentChangeAccount: "Don’t see your rent? [Change bank account]()",
  viewMore: "Don’t see your rent? [View more transactions]()",
  changeAccount: "Used a different bank account? [Change account]()",
  transaction: "{{count}} transaction",
  transaction_plural: "{{count}} transactions",
  selectTransaction: "SELECT TRANSACTION",
  loadingTitle: "Loading transactions...",
  loadingDescription:
    "Please don’t close this page otherwise you’ll lose your progress and will have to start again",
  footNote: "By selecting this transaction you’re confirming that this is your rent payment.",
  errorTitle: "We couldn’t find any transactions",
  errorMessage: "Please contact support to help you troubleshoot this issue.",
  contactSupport: "CONTACT SUPPORT",
  noTransactionErrorMessage: "Please try again later or upload a proof of your rent payment.",
  uploadProof: "UPLOAD PROOF OF PAYMENT",
  notSeeingRentContactSupport: "If you don’t see your rent transactions, please [contact us]()",
  rentNotFound: {
    title: "What to do if you can’t find your rent payment",
    description:
      "If you paid your rent recently, please note that it may take up to 4 days before it shows up in your transactions.",
  },
  rentNotFoundOptions: {
    changeAccount: {
      title: "**Change bank account**",
      titleNoMD: "Change bank account",
      description: "Change your bank account if you paid your rent out of a different account.",
    },
    manualUpload: {
      title: "**Manually upload**",
      titleNoMD: "Manually upload",
      description: "Upload proof of payment for your rent, so we can manually update your rent payment.",
    },
    contactSupport: {
      title: "**Contact support**",
      titleNoMD: "Contact support",
      description: "If your rent payment isn’t showing up on Borrowell, contact support.",
    },
    confirmLater: {
      title: "**Confirm your rent later**",
      titleNoMD: "Confirm your rent later",
      description: "Defer confirming your rent payment.",
    },
  },
  cantFindRent: "[I can’t find my rent payment]()",
  cantFindRentNoMD: "I can’t find my rent payment",
};
