export const validationErrors = {
  emptyField: "Required",
  NaN: "Invalid amount",
  nonPositiveCurrency: "Please enter a value greater than $0",
  nonPositiveNumber: "Please enter a positive number",
  isDateAfter: "Please select a date in the past",
  isDateBefore: "Please select a date in the future",
  invalidDate: "Please enter a valid date",
  invalidEmail: "Please enter a valid email",
  invalidPostalCode: "Please enter a valid postal code",
  invalidPhoneNumber: "Please enter a valid phone number",
  invalidPassword: "Must include at least 8 characters and 1 number",
  invalidRegion: {
    picker: "Please select a valid province/territory",
  },
  invalidText: "You may only use {{- allowed}} in this field.",
};