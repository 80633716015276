export const accountDeactivation = {
  "settingsMenuTitle": "Désactivation",
  "settingsMenuSubtitle": "Désactiver votre compte",
  "chooseStep": {
    "title": "Nous sommes désolés de vous voir partir",
    "options": {
      "deactivate": {
        "label": "Désactiver le compte",
        "caption": "Cela permet d'interrompre la surveillance du crédit",
        "text": "Vous ne serez pas averti des changements et l'historique de votre cote sera sauvegardé au cas où vous souhaiteriez réactiver votre compte."
      },
      "delete": {
        "label": "Supprimer le compte",
        "caption": "",
        "text": "En demandant la suppression de votre compte Borrowell, vous ne pourrez plus y accéder à l'avenir."
      }
    },
    "buttons": {
      "nextStep": "SUIVANT",
      "cancel": "ANNULER"
    }
  },
  "activeLoanStep": {
    "deactivate": {
      "title": "Votre compte ne peut pas être désactivé pour le moment.",
      "subtitle": "Vous avez actuellement un prêt Borrowell actif. Pour désactiver votre compte, finissez de payer votre solde. \n\nPour obtenir de l'aide, veuillez nous contacter via notre [centre d'aide]()."
    },
    "delete": {
      "title": "Votre compte ne peut pas être supprimé pour le moment",
      "subtitle": "Vous avez actuellement un prêt Borrowell actif. Pour supprimer votre compte, finissez de payer le solde restant. \n\nPour obtenir de l'aide, veuillez nous contacter via notre [centre d'aide]()."
    },
    "buttons": {
      "goToDashboard": "ALLER AU TABLEAU DE BORD"
    }
  },
  "confirmCurrentStep": {
    "deactivate": {
      "title": "Êtes-vous sûr de vouloir suspendre votre compte?",
      "buttons": {
        "nextStep": "JE VEUX DÉSACTIVER",
        "cancel": "J'AI CHANGÉ D'AVIS"
      }
    },
    "delete": {
      "title": "Êtes-vous sûr de vouloir supprimer votre compte?",
      "content": "Vous pouvez suspendre votre compte pour pouvoir le rouvrir à l'avenir. Cela vous permet de conserver l'historique de votre cote de crédit dans votre dossier.",
      "dangerBanner": "Votre compte Borrowell sera définitivement supprimé",
      "buttons": {
        "nextStep": "JE VEUX SUPPRIMER",
        "cancel": "J'AI CHANGÉ D'AVIS"
      }
    },
    "subtitle": "Vous perdrez l'accès aux éléments suivants :",
    "options": {
      "one": "Surveillance gratuite du crédit",
      "two": "Accès à votre cote de crédit gratuite",
      "three": "Des recommandations personnalisées et vos probabilités d'approbation",
      "four": "Un service clientèle efficace et amical pour répondre à vos questions sur votre cote de crédit et votre compte."
    }
  },
  "reasonStep": {
    "defaultReason": "Sélectionnez une raison",
    "deactivate": {
      "title": "Pourquoi désactivez-vous votre compte?",
      "reasonsCaption": "Raison de la désactivation",
      "otherReasonLabel": "Indiquez la raison de la désactivation"
    },
    "delete": {
      "title": "Pourquoi supprimez-vous votre compte?",
      "reasonsCaption": "Raison de la suppression",
      "otherReasonLabel": "Indiquez la raison de la suppression"
    },
    "provideFeedback": "Faites-nous part de vos commentaires (facultatif)",
    "title": "Nous sommes désolés de vous voir partir",
    "subtitle": "En cas de désactivation, vous perdez l'accès à :",
    "options": {
      "one": "Surveillance gratuite du crédit",
      "two": "Accès à votre cote de crédit gratuite",
      "three": "Recommandations personnalisées et visualisation de vos probabilités d'approbation"
    },
    "reasons": {
      "tooManyEmails": {
        "label": "Je reçois trop de courriels",
        "content": {
          "copy": "Nous sommes désolés de l'apprendre. Saviez-vous que vous pouvez contrôler vos préférences en matière de communication?",
          "links": {
            "prefs": "Modifier la fréquence des notifications de mise à jour de la cote de crédit"
          }
        }
      },
      "notUseful": {
        "label": "Je ne trouve pas Borrowell bien utile",
        "content": {
          "copy": "Nous sommes désolés de l'apprendre. Voici quelques avantages offerts par Borrowell :",
          "links": {
            "dashboard": "Veillez à la bonne santé de votre crédit",
            "report": "Consulter votre dossier de crédit pour repérer les signes de fraude ou les erreurs de déclaration.",
            "coach": "Faire appel à notre coach en crédit pour comprendre pourquoi votre cote a changé",
            "recommendations": "Accéder à des recommandations de produits financiers adaptés à votre profil de crédit"
          }
        }
      },
      "zeroScore": {
        "label": "Ma cote de crédit est toujours à 0",
        "content": {
          "copy": "Une cote de crédit à zéro signifie que votre dossier de crédit est trop récent pour générer une cote ou qu'il y a une erreur dans votre dossier de crédit. Vous pouvez consulter le lien ci-dessous pour en savoir plus sur ce que vous pouvez faire.",
          "links": {
            "helpCentre": "Pourquoi ai-je une cote de 0?"
          }
        }
      },
      "elsewhere": {
        "label": "Je surveille ma cote ailleurs",
        "content": {
          "copy": "Merci de nous le faire savoir! Beaucoup de nos membres apprécient notre service gratuit parce qu'il est facile à utiliser. Voici d'autres avantages offerts par Borrowell :",
          "links": {
            "creditReport": "Consulter votre dossier de crédit pour repérer les signes de fraude ou les erreurs de déclaration",
            "creditCoach": "Faire appel au coach en crédit pour obtenir des conseils utiles sur les raisons pour lesquelles votre cote a changé.",
            "recommendations": "Visualiser vos recommandations de produits et vos probabilités d'approbation"
          }
        }
      },
      "issueWithAccount": {
        "label": "Problèmes avec mon compte",
        "content": {
          "copy": "Nous sommes désolés de l'apprendre. Pouvez-vous nous indiquer le problème que vous rencontrez?",
          "links": {
            "incorrectInfo": "Mes informations personnelles sont incorrectes",
            "staleScore": "Ma cote de crédit ne s'améliore pas",
            "noScore": "Je n'ai pas de cote de crédit",
            "other": "J'ai un autre problème"
          }
        }
      },
      "other": {
        "label": "Autre, veuillez préciser",
        "content": {
          "copy": "Nous sommes désolés de l'apprendre. Pouvez-vous nous dire pourquoi vous souhaitez partir?"
        }
      }
    },
    "buttons": {
      "nextStep": "SUIVANT",
      "cancel": "J'AI CHANGÉ D'AVIS"
    }
  },
  "confirmationStep": {
    "deactivate": {
      "title": "Entrez votre mot de passe pour confirmer la désactivation",
      "content": "En cliquant sur le bouton ci-dessous, vous désactiverez votre compte Borrowell.",
      "buttons": {
        "action": "DÉSACTIVER LE COMPTE",
        "cancel": "J'AI CHANGÉ D'AVIS"
      }
    },
    "delete": {
      "title": "Entrez votre mot de passe pour confirmer la suppression",
      "content": "En cliquant sur le bouton ci-dessous, vous supprimez définitivement votre compte.",
      "buttons": {
        "action": "SUPPRIMER LE COMPTE",
        "cancel": "J'AI CHANGÉ D'AVIS"
      }
    },
    "label": "Entrez votre mot de passe",
    "forgotPassword": "J'ai oublié mon mot de passe",
    "errors": {
      "emptyField": "Veuillez saisir votre mot de passe pour continuer",
      "invalidField": "Mot de passe incorrect, veuillez réessayer",
      "serverError": "Copie temporaire pour erreur de serveur"
    }
  },
  "deactivatedSuccessfulStep": {
    "deactivate": {
      "title": "Votre compte a été temporairement désactivé",
      "subtitle": "Vous recevrez sous peu un courriel de confirmation, aucune autre action n'est requise.\n\nSi vous souhaitez réactiver votre compte, il vous suffit de contacter notre équipe d'assistance aux membres. \n\nÀ la prochaine!\n"
    },
    "delete": {
      "title": "Nous sommes désolés de vous voir partir!",
      "subtitle": "Vous recevrez sous peu un courriel de confirmation, aucune autre action n'est requise. \n\nSi vous souhaitez utiliser à nouveau nos services, vous devrez créer un nouveau compte. \n\nPour en savoir plus sur la manière dont nous recueillons et conservons vos informations, veuillez consulter notre politique de confidentialité.\n\nÀ la prochaine!\n"
    },
    "buttons": {
      "goToHomePage": "ALLER À LA PAGE D'ACCUEIL"
    }
  }
}
