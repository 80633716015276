import { format } from "date-fns";
import { enCA, frCA } from "date-fns/locale";

import { AvailableLocale, DATE_FORMATS, ENGLISH_LANGUAGE, FRENCH_LANGUAGE } from "@bwll/bw-types";

import { isDateValid } from "./isDateValid";

export interface FormatDateArgs {
  dateString?: string;
  defaultValue?: string;
  dateFormat?: string;
  ignoreOffset?: boolean;
  locale?: AvailableLocale;
}

const DATE_LOCALE_MAP = {
  [ENGLISH_LANGUAGE]: enCA,
  [FRENCH_LANGUAGE]: frCA,
} as const;

export const DEFAULT_FORMAT = DATE_FORMATS.MMMM_d_yyyy;
export const DEFAULT_VALUE = "-";

const isIsoDate = (dateString: string): boolean => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(dateString)) {
    return false;
  }

  const date = new Date(dateString);

  return date instanceof Date && !isNaN(date.valueOf());
};

/**
 * Returns a formatted date based on the given format (see DATE_FORMATS in @bwll/bw-types)
 */
export const formatDate = ({
  dateString,
  defaultValue = DEFAULT_VALUE,
  dateFormat = DEFAULT_FORMAT,
  ignoreOffset = false,
  locale = ENGLISH_LANGUAGE,
}: FormatDateArgs) => {
  if (!dateString) {
    return defaultValue;
  }

  const date = new Date(ignoreOffset && isIsoDate(dateString) ? dateString.split("T")[0] : dateString);

  if (ignoreOffset) {
    date.setMinutes(date.getTimezoneOffset());
  }

  return isDateValid(date) ? format(date, dateFormat, { locale: DATE_LOCALE_MAP[locale] }) : defaultValue;
};
