export const transactionAmountModal = {
  "amountsDontMatch": "Les montants ne correspondent pas",
  "changeSelection": "MODIFIER LA SÉLECTION",
  "editRentAmount": "MODIFIER LE MONTANT DU LOYER",
  "editRentAmountDescription": "Si le montant que vous avez entré est incorrect, veuillez mettre à jour le montant de votre loyer. Sinon, modifiez les transactions que vous avez sélectionnées.",
  "popupContactSupport": "Si vous ne voyez pas toutes les transactions, veuillez [contacter le support]()",
  "rentAmountFromLease": "Montant du loyer du bail",
  "saveChanges": "SAUVEGARDER LES MODIFICATIONS",
  "selectedTransactions": "Transactions sélectionnées",
  "updateRentAmount": "Mise à jour du montant du loyer"
}
