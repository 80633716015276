export const landingPageExternal = {
  "buttonContinue": "CONTINUER",
  "hero": {
    "title": "Génial! Mettons en place Le Bienfait du Loyer",
    "step1": "Confirmez les conditions de votre bail",
    "step2": "Connectez votre compte bancaire",
    "step3": "Identifier le paiement de votre loyer"
  },
  "creditFileConnected": "Votre dossier Equifax Canada est maintenant connecté. Vous pouvez accéder à votre cote et à votre dossier de crédit en tout temps."
}
