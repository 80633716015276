export const config = {
  APP_INSIGHTS_CONNECTION_STRING: import.meta.env.BWLL_APP_INSIGHTS_CONNECTION_STRING as string,
  COOKIES_DOMAIN: import.meta.env.BWLL_COOKIES_DOMAIN as string,
  ENVIRONMENT: import.meta.env.BWLL_ENVIRONMENT as string,
  GEMSTONE_BASE_URL: import.meta.env.BWLL_GEMSTONE_BASE_URL as string,
  GOOGLE_PLACES_API_KEY: import.meta.env.BWLL_GOOGLE_PLACES_API_KEY as string,
  IDENTITY_AUTHORITY: import.meta.env.BWLL_IDENTITY_AUTHORITY as string,
  OIDC_CLIENT: import.meta.env.BWLL_OIDC_CLIENT as string,
  PRODUCT_ID: import.meta.env.BWLL_PRODUCT_ID as string,
  REDIRECT_URI: import.meta.env.BWLL_REDIRECT_URI as string,
} as const;
