import React from "react";

import { StyledCell } from "./Cell.styles";
import { CellProps } from "./Cell.types";

/**
 * @component For use as a building block for Cards and other 'container-style' components
 * @example
 * return (
 *   <Cell />
 * )
 */
export const Cell = ({
  paddingVertical,
  paddingHorizontal,
  borderRadius,
  hasShadow,
  children,
  style,
  testID,
  accessibilityLabel,
}: CellProps) => {
  const forwardedProps = {
    testID,
    accessibilityLabel,
    paddingVertical,
    paddingHorizontal,
    borderRadius,
    hasShadow,
    style,
  };
  return <StyledCell {...forwardedProps}>{children}</StyledCell>;
};
