export const checkoutConfirmationPage = {
  "title": "Vous avez fait un pas de plus vers l'établissement de votre historique de crédit!",
  "infoSection": {
    "title": "Quelle est la prochaine étape?",
    "info": [
      "Une fois votre preuve de paiement approuvée, les informations relatives au paiement de votre loyer seront envoyées à Equifax Canada. Une fois l'information reçue, cela peut prendre jusqu'à 4 semaines pour que la ligne de démarcation “BRWL SELF-RPT RENT” apparaisse sur votre dossier de crédit.",
      "Vous devrez vous connecter chaque mois pour confirmer le paiement de votre loyer.",
      "Continuez à payer votre loyer à temps et voyez votre historique de crédit se renforcer."
    ]
  },
  "checkout": "Paiement"
}
