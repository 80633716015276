export const errorScreen = {
  default: {
    title: "Sorry, there's been an issue",
    subtitle: "Unfortunately an error occurred. Please __[contact us]()__ if you have any questions.",
    ctaButton: "CLOSE",
  },
  cblSomethingWentWrong: {
    title: "Sorry, there's been an issue",
    subtitle:
      "Unfortunately an error occurred and we cannot open a Credit Builder for you at this time. Please [contact us]() if you have any questions.",
    ctaButton: "CLOSE",
  },
  rentReportingSomethingWentWrong: {
    title: "We’re sorry, it looks like something went wrong",
    subtitle: "Please try again later or __[contact support]()__ for further assistance.",
    ctaButton: "CLOSE",
  },
};
