export const reportingStatus = {
  reportedAsMissed: {
    title: "Reported as missed",
    description:
      "We couldn’t detect your rent payment for {{ paymentPeriod }} so we reported it as missed to Equifax Canada. You can expect it to appear on your credit report by {{ date }}. \n\nIf you think there’s been a problem, please reach out to support to dispute this.",
    status: "Reported as missed",
    tooltip:
      "We couldn’t detect your rent payment for {{ paymentPeriod }} so we reported it as missed to Equifax Canada. You can expect it to appear on your credit report by {{ date }}.",
  },
  willBeReportedAsMissed: {
    title: "Reporting in progress",
    description:
      "Your rent for {{ paymentPeriod }} will be reported as missed and sent to Equifax Canada because we couldn’t detect a rent payment. \n\nIf you have any questions, please contact support.",
    status: "Will be reported as missed",
    tooltip:
      "Your rent for {{ paymentPeriod }} will be reported as missed and sent to Equifax Canada because we couldn’t detect a rent payment.",
  },
  reportedAsPaid: {
    title: "Reported as paid",
    description:
      "Your rent for {{ paymentPeriod }} was reported as paid to Equifax Canada. You can expect it to appear on your credit report by {{ date }}.",
    status: "Reported as paid",
    tooltip:
      "Your rent for {{ paymentPeriod }} was reported as paid to Equifax Canada. You can expect it to appear on your credit report by {{ date }}.",
  },
  willBeReportedAsPaid: {
    title: "Reporting in progress",
    description: "Your rent for {{ paymentPeriod }} will be reported as paid and sent to Equifax Canada.",
    status: "Will be reported as paid",
    tooltip: "Your rent for {{ paymentPeriod }} will be reported as paid and sent to Equifax Canada.",
  },
};
