import { IconNames } from "@bwll/bw-components/next";

import { isWeb } from "./constants";

export const styledOS = <W, M>([web, mobile]: [W, M]) => (isWeb ? web : mobile);

// TODO expand this as the marketplace redesign is filled out
export const IconMap: Record<string, IconNames> = {
  ["travel"]: "airplane_hover",
};
