import styled from "@emotion/native";

import { Body2, Button, Caption } from "@bwll/bw-components/next";
import { COLORS, borderRadius, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FlexLayout = styled.View`
  width: 100%;
  border-radius: ${styledValue(borderRadius.s)};
  overflow: hidden;
  background-color: ${COLORS.WHITE};
`;

const SIDE_PADDING = spacing.xs;

export const InnerContent = styled.View`
  max-width: ${styledValue(554 + SIDE_PADDING * 2)};
  width: 100%;
  margin: 0 auto;
`;

export const Container = styled.View<{ mt: number; isMobile: boolean }>`
  padding: ${styledValue(spacing.xxs)} ${styledValue(SIDE_PADDING)};
  margin-top: ${({ mt }) => styledValue(mt)};
  align-items: center;
`;

export const TitleContainer = styled.View`
  height: 40px;
`;

export const TitleText = styled(Body2)<{ isMobile: boolean }>`
  text-align: center;
  font-size: ${({ isMobile }) => styledValue(isMobile ? fontSize.xs : fontSize.s)};
`;

export const ImageContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-horizontal: auto;
`;

export const BannerContainer = styled.View`
  margin-top: ${styledValue(spacing.xs)};
  margin-bottom: ${styledValue(spacing.xxs)};
  align-self: stretch;
`;

export const Row = styled.View`
  margin-top: ${styledValue(spacing.xs)};
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

export const Cell = styled.View`
  flex: 1;
  height: ${styledValue(156)};
  border: 1px solid ${COLORS.NEUTRAL.COOL["100"]};
  border-radius: ${styledValue(borderRadius.s)};
  overflow: hidden;
`;

export const CellTitle = styled.View`
  padding-vertical: ${styledValue(spacing.xxs)};
  margin: 0 auto;
`;

export const CellContent = styled.View<{ isMobile: boolean }>`
  height: ${({ isMobile }) => styledValue(isMobile ? 112 : 120)};
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0px ${styledValue(spacing.xs)};
`;

export const Footer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: auto;
  align-self: stretch;
`;

export const OfferContainer = styled.View`
  height: ${styledValue(spacing.m)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonGroup = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
`;

export const ButtonContainer = styled.View`
  flex: 1;
`;

export const DisclaimerContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${styledValue(spacing.m)};
`;

export const DisclaimerText = styled(Caption)`
  text-align: center;
  margin-top: 4px;
`;

export const PreQualifiedBannerContainer = styled.View<{ isDesktopFlexSmallBanner: boolean }>`
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.TURQUOISE["050"]};
  margin-horizontal: ${({ isDesktopFlexSmallBanner }) => (isDesktopFlexSmallBanner ? "20%" : 0)};
`;

export const ApprovalChanceContainer = styled.View`
  align-self: center;
`;

export const ApplyButton = styled(Button)`
  padding-left: ${styledValue(0)};
  padding-right: ${styledValue(0)};
`;
