import styled, { css } from "@emotion/native";
import { Platform } from "react-native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BoldText, RegularText, SemiBoldText } from "../Default";
import { StyledBody2Props } from "./Body2.types";

const defaultBody2Style = ({ color }: StyledBody2Props) => css`
  font-size: ${styledValue(fontSize.xs)};
  line-height: ${styledValue(spacing.s)};
  letter-spacing: ${styledValue(letterSpacing.m)};
  color: ${color ?? COLORS.NEUTRAL.WARM["700"]};
`;

const underlinedCss = ({ underline }: StyledBody2Props) =>
  underline &&
  `
  text-decoration-line: underline;
  text-decoration-style: ${Platform.OS === "android" ? "solid" : "dotted"};
`;

export const StyledBody2Regular = styled(RegularText)<StyledBody2Props>`
  ${defaultBody2Style};
  ${underlinedCss};
`;

export const StyledBody2SemiBold = styled(SemiBoldText)<StyledBody2Props>`
  ${defaultBody2Style};
  ${underlinedCss};
  letter-spacing: ${styledValue(letterSpacing.l)};
`;

export const StyledBody2Bold = styled(BoldText)<StyledBody2Props>`
  ${defaultBody2Style};
  ${underlinedCss};
  letter-spacing: ${styledValue(letterSpacing.l)};
`;
