export const validationErrors = {
  "emptyField": "Requis",
  "NaN": "Montant non valide",
  "nonPositiveCurrency": "Veuillez entrer une valeur supérieure à 0 $",
  "nonPositiveNumber": "Veuillez entrer un nombre positif",
  "isDateAfter": "Veuillez sélectionner une date dans le passé",
  "isDateBefore": "Veuillez sélectionner une date dans le futur",
  "invalidDate": "Veuillez entrer une date valide",
  "invalidEmail": "Veuillez entrer un courriel valide",
  "invalidPostalCode": "Veuillez entrer un code postal valide",
  "invalidPhoneNumber": "Veuillez entrer un numéro de téléphone valide",
  "invalidPassword": "Doit comporter au moins 8 caractères et 1 chiffre",
  "invalidRegion": {
    "picker": "Veuillez sélectionner une province ou un territoire valide"
  },
  "invalidText": "Vous ne pouvez utiliser que {{- allowed}} dans ce champ."
}
