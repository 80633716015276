import { all } from "./all";
import { autoLoans } from "./autoLoans";
import { creditCards } from "./creditCards";
import { personalLoans } from "./personalLoans";
import { quickApply } from "./quickApply";
import { recommended } from "./recommended";
import { tooltip } from "./tooltip";

export const productCatalog = {
  ...all,
  autoLoans,
  creditCards,
  personalLoans,
  quickApply,
  recommended,
  tooltip,
};
