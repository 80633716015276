import { DateTime } from "luxon";

import { TRANSACTION_DATE_MIN_NUM_SUBTRACT } from "@bwll/bw-modules";
import { DATE_FORMATS, RentReportingProfile } from "@bwll/bw-types";
import { formatDate } from "@bwll/bw-utils";

export const todayDateStr = formatDate({
  dateString: new Date().toISOString(),
  dateFormat: DATE_FORMATS.yyyy_MM_dd,
});

export const getMinTransactionDate = (profileData: RentReportingProfile | undefined): DateTime => {
  const minTransactionDate = DateTime.fromISO(profileData?.currentPeriodDueDate ?? todayDateStr).minus({
    days: TRANSACTION_DATE_MIN_NUM_SUBTRACT,
  });
  return minTransactionDate;
};

export const getMinTransactionDateStr = (minTransactionDate: DateTime) => {
  return formatDate({ dateString: minTransactionDate.toISO(), dateFormat: DATE_FORMATS.yyyy_MM_dd });
};

export const getMinTransactionDateFormatted = (minTransactionDate: DateTime) => {
  return minTransactionDate.minus({ days: 1 }).toLocaleString(DateTime.DATE_FULL);
};

/* 
/ paymentDueDateString: leaseState.fields.paymentDueDate from useFormContext (example: 31st of the month)
/ optionCount: number of lease terms to generate
/ generates [optionCount] lease terms and formats them for use with Dropdown options prop
*/
export const generateLeaseTermOptions = (paymentDueDateString: string, optionCount: number) => {
  // replace any non-digits with nothing
  const paymentDueDate = parseInt(paymentDueDateString.replace(/[^0-9]/g, ""));
  const date = new Date();

  if (date.getDate() < paymentDueDate) {
    date.setMonth(date.getMonth() - 1);
  }

  const options = [
    {
      label: "",
      value: "",
      testID: "",
    },
  ];

  // getLabel takes a date, and returns a label in format of "Jan 1 - Feb 1"
  const getLabel = (date: Date) => {
    // set date to paymentDueDate, clamped to end of month
    const date1 = new Date(date);
    const date2 = new Date(date);
    date2.setMonth(date2.getMonth() + 1);

    // getSubLabel takes a date, and returns a label in format of "Jan 1"
    const getSubLabel = (date: Date, isSecondLabel: boolean) => {
      // get end of month
      const endOfMonth = new Date(date);
      endOfMonth.setMonth(date.getMonth() + 1);
      endOfMonth.setDate(0);

      const day = endOfMonth.getDate() < paymentDueDate ? endOfMonth.getDate() : paymentDueDate;
      date.setDate(day);

      if (isSecondLabel) {
        date.setDate(date.getDate() - 1);
      }

      const monthName = date.toLocaleString("default", { month: "long" });

      return `${monthName} ${date.getDate()}`;
    };

    return `${getSubLabel(date1, false)} - ${getSubLabel(date2, true)}`;
  };

  // getValue takes a date, and returns a value in format of "yyyy_MM_dd"
  const getValue = (date: Date) => {
    const dateCopy = new Date(date);
    dateCopy.setDate(1);
    return formatDate({ dateString: dateCopy.toISOString(), dateFormat: DATE_FORMATS.yyyy_MM_dd });
  };

  // builds the options array with label, value
  for (let i = 0; i < optionCount; i++) {
    options.push({
      label: getLabel(date),
      value: getValue(date),
      testID: `leaseTerm-option-${i}`,
    });

    date.setMonth(date.getMonth() + 1);
  }

  return options;
};
