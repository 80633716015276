export const appIntro = {
  weeklyCreditUpdates: {
    title: "Weekly credit updates",
    subtitle: "Monitor your credit score and report for free without hurting your credit",
  },
  understandYourCredit: {
    title: "Understand your credit",
    subtitle: "Get personalized tips on how you could improve your credit score",
  },
  productsJustForYou: {
    title: "Products just for you",
    subtitle: "Discover personalized products and see your approval chances before you apply",
  },
  createAccount: "CREATE ACCOUNT",
  loginButton: "LOG IN",
};
