export const productVerticals = {
  "v3Title": "Découvrez toutes vos offres",
  "bankaccounts": "Comptes bancaires",
  "creditcards": "Cartes de crédit",
  "insurance": "Assurances",
  "investing": "Investir",
  "loans": "Prêts",
  "mortgages": "Hypothèques",
  "carloans": "Prêts automobiles",
  "v3Cta": "EXPLORER MAINTENANT"
}
