import facepaint from "facepaint";

import { borderRadius, borderWidth } from "./border";
import { COLORS, IColors } from "./colors";
import { spacing } from "./spacing";
import { fontSize, fonts, letterSpacing, lineHeight } from "./typography";
import { toRGBAString } from "./utils";

export const theme = {
  fontSize,
  lineHeight,
  letterSpacing,
  fonts,
  colors: COLORS,
  spacing,
  borderRadius,
  borderWidth,
};

export interface StepperLabelTheme {
  defaultStepFontWeight: number;
  currentStepFontWeight: number;
  fontSize: number;
  color: string;
}

interface StepperStepTheme {
  incompleteBackgroundColor: string;
  completedBackgroundColor: string;
  borderRadius: number;
  marginBottom: number;
  height: number;
  color: string;
}

export interface StepperTheme {
  gap: number;
  label: StepperLabelTheme;
  step: StepperStepTheme;
  margin: number;
  padding: number;
}

export interface IThemeDefinition {
  paragraph?: facepaint.BaseArg;
  h1?: facepaint.BaseArg;
  h2?: facepaint.BaseArg;
  h3?: facepaint.BaseArg;
  h4?: facepaint.BaseArg;
  h5?: facepaint.BaseArg;
  blockquote?: facepaint.BaseArg;
  link?: facepaint.BaseArg;
  secondaryLink?: facepaint.BaseArg;
  button?: facepaint.BaseArg;
  secondaryButton?: facepaint.BaseArg;
  tertiaryButton?: facepaint.BaseArg;
  hr?: facepaint.BaseArg;
  list?: facepaint.BaseArg;
  listItem?: facepaint.BaseArg;
  input?: facepaint.BaseArg;
  inputDark?: facepaint.BaseArg;
  dropdownOption?: facepaint.BaseArg;
  googleAutocompleteDropdownFooter?: facepaint.BaseArg;
  googleAutocompleteDropdownOption?: facepaint.BaseArg;
  googleAutocompletePoweredBy?: facepaint.BaseArg;
  stepper?: StepperTheme;
  COLORS: IColors;
}

const commonDropdownButtonStyles = {
  backgroundColor: COLORS.WHITE,
  borderRadius: "0",
  border: "none",
  borderLeft: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
  borderRight: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
};

const commonButtonStyles = {
  width: ["100%", "360px", "360px"],
  height: "48px",
  fontFamily: "Lato",
  fontWeight: "900",
  fontSize: "18px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 48px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  borderRadius: "28px",
} as const;

export const baseTheme: Required<IThemeDefinition> = {
  paragraph: {
    fontFamily: "Lato",
    fontSize: ["14px", "16px", "16px"],
    lineHeight: ["20px", "24px", "24px"],
    textAlign: ["left", "left", "left"],
    marginTop: "12px",
    color: COLORS.NEUTRAL.COOL["600"],
    a: {
      textDecoration: "underline",
      textDecorationColor: COLORS.PURPLE["700"],
      "&:hover": {
        textDecorationColor: COLORS.PRIMARY["500"],
      },
    },
  },
  h1: {
    fontFamily: "Lato",
    fontSize: ["24px", "32px", "32px"],
    fontWeight: "900",
    lineHeight: ["1.3", "1.2", "1.2"],
    textAlign: "center",
    margin: "0",
    color: COLORS.NEUTRAL.COOL["900"],
  },
  h2: {
    fontFamily: "Lato",
    fontSize: ["24px", "28px", "28px"],
    fontWeight: "900",
    lineHeight: ["1.33", "1.36", "1.36"],
    textAlign: "center",
    margin: "0",
    color: COLORS.NEUTRAL.COOL["900"],
  },
  h3: {
    fontFamily: "Lato",
    fontSize: ["16px", "20px", "20px"],
    fontWeight: "900",
    lineHeight: ["24px", "28px", "28px"],
    textAlign: "center",
    margin: "0",
    color: COLORS.NEUTRAL.COOL["900"],
  },
  h4: {
    fontFamily: "Lato",
    fontSize: ["14px", "18px", "18px"],
    lineHeight: ["24px", "28px", "28px"],
    fontWeight: "900",
    textAlign: "left",
    margin: "0",
    color: COLORS.NEUTRAL.COOL["900"],
  },
  h5: {
    fontFamily: "Lato",
    fontSize: ["19px", "22px", "22px"],
    fontWeight: "900",
    lineHeight: ["1.42", "1.45", "1.45"],
    textAlign: "left",
    margin: "0",
    color: COLORS.NEUTRAL.COOL["900"],
  },
  blockquote: {
    fontFamily: "Lato",
    fontSize: ["16px", "18px", "18px"],
    lineHeight: ["1.5", "1.44", "1.44"],
    textAlign: "left",
    margin: "0",
    color: COLORS.NEUTRAL.COOL["600"],
  },
  link: {
    fontFamily: "Lato",
    fontSize: ["16px", "18px", "18px"],
    lineHeight: ["1.5", "1.44", "1.44"],
    fontWeight: "bold",
    color: COLORS.PURPLE["700"],
    ":hover,:active,:focus": {
      color: COLORS.PRIMARY["500"],
    },
  },
  secondaryLink: {
    fontFamily: "Lato",
    fontSize: "14px",
    color: COLORS.WHITE,
    textDecoration: "none",
    letterSpacing: "0.2px",
    ":hover": {
      color: COLORS.YELLOW["400"],
    },
  },
  button: {
    ...commonButtonStyles,
    color: COLORS.WHITE,
    borderStyle: "none",
    backgroundColor: COLORS.PRIMARY["500"],
    ":hover": {
      backgroundColor: COLORS.PRIMARY["400"],
    },
    ":disabled": {
      color: COLORS.NEUTRAL.WARM["400"],
      backgroundColor: COLORS.NEUTRAL.WARM["200"],
    },
  },
  secondaryButton: {
    ...commonButtonStyles,
    color: COLORS.PURPLE["700"],
    border: `2px solid ${COLORS.PURPLE["700"]}`,
    backgroundColor: "transparent",
    ":hover": {
      backgroundColor: COLORS.PURPLE["050"],
    },
  },
  tertiaryButton: {
    ...commonButtonStyles,
    backgroundColor: "transparent",
    border: `2px solid ${COLORS.WHITE}`,
    color: COLORS.WHITE,
    ":hover": {
      border: "none",
      backgroundColor: COLORS.PURPLE["050"],
      color: COLORS.PURPLE["700"],
    },
    ":active": {
      border: "none",
      color: COLORS.PURPLE["700"],
      backgroundColor: COLORS.WHITE,
    },
  },
  hr: {
    backgroundColor: `${COLORS.NEUTRAL.COOL["600"]}`,
    height: "1px",
    width: "100%",
    border: "none",
  },
  list: {
    alignSelf: "start",
    alignText: "left",
    marginBottom: "0",
    "& > li:last-of-type": {
      marginBottom: "20px",
    },
  },
  listItem: {
    fontFamily: "Lato",
    fontSize: ["16px", "18px", "18px"],
    lineHeight: ["1.5", "1.44", "1.44"],
    textAlign: "left",
    margin: "0",
    color: COLORS.NEUTRAL.COOL["600"],
  },
  input: {
    border: `1px solid ${COLORS.NEUTRAL.WARM["700"]}`,
    fontFamily: "Lato",
    borderRadius: "8px",
    color: COLORS.NEUTRAL.COOL["900"],
    fontSize: "18px",
    width: "100%",
    margin: "6px 0",
    padding: "15px",
    outline: "none",
    "&:focus": {
      border: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
    },
  },
  inputDark: {
    border: "none",
    borderRadius: "8px",
    color: COLORS.NEUTRAL.COOL["900"],
    fontSize: "18px",
    width: "100%",
    height: "54px",
    margin: "6px 0",
    padding: "15px",
    outline: "none",
  },
  googleAutocompleteDropdownFooter: {
    ...commonDropdownButtonStyles,
    borderBottom: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    padding: "8px",
    fontSize: "16px",
    height: 40,
    width: "100%",
    backgroundColor: `${COLORS.NEUTRAL.COOL["050"]}`,
    background: "white",
    display: "flex",
    justifyContent: "flex-end",
    boxSizing: "border-box",
  },
  googleAutocompleteDropdownOption: {
    fontSize: 16,
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  googleAutocompletePoweredBy: {
    width: 144,
  },
  dropdownOption: {
    ...commonButtonStyles,
    ...commonDropdownButtonStyles,
    padding: "15px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "start",

    cursor: "pointer",
    color: COLORS.NEUTRAL.COOL["900"],
    ":focus,:hover": {
      backgroundColor: toRGBAString(COLORS.PRIMARY["050"], 0.5),
    },
    ":first-of-type": {
      borderTop: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
    },
    ":last-of-type": {
      borderBottom: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
  stepper: {
    gap: 12,
    padding: 0,
    margin: 0,
    label: {
      color: COLORS.NEUTRAL.COOL[600],
      currentStepFontWeight: 700,
      defaultStepFontWeight: 400,
      fontSize: 16,
    },
    step: {
      color: COLORS.PRIMARY[500],
      incompleteBackgroundColor: COLORS.NEUTRAL.COOL[200],
      completedBackgroundColor: COLORS.PRIMARY[500],
      height: 5,
      borderRadius: 12,
      marginBottom: 8,
    },
  },
  COLORS,
};
