import styled, { css } from "@emotion/native";

import { Body2, Heading1, Heading2 } from "@bwll/bw-components/next";
import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FullScreenContainer = styled.View<{ backgroundColor: string }>`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  pointer-events: auto;
  text-align: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const ContentContainer = styled.View`
  align-items: center;
  max-width: ${styledValue(800)};
  padding-vertical: ${styledValue(spacing.xxxl)};
  padding-horizontal: ${styledValue(spacing.m)};
`;

export const ErrorTitle = styled(Heading1)`
  text-align: center;
`;

export const ErrorDescription1 = styled(Heading2)`
  text-align: center;
`;

export const ErrorDescription2 = styled(Body2)`
  text-align: center;
`;
