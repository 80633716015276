export const security = {
  title: "Login and Security",
  prompt: {
    title: "Are You Sure?",
    buttons: {
      turnOff: "Turn Off",
      cancel: "Cancel",
    },
  },
  options: {
    rememberLastUser: {
      title: "Remember my email",
      subtitle: "Remember my email when logging into Borrowell.",
      prompt: "You'll be asked to log in with your email and password the next time you visit the app.",
    },
    biometry: {
      title: "{{biometryType}}",
      subtitle: "Use your {{biometryType}} stored on this device to log into your Borrowell account.",
      prompt: "You'll be asked to log in with your password the next time you visit the app.",
    },
    twoStepVerification: {
      title: "Two-step verification",
      subtitle: "Use SMS verification or an authenticator app to add extra security to your account.",
    },
  },
  mfa: {
    smsTitle: "SMS Verification",
    totpTitle: "Authenticator app",
    smsStatus: "Enabled for {{phoneNumber}}",
    totpStatus: "Enabled",
    switchMethod: "Switch Method",
    recoveryTitle: "Recovery code",
    recoveryDescription: "Use this code in case you’re unable to receive your verification code.",
    recoveryDescription2:
      "Generating a new code will cause your existing recovery code to expire and will only be showed once.",
    generateNewCode: "Generate new code",
  },
};