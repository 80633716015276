import styled from "@emotion/native";

import { Body2, Heading2 } from "@bwll/bw-components/next";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ContentContainer = styled.View`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: ${styledValue(spacing.l)} ${styledValue(spacing.xs)};
`;

export const IconContainer = styled.View`
  align-items: center;
`;

export const TextContainer = styled.View`
  flex: 1;
  justify-content: center;
`;

export const ButtonContainer = styled.View`
  width: 100%;
  margin-bottom: ${styledValue(spacing.xxs)};
`;

export const Heading = styled(Heading2)`
  margin-top: ${styledValue(spacing.xs)};
  text-align: center;
  font-size: ${styledValue(fontSize.m)};
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const Body = styled(Body2)`
  margin-top: ${styledValue(spacing.xs)};
  text-align: center;
  font-size: ${styledValue(fontSize.xs)};
  color: ${COLORS.NEUTRAL.WARM["700"]};
`;
