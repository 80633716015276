import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const headlineStyles = {
  color: COLORS.NEUTRAL.WARM[800],
};

export const captionStyles = {
  marginTop: styledValue(spacing.xxxs),
};
