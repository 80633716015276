import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { useNavigationContext } from "@bwll/bw-hooks";

import { Navbar } from "../../molecules/Navbar";
import { Stepper, StepperProps } from "../../molecules/Stepper";

const ContainerWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  width: "100%",
  minHeight: "100vh",
}));

interface ILayout {
  leftIcon?: string;
  stepper?: StepperProps;
  rightIcon?: string;
}

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Layout = ({ leftIcon, stepper, rightIcon }: ILayout) => {
  const navigation = useNavigationContext();

  return (
    <ContainerWrapper>
      <Navbar
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        onClickLeftIcon={navigation.fields.onBackButton}
        onClickRightIcon={navigation.fields.onCloseButton}
        withBorderBottom={navigation.withBorderBottom}
      >
        {stepper && (
          <Stepper
            steps={stepper?.steps}
            showLabels={stepper?.showLabels}
            color={stepper?.color}
            gap={stepper?.gap}
          />
        )}
      </Navbar>
      <Outlet />
    </ContainerWrapper>
  );
};
