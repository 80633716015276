import i18next, { InterpolationOptions, LanguageDetectorAsyncModule } from "i18next";
import { initReactI18next } from "react-i18next";

import { locale as enLocale } from "@bwll/bw-locale/src/en";
import { locale as frLocale } from "@bwll/bw-locale/src/fr";
import { DEFAULT_LANGUAGE } from "@bwll/bw-types";

interface ILocaleInitialization {
  interpolation?: InterpolationOptions;
  localeDetector?: LanguageDetectorAsyncModule;
}

// TODO: Convert to a hook and proper loading state handling.
export const initializeLocalization = ({ interpolation, localeDetector }: ILocaleInitialization = {}) => {
  let isInitializing = false;
  let isInitialized = false;

  return {
    localizationReady() {
      if (isInitializing) {
        console.warn("[initializeLocalization] Localization is initializing.");
        return;
      }
      if (isInitialized) {
        console.warn("[initializeLocalization] Localization is already initialized.");
        return;
      }
      isInitializing = true;

      if (localeDetector) i18next.use(localeDetector);

      i18next
        .use(initReactI18next)
        .init({
          fallbackLng: DEFAULT_LANGUAGE,
          preload: [DEFAULT_LANGUAGE],
          initImmediate: true,
          interpolation,
          resources: {
            en: { ...enLocale },
            fr: { ...frLocale },
          },
        })
        .then(() => {
          isInitializing = false;
          isInitialized = true;
        });
    },
  };
};
