// available under "components:inputs:{key}"
export const inputs = {
  datePicker: {
    placeholder: "Select a date",
  },

  picker: {
    placeholder: "Select a value",
  },

  birthDate: {
    headline: "What is Your Birthdate?",
    subtitle: "We use this to find a match with your credit report.",
    birthdate: "Birthdate",
    year: "Year (YYYY)",
    month: "Month (MM)",
    day: "Day (DD)",
    monthDayError: "Please enter a valid date",
    yearError: "Please enter a valid year",
    enterDateError: "Please enter your birthdate",
  },

  googleSearch: {
    notFound: "I don't see my address here",
  },

  copyFromClipboard: {
    label: "COPY",
  },
};
