// @ts-nocheck

/* eslint-disable @typescript-eslint/ban-ts-ignore */
import styled from "@emotion/styled";
import i18next from "i18next";

import { Heading1, Paragraph } from "@bwll/bw-components/next";
import { COLORS, spacing } from "@bwll/bw-styles";
import { PaymentProfile } from "@bwll/bw-types";

import { ReportingCalendar } from "./styles";

const customParagraphStyle = {
  color: COLORS.NEUTRAL.COOL[600],
};

const ReportCard = styled.div({
  width: "100%",
  border: `1px solid ${COLORS.NEUTRAL.COOL["200"]}`,
  borderRadius: 8,
  "*": {
    textAlign: "left",
  },
  h2: {
    marginBottom: spacing.xs,
    textAlign: "left",
  },
});

const Title = styled.div({
  marginBottom: spacing.xs,
});

const Row = styled.div({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: spacing.xxs,
  "&:last-of-type": {
    marginBottom: spacing.none,
  },
  p: {
    margin: 0,
  },
});

const CardSection = styled.div<{ hasBorderBottom?: boolean }>(({ hasBorderBottom }) => ({
  width: `calc(100% - ${spacing.s * 2}px)`,
  padding: spacing.s,
  borderBottom: hasBorderBottom ? `1px solid ${COLORS.NEUTRAL.COOL["200"]}` : "none",
}));

interface ReportingDetailsProps {
  amount: string;
  dateReported: string;
  leaseStartDate: string;
  creditorName: string;
  paymentProfileList: PaymentProfile[];
}

export const ReportingDetails = ({
  amount,
  dateReported,
  leaseStartDate,
  creditorName,
  paymentProfileList,
}: ReportingDetailsProps) => {
  return (
    <ReportCard>
      <CardSection hasBorderBottom>
        <Title>
          <Heading1>{i18next.t("rentReporting:servicingDashboard:reportingDetails:title")}</Heading1>
        </Title>
        <Row>
          <Paragraph>{i18next.t("rentReporting:servicingDashboard:reportingDetails:amount")}</Paragraph>
          <Paragraph data-cy="reportingDetailsAmount">{amount}</Paragraph>
        </Row>
        <Row>
          <Paragraph>{i18next.t("rentReporting:servicingDashboard:reportingDetails:dateReported")}</Paragraph>
          <Paragraph data-cy="reportingDetailsReportedToAgencyDate">{dateReported}</Paragraph>
        </Row>
      </CardSection>
      {paymentProfileList?.length > 0 && (
        <CardSection hasBorderBottom>
          <ReportingCalendar paymentProfileList={paymentProfileList} />
        </CardSection>
      )}
      <CardSection>
        <Row>
          <Paragraph data-cy="reportingDetailsLeaseStart" style={customParagraphStyle}>
            {`${i18next.t("rentReporting:servicingDashboard:reportingDetails:leaseStart")} ${leaseStartDate}`}
          </Paragraph>
        </Row>
        <Row>
          <Paragraph data-cy="reportingDetailsAppearsAs" style={customParagraphStyle}>
            {i18next.t("rentReporting:servicingDashboard:reportingDetails:appearsAs", {
              creditorName,
            })}
          </Paragraph>
        </Row>
        <Row>
          <Paragraph data-cy="reportingDetailsReportsTo" style={customParagraphStyle}>
            {i18next.t("rentReporting:servicingDashboard:reportingDetails:reportsTo")}
          </Paragraph>
        </Row>
      </CardSection>
    </ReportCard>
  );
};
