export const creditScoreScreen = {
  "titleLine1": "Votre dossier de crédit Equifax",
  "titleLine2": "Dossier de crédit",
  "title": "Votre dossier de crédit Equifax",
  "R": "®",
  "ers": "Votre cote de crédit (ERS 2.0) est fournie par EQUIFAX®",
  "noHistory": "C'est ici que \n votre historique de cote de crédit \n apparaîtra.",
  "tooltipTitle": "À propos de votre cote",
  "tooltip": "Votre cote de crédit a été calculée par Equifax et s'appelle Equifax Risk Score 2.0 (ERS 2.0).\n\nVotre cote peut aller de 300 à 900.\n\nERS 2.0 (la cote de risque d'Equifax) est basé sur le modèle exclusif d'Equifax et peut ne pas être le même que celui utilisé par des tiers pour évaluer votre solvabilité.\n\nLa mise à disposition de cette cote est destinée à votre propre usage éducatif.\n\nLes tiers prendront en considération d'autres informations en plus de la cote de crédit lors de l'évaluation de votre solvabilité.",
  "tooltipFooter": "Votre cote de crédit sera mise à jour chaque semaine. Nous vous enverrons un courriel lorsque votre nouvelle cote sera disponible!",
  "tooltipEquifax": "Propulsé par",
  "creditReportTitle": "Consultez votre dossier de crédit complet.",
  "creditReportLink": "Cliquez ici",
  "recommendationsTitle": "Essayez notre moteur amélioré de recommandations de produits.",
  "recommendationsLink": "Voir mes recommandations",
  "creationDate": "Date de création du fichier",
  "nextUpdateDate": "Prochaine mise à jour",
  "yourEquifaxReport": "Votre dossier de crédit Equifax",
  "aboutReport": "À propos de votre dossier",
  "creditScore": "Cote de crédit",
  "reportLastPulled": "Dernier rapport genere",
  "reengagingMemberBannerMessage": "Nous sommes en train de mettre à jour votre cote de crédit et cela peut prendre un certain temps. Voici votre dossier de crédit en date du {{date}}"
}
