export const alerts = {
  maintenanceMode: {
    title: "Temporarily Unavailable",
    message: "Borrowell is currently performing regularly scheduled maintenance. Please try again later.",
    buttons: {
      ok: "OK",
    },
  },
  versionGuard: {
    title: "Version unsupported",
    message:
      "Sorry, this version of the Borrowell App is no longer supported. Please update to the latest version from the {{storeName}}",
    buttons: {
      confirm: "Update",
      cancel: "Later",
    },
  },
  notificationPermissionError: {
    title: "Notifications are blocked",
    message: "Please allow Notifications to be received in your device settings",
    buttons: {
      confirm: "Settings",
      cancel: "Cancel",
    },
  },
  notificationsEnabled: {
    title: "Notifications have been enabled",
    body: "You'll now receive updates when your credit score changes!",
  },
};