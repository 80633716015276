import { IApiHookOptions } from "@bwll/bw-types";

export const queryBehavior = <TData, TReturn = TData>({
  expiresIn,
  enabled,
  suspense,
  retry = 0,
  refetchOnWindowFocus = false,
  transform,
  onSuccess,
  onError,
}: IApiHookOptions<TData, TReturn>) => ({
  ...(expiresIn && { staleTime: expiresIn * 1000, cacheTime: expiresIn * 1000 }),
  enabled,
  retry,
  refetchOnWindowFocus,
  suspense,
  onSuccess,
  onError,
  ...(suspense && { useErrorBoundary: false }),
  ...(transform && { select: (data: TData) => transform(data) }),
});
