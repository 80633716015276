// eslint-disable-next-line
// @ts-nocheck
import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Combobox } from "@headlessui/react";
import React, { useState } from "react";

import { Input } from "../../atoms/Inputs/Input";
import { OptionButton } from "../../atoms/OptionButton/OptionButton";
import { AutocompleteProps } from "./Autocomplete.types";

export type { AutocompleteProps } from "./Autocomplete.types";

const ComboboxWrapper = styled.div(() => ({
  position: "relative",
  width: "100%",
}));
const ComboboxOptionsWrapper = styled.div(() => ({
  position: "absolute",
  width: "100%",
  top: 0,
}));

const ComboboxOptionsPopup = styled.div(({ theme }) => ({
  position: "absolute",
  width: "100%",
  top: "100px",
  backgroundColor: theme.COLORS.NEUTRAL.COOL["050"],
  zIndex: 99,
}));

const listButtonStyles = css`
  list-style: none;
  margin: 0;
  padding: 0;
  & > li:not(:first-child) > button {
    border-top: none;
  }
  & > li:not(:last-child) > button {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

/**
 * A wrapper around headlessui combobox input to support our custom input field while maintaining accessibility.
 */
const ComboboxInput = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    labelIsScreenReaderonly?: boolean;
    caption?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    hasError?: boolean;
    errorHint?: string;
  }
>((props, ref) => {
  return <Input inputRef={ref as React.ForwardedRef<HTMLInputElement>} {...props} />;
});

ComboboxInput.displayName = "ComboboxInput";

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Autocomplete = <T,>({
  displayValue,
  label,
  caption,
  errorHint,
  options,
  selected,
  onSelection,
  onChange,
  renderOption,
  renderSuggestionFooter,
  labelIsScreenReaderonly,
  dataCy,
}: AutocompleteProps<T>) => {
  const dropdownOptionStyles = useTheme().dropdownOption;

  return (
    <Combobox value={selected} onChange={onSelection}>
      <ComboboxWrapper>
        <Combobox.Input as={React.Fragment} displayValue={displayValue} onChange={onChange}>
          <ComboboxInput
            data-cy={dataCy}
            aria-autocomplete="list"
            label={label}
            labelIsScreenReaderonly={labelIsScreenReaderonly}
            caption={caption}
            errorHint={errorHint}
            hasError={!!errorHint}
          />
        </Combobox.Input>
        <ComboboxOptionsWrapper>
          <ComboboxOptionsPopup>
            <Combobox.Options
              role="status"
              aria-live="polite"
              aria-atomic="true"
              className={listButtonStyles}
            >
              {options.map((option, index) => (
                <Combobox.Option key={index} value={option}>
                  {({ active }) => (
                    <OptionButton
                      isHighlighted={active}
                      dropdownOptionStyles={dropdownOptionStyles}
                      data-cy={`dropdown-option-${index}`}
                    >
                      {renderOption ? renderOption(option) : displayValue(option)}
                    </OptionButton>
                  )}
                </Combobox.Option>
              ))}
              {options?.length > 0 && renderSuggestionFooter && renderSuggestionFooter()}
            </Combobox.Options>
          </ComboboxOptionsPopup>
        </ComboboxOptionsWrapper>
      </ComboboxWrapper>
    </Combobox>
  );
};
