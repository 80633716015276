import styled from "@emotion/styled";
import React from "react";

interface IForm {
  children: React.ReactNode;
}

const FormWrapper = styled.form({});

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Form = ({ children }: IForm) => {
  return <FormWrapper>{children}</FormWrapper>;
};
