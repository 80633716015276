export const tabBar = {
  "dashboard": "Tableau de bord",
  "recommendations": "Recommandé",
  "recommendationsExperiment": "Découvrir",
  "score": "Cote",
  "report": "Dossier",
  "creditCoach": "Coach",
  "cbl": "Établir",
  "cblHeader": "Établir le crédit",
  "rentReporting": "Loyer",
  "verticalLoans": "Prêts",
  "verticalCarLoans": "Autos",
  "verticalCards": "Cartes"
}
