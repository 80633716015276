export const newLogin = {
  changeUserAlert: {
    title: "Are You Sure?",
    body: "This will log you out of your account and you'll be asked to enter your email and password.",
    buttons: {
      confirm: "Change account",
      cancel: "Cancel",
    },
  },
  button: {
    login: "LOG IN",
    forgotMyPassword: "I FORGOT MY PASSWORD",
  },
  title: {
    welcomeBack: "Welcome Back to Borrowell",
    welcomeBackWithName: "Welcome back, {{name}}",
  },
  subtitle: {
    createAccount: "Not a member? [__Create an account__]()",
    changeAccount: "Not you? [__Change account__]()",
  },
  biometry: {
    title: "Welcome Back, {{firstName}}",
    useButton: {
      title: "USE {{biometryType}}",
    },
  },
  loginForm: {
    emailInput: "Email",
    passwordInput: "Password",
    mfaInput: "Code",
  },
};
