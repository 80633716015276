import { Icon } from "@bwll/bw-components";

import { BANNER_ICONS, BANNER_TYPE, IBannerProps } from "./banner";
import { Container, LeftIcon, MessageContent } from "./styles";

export const BannerMessage = ({ type, children, hideLeftIcon }: IBannerProps) => {
  return (
    <Container type={type}>
      {!hideLeftIcon && (
        <LeftIcon type={type}>
          <Icon icon={BANNER_ICONS[type as BANNER_TYPE]} size={20} />
        </LeftIcon>
      )}

      <MessageContent>{children}</MessageContent>
    </Container>
  );
};
