export const formPage = {
  "continueButton": "CONTINUER",
  "backButton": "RETOUR",
  "saveButton": "ENREGISTRER LES INFORMATIONS",
  "cancelButton": "ANNULER",
  "submitButton": "ENREGISTRER LES INFORMATIONS",
  "addressForm": {
    "title": "Quelle est votre adresse de location?",
    "street": "Rue",
    "streetCaption": "Commencez à taper votre adresse pour le remplissage automatique",
    "unitApt": "No d'unité/app.",
    "city": "Ville",
    "province": "Province",
    "postalCode": "Code postal"
  },
  "leaseForm": {
    "title": "Quelles sont les conditions du bail?",
    "monthlyRentAmount": "Montant mensuel du loyer",
    "monthlyRentAmountCaption": "Si vous partagez le loyer, n'indiquez que le montant que vous payez",
    "leaseStartDate": "Début du bail (MM/AAAA)",
    "rentPaymentDueDate": "Date de paiement du loyer",
    "rentPaymentDueDateCaption": "Entrez le jour où votre loyer est dû chaque mois",
    "dayOfMonth": "{{ day }} du mois"
  },
  "errorMessages": {
    "monthlyRentAmountErrorHint": "Veuillez entrer un loyer compris entre {{minRentAmount}} $ et 10 000 $",
    "rentPaymentDueDateErrorHint": "Veuillez entrer un nombre entre 1 et 31",
    "leaseStartDateErrorHint": "Veuillez entrer une date au-delà du 01/01/1990",
    "dateBefore": "Veuillez entrer une date avant le {{date}}.",
    "isLeaseStartMonth": "Veuillez entrer une date comprise entre le 01/01/1990 et le ",
    "enterValidDate": "Veuillez saisir une date valide",
    "required": "Requis",
    "invalidProvince": "Province non valide",
    "quebecNotAvailable": "Désolé, Le Bienfait du Loyer n'est pas encore disponible au Québec.",
    "invalidPostalCode": "Le code postal doit être valide (ex. L3R1E8)",
    "noSpecialCharacters": "L'utilisation de caractères spéciaux est interdite dans ce champ",
    "maximumCharactersLimit": "Ce champ peut contenir un maximum de {{maximum}} caractères"
  }
}
