import { InitializeOptions, initialize } from "react-ga";

type GoogleAnalyticsConfig = {
  trackingId: string;
  options?: InitializeOptions;
};

export const initGoogleAnalytics = ({ trackingId, options }: GoogleAnalyticsConfig): void => {
  initialize(trackingId, options);
};
