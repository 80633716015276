export const editAddressPage = {
  continueButton: "CONFIRM NEW ADDRESS",
  title: "Edit rental address",
  subTitle:
    "Update your rent details if you have recently moved. Updating your rental details here won't influence your tradeline.",
  street: "Street Address",
  streetCaption: "Start typing your address to autofill",
  unitApt: "Unit/Apt. #",
  city: "City",
  province: "Province",
  postalCode: "Postal Code",
};
