import styled from "@emotion/native";

import { ExpressiveLabel, Subheading2 } from "@bwll/bw-components/next";
import { COLORS, fontSize, lineHeight } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  align-items: center;
  background-color: ${COLORS.TURQUOISE["050"]};
`;

export const Text = styled(ExpressiveLabel)`
  color: ${COLORS.PURPLE["900"]};
`;

export const RowWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ResponsiveText = styled(Subheading2)<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => styledValue(isMobile ? fontSize.xs : fontSize.s)};
  line-height: ${styledValue(lineHeight.xl)};
`;
