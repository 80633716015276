import React from "react";

import { StyledDisplay2Component } from "./Display2.styles";
import { Display2Props } from "./Display2.types";

/**
 * Component for showing the Display2.
 *
 * @component
 * @example
 * return (
 *   <Display2>My text</Display2>
 * )
 */
export const Display2 = ({ accessibilityLabel, style, testID, children, color, onPress }: Display2Props) => {
  return (
    <StyledDisplay2Component
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      color={color}
      accessibilityRole="text"
      onPress={onPress}
    >
      {children}
    </StyledDisplay2Component>
  );
};
