import styled from "@emotion/native";

import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const TableContainer = styled.View`
  border-radius: ${styledValue(borderRadius.s)};
  overflow: hidden;
  background-color: ${COLORS.WHITE};
`;

export const TableRow = styled.View``;

export const TableRowHeader = styled.View`
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.NEUTRAL.COOL["200"]};
`;

export const TableCellsContainer = styled.View`
  flex-direction: row;
`;

export const TableCell = styled.View<{ numberOfCells: number }>`
  padding: ${styledValue(spacing.xs)};
  width: ${({ numberOfCells }) => `${100 / numberOfCells}%`};
`;
