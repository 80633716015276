import React, { memo, useEffect, useState } from "react";
import { Image, ImageProps } from "react-native";

import {
  DEFAULT_IMAGE_HEIGHT_PX,
  DEFAULT_IMAGE_WIDTH_PX,
  HorizontalImage,
  VerticalImage,
  VerticalImageContainer,
} from "./CreditCardImage.styles";
import { CreditCardImageProps } from "./CreditCardImage.types";

const defaultImageSize = {
  width: 0,
  height: 0,
};

const defaultProps: Omit<ImageProps, "source"> = {
  accessibilityRole: "image",
  resizeMode: "contain",
};

const transform = [{ perspective: 300 }, { rotateX: "51deg" }, { rotateZ: "-43deg" }];

export const CreditCardImage = memo(
  ({ uri, width = DEFAULT_IMAGE_WIDTH_PX, height = DEFAULT_IMAGE_HEIGHT_PX }: CreditCardImageProps) => {
    const [size, setSize] = useState(defaultImageSize);

    useEffect(() => {
      Image.getSize(uri, (w, h) => {
        setSize({ width: w, height: h });
      });
    }, [uri]);

    const isCardVertical = size.height / size.width > 1;

    if (!isCardVertical) {
      return <HorizontalImage width={width} height={height} {...defaultProps} source={{ uri }} />;
    }

    return (
      <VerticalImageContainer width={width} height={height}>
        <VerticalImage
          width={width}
          height={height}
          {...defaultProps}
          source={{ uri }}
          style={{ transform }}
        />
      </VerticalImageContainer>
    );
  },
  (prevProps, nextProps) => prevProps.uri === nextProps.uri,
);

CreditCardImage.displayName = "CreditCardImage";
