export const landingPage = {
  "buttonGetStarted": "DÉMARRAGE",
  "buttonContinue": "CONTINUER",
  "headerTitle": "Le Bienfait du Loyer",
  "hero": {
    "title": "Établir un historique de crédit avec Le Bienfait du Loyer™",
    "description": "Déclarez votre loyer et établissez votre historique de crédit pour seulement 5 $/mois",
    "descriptionWithAmount": "Déclarez votre loyer et établissez votre historique de crédit pour seulement {{ amount }} $/mois",
    "info1": "Établir un historique de crédit avec Equifax Canada",
    "info2": "Ne contractez aucune dette supplémentaire. Utilisez le loyer que vous payez déjà mensuellement.",
    "info3": "Installation facile. Aucune enquête de crédit. Pas de frais cachés. Annulez à tout moment."
  },
  "creditScoreGrowth": {
    "mainTitle": "Les membres de Rent Advantage qui effectuent des paiements à temps sur tous leurs comptes ont vu leur cote de crédit augmenter en moyenne de ",
    "mainTitle2": " en 7 mois.*",
    "chipText": "32 points",
    "ctaText": "CONSTRUISEZ VOTRE CRÉDIT",
    "subtitle": "Aucune enquête de crédit n'est nécessaire. Résiliez à tout moment.",
    "notes": "* Basé sur les membres de Rent Advantage ayant une cote de crédit ERS 2.0 d'Equifax Canada entre 300 et 600 et n'ayant manqué aucun paiement sur aucun de leurs comptes. Si l'on inclut les membres qui ont effectué des paiements à temps sur leur compte Rent Advantage, mais qui peuvent avoir manqué des paiements sur d'autres comptes, l'augmentation moyenne de la cote de crédit est de 20 points au 8e mois. Les résultats peuvent varier d'une personne à l'autre. D'autres facteurs, notamment les retards de paiement et l'activité de vos autres comptes, peuvent avoir une incidence sur votre cote de crédit."
  },
  "howDoesItWork": {
    "title": "Comment cela fonctionne-t-il?",
    "content": [
      {
        "title": "Inscrivez-vous à Le Bienfait du Loyer de Borrowell Rent™",
        "description": "Inscrivez-vous et connectez le compte que vous utilisez pour payer votre loyer."
      },
      {
        "title": "Confirmez votre loyer",
        "description": "Connectez-vous pour confirmer votre loyer chaque mois."
      },
      {
        "title": "Établissez votre historique de crédit avec Equifax Canada",
        "description": "Effectuez vos paiements de loyer à temps. L'état de vos paiements de loyer sera communiqué à Equifax Canada pour vous aider à établir votre historique en matière de crédit."
      },
      {
        "title": "Regardez votre crédit s'améliorer",
        "description": "Suivez l'historique de vos paiements de loyer dans votre dossier de crédit à l'aide de l'application Borrowell. Nous vous recommandons de vous abonner pour au moins 6 mois."
      }
    ]
  },
  "whatGoodCreditScoreGetMe": {
    "title": "Pourquoi est-il important d'avoir un bon historique de crédit?",
    "content": [
      {
        "title": "De meilleurs taux d'intérêt",
        "description": "Un bon historique de crédit peut vous aider à obtenir des taux plus bas, ce qui vous permettra d'économiser de l'argent à long terme."
      },
      {
        "title": "Être admissible à davantage de produits financiers",
        "description": "Accédez à de meilleures cartes de crédit avec des récompenses plus élevées et des taux plus bas sur les prêts et les hypothèques."
      }
    ]
  },
  "FAQ": {
    "title": "Questions fréquemment posées",
    "ctaButton": "VOIR TOUTES LES FAQ",
    "content": [
      {
        "title": "À quelle agence rapportez-vous mon loyer et comment puis-je le voir?",
        "description": "Vos paiements de loyer sont rapportés à Equifax Canada seulement. Pour le moment, ce produit n'est pas signalé à TransUnion. Le Bienfait du Loyer apparaîtra dans votre dossier de crédit comme un crédit ouvert sous BRWL SELF-RPT RENT. Un crédit ouvert est un type de crédit qui exige un paiement complet chaque mois. Un exemple de crédit ouvert est votre facture de téléphone cellulaire."
      },
      {
        "title": "Que se passe-t-il si j'oublie de payer mon loyer ou si je ne le confirme pas?",
        "description": "Si vous oubliez de payer votre loyer, il est important que vous vous en acquittiez immédiatement auprès de votre propriétaire. Si votre loyer est en retard de plus de 30 jours, il sera signalé à l'agence d'évaluation du crédit comme étant manqué ou en retard. Cela aura un impact négatif sur votre historique de crédit.\n\nVous disposez de 14 jours à compter de la date d'échéance de votre loyer (le 1er du mois dans la plupart des cas) pour vous connecter et confirmer que vous avez payé votre loyer.\n\nSi vous oubliez de vous connecter et de confirmer que vous avez payé votre loyer dans les 14 jours, il se peut que le paiement soit signalé comme manqué. Toutefois, s'il s'agit de la première fois et que vous avez effectué votre paiement, vous pouvez nous contacter et notre équipe d'assistance fera de son mieux pour vous aider à ajuster votre loyer si vous pouvez prouver que vous avez effectué votre paiement avant la date d'échéance du loyer."
      },
      {
        "title": "Que se passe-t-il si je paie un montant différent chaque mois?",
        "description": "Nous comprenons qu'il est possible que le montant exact de votre loyer mensuel fluctue, c'est pourquoi nous autorisons une certaine variation du montant de votre loyer mensuel confirmé.\n\nSi vous partagez votre loyer, lorsque vous vous inscrivez à Le Bienfait du Loyer, il vous sera demandé d'indiquer le montant de votre loyer mensuel, veuillez n'indiquer que la partie que vous payez. Lorsqu'il vous sera demandé de confirmer le paiement de votre loyer chaque mois, veillez à sélectionner la transaction bancaire qui indique uniquement la partie que vous payez."
      },
      {
        "title": "Dans quelle mesure cela améliorera-t-il ma cote de crédit?",
        "description": "Le Bienfait du Loyer est un produit conçu pour vous aider à établir un historique de crédit en déclarant vos paiements de loyer à Equifax Canada. Le fait de payer à temps a un effet positif sur votre historique de paiement, qui représentent environ 35 % de votre cote de crédit. Veuillez noter que tous les paiements (y compris les paiements manqués) sont signalés à Equifax Canada. Pour profiter pleinement des avantages de ce produit, nous vous recommandons de l'utiliser pendant au moins 6 mois. \n\\Borrowell ne garantit pas l'amélioration de la cote de crédit, qui dépend d'un certain nombre de facteurs comme l'historique de paiement. L'établissement d'un historique de paiement positif peut contribuer à améliorer votre cote de crédit."
      },
      {
        "title": "Qu'arrive-t-il à mon crédit si j'annule?",
        "description": "La fermeture d'un produit ou d'un service d'information active peut entraîner une légère diminution de votre cote si votre dossier de crédit est peu étoffé et comporte peu de comptes ouverts. Plus un compte reste ouvert longtemps, mieux c'est pour votre cote de crédit. Toutefois, cet impact n'est généralement que de courte durée. En cas d'annulation, nous ne signalerons plus vos futurs paiements de loyer à Equifax Canada. Cela signifie que si vous payez encore un loyer, vous pourriez manquer une excellente occasion d'améliorer votre cote de crédit! \n\nL'historique de vos paiements de loyer rapporté à Equifax Canada pendant que le programme Le Bienfait du Loyer était actif demeurera dans votre dossier jusqu'à 7 ans à partir de la date de la dernière activité."
      }
    ]
  }
}
