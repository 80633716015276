import styled from "@emotion/styled";
import React, { useState } from "react";

import { mq } from "@bwll/bw-styles";

import { FormFieldWrapper } from "./FormField";

export interface TextAreaInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  labelIsScreenReaderonly?: boolean;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  isRequired?: boolean;
  caption?: string;
  id: string;
  errorHint?: string;
  hasError?: boolean;
  forceError?: boolean;
  resize?: string;
  placeholder?: string;
}

const DefaultTextArea = styled.textarea<TextAreaInputProps>(({ theme, resize }) =>
  mq({
    ...theme.input,
    height: "200px",
    width: ["100%", "540px", "600px"],
    resize: resize ?? "none",
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  id,
  label,
  isRequired,
  caption,
  "aria-label": ariaLabel,
  resize = "none",
  errorHint,
  hasError,
  onChange,
  forceError,
  labelIsScreenReaderonly,
  placeholder,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const shouldShowError = forceError || isDirty;

  return (
    <FormFieldWrapper
      id={id}
      label={label}
      isRequired={isRequired}
      isDirty={shouldShowError}
      caption={caption}
      error={hasError ? errorHint : undefined}
      labelIsScreenReaderonly={labelIsScreenReaderonly}
    >
      <DefaultTextArea
        aria-label={ariaLabel || labelIsScreenReaderonly ? label : undefined}
        resize={resize}
        label={label}
        id={id}
        onChange={onChange}
        onBlur={() => !isDirty && setIsDirty(true)}
        placeholder={placeholder}
      />
    </FormFieldWrapper>
  );
};
