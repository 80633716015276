import React, { ReactNode, createContext, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ENV_TYPE, getConfigEnvironment } from "@bwll/bw-hooks/src/utils";
import { IEndpoint } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

export interface IEnvironmentContext {
  isProduction: boolean;
  envConfigs: IEndpoint;
}

interface IEnvironmentProviderProps {
  children: ReactNode;
  environment: ENV_TYPE;
  queryClient?: QueryClient;
}

const defaultQueryClient = new QueryClient();

export const EnvironmentContext = createContext<IEnvironmentContext>({} as IEnvironmentContext);

export const EnvironmentProvider = (props: IEnvironmentProviderProps) => {
  const { environment, children } = props;
  const queryClient = props.queryClient ?? defaultQueryClient;

  const value = useMemo(
    () => ({
      isProduction: environment === ENV_TYPE.PROD,
      envConfigs: getConfigEnvironment(environment) as IEndpoint,
    }),
    [environment],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
    </QueryClientProvider>
  );
};

export const useEnvironmentContext = () =>
  useContextWrapper(EnvironmentContext, ContextName.EnvironmentContext);
