export const updateFrequency = {
  "title": "Mise à jour du pointage et du rapport de crédit",
  "description": "Choisissez lorsque vous souhaitez être informé des changements de votre pointage de crédit et de votre dossier de crédit.",
  "emailOptions": {
    "weekly": {
      "title": "Envoyez-moi un courriel chaque semaine",
      "description": "Vous serez informé chaque semaine des mises à jour de votre pointage et de votre rapport de crédit."
    },
    "onChange": {
      "title": "Envoyez-moi un e-mail uniquement en cas de changement",
      "description": "Vous ne serez informé que lorsque nous détectons des changements comme une augmentation ou une diminution de votre cote, ou un nouvel élément dans votre dossier de crédit."
    }
  },
  "link": "[__En savoir plus sur les courriels de Borrowell__]()"
}
