import { isValid } from "date-fns";

/**
 * Returns `true` is the input might be a valid date or `false` otherwise
 *
 * @example
 * isDateValid('2023-11-01) // true
 * isDateValid(undefined) // false
 */
export const isDateValid = (value: unknown): boolean => {
  if (!value) return false;

  return isValid(new Date(value as never));
};
