import { reportItemLine } from "./reportItemLine";
import { reportItem } from "./reportItem";
import { report } from "./report";
import { reportOptions } from "./reportOptions";
import { creditScore } from "./creditScore";
import { creditScoreScreen } from "./creditScoreScreen";

export const creditReport = {
  reportItemLine,
  reportItem,
  report,
  reportOptions,
  creditScore,
  creditScoreScreen,
};
