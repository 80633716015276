import styled from "@emotion/native";

import { Body1, Link1 } from "@bwll/bw-components/next";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { MenuLinkStyleProps, SubmenuLinkStyleProps } from "./MenuLink.types";

export const Container = styled.Pressable(
  ({ hovered, isCombinedLink }: MenuLinkStyleProps) => `
  flex-direction: row;
  align-items: center;
  background-color: ${
    hovered ? (isCombinedLink ? COLORS.PRIMARY["025"] : COLORS.NEUTRAL.COOL["050"]) : "transparent"
  };
  padding-vertical: ${styledValue(spacing.xxs)};
  padding-right: ${styledValue(spacing.xxs)};
  border-radius: ${styledValue(spacing.xxs)};
`,
);

export const Label = styled(Body1)(
  ({ hovered, isCombinedLink }: MenuLinkStyleProps) => `
  margin-left: ${styledValue(spacing.xxs)};
  color: ${hovered && isCombinedLink ? COLORS.PRIMARY.DEFAULT : COLORS.BLACK};
`,
);

export const Link = styled(Link1)`
  font-weight: normal;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Submenu = styled.View(
  ({ isCloseToViewportLimit }: SubmenuLinkStyleProps) => `
  position: absolute;
  width: 220px;
  padding-vertical: ${styledValue(spacing.xxxs)};
  padding-horizontal: ${styledValue(spacing.xxs)};
  background-color: ${COLORS.WHITE};
  border-radius: ${styledValue(spacing.xxxs)};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  top: ${styledValue(spacing.xxl * 0.8)};
  right: ${isCloseToViewportLimit ? 0 : undefined}
`,
);

export const Dot = styled.View`
  width: ${styledValue(spacing.xxs)};
  height: ${styledValue(spacing.xxs)};
  border-radius: ${styledValue(spacing.xxs / 2)};
  background-color: ${COLORS.TURQUOISE["900"]};
  margin-left: ${styledValue(spacing.xxs)};
`;

export const Flag = styled.View`
  border-radius: ${styledValue(spacing.xxxs)};
  padding-horizontal: ${styledValue(spacing.xxs)};
  font-size: ${styledValue(fontSize.xxs)};
  color: ${COLORS.WHITE};
  font-weight: bold;
  background-color: ${COLORS.TURQUOISE["900"]};
`;
