import styled from "@emotion/styled";
import { useState } from "react";
import usePlacesAutocomplete, { Suggestion } from "use-places-autocomplete";

import { mq } from "@bwll/bw-styles";

import { Autocomplete } from "../../molecules/Autocomplete";
import { GooglePlacesAutocompleteProps } from "./GooglePlacesAutocomplete.types";
import {
  DEBOUNCE_QUERY_MS,
  DEFAULT_RESTRICTED_COUNTRY,
  POWERED_BY_GOOGLE_ATTRIBUTION_ALT_TEXT,
  POWERED_BY_GOOGLE_ATTRIBUTION_BASE64,
} from "./constants";

export type { GooglePlacesAutocompleteProps } from "./GooglePlacesAutocomplete.types";

const SuggestionFooterWrapper = styled.li(({ theme }) => mq({ ...theme.googleAutocompleteDropdownFooter }));
const OptionDetailWrapper = styled.div(({ theme }) => mq({ ...theme.googleAutocompleteDropdownOption }));
const PoweredByGoogleImageWrapper = styled.div(({ theme }) => mq({ ...theme.googleAutocompletePoweredBy }));

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const GooglePlacesAutocomplete = ({
  onSelection,
  onBlur,
  requestOptions = {
    componentRestrictions: {
      country: DEFAULT_RESTRICTED_COUNTRY,
    },
  },
  labelIsScreenReaderonly,
  label,
  errorHint,
  caption,
  debounce = DEBOUNCE_QUERY_MS,
  dataCy,
}: GooglePlacesAutocompleteProps) => {
  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: requestOptions,
    debounce: debounce,
  });
  const [selected, setSelected] = useState<Suggestion | null>(null);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Autocomplete
      dataCy={dataCy}
      selected={selected}
      labelIsScreenReaderonly={labelIsScreenReaderonly}
      label={label}
      errorHint={errorHint}
      caption={caption}
      displayValue={(suggestion) => {
        return suggestion ? `${suggestion.structured_formatting.main_text}` : "";
      }}
      onSelection={(selectedValue) => {
        clearSuggestions();
        setSelected(selectedValue);
        selectedValue && onSelection(selectedValue);
      }}
      onChange={handleTextChange}
      onBlur={onBlur}
      options={data.map((suggestion) => {
        return suggestion;
      })}
      renderOption={(option) => (
        <OptionDetailWrapper>
          <div>{option?.structured_formatting?.main_text}</div>
          <div>{option?.structured_formatting?.secondary_text}</div>
        </OptionDetailWrapper>
      )}
      renderSuggestionFooter={() => (
        <SuggestionFooterWrapper>
          {/* HeadlessUI Combobox will render role none so it isn't confused as an option to screen readers*/}
          <PoweredByGoogleImageWrapper>
            <img src={POWERED_BY_GOOGLE_ATTRIBUTION_BASE64} alt={POWERED_BY_GOOGLE_ATTRIBUTION_ALT_TEXT} />
          </PoweredByGoogleImageWrapper>
        </SuggestionFooterWrapper>
      )}
    />
  );
};
