export interface IColor {
  "025"?: string;
  "050": string;
  "100": string;
  "200": string;
  "300": string;
  "400": string;
  "500": string;
  "600": string;
  "700": string;
  "800": string;
  "900": string;
  DEFAULT: string;
}

export interface IColors {
  WHITE: string;
  BLACK: string;
  TRANSPARENT: string;
  BRIGHT_GREEK: Record<string, string>;
  MOBILE: Record<string, string>;
  PRIMARY: IColor;
  PURPLE: IColor;
  PINK: IColor;
  YELLOW: IColor;
  TURQUOISE: IColor;
  RED: IColor;
  NEUTRAL: {
    COOL: IColor;
    WARM: IColor;
  };
  GREEN: IColor;
}

// Not green, various shades of blue
const BRIGHT_GREEK = {
  100: "#EDF0FF",
  200: "#B9C3FF",
  300: "#7287FF",
  400: "#304FFF",
  500: "#4066FD",
} as const;

const PRIMARY: IColor = {
  "025": "#F3F9FF",
  "050": "#CCE4FF",
  100: "#9DC6F5",
  200: "#6B9BED",
  300: "#5682F4",
  400: "#4066FD",
  500: "#304FFF",
  600: "#2B41EA",
  700: "#2B38CC",
  800: "#1013A3",
  900: "#0A0485",
  DEFAULT: "#304FFF",
} as const;

const PURPLE: IColor = {
  "050": "#E1DBF1",
  100: "#D2C6EE",
  200: "#BCA8EA",
  300: "#A286E5",
  400: "#8966DF",
  500: "#744DD6",
  600: "#623CC9",
  700: "#512FB9",
  800: "#4125A7",
  900: "#341E98",
  DEFAULT: "#744DD6",
} as const;

const PINK: IColor = {
  "050": "#E6D2EF",
  100: "#E0C3EC",
  200: "#D7AEE8",
  300: "#CC96E3",
  400: "#BA70DA",
  500: "#A853CF",
  600: "#9843C3",
  700: "#8637B3",
  800: "#752EA4",
  900: "#662796",
  DEFAULT: "#BA70DA",
} as const;

const YELLOW: IColor = {
  "050": "#FFFBE5",
  100: "#FFF4BE",
  200: "#FFEC8A",
  300: "#FCDD3F",
  400: "#FFD000",
  500: "#FFBF00",
  600: "#EBAC00",
  700: "#E09600",
  800: "#D68100",
  900: "#CC6D00",
  DEFAULT: "#FCDD3F",
} as const;

const TURQUOISE: IColor = {
  "050": "#E4FFFD",
  100: "#D0F6F4",
  200: "#75dad3",
  300: "#1ECBC0",
  400: "#00beb0",
  500: "#00b09f",
  600: "#00a291",
  700: "#1F9991",
  800: "#009091",
  900: "#007C80",
  DEFAULT: "#00B09F",
} as const;

const RED: IColor = {
  "050": "#FAE8E9",
  100: "#FBC5C6",
  200: "#ef9b9d",
  300: "#e67478",
  400: "#F05558",
  500: "#f6423d",
  600: "#d52f36",
  700: "#C22B32",
  800: "#AF272D",
  900: "#991F21",
  DEFAULT: "#D52F36",
} as const;

const NEUTRAL = {
  WARM: {
    "050": "#F9F8F7",
    100: "#F9F8F7",
    200: "#E5E3DF",
    300: "#CCC9C6",
    400: "#B3B0AD",
    500: "#9B9896",
    600: "#73716F",
    700: "#4D4B4A",
    800: "#262625",
    900: "#181817",
    DEFAULT: "#FFFFFF",
  },
  COOL: {
    "050": "#F5F6FA",
    100: "#EDEEF2",
    200: "#DFE0E6",
    300: "#C6C7CC",
    400: "#ADAEB3",
    500: "#97989C",
    600: "#6F7073",
    700: "#494A4D",
    800: "#252526",
    900: "#161617",
    DEFAULT: "#FFFFFF",
  },
} as const;

const GREEN: IColor = {
  "050": "#EEFCE9",
  100: "#D9F7D0",
  200: "#B6F0A6",
  300: "#88E472",
  400: "#60D546",
  500: "#40BA28",
  600: "#33A61F",
  700: "#26711A",
  800: "#225A1A",
  900: "#204D1A",
  DEFAULT: "#40BA28",
} as const;

const MOBILE = {
  HEADER: "#253484",
  XXLIGHT: "#F2F1ED",
  BACKGROUND_LIGHT: "#F6F7FA",
  ERROR_DARK: "#E7393D",
  ERROR_DEFAULT: "#CC292C",
  SUCCESS_LIGHT: "#A9EBE6",
  ACCENT_DARK: "#F7E3A9",
} as const;

// TODO: PF-211 - Improve typing on COLORS
export const COLORS: IColors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  TRANSPARENT: "transparent",
  BRIGHT_GREEK,
  MOBILE,
  PRIMARY,
  PURPLE,
  PINK,
  RED,
  YELLOW,
  TURQUOISE,
  NEUTRAL,
  GREEN,
} as const;
