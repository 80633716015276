export const mxConnectBankingPage = {
  "bannerInfoMessage": "La connexion de votre compte bancaire peut prendre jusqu'à 5 minutes. Veuillez garder cette page ouverte afin de ne pas perdre votre progression.",
  "bannerInfoMessageManualRent": "La connexion de votre compte bancaire peut prendre jusqu'à 10 minutes. Veuillez garder cette page ouverte afin de ne pas perdre votre progrès.\n\n [Vous êtes pressé ? Téléversez simplement une preuve de paiement de votre loyer]()",
  "addManualBankAccountBanner": "Vous ne parvenez pas à connecter votre compte bancaire ? [Téléversez simplement une preuve de paiement de votre loyer]()",
  "connectBankAccount": {
    "title": "Connectez-vous en toute sécurité à votre banque",
    "subtitle": "Borrowell utilise MX pour connecter votre compte",
    "buttons": {
      "connect": "CONNECTER VOTRE BANQUE"
    },
    "browserTitle": "Connecter votre banque"
  },
  "reconnectBankAccount": {
    "title": "Reconnectez votre compte bancaire",
    "subtitle": "Votre compte bancaire a été déconnecté. Veuillez reconnecter votre compte bancaire afin que nous puissions retrouver votre transaction de loyer.",
    "buttons": {
      "connect": "CONNECTER VOTRE BANQUE",
      "connectLater": "CONNECTER PLUS TARD"
    }
  }
}
