import React from "react";

import { StyledLabel } from "./Label.styles";
import { LabelProps } from "./Label.types";

export const Label = ({ children, style, accessibilityLabel, testID, numberOfLines, color }: LabelProps) => {
  return (
    <StyledLabel
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
      numberOfLines={numberOfLines}
      color={color}
    >
      {children}
    </StyledLabel>
  );
};
