import styled from "@emotion/styled";
import React from "react";

import { COLORS, mq } from "@bwll/bw-styles";

import { IBlog } from "./BlogPost.types";

const BodySection = styled.div`
  ${mq({
    marginTop: "auto",
  })}
`;

const BlogContainer = styled.article`
  ${mq({
    display: "flex",
    maxWidth: ["none", "340px", "434px"],
    flexBasis: ["100%", "50%", "100%"],
    flexWrap: "wrap",
    alignItems: "stretch",
    alignSelf: "stretch",
    margin: ["15px 0", "25px 0", "0 0 50px 0"],
    padding: ["0", "0 10px", "0 10px"],
    flexDirection: "column",
    ":hover,:focus,:active": {
      cursor: "pointer",
      [`a > span > h3, ${BodySection} > p:last-of-type`]: {
        color: COLORS.PRIMARY["500"],
      },
    },
  })}
`;

const AuthorAndDate = styled.div`
  ${mq({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: ["5px 0", "", "10px 0"],
  })}
`;

const TitleSection = styled.div`
  ${mq({
    marginBottom: "auto",
  })}
`;

const BlogImageHeader = styled.img`
  ${mq({
    height: "auto",
    borderRadius: "8px",
  })}
`;

const BlogTitle = styled.h3(({ theme }) =>
  mq({
    ...theme.h5,
    color: COLORS.NEUTRAL.COOL["900"],
    textAlign: "left",
  }),
);

const BlogTitleLink = styled.a(({ theme }) =>
  mq({
    ...theme.link,
    ...theme.h5,
    textDecoration: "none",
    display: "block",
    margin: ["25px 0 15px 0", "25px 0 25px 0", "15px 0 25px 0"],
  }),
);

const BlogParagraph = styled.p(({ theme }) =>
  mq({
    ...theme.paragraph,
    textAlign: "left",
    margin: 0,
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "normal",
  }),
);

const AuthorTitle = styled.p(({ theme }) =>
  mq({
    ...theme.paragraph,
    fontSize: ["12px", "", "14px"],
    fontWeight: "bold",
    lineHeight: ["1.5", "", "1.43"],
    margin: "0",
  }),
);

const DateText = styled.p(({ theme }) =>
  mq({
    ...theme.paragraph,
    fontSize: ["12px", "", "14px"],
    fontWeight: "bold",
    lineHeight: ["1.5", "", "1.43"],
    margin: "0",
  }),
);

const LinkText = styled.p(({ theme }) =>
  mq({
    ...theme.paragraph,
    fontSize: ["16px", "18px", "18px"],
    lineHeight: ["2.13", "1.89", "1.44"],
    fontWeight: "bold",
    color: COLORS.PURPLE["700"],
    textAlign: "left",
    margin: "0",
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const BlogPost: React.FC<IBlog> = ({
  title,
  authorBio,
  date,
  text,
  image: { src, alt },
  href,
  ctaButtonCopy = "Learn More",
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      location.href = href;
    }
  };

  return (
    <BlogContainer onClick={handleClick}>
      <TitleSection>
        <BlogImageHeader src={src} alt={alt} />
        <BlogTitleLink href={href}>
          <BlogTitle>{title}</BlogTitle>
        </BlogTitleLink>
      </TitleSection>
      <BodySection>
        <BlogParagraph>{text}</BlogParagraph>
        <AuthorAndDate>
          <AuthorTitle>{authorBio?.title}</AuthorTitle>
          <DateText>{date}</DateText>
        </AuthorAndDate>
        <LinkText>{ctaButtonCopy}</LinkText>
      </BodySection>
    </BlogContainer>
  );
};
