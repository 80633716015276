export const notificationPreferences = {
  "title": "Préférences de notification",
  "subtitle": "Choisissez comment vous souhaitez recevoir des communications de Borrowell.",
  "options": {
    "push": {
      "title": "Notifications push",
      "customize": {
        "title": "Personnaliser les notifications push",
        "content": "Choisissez le type de notifications que vous souhaitez recevoir sur votre appareil. Vous pouvez toujours changer d'avis si nécessaire."
      },
      "promotional": {
        "title": "Alertes promotionnelles",
        "content": "Alertes pour les offres exclusives, les lancements de produits et les offres arrivant à échéance."
      }
    },
    "email": {
      "title": "Notifications par courriel",
      "creditScore": {
        "title": "Fréquence de mise à jour du pointage et du rapport de crédit",
        "content": "Choisissez lorsque vous souhaitez être informé des changements de votre pointage de crédit et de votre dossier de crédit."
      },
      "offers": {
        "title": "Offres promotionnelles",
        "content": "Recommandations de produits et offres exclusives disponibles pour votre dernier profil de crédit.",
        "link": "Comment désactiver les courriels promotionnels"
      }
    },
    "learnMore": {
      "title": "En savoir plus",
      "push": "En savoir plus sur les notifications push de Borrowell",
      "email": "En savoir plus sur les courriels de Borrowell"
    },
    "score": {
      "title": "Mises à jour de la cote et du dossier",
      "subtitle": "Contrôlez la manière dont vous souhaitez être informé des changements apportés à votre cote et à votre dossier de crédit."
    }
  },
  "cardNotificationText": {
    "pushAndEmail": "Vous recevrez des communications de Borrowell par le biais de notifications et de courriels.",
    "email": "Vous recevrez des communications de Borrowell par courriel.",
    "turnOffEmailLink": "Pourquoi ne puis-je pas désactiver les courriels ici ?"
  },
  "turnOffEmailsModal": {
    "markdownContent": "## Communications que vous recevez de Borrowell \n \nBorrowell vous envoie deux types de courriels : des courriels promotionnels et des courriels concernant les mises à jour de votre cote et de votre dossier de crédit. Vous pouvez actuellement désactiver les courriels promotionnels, mais vous ne pouvez pas désactiver les courriels concernant votre cote et votre dossier de crédit. \n \n Mises à jour de la cote et du dossier \n \nNous vous informons en tant que membre des mises à jour de votre compte afin que vous soyez toujours au courant des changements apportés à votre profil de crédit. \n \n \nLes courriels que vous recevez au sujet de votre cote et de votre dossier de crédit sont des courriels transactionnels. Les courriels transactionnels ou fonctionnels ne sont pas tenus d'avoir un lien de désabonnement en vertu de la législation anti-pourriel du Canada. \n \n \nVous pouvez contrôler la façon dont vous souhaitez être informé des changements apportés à votre profil de crédit **[__here__]()**. \n \n#### Courriels promotionnels \n \nLes courriels promotionnels nous permettent de vous informer sur les produits financiers qui correspondent à votre profil de crédit. Ces produits financiers peuvent vous aider à améliorer votre crédit ou à atteindre vos objectifs financiers. \n \nPour désactiver ces courriels, allez au bas du courriel et cliquez sur le lien de désabonnement."
  },
  "notificationTypes": {
    "all": "Promotionnel et transactionnel",
    "transactional": "Transactionnel",
    "none": "Aucun"
  },
  "selectedNotificationStatus": "Vous avez choisi de recevoir des notifications **{{type}}**.",
  "emailTypes": {
    "weekly": "Envoyez-moi un courriel chaque semaine",
    "changes": "Envoyez-moi un e-mail uniquement en cas de changement"
  },
  "selectedEmailStatus": "Vous avez sélectionné **{{type}}.**"
}
