import { User } from "oidc-client-ts";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useSignInRedirectCallback = (isSilentAuth = false) => {
  const navigate = useNavigate();
  const location = useLocation();
  const signInRedirectCallback = useCallback(
    (user: User | void) => {
      // If isSilentAuth is true, skip handling signinRedirect
      if (isSilentAuth) {
        return;
      }

      // Type assertion for user.state.originLocation.pathname and search to exist and to be a string
      if (
        user?.state &&
        typeof user.state === "object" &&
        "originLocation" in user.state &&
        typeof user.state.originLocation === "object" &&
        user.state.originLocation &&
        "pathname" in user.state.originLocation &&
        typeof user.state.originLocation.pathname === "string" &&
        "search" in user.state.originLocation &&
        typeof user.state.originLocation.search === "string"
      ) {
        // user.state.originLocation.pathname and search are both strings
        const { pathname, search } = user.state.originLocation;
        navigate(`${pathname}${search}`, { replace: true });
        return;
      }
      // 1. Navigate to the pathname to remove code and state parameters from the
      // url when you are redirected from the authorize page.
      // https://github.com/authts/react-oidc-context/blob/f175dcba6ab09871b027d6a2f2224a17712b67c5/src/AuthProvider.tsx#L20-L30
      // 2. Using location hook instead of window.location to respect
      // BrowserRouter's basename configuration.
      navigate(location.pathname, { replace: true });
    },
    [navigate, isSilentAuth, location],
  );

  return signInRedirectCallback;
};
