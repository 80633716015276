export const sponsoredCard = {
  titleTooltip:
    "Borrowell receives additional compensation for products with the label “Sponsored”. This compensation helps Borrowell provide you with free tools like your weekly credit score.",
  ctaMarkdown: "[__Learn more about this product__]()",
  cta: "Learn more about this product",
  title: "SPONSORED",
  approvalChanceTooltip: {
    excellent:
      "This is a strong match. You’ve met the qualification criteria and, based on your credit profile, our calculations show you’ve got an excellent chance of being approved for this product. You can apply with a strong degree of confidence.",
    good: "Your chances are looking favourable. Positive signs — your profile meets all the product’s eligibility requirements, and your credit profile suggests you’re likely to be approved. You can apply with a good degree of confidence.",
    fair: "Your outlook is fair. Based on your credit profile, our calculations suggest you’ve got a reasonable chance of being approved for this product. You can take this into consideration as you decide whether to apply.",
    low: "You have a low likelihood of approval on this product. While you meet the basic qualification criteria for this product, we’re not confident, based on your credit profile, that you’ll be approved. Apply with caution.",
    notEligible:
      "Just a heads up... We noticed that you have an open trade with this partner. Unfortunately, you’ve reached the maximum number of trades that this partner allows. Best not to apply at this time.",
    none: "We are unable to calculate your approval chance for this product at this time. Check back later.",
  },
};
