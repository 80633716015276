export const productVerticalsNavBar = {
  "categories": {
    "loans": "Prêts",
    "creditCards": "Cartes de crédit",
    "carLoans": "Prêts automobiles",
    "mortgages": "Hypothèques",
    "bankAccounts": "Comptes bancaires",
    "insurance": "Assurance",
    "buildCredit": "Construire le crédit",
    "investing": "Investir"
  },
  "showMore": "Plus"
}
