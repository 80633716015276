import styled from "@emotion/native";

import { COLORS } from "@bwll/bw-styles";

export const Wrapper = styled.View`
  position: relative;
  width: fit-content;
`;

export const StrokeLine = styled.View<{
  angle: number;
  width: number;
  height: number;
  strokeWidth?: number;
  color?: string;
}>(({ angle = 0, width = 0, height = 0, strokeWidth = 2, color = COLORS.RED["400"] }) => ({
  top: height / 2,
  position: "absolute",
  height: strokeWidth,
  // Rounding up to prevent issues with decimals.
  borderRadius: Math.ceil(strokeWidth / 2),
  backgroundColor: color,
  width: width,
  transform: `rotate(${-angle / 2}deg)`,
}));
