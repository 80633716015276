import styled from "@emotion/native";

import { COLORS, borderRadius, fontSize, lineHeight, spacing, toRGBAString } from "@bwll/bw-styles";
import { calculateRelativeSize, styledValue } from "@bwll/bw-utils";

import { Body2, Subtitle } from "../../atoms/Typography";

type WithCardWidth = {
  cardWidth: number;
};
const DEFAULT_CARD_WIDTH_PX = 175;

const innerCircle1SizePx = 196;
const innerCircle1LeftOffsetPx = 57;
const innerCircle1TopOffsetPx = 57;

const innerCircle2SizePx = 160;
const innerCircle2LeftOffsetPx = 33;
const innerCircle2TopOffsetPx = 16;

export const calculateLayout = calculateRelativeSize(DEFAULT_CARD_WIDTH_PX);

export const PressableContainer = styled.TouchableOpacity<{ color?: string } & WithCardWidth>`
  padding-vertical: ${({ cardWidth }) => styledValue(calculateLayout(spacing.xxs, cardWidth))};
  padding-horizontal: ${({ cardWidth }) => styledValue(calculateLayout(spacing.xs, cardWidth))};
  background-color: ${({ color }) => color || COLORS.PRIMARY["700"]};
  border-radius: ${styledValue(borderRadius.s)};
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  flex: 1;
`;

export const InnerCircle1 = styled.View<WithCardWidth>`
  position: absolute;
  width: ${({ cardWidth }) => styledValue(calculateLayout(innerCircle1SizePx, cardWidth))};
  height: ${({ cardWidth }) => styledValue(calculateLayout(innerCircle1SizePx, cardWidth))};
  left: -${({ cardWidth }) => styledValue(calculateLayout(innerCircle1LeftOffsetPx, cardWidth))};
  top: -${({ cardWidth }) => styledValue(calculateLayout(innerCircle1TopOffsetPx, cardWidth))};
  border-radius: ${({ cardWidth }) =>
    styledValue(Math.floor(calculateLayout(innerCircle1SizePx, cardWidth) / 2))};
  background-color: ${toRGBAString(COLORS.WHITE, 0.1)};
`;

export const InnerCircle2 = styled.View<WithCardWidth>`
  position: absolute;
  width: ${({ cardWidth }) => styledValue(calculateLayout(innerCircle2SizePx, cardWidth))};
  height: ${({ cardWidth }) => styledValue(calculateLayout(innerCircle2SizePx, cardWidth))};
  left: -${({ cardWidth }) => styledValue(calculateLayout(innerCircle2LeftOffsetPx, cardWidth))};
  top: -${({ cardWidth }) => styledValue(calculateLayout(innerCircle2TopOffsetPx, cardWidth))};
  border-radius: ${({ cardWidth }) =>
    styledValue(Math.floor(calculateLayout(innerCircle2SizePx, cardWidth) / 2))};
  background-color: ${toRGBAString(COLORS.WHITE, 0.1)};
`;

export const Row = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CardTitle = styled(Subtitle)<WithCardWidth>`
  color: ${COLORS.WHITE};
  flex-wrap: wrap;
  font-size: ${({ cardWidth }) => styledValue(calculateLayout(fontSize.xxs, cardWidth))};
  line-height: ${({ cardWidth }) => styledValue(calculateLayout(lineHeight.xs, cardWidth))};
  position: absolute;
  top: ${({ cardWidth }) => styledValue(calculateLayout(spacing.xxs, cardWidth))};
  left: ${({ cardWidth }) => styledValue(calculateLayout(spacing.xs, cardWidth))};
`;

export const CardData = styled(Body2)<WithCardWidth>`
  font-size: ${({ cardWidth }) => styledValue(calculateLayout(fontSize.xxxxs, cardWidth))};
  line-height: ${({ cardWidth }) => styledValue(calculateLayout(fontSize.xxs, cardWidth))};
  color: ${COLORS.WHITE};
`;
