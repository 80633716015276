export const report = {
  "titleLine1": "Votre Equifax",
  "titleLine2": "Dossier de crédit",
  "registeredTrademark": "🅫",
  "fileCreationDate": "Date de création du fichier",
  "lastPulled": "Dernier rapport genere",
  "reportItemsNone": "Aucun(e) rapporté(e)",
  "reportItem": "article",
  "reportItems": "articles",
  "expandLess": "moins",
  "expandMore": "plus"
}
