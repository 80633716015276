import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const DropdownContainer = styled.View<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "unset" : "340px")};
  max-width: 100%;
  margin-vertical: ${styledValue(spacing.xs)};
  z-index: 1;
`;
