export const pushNotificationSettings = {
  "title": "Paramètres des notifications push",
  "subTitle": "Choisissez les types de notifications que vous souhaitez recevoir sur votre appareil.",
  "options": {
    "all": {
      "title": "Promotionnel et transactionnel",
      "text": "Soyez le premier à recevoir des alertes pour des offres exclusives, des produits nouvellement lancés et des offres arrivant à échéance ! Garder une longueur d'avance n'a jamais été aussi gratifiant."
    },
    "transactional": {
      "title": "Transactionnel",
      "text": "Nous vous informerons uniquement des mises à jour de votre score de crédit, de votre rapport de crédit et de l'utilisation des produits Borrowell. "
    },
    "none": {
      "title": "Aucun",
      "text": "Vous préférez faire une pause de notifications ? Même si nous allons vous manquer, prenez le temps de vous reposer."
    }
  }
}
