import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";

export const ContentWrapper = styled.View(() => ({
  flexDirection: "row",
  flexWrap: "wrap",
  flex: 1,
  justifyContent: "space-between",
}));

export const CardWrapper = styled.View<{
  cardsPerRow: number;
  index: number;
  horizontalPadding: { paddingLeft: number; paddingRight: number };
}>(({ cardsPerRow, horizontalPadding = {} }) => ({
  width: `${100 / cardsPerRow}%`,
  ...horizontalPadding,
}));

export const FlexGap = styled.View({
  width: spacing.s,
});
