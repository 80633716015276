import styled from "@emotion/styled";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Suggestion, getDetails } from "use-places-autocomplete";

import { mq } from "@bwll/bw-styles";
import { spacing } from "@bwll/bw-styles";
import { REGIONS_ORDERED } from "@bwll/bw-types";

import { GooglePlacesAutocomplete } from "../..";
import { Dropdown, Form, IDropdownOption, Input } from "../..";

type IAddressField = {
  id: string;
  label: string;
  value: string;
  isRequired?: boolean;
  hasError?: boolean;
  errorHint?: string;
  isDirty?: boolean;
  caption?: string;
  maskOnBlurOnly?: boolean;
  mask?: (value: string) => string;
};

const provinces: IDropdownOption[] = REGIONS_ORDERED.map(({ abbreviation, name }) => ({
  value: abbreviation,
  label: name,
}));

export interface IAddress {
  street: IAddressField;
  unitApt: IAddressField;
  city: IAddressField;
  province: IAddressField;
  postalCode: IAddressField;
}

interface IProps {
  fields: IAddress;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement> | Record<string, Record<string, string>>) => void;
  handleDropdownChange: (key: string, value: string | number) => void;
  onFieldBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autocompleteApiKey?: string;
}

export const AddressRow = styled.div<{ leftInputSize: number }>(({ leftInputSize }) =>
  mq({
    display: "flex",
    position: "relative",
    flexDirection: ["column", "row", "row"],
    alignItems: "flex-start",
    "&>div:first-of-type": {
      width: [`100%`, `${leftInputSize}%`, `${leftInputSize}%`],
      marginRight: spacing.xxs,
    },

    "&>div:last-of-type": {
      width: ["100%", `${100 - leftInputSize}%`, `${100 - leftInputSize}%`],
    },
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const AddressForm = ({
  fields,
  handleTextChange,
  handleDropdownChange,
  onFieldBlur,
  autocompleteApiKey,
}: IProps) => {
  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFieldBlur?.(e);
  };

  interface IGoogleAddressField {
    types: string[];
    long_name: string;
    short_name: string;
  }

  interface IAddressField {
    [key: string]: string;
  }

  const onGoogleAutocompleteChange = (e: Suggestion) => {
    getDetails({
      placeId: e.place_id,
      fields: ["address_components"],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).then(({ address_components }: any) => {
      const {
        street_number: streetNumber,
        route: streetName,
        locality: city,
        administrative_area_level_1: province,
        subpremise: unitApt,
        postal_code: postalCode,
      } = address_components
        .map((field: IGoogleAddressField) =>
          field.types.map((fieldType: string) => ({
            ...extractAddressFieldDetails(fieldType, field),
          })),
        )
        .flat()
        .reduce((group: IAddressField, item: IAddressField) => {
          const key = Object.keys(item)[0];
          group[`${key}`] = item[`${key}`];
          return group;
        }, {});

      const addressDetails: Record<string, string> = {
        street: `${streetNumber ? `${streetNumber} ` : ""}${streetName ?? ""}`,
        city: city ?? "",
        province: `${provinces.filter((item) => item.value === province)[0]?.value ?? ""}`,
        unitApt: unitApt ?? "",
        postalCode: postalCode ?? "",
      };

      Object.keys(fields)?.map((key) => {
        handleTextChange({
          target: {
            id: key,
            value: addressDetails[`${key}`] ?? "",
          },
        });
      });
    });
  };

  const extractAddressFieldDetails = (fieldType: string, field: IGoogleAddressField) => {
    // administrative_area_level_1 is the province
    // using the short_name abbreviation for the province to capture both references to Quebec and Québec
    if (fieldType === "administrative_area_level_1") {
      return { [fieldType]: field.short_name };
    }
    return {
      [fieldType]: field.long_name,
    };
  };

  return (
    <Form>
      <AddressRow leftInputSize={80}>
        {!fields.street.value && autocompleteApiKey ? (
          <Wrapper
            key={autocompleteApiKey}
            apiKey={autocompleteApiKey}
            render={(status: Status) => {
              switch (status) {
                case Status.SUCCESS:
                  return (
                    <GooglePlacesAutocomplete
                      dataCy="street-autocomplete"
                      onSelection={onGoogleAutocompleteChange}
                      label={fields.street.label}
                      caption={fields.street.caption}
                      onBlur={(e) =>
                        handleTextChange({ target: { value: e.target.value, id: fields.street.id } })
                      }
                      errorHint={fields.street.errorHint}
                    />
                  );
                default:
                  return (
                    <Input
                      id={fields.street.id}
                      label={fields.street.label}
                      onChange={handleTextChange}
                      hasError={!!fields.street.errorHint}
                      errorHint={fields.street.errorHint}
                      onBlur={onBlur}
                      value={fields.street.value}
                    />
                  );
              }
            }}
            libraries={["places"]}
          />
        ) : (
          <Input
            id={fields.street.id}
            label={fields.street.label}
            onChange={handleTextChange}
            hasError={!!fields.street.errorHint}
            errorHint={fields.street.errorHint}
            onBlur={onBlur}
            value={fields.street.value}
          />
        )}

        <Input
          id={fields.unitApt.id}
          label={fields.unitApt.label}
          onChange={handleTextChange}
          onBlur={onBlur}
          value={fields.unitApt.value}
        />
      </AddressRow>
      <Input
        id={fields.city.id}
        label={fields.city.label}
        onChange={handleTextChange}
        onBlur={onBlur}
        value={fields.city.value}
        hasError={!!fields.city.errorHint}
        errorHint={fields.city.errorHint}
      />
      <AddressRow leftInputSize={50}>
        <Dropdown
          id={fields.province.id}
          options={provinces}
          label={fields.province.label}
          handleSelection={(e) => {
            handleDropdownChange(fields?.province?.id as keyof IAddress, e.value);
          }}
          selectedOption={
            provinces.filter((province) => province.value === fields.province.value)[0] ?? {
              value: "",
              label: "",
            }
          }
          hasError={!!fields.province.errorHint}
          errorHint={fields.province.errorHint}
        />
        <Input
          id={fields.postalCode.id}
          label={fields.postalCode.label}
          onChange={handleTextChange}
          onBlur={onBlur}
          value={fields.postalCode.value}
          hasError={!!fields.postalCode.errorHint}
          errorHint={fields.postalCode.errorHint}
        />
      </AddressRow>
    </Form>
  );
};
