import { ReactNode, createContext, useMemo, useState } from "react";

import { ContextName, useContextWrapper } from "./contextWrapper";

interface ILoading {
  isLoading: boolean;
  icon?: string;
  title?: string;
  description?: string;
}

export interface ILoadingContext {
  loading: ILoading;
  setLoading: (value: ILoading) => void;
}

interface ILoadingProviderProps {
  children: ReactNode;
}

export const LoadingContext = createContext<ILoadingContext>({} as ILoadingContext);

export const LoadingProvider = ({ children }: ILoadingProviderProps) => {
  const [loading, setLoading] = useState<ILoading>({
    isLoading: false,
    icon: "",
    title: "",
    description: "",
  });

  const value = useMemo(() => ({ loading, setLoading }), [loading]);

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};

export const useLoadingContext = () => useContextWrapper(LoadingContext, ContextName.LoadingContext);
