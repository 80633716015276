export const tooltip = {
  "approvalChance": {
    "excellent": "This is a strong match. You’ve met the eligibility requirements, and based on your credit report and Borrowell account profile information, our calculations show you’ve got an excellent chance of being approved for this product. You can apply with a strong degree of confidence. This is not a guarantee, but can help you compare your options.",
    "good": "Your chances are looking favourable. Positive signs - your profile meets all the product’s eligibility requirements, and your credit report and Borrowell account profile information suggests you’re likely to be approved. You can apply with a good degree of confidence. This is not a guarantee, but can help you compare your options.",
    "fair": "Your outlook is fair. Based on your credit report profile and Borrowell account profile information, our calculations suggest you’ve got a reasonable chance of being approved for this product. You can take this into consideration as you decide whether to apply. This is not a guarantee, but can help you compare your options.",
    "low": "You have a low likelihood of approval on this product. While you meet the basic qualification criteria for this product, we’re not confident, based on your credit profile, that you’ll be approved. Apply with caution.",
    "notEligible": "Just a heads up... We noticed that you have an open trade with this partner. Unfortunately, you’ve reached the maximum number of trades that this partner allows. Best not to apply at this time.",
    "none": "We are unable to calculate your approval chance for this product at this time. Check back later."
  }
}
