export const bankingAccountSelectionPage = {
  "title": "Sélectionnez le compte que vous utilisez pour payer votre loyer",
  "button": "SÉLECTIONNER UN COMPTE",
  "change": "Changer",
  "bankAccounts": {
    "title": "Comptes bancaires"
  },
  "creditCards": {
    "title": "Cartes de crédit"
  },
  "any": {
    "title": "Autre"
  },
  "loadingTitle": "Chargement des comptes...",
  "loadingDescription": "Veuillez ne pas fermer cette page, sinon vous perdrez votre avancement et devrez recommencer.",
  "noAccountsTitle": "Nous sommes désolés, il semble que quelque chose n’a pas fonctionné",
  "noAccountsMessage": "Veuillez réessayer plus tard ou téléverser une preuve de votre paiement de loyer.",
  "noAccountsButton": "TÉLÉVERSER"
}
