import styled from "@emotion/styled";

import { mq } from "@bwll/bw-styles";

export const Container = styled.div<{ isLoading: boolean }>(({ isLoading = false }) =>
  mq({
    ...(!isLoading && { display: "none" }),
  }),
);

export const Background = styled.div<{ opacity?: number }>(({ theme, opacity }) =>
  mq({
    ...(opacity && { opacity }),
    background: theme.COLORS.WHITE,

    position: "fixed",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",

    zIndex: 998,
  }),
);

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999;
`;
