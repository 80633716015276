import { AdjustableTextProps, DefaultTextProps, WithChildren } from "@bwll/bw-components/next/types";

export enum SUBTITLE_TYPE {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export type SubtitleProps = DefaultTextProps &
  AdjustableTextProps &
  WithChildren<{
    type?: SUBTITLE_TYPE;
  }>;
