import styled from "@emotion/styled";

import { mq } from "@bwll/bw-styles";

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Well = styled.div(({ theme }) =>
  mq({
    background: theme.COLORS.NEUTRAL.COOL["050"],
    border: `1px solid ${theme.COLORS.NEUTRAL.COOL["200"]}`,
    borderRadius: "8px",
  }),
);
