import styled from "@emotion/styled";
import React from "react";

import { COLORS, mq, spacing } from "@bwll/bw-styles";

import { Icon } from "../..";

interface INavbar {
  leftIcon?: string;
  rightIcon?: string;
  children?: React.ReactNode;
  onClickLeftIcon?: () => void;
  onClickRightIcon?: () => void;
  withBorderBottom?: boolean;
}

const ContentWrapper = styled.div<{ withBorderBottom: boolean }>(({ theme, withBorderBottom }) => ({
  width: `calc(100% - ${spacing.s * 2}px)`,
  ...(withBorderBottom && { borderBottom: `1px solid ${theme.COLORS.NEUTRAL.COOL["100"]}` }),
  height: "40px",
  padding: `${spacing.xxs}px ${spacing.s}px`,
  display: "flex",
  alignItems: "center",
}));

const IconBox = styled.div({
  cursor: "pointer",
});

const ChildrenWrapper = styled.div(
  mq({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: ["100%", "560px", "560px"],
    maxWidth: [
      `calc(100% - ${spacing.m * 2}px)`,
      `calc(100% - ${spacing.xl * 2}px)`,
      `calc(100% - ${spacing.xl * 2}px)`,
    ],
    height: ["100%", "auto", "auto"],
    padding: [
      `${spacing.none}px ${spacing.m}px`,
      `${spacing.none}px ${spacing.xl}px`,
      `${spacing.none}px ${spacing.xl}px`,
    ],
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Navbar = ({
  leftIcon,
  rightIcon,
  onClickLeftIcon,
  onClickRightIcon,
  children,
  withBorderBottom = true,
}: INavbar) => {
  const onClickLeft = () => {
    onClickLeftIcon?.();
  };
  const onClickRight = () => {
    onClickRightIcon?.();
  };

  return (
    <ContentWrapper data-cy="navbarContent" withBorderBottom={withBorderBottom}>
      <div>
        {leftIcon && (
          <IconBox onClick={onClickLeft} data-cy="navbarLeft-button">
            <Icon icon={leftIcon} size={24} color={COLORS.NEUTRAL.WARM["800"]} />
          </IconBox>
        )}
      </div>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <div>
        {rightIcon && (
          <IconBox onClick={onClickRight} data-cy="navbarRight-button">
            <Icon icon={rightIcon} size={24} color={COLORS.NEUTRAL.WARM["800"]} />
          </IconBox>
        )}
      </div>
    </ContentWrapper>
  );
};
