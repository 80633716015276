import { COLORS, fontSize, letterSpacing, lineHeight } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const bannerMessageStyles = {
  paragraph: {
    fontSize: fontSize.xs,
    lineHeight: styledValue(lineHeight.m),
    letterSpacing: styledValue(letterSpacing.m),
    color: COLORS.NEUTRAL.WARM[800],
    margin: 0,
    a: {
      fontSize: fontSize.xs,
      lineHeight: styledValue(lineHeight.m),
      letterSpacing: "0.32px",
      color: COLORS.PRIMARY.DEFAULT,
      textDecoration: "underline",
      textDecorationColor: COLORS.PRIMARY.DEFAULT,
      ":hover": {
        color: COLORS.PRIMARY[300],
        textDecorationColor: COLORS.PRIMARY[300],
      },
    },
  },
};
