import styled from "@emotion/native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BoldText } from "../Default";
import { StyledSubheading1Props } from "./Subheading1.types";

export const StyledSubheading1 = styled(BoldText)<StyledSubheading1Props>`
  font-size: ${styledValue(fontSize.s)};
  line-height: ${styledValue(spacing.s)};
  letter-spacing: ${styledValue(letterSpacing.m)};
  color: ${({ color }) => color ?? COLORS.NEUTRAL.WARM["700"]};
`;
