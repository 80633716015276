import styled from "@emotion/styled";
import React from "react";

import { Image, Section } from "@bwll/bw-components";
import { mq, spacing } from "@bwll/bw-styles";

interface IHeroSection {
  image: string;
  imageWidth?: number;
  imageHeight?: number;
  backgroundColor?: string;
  children: React.ReactNode;
}

interface ImageProps {
  height?: number;
  width?: number;
}

const ContentWrapper = styled.div({
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const ChildrenWrapper = styled.div({
  display: "flex-column",
  flex: 1,
  "h1, h2, h3, h4, h5, h6, p, span": {
    textAlign: "left",
  },
});

const ImageWrapper = styled.div(({ width, height }: ImageProps) =>
  mq({
    width: width ?? 240,
    height: height ?? 240,
    paddingLeft: [spacing.m, spacing.l, spacing.xl],
    display: ["none", "block", "block"],
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const HeroSection = ({ image, children, imageHeight, imageWidth, backgroundColor }: IHeroSection) => {
  return (
    <Section backgroundColor={backgroundColor}>
      <ContentWrapper>
        <ChildrenWrapper>{children}</ChildrenWrapper>
        <ImageWrapper width={imageWidth} height={imageHeight}>
          <Image src={image} width={240} height={240} />
        </ImageWrapper>
      </ContentWrapper>
    </Section>
  );
};
