import styled from "@emotion/native";
import { Platform } from "react-native";

import { COLORS, fontSize } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { StyledSubheading1 } from "../Subheading1/Subheading1.styles";
import { StyledSubheading2Props } from "./Subheading2.types";

export const StyledSubheading2 = styled(StyledSubheading1)<StyledSubheading2Props>`
  font-size: ${styledValue(fontSize.xs)};
  color: ${({ color }) => color ?? COLORS.NEUTRAL.WARM["700"]};
  ${({ underline }) =>
    underline &&
    `
  text-decoration-line: underline;
  text-decoration-style: ${Platform.OS === "android" ? "solid" : "dotted"};`};
`;
