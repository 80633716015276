import { RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE } from "./rentReporting/attributes";

export enum ANALYTICS_ATTRIBUTES {
  // base
  PLATFORM = "platform",
  LAYOUT_TYPE = "layoutType",
  APPLICATION_TYPE = "applicationType",
  VERSION = "version",
  EVENT_LABEL = "eventLabel",
  EVENT_ACTION = "eventAction",
  EVENT_CATEGORY = "eventCategory",
  EVENT_TYPE = "eventType",
  SHOULD_EMIT_TO_SEGMENT = "shouldEmitToSegment",
  AUTHENTICATED_SESSION_ID = "authenticatedSessionId",
  SESSION_ID = "sessionId",
  INDIVIDUAL_CLIENT_ID = "individualClientId",
  MEMBER_GUID = "member_guid",
  USER_GUID = "user_guid",
  SESSION_GUID = "session_guid",
  FLOW_NAME = "flowName",
  FLOW_TYPE = "flowType",
  // step
  CURRENT_STEP_NAME = "currentStepName",
  CURRENT_STEP_NUMBER = "currentStepNumber",
  CURRENT_STEP_NUMBER_GRANULAR = "currentStepNumberGranular",
  NUMBER_OF_STEPS = "numberOfSteps",
  SUCCESS_STEP_NAME = "completedStepName",
  SUCCESS_STEP_NUMBER = "completedStepNumber",

  // click
  CALL_TO_ACTION = "callToAction",
  EVENT_TIMESTAMP = "eventTimestamp",
  // navigation click
  ORIGIN = "origin",
  DESTINATION = "destination",
  // page
  SCREEN_NAME = "screenName",
  // sources id
  REFERRER = "referrer",
  IMPRESSION_ID = "impressionId",
  CLICK_ID = "clickId",
  // button
  BUTTON_NAME = "buttonName",
  // link
  LINK_NAME = "linkName",
  LINK_TEXT = "linkText",
  // checkbox
  CHECKBOX_NAME = "checkboxName",
  // mx
  BANK_NAME = "bankName",
  BANK_ID = "bank_id",
  CONNECTION_STATUS = "connection_status",
  ERROR_TYPE = "error_type",
  // custom
  PAGE_ROUTE = "pageRoute",
  PREVIOUS_PAGE_ROUTE = "previousPageRoute",
  TRANSACTION_COUNT = "numberOfTransactionsReturned",
  POPUP_CATEGORY = "popup_category",
  POPUP_TEXT_BODY = "popup_text_body",
  POPUP_IMPRESSION_ID = "pop_up_impression_id",
  NAME = "name",
  PLACEMENT = "placement",
  PRODUCT_ID = "product_id",
  POSITION = "position",
  LAYOUT = "layout",
  PROMO_TEXT_HEADER = "promoTextHeader",
  PROMO_TEXT_BODY = "promoTextBody",
  MX_FLOW_NAME = "MX_flow_name",
  MX_INITIAL_CONNECTION = "Initial connection",
  MX_RECONNECTION = "Reconnection",
  CONTENT_TITLE = "content_title",
  CONTENT_LINK_URL = "content_link_url",
  CONTENT_POSITION = "content_position",
  CONTENT_TYPE = "content_type",
  PRODUCT_VERTICAL = "product_vertical",
  TRANSACTION_MATCHES_CRITERIA = "transaction_matches_criteria",
  VALIDATION_ERRORS = "validation_errors",
  // Top Picks
  BUTTON_TEXT = "button_text",
  // Product catalog
  BUTTON_CONTEXT = "button_context",
  _BUTTON_NAME = "button_name",
  _CALL_TO_ACTION = "call_to_action",
  CASHBACK = "is_cashback",
  _CLICK_ID = "click_id",
  CTA_TEXT = "cta_text",
  DISPLAY_ORDER = "display_order",
  DISPLAY_ORIENTATION = "display_orientation",
  FEATURES_SHOWN = "is_features_shown",
  FILTER_BY_VALUE = "filter_by_value",
  _IMPRESSION_ID = "impression_id",
  LEGAL_DETAILS_EXPANDED = "is_legal_details_expanded",
  LIMITED_TIME_OFFER = "is_limited_time_offer",
  LIMITED_TIME_OFFER_END_DATE = "limited_time_offer_end_date",
  LIKELIHOOD_OF_APPROVAL = "likelihood_of_approval",
  LIKELIHOOD_OF_APPROVAL_BUCKET = "likelihood_of_approval_bucket",
  LIKELIHOOD_OF_APPROVAL_LABEL = "likelihood_of_approval_label",
  NUMBER_OF_ADDITIONAL_PRODUCTS_SHOWN = "number_of_additional_products_shown",
  NUMBER_OF_PRODUCTS_SHOWN = "number_of_products_showing",
  PREFERRED_ORDER = "preferred_order",
  PREPOPULATION_FLOW = "is_prepopulation_flow",
  PRESELECTED = "is_preselected",
  PRODUCT_CAROUSEL_NAME = "product_carousel_name",
  QUICK_APPLY = "is_quick_apply",
  RANK = "rank",
  SHOWN_IN_CAROUSEL = "is_shown_in_carousel",
  SORT_BY_VALUE = "sort_by_value",
  SPONSORED = "is_sponsored",
  SPONSORED_PLACEMENT = "sponsored_placement_order_property",
  TAB_SWITCHED = "is_tab_switched",
  TRACKING_TAG = "tracking_tag",
  VERTICAL = "vertical",
  SUBVERTICAL = "subvertical",
  TAB_VIEWED = "tab_viewed",
}

export interface IAnalyticsAttributes {
  // base
  [ANALYTICS_ATTRIBUTES.PLATFORM]?: any;
  [ANALYTICS_ATTRIBUTES.LAYOUT_TYPE]?: any;
  [ANALYTICS_ATTRIBUTES.APPLICATION_TYPE]?: any;
  [ANALYTICS_ATTRIBUTES.VERSION]?: any;
  [ANALYTICS_ATTRIBUTES.EVENT_LABEL]?: any;
  [ANALYTICS_ATTRIBUTES.EVENT_ACTION]?: any;
  [ANALYTICS_ATTRIBUTES.EVENT_CATEGORY]?: any;
  [ANALYTICS_ATTRIBUTES.EVENT_TYPE]?: any;
  [ANALYTICS_ATTRIBUTES.SHOULD_EMIT_TO_SEGMENT]?: any;
  [ANALYTICS_ATTRIBUTES.AUTHENTICATED_SESSION_ID]?: any;
  [ANALYTICS_ATTRIBUTES.SESSION_ID]?: any;
  [ANALYTICS_ATTRIBUTES.INDIVIDUAL_CLIENT_ID]?: any;
  [ANALYTICS_ATTRIBUTES.MEMBER_GUID]?: any;
  [ANALYTICS_ATTRIBUTES.USER_GUID]?: any;
  [ANALYTICS_ATTRIBUTES.SESSION_GUID]?: any;
  [ANALYTICS_ATTRIBUTES.FLOW_NAME]?: any;
  [ANALYTICS_ATTRIBUTES.FLOW_TYPE]?:
    | RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.RA_INTERNAL_FLOW
    | RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.RA_EXTERNAL_FLOW;
  // step
  [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME]?: any;
  [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NUMBER]?: any;
  [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NUMBER_GRANULAR]?: any;
  [ANALYTICS_ATTRIBUTES.NUMBER_OF_STEPS]?: any;
  [ANALYTICS_ATTRIBUTES.SUCCESS_STEP_NAME]?: any;
  [ANALYTICS_ATTRIBUTES.SUCCESS_STEP_NUMBER]?: any;

  // click
  [ANALYTICS_ATTRIBUTES.CALL_TO_ACTION]?: any;
  [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]?: any;
  // navigation click
  [ANALYTICS_ATTRIBUTES.ORIGIN]?: any;
  [ANALYTICS_ATTRIBUTES.DESTINATION]?: any;
  // page
  [ANALYTICS_ATTRIBUTES.SCREEN_NAME]?: any;
  // sources id
  [ANALYTICS_ATTRIBUTES.REFERRER]?: any;
  [ANALYTICS_ATTRIBUTES.IMPRESSION_ID]?: any;
  [ANALYTICS_ATTRIBUTES.CLICK_ID]?: any;
  // button
  [ANALYTICS_ATTRIBUTES.BUTTON_NAME]?: any;
  // mx
  [ANALYTICS_ATTRIBUTES.BANK_NAME]?: any;
  [ANALYTICS_ATTRIBUTES.BANK_ID]?: any;
  [ANALYTICS_ATTRIBUTES.CONNECTION_STATUS]?: any;
  [ANALYTICS_ATTRIBUTES.ERROR_TYPE]?: any;
  // custom
  [ANALYTICS_ATTRIBUTES.PREVIOUS_PAGE_ROUTE]?: any;
  [ANALYTICS_ATTRIBUTES.PAGE_ROUTE]?: any;
  [ANALYTICS_ATTRIBUTES.TRANSACTION_COUNT]?: any;
  [ANALYTICS_ATTRIBUTES.POPUP_CATEGORY]?: any;
  [ANALYTICS_ATTRIBUTES.POPUP_TEXT_BODY]?: any;
  [ANALYTICS_ATTRIBUTES.POPUP_IMPRESSION_ID]?: any;
  [ANALYTICS_ATTRIBUTES.NAME]?: any;
  [ANALYTICS_ATTRIBUTES.PLACEMENT]?: any;
  [ANALYTICS_ATTRIBUTES.PRODUCT_ID]?: any;
  [ANALYTICS_ATTRIBUTES.POSITION]?: any;
  [ANALYTICS_ATTRIBUTES.LAYOUT]?: any;
  [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]?: any;
  [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]?: any;
  [ANALYTICS_ATTRIBUTES.MX_FLOW_NAME]?: any;
  [ANALYTICS_ATTRIBUTES.MX_INITIAL_CONNECTION]?: any;
  [ANALYTICS_ATTRIBUTES.MX_RECONNECTION]?: any;
  [ANALYTICS_ATTRIBUTES.CONTENT_TITLE]?: any;
  [ANALYTICS_ATTRIBUTES.CONTENT_LINK_URL]?: any;
  [ANALYTICS_ATTRIBUTES.CONTENT_POSITION]?: any;
  [ANALYTICS_ATTRIBUTES.CONTENT_TYPE]?: any;
  [ANALYTICS_ATTRIBUTES.PRODUCT_VERTICAL]?: any;
}

export type AnalyticsEvent = {
  [P in ANALYTICS_ATTRIBUTES]?: string | number | boolean;
};
