import styled from "@emotion/styled";
import React, { useEffect, useMemo, useState } from "react";

import { Checkbox } from "./index";

export interface CheckboxElementProps {
  value: string;
  checked?: boolean;
  label?: string;
  subLabel?: string;
  subText?: string;
}

export interface CheckboxGroupProps {
  id: string;
  checkboxList: CheckboxElementProps[];
  onItemChange?: (selected: Record<string, boolean>) => void;
  withBorder?: boolean;
  showDivider: boolean;
  maxOptionsSelected?: number;
}

const ListContainer = styled.div``;

const Divider = styled.div`
  min-height: 1px;
  background: rgba(242, 241, 237, 0.6);
  margin: 10px 0px;
`;

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  id,
  checkboxList,
  onItemChange,
  withBorder,
  showDivider,
  maxOptionsSelected = checkboxList.length,
}) => {
  const getInitial = (list: CheckboxElementProps[]) => {
    const initState: Record<string, boolean> = {};

    list.forEach((v: CheckboxElementProps) => {
      initState[v.value] = v.checked ?? false;
    });

    return initState;
  };
  const [selected, setSelected] = useState<Record<string, boolean>>(getInitial(checkboxList));

  let count = 0;
  const totalItem = checkboxList.length;
  const disableOtherOptions = useMemo(() => {
    const totalSelected = Object.keys(selected).filter((key) => selected[key as string]).length;
    return totalSelected >= maxOptionsSelected;
  }, [maxOptionsSelected, selected]);

  const onCheckedChange = (value: string, checkState: boolean) => {
    const newSelected = { ...selected, [value]: checkState };
    setSelected(newSelected);
    onItemChange?.(newSelected);
  };

  return (
    <ListContainer id={id}>
      {checkboxList.map((checkbox: CheckboxElementProps) => {
        count++;

        const isSelected = !!selected[checkbox.value];

        return (
          <React.Fragment key={checkbox.value}>
            <Checkbox
              key={checkbox.value}
              value={checkbox.value}
              initialChecked={checkbox.checked}
              onCheckedChange={onCheckedChange}
              withBorder={withBorder}
              label={checkbox.label}
              subLabel={checkbox.subLabel}
              subText={checkbox.subText}
              disabled={disableOtherOptions && !isSelected}
            />
            {showDivider && (count < totalItem ? <Divider /> : null)}
          </React.Fragment>
        );
      })}
    </ListContainer>
  );
};
