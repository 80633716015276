export const products = {
  "creditReport": "Rapport de crédit",
  "loans": "Prêts",
  "buildCredit": "Construire le crédit",
  "creditBuilder": "Constructeur de crédit",
  "rentAdvantage": "Avantage de location",
  "recommendations": "Recommandations",
  "creditCards": "Cartes de crédit",
  "investments": "Investir",
  "carLoans": "Prêts automobiles",
  "mortgages": "Hypothèques",
  "bankAccounts": "Comptes bancaires",
  "insurance": "Assurance"
}
