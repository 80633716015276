import styled from "@emotion/native";

import { COLORS } from "@bwll/bw-styles";

import { DividerProps } from "./Divider.types";

export const StyledDivider = styled.View<DividerProps>(({ color, marginVertical }) => ({
  height: 1,
  width: "100%",
  backgroundColor: color ?? COLORS.NEUTRAL.COOL["100"],
  marginVertical: marginVertical,
}));
