import { COLORS } from "./colors";

export const shadow = {
  shadowColor: COLORS.NEUTRAL.COOL["600"],
  shadowOpacity: 0.1,
  shadowOffset: { width: 0, height: 2 },
  shadowRadius: 4,
  elevation: 1,
};

export const boxShadow = "0px 2px 6px rgba(27, 27, 34, 0.2)";
