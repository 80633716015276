export const preMemberDashboard = {
  "title": "Bienvenue chez Borrowell",
  "whatIsYourCreditScoreCard": {
    "title": "Connaissez-vous votre cote de crédit?",
    "titleContinue": "Obtenez votre cote de crédit",
    "description": "Obtenez votre cote et votre dossier de crédit gratuits d'Equifax Canada",
    "descriptionContinue": "Continuez d'où vous vous étiez arrêté pour obtenir votre cote de crédit gratuite auprès d'Equifax Canada.",
    "buttonCopy": "OBTENIR MON DOSSIER GRATUIT",
    "buttonCopyContinue": "CONTINUER L’INSCRIPTION",
    "footnote": "Vote dossier gratuit n'affectera pas votre cote, et il faut moins de 3 minutes pour s'inscrire."
  },
  "blogCards": {
    "title": "En savoir plus sur le crédit",
    "whatIsCreditScore": {
      "title": "Qu'est-ce qu'une cote de crédit et pourquoi est-elle importante?",
      "subtitle": "La cote de crédit est un nombre à trois chiffres que les institutions financières utilisent pour évaluer la probabilité que vous remboursiez vos dettes.",
      "linkText": "LIRE LA SUITE"
    },
    "introductionToLoans": {
      "title": "Introduction aux prêts et aux marges de crédit",
      "subtitle": "Le prêt personnel est un montant d'argent que vous empruntez et que vous remboursez avec intérêt par le biais de versements.",
      "linkText": "LIRE LA SUITE"
    }
  },
  "marketplaceCards": {
    "title": "Découvrez toutes vos offres",
    "loans": {
      "title": "Prêts",
      "linkText": "EXPLORER"
    },
    "creditCards": {
      "title": "Cartes de crédit",
      "linkText": "EXPLORER"
    },
    "autoLoans": {
      "title": "Prêts automobiles",
      "linkText": "EXPLORER"
    },
    "mortgages": {
      "title": "Hypothèques",
      "linkText": "EXPLORER"
    },
    "bankAccounts": {
      "title": "Comptes bancaires",
      "linkText": "EXPLORER"
    },
    "insurance": {
      "title": "Assurances",
      "linkText": "EXPLORER"
    }
  },
  "noHit": {
    "title": "Nous ne parvenons pas à trouver votre cote de crédit...",
    "description": "Nous n'avons pas été en mesure de trouver un profil dans les dossiers d'Equifax Canada qui corresponde à l'information que vous nous avez fournie.",
    "whatYouCanDo": "Ce que vous pouvez faire :",
    "contactEquifaxCanada": "1. Contacter Equifax Canada",
    "contactEquifaxCanadaDescription": "Contactez Equifax Canada au __[1-866-828-5961](https://borrowell.com/blog/how-to-rebuild-your-credit-in-canada)__ pour confirmer que les informations que vous avez entrées par l'intermédiaire de Borrowell correspondent aux informations qu'Equifax Canada a actuellement à votre dossier.",
    "informationCard": {
      "title": "Les informations que vous avez saisies :",
      "fullName": "Nom complet",
      "DOB": "Date de naissance",
      "phoneNumber": "Numéro de téléphone",
      "residentialAddress": "Adresse résidentielle"
    },
    "contactBorrowell": "2. Contacter Borrowell",
    "contactBorrowellDescription": "Une fois que toutes les informations sont correctes et correspondent à votre dossier Equifax Canada, veuillez nous contacter à __[info@borrowell.com](mailto:info@borrowell.com)__ pour que vos questions de vérification d'identité soient réinitialisées."
  },
  "guest": "Invité"
}
