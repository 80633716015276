import { QUERY_KEYS } from "@bwll/bw-types";

export const generateQueryKey = (
  key: QUERY_KEYS,
  accessToken?: string,
  queryParams?: object,
): (string | object | undefined)[] => {
  if (queryParams) {
    return [key, JSON.stringify({ accessToken: accessToken ?? "" }), queryParams];
  }
  return [key, JSON.stringify({ accessToken: accessToken ?? "" })];
};
