import styled from "@emotion/styled";

import { Banner as _Banner } from "@bwll/bw-components";
import { mq, spacing } from "@bwll/bw-styles";

export const Banner = styled(_Banner)(() =>
  mq({
    margin: `${spacing.s}px ${spacing.none}px`,
  }),
);
