export const enstream = {
  "start": {
    "title": "Vérifiez votre identité à l'aide de votre numéro de téléphone",
    "subtitle": "Nous n'avons pas pu vérifier votre identité avec vos réponses aux questions de sécurité.",
    "body": "Ensuite, nous essaierons de vérifier votre identité en associant vos informations d'abonné mobile à un code de vérification.",
    "poweredBy": "Vérification du téléphone par",
    "primaryButton": "VÉRIFIER PAR MESSAGE TEXTE"
  },
  "incorrectCode": {
    "title": "Vous avez entré un code incorrect trop souvent",
    "subtitle": "Vous avez saisi un code de vérification à 6 chiffres incorrect, ce qui a entraîné son expiration.",
    "body": "Pour continuer, envoyez un nouveau code à {{phoneNumber}}. [Changer de numéro de téléphone](navigation://)",
    "primaryButton": "ENVOYER UN NOUVEAU CODE"
  },
  "expired": {
    "title": "Votre code de vérification est échu",
    "changePhone": "Pour continuer, envoyez un nouveau code à\n{{phoneNumber}}. [Changer de numéro de téléphone](navigation://)",
    "primaryButton": "ENVOYER UN NOUVEAU CODE"
  },
  "failed": {
    "title": "Nous n'avons pas pu vérifier votre numéro de téléphone",
    "subtitle": "Malheureusement, notre système de vérification de l'identité téléphonique ne prend pas en charge votre opérateur de téléphonie mobile.",
    "body": "Vous pouvez essayer un autre numéro de téléphone ou cliquer ci-dessous pour savoir pourquoi votre numéro de téléphone n'a pas pu être vérifié.\n\n[Pourquoi mon numéro de téléphone n'a-t-il pas pu être vérifié ?](navigation://)",
    "primaryButton": "ESSAYEZ UN AUTRE NUMÉRO"
  },
  "verify": {
    "primaryCopy": "Vérifier par message texte",
    "secondaryCopy": "Saisissez votre numéro de téléphone pour que nous puissions vous envoyer un code de vérification.",
    "primaryButton": "ENVOYER LE CODE DE VÉRIFICATION",
    "phoneNumberLabel": "Numéro de téléphone",
    "phoneNumberError": "Veuillez saisir un numéro de téléphone mobile",
    "generalPhoneNumberError": "Il semble que vous ayez entré un numéro de téléphone fixe. Veuillez entrer votre numéro de téléphone mobile.",
    "errorMessage": "Un problème s'est produit ou ce numéro de téléphone mobile n'est pas valide. Veuillez réessayer plus tard."
  },
  "confirm": {
    "primaryCopy": "Saisir le code de vérification",
    "secondaryCopy": "Entrez le code à 6 chiffres qui vous a été envoyé à l'adresse\n{{phoneNumber}}. [Changer de numéro de téléphone](navigation://)",
    "resendButton": "RENVOYER LE CODE DE VÉRIFICATION",
    "primaryButton": "SOUMETTRE LE CODE",
    "confirmationCodeError": "Veuillez entrer votre code de vérification",
    "verificationLabel": "Code de vérification",
    "verificationError": "Le code de vérification que vous avez entré est incorrect. Il vous reste {{attempts}} tentatives.",
    "errorMessage": "Veuillez entrer le bon code de vérification"
  }
}
