import React from "react";
import { TouchableOpacity } from "react-native";

import { StyledLink1 } from "./Link.styles";
import { LinkProps } from "./Link.types";

/**
 * @component Link1
 * @example
 * <Link onPress={() => {}} textAlign={"center"} hasUnderline>{"Link 1"}</Link>
 */
export const Link1 = ({ children, onPress, ...props }: LinkProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <StyledLink1 {...props}>{children}</StyledLink1>
    </TouchableOpacity>
  );
};
