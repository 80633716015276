import styled from "@emotion/native";

import { COLORS, fontSize, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { SemiBoldText } from "../Default";
import { StyledExpressiveLabelProps } from "./ExpressiveLabel.types";

export const StyledExpressiveLabel = styled(SemiBoldText)<StyledExpressiveLabelProps>`
  font-size: ${styledValue(fontSize.xxs)};
  line-height: ${styledValue(spacing.xs)};
  letter-spacing: ${styledValue(letterSpacing.xxl)};
  color: ${({ color }) => color ?? COLORS.NEUTRAL.WARM["700"]};
`;
