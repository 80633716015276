export enum PLATFORM {
  MOBILE_APP = "MOBILE_APP",
  WEB_APP = "WEB_APP",
}

/**
 * @deprecated Use autocomplete values (e.g. `ios`, `android`, `web`, `windows`, `macos`) instead.
 * This enum is used to define the platforms that the app can run on.
 * The values are taken directly from the `react-native` library.
 * @see https://reactnative.dev/docs/platform-specific-code
 * If you need to use any of these values, would be better to use the `Platform` module from `react-native` instead of this enum.
 */
export enum Platforms {
  IOS = "ios",
  ANDROID = "android",
  WEB = "web",
}
