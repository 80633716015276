import styled from "@emotion/styled";

import { mq, spacing } from "@bwll/bw-styles";

export const BulletLi = styled.li(
  mq({
    listStyleImage: "dot",
    minHeight: 32,
  }),
);

export const BulletUl = styled.ul(
  mq({
    paddingLeft: spacing.m,
    marginBottom: spacing.none
  }),
);
