export const successfulOnboardingPage = {
  "button": "FAIT",
  "title": "Vous avez fait un pas de plus vers l'établissement de votre historique de crédit!",
  "whatsNext": {
    "title": "Quelle est la prochaine étape?",
    "content": [
      "Vous devrez vous connecter chaque mois pour confirmer le paiement de votre loyer.",
      "Continuez à payer votre loyer à temps et voyez votre historique de crédit se renforcer."
    ],
    "contentBankFlow": [
      "Les informations relatives à votre paiement de loyer seront envoyées à Equifax Canada. Une fois reçu, cela peut prendre jusqu'à 4 semaines pour que la transaction « BRWL SELF-RPT RENT » apparaisse sur votre dossier de crédit."
    ],
    "contentManualFlow": [
      "Une fois votre preuve de paiement approuvée, les informations relatives à votre paiement de loyer seront envoyées à Equifax Canada. Une fois reçu, cela peut prendre jusqu'à 4 semaines pour que la transaction « BRWL SELF-RPT RENT » apparaisse sur votre dossier de crédit."
    ]
  }
}
