enum BIOMETRY_TYPE {
  FINGERPRINT = "Fingerprint",
  TOUCH_ID = "TouchID",
  FACE_ID = "FaceID",
  IRIS = "Iris Recognition",
  FINGERPRINT_OR_FACE = "Fingerprint or FaceID",
  FINGERPRINT_OR_IRIS = "Fingerprint or Iris Recognition",
  FACE_OR_IRIS = "FaceID or Iris Recognition",
  FINGERPRINT_OR_FACE_OR_IRIS = "Fingerprint, FaceID or Iris Recognition",
}

enum BIOMETRY_STATES {
  IN_PROGRESS = "prompt",
  TRY_AGAIN = "tryAgain",
  FAILED = "error",
  SUCCESS = "success",
  LOCKOUT = "lockout",
}

export const biometry = {
  title: "Log in quickly and securely",
  buttons: {
    dismiss: "MAYBE LATER",
  },
  states: {
    [BIOMETRY_STATES.IN_PROGRESS]: {
      title: "Please Log In With Your Fingerprint",
      message: "Touch your phone's\nfingerprint sensor",
      cancelLabel: "USE PASSWORD",

      variants: {
        permission: {
          title: "Please Confirm Your Fingerprint",
          cancelLabel: "CANCEL",
        },
      },
    },

    [BIOMETRY_STATES.TRY_AGAIN]: {
      message: "Unable to read your Fingerprint,\nPlease try again.",
    },

    [BIOMETRY_STATES.FAILED]: {
      message: "Fingerprint not recognized,\nplease try again",
    },

    [BIOMETRY_STATES.LOCKOUT]: {
      message: "Too many attempts. Please log\nin with your password",

      variants: {
        permission: {
          message: "Too many attempts. Please try\nagain later.",
        },
      },
    },

    [BIOMETRY_STATES.SUCCESS]: {
      message: "Fingerprint recognized",
    },
  },
  options: {
    [BIOMETRY_TYPE.TOUCH_ID]: {
      subTitle:
        "Enable fingerprint authentication to make logging in easier so you don’t need to type your password each time.",
      buttonText: "ENABLE AUTHENTICATION",
    },
    [BIOMETRY_TYPE.FINGERPRINT]: {
      subTitle:
        "Enable fingerprint authentication to make logging in easier so you don’t need to type your password each time.",
      buttonText: "ENABLE AUTHENTICATION",
    },
    [BIOMETRY_TYPE.FACE_ID]: {
      subTitle:
        "Enable face authentication to make logging in easier so you don’t need to type your password each time.",
      buttonText: "ENABLE AUTHENTICATION",
    },
    [BIOMETRY_TYPE.IRIS]: {
      subTitle:
        "Enable iris authentication to make logging in easier so you don’t need to type your password each time.",
      buttonText: "ENABLE AUTHENTICATION",
    },
    [BIOMETRY_TYPE.FINGERPRINT_OR_FACE]: {
      subTitle:
        "Enable fingerprint or face authentication to make logging in easier so you don’t need to type your password each time.",
      buttonText: "ENABLE AUTHENTICATION",
    },
    [BIOMETRY_TYPE.FINGERPRINT_OR_IRIS]: {
      subTitle:
        "Enable fingerprint or iris authentication to make logging in easier so you don’t need to type your password each time.",
      buttonText: "ENABLE AUTHENTICATION",
    },
    [BIOMETRY_TYPE.FACE_OR_IRIS]: {
      subTitle:
        "Enable face or iris authentication to make logging in easier so you don’t need to type your password each time.",
      buttonText: "ENABLE AUTHENTICATION",
    },
    [BIOMETRY_TYPE.FINGERPRINT_OR_FACE_OR_IRIS]: {
      subTitle:
        "Enable fingerprint, face, or iris authentication to make logging in easier so you don’t need to type your password each time.",
      buttonText: "ENABLE AUTHENTICATION",
    },
  },
};
