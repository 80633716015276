import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { COLORS, mq, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const shadedCellStyle = {
  backgroundColor: COLORS.NEUTRAL.COOL["050"],
};

const baseColumnStyle = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
});

export const LinkButton = styled.button(({ theme }) =>
  mq({
    border: 0,
    background: "transparent",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: fontSize.xs,
    lineHeight: styledValue(lineHeight.m),
    letterSpacing: "0.32px",
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.COLORS.PRIMARY.DEFAULT,
    "&:hover": {
      color: theme.COLORS.PRIMARY["300"],
    },
  }),
);

export const AccordionItemWrapper = styled.div<{ lastElement?: boolean }>(({ theme, lastElement }) => ({
  backgroundColor: theme.COLORS.NEUTRAL.COOL["050"],
  ...(lastElement && {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }),
}));

export const RentTransactionWrapper = styled.div({
  padding: spacing.xxs,
});

export const TitleRow = styled.div(() =>
  mq({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  }),
);

export const LeftColumn = styled.div(() => ({
  ...baseColumnStyle,
  marginRight: spacing.xxxs,
}));

export const RightColumn = styled.div({
  ...baseColumnStyle,
  alignItems: "flex-end",
});

export const LinkButtonWrapper = styled.div({
  padding: spacing.xxs,
  textAlign: "center",
});

export const TitleText = styled.h4(({ theme }) =>
  mq({
    ...theme.h4,
    fontSize: fontSize.xs,
    fontWeight: "600",
  }),
);

export const Divider = styled.hr(({ theme }) => ({
  backgroundColor: theme.COLORS.NEUTRAL.COOL["200"],
  flex: 1,
  height: "1px",
  borderWidth: 0,
  margin: `0 ${spacing.xxs}px`,
}));
