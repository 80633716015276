import styled from "@emotion/styled";
import { BaseArg } from "facepaint";

import { mq } from "@bwll/bw-styles";

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Paragraph = styled.p<{ styleOverrides?: BaseArg }>(({ theme, styleOverrides }) =>
  mq({ ...theme.paragraph, ...styleOverrides }),
);
