const updateScreenSharedCopy = {
  subtitle: "Please double check this information to make sure it's correct",
  button: {
    updateInformation: "UPDATE INFORMATION",
  },
};

export const noHit = {
  supportCard: {
    haveQuestions: "Have more questions?",
    happyToHelp: "We are happy to help!",
    button: {
      contactSupport: "VISIT OUR HELP CENTRE",
    },
  },
  formLabel: {
    fullName: "Full Name",
    dob: "Date of Birth",
    phoneNumber: "Phone Number",
    address: "Residential Address",
  },
  firstNoHit: {
    title: "Sorry, we couldn't find your Equifax credit profile",
    subtitle: "Please review the information that you submitted and try again.",
    button: {
      resubmitInformation: "RESUBMIT INFORMATION",
    },
  },
  secondNoHit: {
    title: "Your information doesn’t match Equifax’s file",
    subtitle: "Here are some steps you could take.",
    accordion: {
      contactEquifax: {
        title: "Contact Equifax",
        markdownContent:
          "Contact Equifax at [__1-866-828-5961__](tel:18668285961) to confirm the information that you entered through Borrowell matches the information that Equifax currently has on file for you. \n \nInformation that you entered:",
      },
      contactBorrowell: {
        title: "Contact Borrowell",
        markdownContent:
          "Once you’ve contacted Equifax and confirmed your information, contact us [__here__]() and we’ll activate your profile so you can get your free credit score!",
      },
    },
  },
  updateName: {
    title: "Update your full name",
    ...updateScreenSharedCopy,
  },
  updateDob: {
    title: "Update your date of birth",
    ...updateScreenSharedCopy,
  },
  updatePhoneNumber: {
    title: "Update your phone number",
    ...updateScreenSharedCopy,
  },
  updateAddress: {
    title: "Update your address",
    ...updateScreenSharedCopy,
  },
};
