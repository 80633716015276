import { Icon } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";

import { Container } from "../styles";

export const CheckoutSuccessPage = () => {
  return (
    <Container>
      <Icon icon="checkmark_circle" color={COLORS.PRIMARY.DEFAULT} size="40%" />
    </Container>
  );
};
