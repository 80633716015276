export const forgotMyPassword = {
  "request": {
    "forgotMyPasswordNavigation": "J'AI OUBLIÉ MON MOT DE PASSE",
    "title": "Vous avez oublié votre mot de passe?",
    "subtitle": "Il vous suffit de réinitialiser votre mot de passe en indiquant votre adresse courriel ci-dessous pour recevoir des instructions.",
    "emailLabel": "Courriel",
    "emailAccessibilityLabel": "Entrez votre adresse courriel",
    "resetPassword": "RÉINITIALISER LE MOT DE PASSE",
    "cancel": "ANNULER",
    "isEmailEmptyError": "Veuillez entrer une adresse courriel",
    "isEmailInvalidError": "Veuillez entrer une adresse courriel valide"
  },
  "success": {
    "title": "Envoi de la réinitialisation du mot de passe",
    "subtitle": "Si cette adresse courriel est associée à un compte Borrowell, vous recevrez un courriel contenant des instructions pour réinitialiser votre mot de passe.\n\nVous n’avez pas reçu de courriel? Vérifiez votre dossier de spam ou de courrier indésirable.",
    "goBack": "RETOUR"
  }
}
