export const creditScore = {
  "poor": "Faible",
  "below": "Inférieur à la moyenne",
  "fair": "Correct",
  "good": "Bon",
  "excellent": "Excellent",
  "error": "Une erreur s'est produite dans le calcul de votre cote",
  "noScore": "Pas de cote",
  "delta": "{{value}} POINTS",
  "noChange": "PAS DE CHANGEMENT DE COTE",
  "updating": "Nous mettons à jour vos informations",
  "up": "EN HAUSSE",
  "down": "EN BAISSE",
  "nextUpdate": "Prochaine mise à jour de votre cote",
  "day": "jour",
  "days": "jours",
  "upDelta": "EN HAUSSE DE {{value}} POINTS",
  "downDelta": "EN BAISSE DE {{value}} POINTS"
}
