import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Listbox } from "@headlessui/react";
import { BaseArg } from "facepaint";
import React, { useState } from "react";

import { COLORS, mq } from "@bwll/bw-styles";

import { OptionButton } from "../OptionButton/OptionButton";
import { FormFieldWrapper } from "./FormField";
import { ChevronDown, ChevronUp } from "./Icon";

export interface IDropdownOption {
  value: string | number;
  label: string;
}

export interface IDropdownProps {
  id: string;
  hasError?: boolean;
  errorHint?: string;
  label?: string;
  isVisibleLabel?: boolean;
  options: IDropdownOption[];
  selectedOption: IDropdownOption;
  isRequired?: boolean;
  caption?: string;
  handleSelection: (option: IDropdownOption) => void;
}

const Container = styled.div`
  ${mq({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "0",
    fontFamily: "Lato",
  })}

  &:focus-within > label {
    color: ${COLORS.PRIMARY.DEFAULT};
  }
`;

const DropDownButton = styled.button<{
  active: boolean;
  hasError?: boolean;
  dropdownInputStyles: BaseArg;
}>(({ active, hasError, dropdownInputStyles }) => {
  const borderStyle = hasError ? `1px solid ${COLORS.RED["700"]}` : `1px solid ${COLORS.NEUTRAL.COOL["600"]}`;

  const common = {
    display: "flex",
    alignItems: "center",
    margin: "6px 0 0 0",
    cursor: "pointer",
    padding: "0 15px",
    textAlign: "left",
    backgroundColor: COLORS.WHITE,
  };

  const dropdownStyles = {
    active: {
      ...dropdownInputStyles,
      ...common,
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      border: `1px solid ${COLORS.NEUTRAL.COOL["600"]}`,
      borderBottomColor: COLORS.WHITE,
      ":focus,:hover,:active": {
        outline: "none",
        boxShadow: "none",
      },
    },
    inactive: {
      ...dropdownInputStyles,
      ...common,
      color: COLORS.NEUTRAL.COOL["900"],
      border: borderStyle,
      ":focus,:hover,:active": {
        outline: "none",
        boxShadow: "none",
        border: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
      },
    },
  };

  return mq(dropdownStyles[active ? "active" : "inactive"]);
});

const Icon = styled.div`
  ${mq({
    marginLeft: "auto",
    paddingTop: "20px",
  })}
`;

const DropdownActive = styled.div(() => {
  return mq({
    backgroundColor: COLORS.WHITE,
    margin: "0",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    border: "none",
    borderTop: COLORS.PRIMARY.DEFAULT,
  });
});

const NavBarToggler = ({ active }: { active: boolean }) => (
  <Icon>
    {active ? (
      <ChevronUp color={COLORS.PURPLE["800"]} size={25} />
    ) : (
      <ChevronDown color={COLORS.PURPLE["800"]} size={25} />
    )}
  </Icon>
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Dropdown: React.VFC<IDropdownProps> = ({
  id,
  hasError,
  errorHint,
  label,
  isVisibleLabel = true,
  options = [],
  selectedOption,
  isRequired,
  caption,
  handleSelection,
}) => {
  const testId = id.replace(/\s/g, "");
  const dropdownOptionStyles = useTheme().dropdownOption;
  const dropdownInputStyles = useTheme().input;
  const [isDirty, setIsDirty] = useState(false);
  return (
    <FormFieldWrapper
      label={label}
      labelIsScreenReaderonly={!isVisibleLabel}
      id={id}
      error={hasError && !!errorHint ? errorHint : undefined}
      isDirty={isDirty}
      caption={caption}
    >
      <Listbox
        id={id}
        value={selectedOption}
        onChange={handleSelection}
        as={Container}
        onBlur={() => setIsDirty(true)}
        aria-required={isRequired}
        aria-invalid={hasError}
        aria-describedby={caption ? `${id}-caption` : undefined}
        aria-label={label || isVisibleLabel ? label : undefined}
      >
        {({ open }) => (
          <>
            <Listbox.Button as={React.Fragment}>
              <DropDownButton
                hasError={isDirty && hasError}
                active={open}
                dropdownInputStyles={dropdownInputStyles}
                data-cy={`dropdown-button-${testId}`}
              >
                {selectedOption.label}
                <NavBarToggler active={open} />
              </DropDownButton>
            </Listbox.Button>
            <Listbox.Options as={DropdownActive}>
              {options.map((option, index) => (
                <Listbox.Option key={option.value} value={option} as={React.Fragment}>
                  {({ active }) => (
                    <OptionButton
                      isHighlighted={active}
                      dropdownOptionStyles={dropdownOptionStyles}
                      data-cy={`dropdown-option-${testId}-${index}`}
                    >
                      {option.label}
                    </OptionButton>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </FormFieldWrapper>
  );
};
