export const formPage = {
  continueButton: "CONTINUE",
  backButton: "BACK",
  saveButton: "SAVE INFORMATION",
  cancelButton: "CANCEL",
  submitButton: "SAVE INFORMATION",
  addressForm: {
    title: "What’s your rental address?",
    street: "Street",
    streetCaption: "Start typing your address to autofill",
    unitApt: "Unit/Apt. #",
    city: "City",
    province: "Province",
    postalCode: "Postal Code",
  },
  leaseForm: {
    title: "What are your lease terms?",
    monthlyRentAmount: "Monthly Rent Amount",
    monthlyRentAmountCaption: "If you split rent, only enter the amount you pay",
    leaseStartDate: "Lease Start (MM/YYYY)",
    rentPaymentDueDate: "Rent Payment Date",
    rentPaymentDueDateCaption: "Enter the day your rent is due every month",
    dayOfMonth: "{{ day }} of the month",
  },
  errorMessages: {
    monthlyRentAmountErrorHint: "Please enter a rent amount between ${{minRentAmount}} and $10,000",
    rentPaymentDueDateErrorHint: "Please enter a number between 1 and 31",
    leaseStartDateErrorHint: "Please enter a date after 01/01/1990",
    dateBefore: `Please enter a date before {{date}}.`,
    isLeaseStartMonth: "Please enter a date between 01/01/1990 and ",
    enterValidDate: "Please enter a valid date",
    required: "Required",
    invalidProvince: "Invalid Province",
    quebecNotAvailable: "Sorry, Rent Advantage is not yet available in Quebec.",
    invalidPostalCode: "Postal Code must be valid (Ex. L3R1E8)",
    noSpecialCharacters: "Use of special characters is prohibited in this field",
    maximumCharactersLimit: "This field supports maximum {{maximum}} characters",
  },
};
