export const signupFlow = {
  preMember: {
    primaryCopy: "Welcome to Borrowell",
    secondaryCopy: "Start tracking your credit score for free",
    emailLabel: "Email Address",
    confirmEmailLabel: "Confirm Email Address",
    passwordLabel: "Password",
    passwordCaption: "Must contain at least 8 characters and 1 number",
    tertiaryCopy:
      "By clicking Create Account, you agree to the\n[__Terms of Use__](https://borrowell.com/terms) and [__Privacy Policy__](https://borrowell.com/privacy-policy)",
    primaryButton: "CREATE ACCOUNT",
    login: "LOG IN",
    isEmailEmptyError: "Please enter an email address",
    isEmailInvalidError: "Please enter a valid email address",
    isPasswordEmptyError: "Please enter an password",
    isPasswordInvalidError: "Please enter a password that contains at least 8 characters and 1 number",
    emailExistsError:
      "This email is already associated with an account. If you forgot your password, you can reset it [here](navigation://).",
    emailsNotMatchError: "The emails you entered do not match",
    matchEmailError: "The emails you entered don’t match. Please double-check them and try again.",
    accountCreationError:
      "An error ocurred when trying to create the account. Please double-check and try again.",
  },
  name: {
    primaryCopy: "What’s your name?",
    secondaryCopy: "We need this to find your Equifax credit profile.",
    firstNameLabel: "First Name ",
    lastNameLabel: "Last Name",
    primaryButton: "CONTINUE",
    isFirstNameEmptyError: "Please enter your first name",
    isLastNameEmptyError: "Please enter your last name",
  },
  birthDate: {
    primaryCopy: "What's your date of birth?",
    secondaryCopy: "We need this to find a match with your Equifax credit profile.",
    primaryButton: "CONTINUE",
    isBirthDateEmptyError: "Please enter your date of birth",
    isBirthDateValidError: "Please enter a valid date of birth",
  },
  address: {
    primaryCopy: "What's your address?",
    primaryCopyConfirm: "Confirm your address",
    secondaryCopy: "We need this to find a match with your Equifax credit profile.",
    addressLabel: "Address",
    unitLabel: "Unit / Apartment # (Optional)",
    cityLabel: "City",
    provinceLabel: "Province",
    postalCodeLabel: "Postal Code",
    primaryButton: "CONTINUE",
    isAddressEmptyError: "Please enter your address",
    isCityEmptyError: "Please enter your city",
    isProvinceEmptyError: "Please enter your province",
    isPostalCodeEmptyError: "Please enter your postal code",
    isPostalCodeValidError: "Please enter a valid postal code",
    enterAddressManually: "ENTER ADDRESS MANUALLY",
  },
  phoneNumber: {
    primaryCopy: "What's your phone number?",
    secondaryCopy: "We need this to find a match with your Equifax credit profile.",
    primaryButton: "CONTINUE",
    phoneNumberLabel: "Phone Number",
    isPhoneNumberEmptyError: "Please enter your phone number",
    isPhoneNumberInvalidError: "Please enter a valid phone number",
  },
  financialGoals: {
    primaryCopy: "What are your financial goals this year?",
    secondaryCopy: "We use this to personalize your experience. Select all that apply.",
    primaryButton: "CONTINUE",
    goals: [
      "Monitor and improve my credit score",
      "Pay off credit cards or other debt",
      "Home purchase or mortgage renewal",
      "Invest my money",
    ],
    errorMessage: "Please select a financial goal to continue.",
  },
  income: {
    primaryCopy: "What's your income?",
    secondaryCopy: "We use this to personalize your experience.",
    ranges: [
      "$0 to $19,999",
      "$20,000 to $39,999",
      "$40,000 to $59,999",
      "$60,000 to $79,999",
      "$80,000 to $99,999",
      "$100,000 or more",
    ],
    errorMessage: "Please select your income to continue.",
  },
  equifax: {
    primaryCopy: "You're almost done!",
    secondaryCopy:
      "Before you can get your free credit score, you have to consent below to authorize access to your credit report and answer questions to verify your identity.",
    consent:
      "I consent to Borrowell periodically obtaining my credit score and credit report from credit reporting agencies for as long as I have an account. Borrowell will obtain and periodically refresh my credit score and credit report to present me tailored financial information, recommendations and third-party offers.",
    terms:
      "I have read and agree to the [__Terms of Use__](https://borrowell.com/terms) and\n[__Privacy Policy__](https://borrowell.com/privacy-policy)",
    poweredBy: "Powered by",
    primaryButton: "PROCEED TO VERIFICATION",
    errorMessage: "You must consent below before you can access your credit score and report.",
    alertTitle: "Error",
    alertMessage: {
      generic: "An error ocurred when trying to create your profile. Please try again in a few minutes.",
      duplicateAccount:
        "It looks like you already have an account. Please sign in using the correct credentials. If you think this is an error, please contact support.",
    },
  },
  equifaxConsent: {
    primaryCopy: "Let’s Get Started!",
    secondaryCopy:
      "To find your Equifax credit file we need to ask you for some information.\n\nOnce set up, you’ll get free access to your credit score and report and unique product recommendations based on your credit profile.",
    contentTitle: "Here’s what you need to provide:",
    bulletPoints: [
      {
        title: "Your personal information",
        description: "Your full name - no nicknames.",
      },
      {
        title: "Your home address",
        description: "Your current home address, or last address if you just moved.",
      },
      {
        title: "Your financial goals ",
        description: "We use this to personalize recommendations to help meet your goals.",
      },
      {
        description:
          "Over 3 million Canadians trust Borrowell to keep their information safe and secure. We use bank-grade 256-bit encryption and we do not share your data without your consent.",
      },
    ],
    terms:
      "I consent to Borrowell periodically obtaining my credit score, report and related information from credit reporting agencies including Equifax and/or TransUnion for as long as I have an account. I understand that this information will be obtained for the purpose of providing Borrowell services, including personalized recommendations.",
    primaryButton: "GET STARTED",
    errorMessage: "You must consent below before you can access your credit score and report.",
    alertTitle: "Error",
  },
  nonEngaged: {
    cta: "LOG OUT",
  },
};
