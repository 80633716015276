import styled from "@emotion/native";

export const Container = styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 1;
`;

export const TextContainer = styled.View`
  flex-shrink: 1;
`;
