import React from "react";

import { StyledHeading1 } from "./Heading1.styles";
import { Heading1Props } from "./Heading1.types";

/**
 * Component for showing the Heading1.
 *
 * @component
 * @example
 * return (
 *   <Heading1 color={COLORS.NEUTRAL.WARM["700"]} addMargin>Hello World</Heading1>
 * )
 */
export const Heading1 = ({
  accessibilityLabel,
  adjustsFontSizeToFit,
  ellipsizeMode,
  numberOfLines,
  style,
  testID,
  children,
  color,
  onPress,
  textAlign,
}: Heading1Props) => {
  return (
    <StyledHeading1
      accessibilityLabel={accessibilityLabel}
      adjustsFontSizeToFit={adjustsFontSizeToFit}
      ellipsizeMode={ellipsizeMode}
      numberOfLines={numberOfLines}
      style={style}
      testID={testID}
      accessibilityRole="text"
      color={color}
      onPress={onPress}
      textAlign={textAlign}
    >
      {children}
    </StyledHeading1>
  );
};
