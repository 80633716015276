export const landingPageExternal = {
  buttonContinue: "CONTINUE",
  hero: {
    title: "Great! Let’s get set up with Rent Advantage",
    step1: "Confirm your lease terms",
    step2: "Connect your bank account",
    step3: "Identify your rent payment",
  },
  creditFileConnected:
    "Your Equifax Canada file is now connected. You can access your credit score and report at any time.",
};
