import styled from "@emotion/styled";

import { boxShadow, spacing } from "@bwll/bw-styles";
import { styledLinearGradient, styledValue } from "@bwll/bw-utils";

import { GradientCellStyleProps } from "./GradientCell.types";

export const GradientBackground = styled.div<GradientCellStyleProps>`
  padding: ${({ paddingVertical }) => styledValue(paddingVertical ?? spacing.xs)}
    ${({ paddingHorizontal }) => styledValue(paddingHorizontal ?? spacing.xs)};
  border-radius: ${({ borderRadius }) => styledValue(borderRadius ?? 0)};
  border: ${({ border }) => border};
  box-shadow: ${({ hasShadow }) => hasShadow && boxShadow};
  background: ${({ linearGradient }) => styledLinearGradient(linearGradient)};
`;
