/**
 * Don't use the absolute "@app/utils"
 * Reason: helpers.ts depends on the `PAGE_ROUTES`, and the `PAGE_ROUTES` requires interceptSingleSpaRoute,
 * which lives inside utils. Importing it through the index.ts will cause the require cycle in the Vitest runtime.
 */
import { interceptSingleSpaRoute } from "../utils/routingHelpers";

import { config } from "@app/config";

export const PAGE_ROUTES = {
  LANDING_PAGE: interceptSingleSpaRoute("/"),
  LANDING_PAGE_EXTERNAL: interceptSingleSpaRoute("/getting-started"),
  FORM: interceptSingleSpaRoute("/form"),
  ONBOARDING_STEPS: interceptSingleSpaRoute("/form/steps"),
  ADDRESS_FORM: interceptSingleSpaRoute("/form/address"),
  EDIT_ADDRESS_FORM: interceptSingleSpaRoute("/form/edit-address"),
  LEASE_FORM: interceptSingleSpaRoute("/form/lease"),
  BANKING: interceptSingleSpaRoute("/banking"),
  BANKING_STEPS: interceptSingleSpaRoute("/banking/steps"),
  BANKING_TRANSACTION_METHOD: interceptSingleSpaRoute("/banking/transaction-method"),
  BANKING_CONNECT_MX: interceptSingleSpaRoute("/banking/connect-banking"),
  BANKING_ACCOUNT_SELECTION: interceptSingleSpaRoute("/banking/select-account"),
  BANKING_ACCOUNT_RESELECTION: interceptSingleSpaRoute("/banking/reselect-account"),
  BANKING_RENT_PAYMENT_SELECTION: interceptSingleSpaRoute("/banking/select-rent-payment"),
  BANKING_PROOF_OF_PAYMENT: interceptSingleSpaRoute("/banking/upload-payment-proof"),
  CHECKOUT: interceptSingleSpaRoute("/checkout"),
  CHECKOUT_STEPS: interceptSingleSpaRoute("/checkout/steps"),
  CHECKOUT_SUMMARY: interceptSingleSpaRoute("/checkout/summary"),
  MANUAL_CHECKOUT_SUMMARY: interceptSingleSpaRoute("/checkout/summary-manual"),
  CHECKOUT_MX_ACCOUNT_VERIFY: interceptSingleSpaRoute("/checkout/mx-account-verify"),
  CHECKOUT_DEBIT_CONFIRMATION: interceptSingleSpaRoute("/checkout/debit-confirmation"),
  CHECKOUT_CONFIRMATION_SCREEN: interceptSingleSpaRoute("/checkout/confirmation"),
  SERVICING_DASHBOARD: interceptSingleSpaRoute("/dashboard"),
  SETTINGS: interceptSingleSpaRoute("/settings"),
  RENTAMOUNTSETTINGS: interceptSingleSpaRoute("/settings/edit-rent"),
  SETTINGS_EDIT_ADDRESS_FORM: interceptSingleSpaRoute("/settings/edit-address"),
  RENT_CONFIRMATION: interceptSingleSpaRoute("/rent-confirmation"),
  RENT_CONFIRMATION_TRANSACTION_METHOD: interceptSingleSpaRoute("/rent-confirmation/transaction-method"),
  RENT_CONFIRMATION_PAYMENT_SELECTION: interceptSingleSpaRoute("/rent-confirmation/payment-selection"),
  RENT_CONFIRMATION_PAYMENT_REVIEW: interceptSingleSpaRoute("/rent-confirmation/payment-review"),
  RENT_CONFIRMATION_RENT_NOT_FOUND: interceptSingleSpaRoute("/rent-confirmation/rent-not-found"),
  RENT_CONFIRMATION_PROOF_OF_PAYMENT: interceptSingleSpaRoute("/rent-confirmation/upload-payment-proof"),
  RENT_CONFIRMATION_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS: interceptSingleSpaRoute(
    "/rent-confirmation/upload-payment-proof/enter-transaction-details",
  ),
  RENT_CONFIRMATION_MANUAL_UPLOAD_SUCCESS: interceptSingleSpaRoute(
    "/rent-confirmation/manual-upload-success",
  ),
  BANKING_RECONNECT: interceptSingleSpaRoute("/banking/reconnect-banking"),
  BANKING_RECONNECT_MX: interceptSingleSpaRoute("/banking/reconnect-banking-mx"),
  BANKING_RECONNECT_MX_ONBOARDING: interceptSingleSpaRoute("/banking/reconnect-banking-mx-onboarding"),
  MANUAL_BANKING_PROOF_OF_PAYMENT: interceptSingleSpaRoute("/banking/upload-payment-proof"),
  MANUAL_BANKING_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS: interceptSingleSpaRoute(
    "/banking/upload-payment-proof/enter-transaction-details",
  ),
  MANUAL_BANKING_PROOF_OF_PAYMENT_COMMENTS: interceptSingleSpaRoute("/banking/upload-payment-proof/comments"),
} as const;

export type PageRoute = typeof PAGE_ROUTES[keyof typeof PAGE_ROUTES];

export type IRoutes = {
  [key in PageRoute]?: {
    path: PageRoute;
    nextPage?: PageRoute;
    previousPage?: PageRoute | string;
    hideCloseButton?: boolean;
    closePage?: PageRoute;
  };
};

export const ROUTES: IRoutes = {
  [PAGE_ROUTES.LANDING_PAGE]: {
    path: PAGE_ROUTES.LANDING_PAGE,
    nextPage: PAGE_ROUTES.ADDRESS_FORM,
  },
  [PAGE_ROUTES.LANDING_PAGE_EXTERNAL]: {
    path: PAGE_ROUTES.LANDING_PAGE_EXTERNAL,
    nextPage: PAGE_ROUTES.ADDRESS_FORM,
  },
  [PAGE_ROUTES.ADDRESS_FORM]: {
    path: PAGE_ROUTES.ADDRESS_FORM,
    nextPage: PAGE_ROUTES.LEASE_FORM,
    previousPage: PAGE_ROUTES.LANDING_PAGE,
  },
  [PAGE_ROUTES.EDIT_ADDRESS_FORM]: {
    path: PAGE_ROUTES.EDIT_ADDRESS_FORM,
    nextPage: PAGE_ROUTES.CHECKOUT_SUMMARY,
    previousPage: PAGE_ROUTES.CHECKOUT_SUMMARY,
    hideCloseButton: true,
  },
  [PAGE_ROUTES.LEASE_FORM]: {
    path: PAGE_ROUTES.LEASE_FORM,
    nextPage: PAGE_ROUTES.BANKING_TRANSACTION_METHOD,
    previousPage: PAGE_ROUTES.ADDRESS_FORM,
  },
  [PAGE_ROUTES.BANKING_TRANSACTION_METHOD]: {
    path: PAGE_ROUTES.BANKING_TRANSACTION_METHOD,
    previousPage: PAGE_ROUTES.LEASE_FORM,
  },
  [PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT]: {
    path: PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT,
    nextPage: PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS,
    previousPage: PAGE_ROUTES.BANKING_TRANSACTION_METHOD,
  },
  [PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS]: {
    path: PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS,
    nextPage: PAGE_ROUTES.MANUAL_CHECKOUT_SUMMARY,
  },
  [PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_COMMENTS]: {
    path: PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_COMMENTS,
    nextPage: PAGE_ROUTES.MANUAL_CHECKOUT_SUMMARY,
    previousPage: PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS,
  },
  [PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_COMMENTS]: {
    path: PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_COMMENTS,
    nextPage: PAGE_ROUTES.MANUAL_CHECKOUT_SUMMARY,
    previousPage: PAGE_ROUTES.MANUAL_BANKING_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS,
  },
  [PAGE_ROUTES.BANKING_CONNECT_MX]: {
    path: PAGE_ROUTES.BANKING_CONNECT_MX,
    nextPage: PAGE_ROUTES.BANKING_ACCOUNT_SELECTION,
    previousPage: PAGE_ROUTES.BANKING_TRANSACTION_METHOD,
  },
  [PAGE_ROUTES.BANKING_RECONNECT]: {
    path: PAGE_ROUTES.BANKING_RECONNECT,
    nextPage: PAGE_ROUTES.BANKING_RECONNECT_MX,
    closePage: PAGE_ROUTES.SERVICING_DASHBOARD,
  },
  [PAGE_ROUTES.BANKING_RECONNECT_MX]: {
    path: PAGE_ROUTES.BANKING_RECONNECT_MX,
    nextPage: PAGE_ROUTES.RENT_CONFIRMATION_PAYMENT_SELECTION,
    previousPage: PAGE_ROUTES.BANKING_RECONNECT,
    closePage: PAGE_ROUTES.SERVICING_DASHBOARD,
  },
  [PAGE_ROUTES.BANKING_ACCOUNT_SELECTION]: {
    path: PAGE_ROUTES.BANKING_ACCOUNT_SELECTION,
    nextPage: PAGE_ROUTES.BANKING_RENT_PAYMENT_SELECTION,
    previousPage: PAGE_ROUTES.LEASE_FORM,
  },
  [PAGE_ROUTES.BANKING_RENT_PAYMENT_SELECTION]: {
    path: PAGE_ROUTES.BANKING_RENT_PAYMENT_SELECTION,
    nextPage: PAGE_ROUTES.CHECKOUT_SUMMARY,
    previousPage: PAGE_ROUTES.BANKING_ACCOUNT_SELECTION,
  },
  [PAGE_ROUTES.CHECKOUT_SUMMARY]: {
    path: PAGE_ROUTES.CHECKOUT_SUMMARY,
    nextPage: PAGE_ROUTES.CHECKOUT_MX_ACCOUNT_VERIFY,
    previousPage: PAGE_ROUTES.BANKING_RENT_PAYMENT_SELECTION,
  },
  [PAGE_ROUTES.MANUAL_CHECKOUT_SUMMARY]: {
    path: PAGE_ROUTES.MANUAL_CHECKOUT_SUMMARY,
    nextPage: PAGE_ROUTES.CHECKOUT_MX_ACCOUNT_VERIFY,
  },
  [PAGE_ROUTES.CHECKOUT_MX_ACCOUNT_VERIFY]: {
    path: PAGE_ROUTES.CHECKOUT_MX_ACCOUNT_VERIFY,
    nextPage: PAGE_ROUTES.CHECKOUT_DEBIT_CONFIRMATION,
    previousPage: PAGE_ROUTES.CHECKOUT_SUMMARY,
  },
  [PAGE_ROUTES.CHECKOUT_DEBIT_CONFIRMATION]: {
    path: PAGE_ROUTES.CHECKOUT_DEBIT_CONFIRMATION,
    nextPage: PAGE_ROUTES.CHECKOUT_CONFIRMATION_SCREEN,
    previousPage: PAGE_ROUTES.CHECKOUT_MX_ACCOUNT_VERIFY,
  },
  [PAGE_ROUTES.CHECKOUT_CONFIRMATION_SCREEN]: {
    path: PAGE_ROUTES.CHECKOUT_CONFIRMATION_SCREEN,
    nextPage: PAGE_ROUTES.SERVICING_DASHBOARD,
  },
  [PAGE_ROUTES.SERVICING_DASHBOARD]: {
    path: PAGE_ROUTES.SERVICING_DASHBOARD,
    previousPage: config.GEMSTONE_BASE_URL,
  },
  [PAGE_ROUTES.SETTINGS]: {
    path: PAGE_ROUTES.SETTINGS,
    previousPage: PAGE_ROUTES.SERVICING_DASHBOARD,
  },
  [PAGE_ROUTES.RENTAMOUNTSETTINGS]: {
    path: PAGE_ROUTES.RENTAMOUNTSETTINGS,
    nextPage: PAGE_ROUTES.SETTINGS,
    previousPage: PAGE_ROUTES.SETTINGS,
  },
  [PAGE_ROUTES.SETTINGS_EDIT_ADDRESS_FORM]: {
    path: PAGE_ROUTES.SETTINGS_EDIT_ADDRESS_FORM,
    previousPage: PAGE_ROUTES.SETTINGS,
  },
  [PAGE_ROUTES.RENT_CONFIRMATION_TRANSACTION_METHOD]: {
    previousPage: PAGE_ROUTES.SERVICING_DASHBOARD,
    hideCloseButton: true,
  },
  [PAGE_ROUTES.RENT_CONFIRMATION_PAYMENT_SELECTION]: {
    path: PAGE_ROUTES.RENT_CONFIRMATION_PAYMENT_SELECTION,
    previousPage: PAGE_ROUTES.SERVICING_DASHBOARD,
    hideCloseButton: true,
  },
  [PAGE_ROUTES.RENT_CONFIRMATION_PAYMENT_REVIEW]: {
    path: PAGE_ROUTES.RENT_CONFIRMATION_PAYMENT_REVIEW,
    previousPage: PAGE_ROUTES.RENT_CONFIRMATION_PAYMENT_SELECTION,
    hideCloseButton: true,
  },
  [PAGE_ROUTES.RENT_CONFIRMATION_RENT_NOT_FOUND]: {
    path: PAGE_ROUTES.RENT_CONFIRMATION_RENT_NOT_FOUND,
    previousPage: PAGE_ROUTES.RENT_CONFIRMATION_PAYMENT_SELECTION,
    hideCloseButton: true,
  },
  [PAGE_ROUTES.RENT_CONFIRMATION_PROOF_OF_PAYMENT]: {
    path: PAGE_ROUTES.RENT_CONFIRMATION_PROOF_OF_PAYMENT,
    previousPage: PAGE_ROUTES.RENT_CONFIRMATION_RENT_NOT_FOUND,
    closePage: PAGE_ROUTES.SERVICING_DASHBOARD,
  },
  [PAGE_ROUTES.RENT_CONFIRMATION_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS]: {
    path: PAGE_ROUTES.RENT_CONFIRMATION_PROOF_OF_PAYMENT_ENTER_TRANSACTION_DETAILS,
    nextPage: PAGE_ROUTES.RENT_CONFIRMATION_MANUAL_UPLOAD_SUCCESS,
    closePage: PAGE_ROUTES.SERVICING_DASHBOARD,
  },
  [PAGE_ROUTES.RENT_CONFIRMATION_MANUAL_UPLOAD_SUCCESS]: {
    path: PAGE_ROUTES.RENT_CONFIRMATION_MANUAL_UPLOAD_SUCCESS,
    nextPage: PAGE_ROUTES.SERVICING_DASHBOARD,
    closePage: PAGE_ROUTES.SERVICING_DASHBOARD,
  },
  [PAGE_ROUTES.BANKING_RECONNECT_MX_ONBOARDING]: {
    path: PAGE_ROUTES.BANKING_RECONNECT_MX_ONBOARDING,
    nextPage: PAGE_ROUTES.BANKING_ACCOUNT_SELECTION,
  },
  [PAGE_ROUTES.BANKING_ACCOUNT_RESELECTION]: {
    path: PAGE_ROUTES.BANKING_ACCOUNT_RESELECTION,
    nextPage: PAGE_ROUTES.RENT_CONFIRMATION_PAYMENT_SELECTION,
    closePage: PAGE_ROUTES.SERVICING_DASHBOARD,
  },
};
