import React from "react";

import { Body1Props } from "./Body1.types";
import { getBody1ComponentStyleByVariant } from "./Body1.utils";

/**
 * Component for showing the Body1.
 *
 * @component
 * @example
 * return (
 *   <Body1 color={COLORS.NEUTRAL.WARM["700"]} addMargin>Hello World</Body1>
 * )
 */
export const Body1 = ({
  accessibilityLabel,
  style,
  testID,
  children,
  color,
  variant = "regular",
  onPress,
  numberOfLines,
  textAlign,
  underline,
}: Body1Props) => {
  const StyledBodyComponent = getBody1ComponentStyleByVariant(variant);

  return (
    <StyledBodyComponent
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
      color={color}
      variant={variant}
      onPress={onPress}
      numberOfLines={numberOfLines}
      textAlign={textAlign}
      underline={underline}
    >
      {children}
    </StyledBodyComponent>
  );
};
