import React from "react";

import { Body2Props } from "./Body2.types";
import { getBody2ComponentStyleByVariant } from "./Body2.utils";

/**
 * Component for showing the Body2.
 *
 * @component
 * @example
 * return (
 *   <Body2 color={COLORS.NEUTRAL.WARM["700"]} addMargin>Hello World</Body2>
 * )
 */
export const Body2 = ({
  accessibilityLabel,
  style,
  testID,
  children,
  color,
  variant = "regular",
  numberOfLines,
  underline,
  onPress,
}: Body2Props) => {
  const StyledBodyComponent = getBody2ComponentStyleByVariant(variant);

  return (
    <StyledBodyComponent
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
      color={color}
      variant={variant}
      numberOfLines={numberOfLines}
      underline={underline}
      onPress={onPress}
    >
      {children}
    </StyledBodyComponent>
  );
};
