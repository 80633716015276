export const address = {
  streetAddressLabel: "Address",
  unitLabel: "Unit / Apartment # (Optional)",
  cityLabel: "City",
  provinceLabel: "Province",
  postalCodeLabel: "Postal Code",
  buttons: {
    continue: "CONTINUE",
    enterAddressManually: "ENTER ADDRESS MANUALLY",
  },
  errors: {
    isAddressEmpty: "Please enter your address",
    isCityEmpty: "Please enter your city",
    isProvinceEmpty: "Please enter your province",
    isPostalCodeEmpty: "Please enter your postal code",
    isPostalCodeValid: "Please enter a valid postal code",
    isProvinceUnavailable: "This offer is currently not available to residents of {{province}}", 
  },
};
