export const products = {
  creditReport: "Credit Report",
  loans: "Loans",
  buildCredit: "Build Credit",
  creditBuilder: "Credit Builder",
  rentAdvantage: "Rent Advantage",
  recommendations: "Recommendations",
  creditCards: "Credit Cards",
  investments: "Investing",
  carLoans: "Car Loans",
  mortgages: "Mortgages",
  bankAccounts: "Bank Accounts",
  insurance: "Insurance",
};
