export const confirmRentTransaction = {
  title: "Confirm your rent",
  message:
    "You have **{{count}} day** to confirm your rent.<br/><br/>If you have already reached out to our team, not to worry! We will ensure that your rent is properly reported to Equifax Canada.",
  message_plural:
    "You have **{{count}} days** to confirm your rent.<br/><br/>If you have already reached out to our team, not to worry! We will ensure that your rent is properly reported to Equifax Canada.",
  messageLastDay:
    "You have **until today** to confirm your rent.<br/><br/>If you have already reached out to our team, not to worry! We will ensure that your rent is properly reported to Equifax Canada.",

  /* On mobile we handle the markdown differently */
  messageMobile: "You have **{{count}} day** to confirm your rent.",
  messageMobile_plural: "You have **{{count}} days** to confirm your rent.",
  messageMobileLastDay: "You have **until today** to confirm your rent.",

  messageMobileReported:
    "If you have already reached out to our team, not to worry! We will ensure that your rent is properly reported to Equifax Canada.",

  buttonText: "CONFIRM RENT",
};
