export const inputs = {
  "datePicker": {
    "placeholder": "Sélectionnez une date"
  },
  "picker": {
    "placeholder": "Sélectionnez une valeur"
  },
  "birthDate": {
    "headline": "Quelle est votre date de naissance?",
    "subtitle": "Nous utilisons ceci pour trouver une correspondance avec votre dossier de crédit.",
    "birthdate": "Date de naissance",
    "year": "Année (AAAA)",
    "month": "Mois (MM)",
    "day": "Jour (JJ)",
    "monthDayError": "Veuillez entrer une date valide",
    "yearError": "Veuillez entrer une année valide",
    "enterDateError": "Veuillez entrer votre date de naissance"
  },
  "googleSearch": {
    "notFound": "Je ne vois pas mon adresse ici"
  },
  "copyFromClipboard": {
    "label": "COPIER"
  }
}
