import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { useMemo } from "react";

export const BORROWELL_MEMBER_ROLE = "Borrowell Member";

export interface IAuthUserManagerConfig {
  authority: string;
  clientId: string;
  redirectUri: string;
  isSilentAuth?: boolean;
}
export const useUserManager = ({
  authority,
  clientId,
  redirectUri,
  isSilentAuth = false,
}: IAuthUserManagerConfig) => {
  return useMemo(() => {
    const userManager = new UserManager({
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority,
      client_id: clientId,
      redirect_uri: redirectUri,
      silent_redirect_uri: redirectUri,
      post_logout_redirect_uri: redirectUri,
      response_type: "code",
      scope: [
        "openid",
        "roles",
        "email",
        "pv.read",
        "ecr.read",
        "gemstone.web.public.full_access",
        "gemstone.web.identity.full_access",
        "mc.read",
        "cc.read",
        "uc.read",
        "IdentityServerApi",
        "ns.read",
        "pp.read",
        "ps.read",
        "ge.read",
        "rr.read",
        "ss.read",
      ].join(" "),
      loadUserInfo: true,
      prompt: isSilentAuth ? "none" : undefined,
      monitorSession: true,
    });

    return userManager;
  }, [authority, clientId, isSilentAuth, redirectUri]);
};
