export const reportingStatus = {
  "reportedAsMissed": {
    "title": "Signalé comme manqué",
    "description": "Nous n'avons pas pu détecter votre paiement de loyer pour {{ paymentPeriod }} et nous l'avons donc signalé comme manqué à Equifax Canada. Celui-ci apparaîtra à votre dossier de crédit d'ici {{ date }}. \n\nSi vous pensez qu'il y a eu un problème, veuillez contacter le support pour contester ceci.",
    "status": "Signalé comme manqué",
    "tooltip": "Nous n'avons pas pu détecter votre paiement de loyer pour {{ paymentPeriod }} et nous l'avons donc signalé comme manqué à Equifax Canada. Celui-ci apparaîtra à votre dossier de crédit d'ici {{ date }}."
  },
  "willBeReportedAsMissed": {
    "title": "Rapport en cours",
    "description": "Votre loyer pour {{ paymentPeriod }} sera signalé comme manqué et envoyé à Equifax Canada parce que nous n'avons pas pu détecter un paiement de loyer. \n\nSi vous avez des questions, veuillez contacter le support.",
    "status": "Sera déclaré comme manqué",
    "tooltip": "Votre loyer pour {{ paymentPeriod }} sera signalé comme manqué et envoyé à Equifax Canada parce que nous n'avons pas pu détecter un paiement de loyer."
  },
  "reportedAsPaid": {
    "title": "Déclaré comme payé",
    "description": "Votre loyer pour {{ paymentPeriod }} a été signalé comme payé à Equifax Canada. Celui-ci apparaîtra à votre dossier de crédit d'ici le {{ date }}.",
    "status": "Déclaré comme payé",
    "tooltip": "Votre loyer pour {{ paymentPeriod }} a été signalé comme payé à Equifax Canada. Celui-ci apparaîtra à votre dossier de crédit d'ici le {{ date }}."
  },
  "willBeReportedAsPaid": {
    "title": "Rapport en cours",
    "description": "Votre loyer pour {{ paymentPeriod }} sera déclaré comme payé et envoyé à Equifax Canada.",
    "status": "Sera déclaré comme payé",
    "tooltip": "Votre loyer pour {{ paymentPeriod }} sera déclaré comme payé et envoyé à Equifax Canada."
  }
}
