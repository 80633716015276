export const onboardingStepsPage = {
  button: "CONTINUE",
  steps: [
    {
      title: "Personal and rental details",
      description: "Confirm your personal information and add your rental information.",
    },
    {
      title: "Connect your account and select transactions",
      description: "Connect your bank account with and we’ll automatically detect rent and utity payments.",
    },
    {
      title: "Subscription and agreements",
      description: "Confirm your subscription and sign a PAD agreement.",
    },
  ],
};
