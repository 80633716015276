export const transactionAmountModal = {
  amountsDontMatch: "Amounts don't match",
  changeSelection: "CHANGE SELECTION",
  editRentAmount: "EDIT RENT AMOUNT",
  editRentAmountDescription:
    "If the amount you entered is incorrect, please update your rent amount. Otherwise, change the transactions you selected.",
  popupContactSupport: "If you don't see all the transactions, please [contact support]()",
  rentAmountFromLease: "Rent amount from lease",
  saveChanges: "SAVE CHANGES",
  selectedTransactions: "Selected transactions",
  updateRentAmount: "Update rent amount",
};
