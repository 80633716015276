import { Icon } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";

import { Container } from "../styles";

export const CheckoutFailurePage = () => {
  return (
    <Container>
      <Icon icon="close_circle" color={COLORS.RED.DEFAULT} size="40%" />
    </Container>
  );
};
