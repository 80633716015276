import styled from "@emotion/native";

import { COLORS, borderRadius } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Layout = styled.View`
  width: 100%;
  border: 1px solid ${COLORS.NEUTRAL.COOL["100"]};
  border-radius: ${styledValue(borderRadius.s)};
  background-color: ${COLORS.WHITE};
  overflow: hidden;
`;
