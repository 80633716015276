import styled from "@emotion/native";
import { Platform, View } from "react-native";

import { Caption } from "@bwll/bw-components/next";
import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { TextDecorationLineProps } from "./TextTag.types";

export const StyledTextTagComponent = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: ${styledValue(spacing.xxxs)};
`;

export const StyledCaptionComponent = styled(Caption)<{ textDecoration: TextDecorationLineProps }>`
  color: ${COLORS.NEUTRAL.COOL["600"]};
  text-decoration-line: ${({ textDecoration }) => textDecoration};
  text-decoration-style: ${Platform.OS === "android" ? "solid" : "dotted"};
  text-decoration-color: ${COLORS.NEUTRAL.COOL["600"]};
`;
export const StyledViewComponent = styled(View)<{ color: string }>`
  margin-horizontal: ${styledValue(spacing.xxxs)};
  background-color: ${({ color }) => color};
  height: ${styledValue(spacing.xxs)};
  width: ${styledValue(spacing.xxs)};
  border-radius: ${styledValue(spacing.xxxs)};
`;
