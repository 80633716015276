import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

import { mq } from "@bwll/bw-styles";

import { Icon } from "../../atoms/Icon";
import { Heading, Paragraph } from "../../atoms/typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  width: 136px;
  height: 136px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`;

const spinnerBase = (backgroundColor: string) => ({
  width: 8,
  height: 8,
  content: "''",
  background: backgroundColor,
  borderRadius: "50%",
  position: "absolute",
});

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Spinner = styled.div<{ duration?: number }>(({ theme, duration }) =>
  mq({
    width: 120,
    height: 120,
    border: `8px solid ${theme.COLORS.PRIMARY.DEFAULT}`,
    borderRadius: "50%",
    borderColor: `${theme.COLORS.PRIMARY.DEFAULT} ${theme.COLORS.PRIMARY.DEFAULT} ${theme.COLORS.PRIMARY.DEFAULT} ${theme.COLORS.NEUTRAL.COOL[100]}`,
    animation: `${spin} ${duration || 1.2}s linear infinite`,
    position: "relative",

    "&:before": {
      ...spinnerBase(theme.COLORS.PRIMARY.DEFAULT),
      top: 10,
      left: 11.5,
    },

    "&:after": {
      ...spinnerBase(theme.COLORS.PRIMARY.DEFAULT),
      left: 11,
      bottom: 10.5,
    },
  }),
);

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;

  position: absolute;
`;

export const TitleContainer = styled.div`
  margin-top: 32px;
`;

export const DescriptionContainer = styled.div`
  margin-top: 16px;
  padding: 0 48px;
`;

export interface ILoadingProps {
  icon?: string;
  title?: string;
  duration?: number;
  description?: string;
}

export const Loading = ({ icon, title, duration, description }: ILoadingProps) => {
  return (
    <Container>
      <SpinnerContainer>
        <Spinner duration={duration} />

        {icon && (
          <IconContainer>
            <Icon icon={icon} size={48} />
          </IconContainer>
        )}
      </SpinnerContainer>

      {title && (
        <TitleContainer>
          <Heading as="h1" styledAs="h2">
            {title}
          </Heading>
        </TitleContainer>
      )}

      {description && (
        <DescriptionContainer>
          <Paragraph>{description}</Paragraph>
        </DescriptionContainer>
      )}
    </Container>
  );
};
