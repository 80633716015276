import React from "react";

import { SUBTITLE_TYPE, SubtitleProps } from "./Subtitle.types";
import { getSubtitleComponentStyleByType } from "./Subtitle.utils";

export const Subtitle = ({
  children,
  type = SUBTITLE_TYPE.PRIMARY,
  style,
  accessibilityLabel,
  testID,
  onPress,
  ...rest
}: SubtitleProps) => {
  const SubtitleStyledComponent = getSubtitleComponentStyleByType(type);

  return (
    <SubtitleStyledComponent
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
      onPress={onPress}
      {...rest}
    >
      {children}
    </SubtitleStyledComponent>
  );
};
