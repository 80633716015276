export const reportItemLine = {
  "dateReported": "Date de notification",
  "dateDisposition": "Date de décision",
  "categoryOverviewTitle": "Vue d'ensemble",
  "categoryDetailsTitle": "Détails du compte",
  "balanceTitle": "Solde",
  "highCreditTitle": "Crédit élevé",
  "pastDueTitle": "Montant en souffrance",
  "paymentDetailsTitle": "Modalités de paiement",
  "accountTypeTitle": "Type",
  "lastActivityTitle": "Dernière activité",
  "openDateTitle": "Date d'ouverture",
  "lastReportedTitle": "Date de déclaration",
  "lastPaymentTitle": "Dernier paiement",
  "datePaidTitle": "Date de paiement",
  "dateFiled": "Date de dépôt",
  "dateSatisfied": "Date de paiement final",
  "originalBalanceTitle": "Solde initial",
  "currentBalanceTitle": "Solde actuel",
  "agency": "Agence",
  "amount": "Montant",
  "type": "Type",
  "courtName": "Nom du tribunal",
  "intentOrDispositionDate": "Date de mise à disposition",
  "status": "Statut"
}
