import { createContext, useCallback, useMemo, useState } from "react";

import { ContextName, useContextWrapper } from "../contextWrapper";

interface IManualUploadFields {
  leaseTerm: string | null;
  transactionDate: string | null;
  transactionAmount: number | null;
  transactionDescription: string | null;
}

interface IManualUploadContext {
  manualUploadState: IManualUploadFields;
  updateManualUploadState: (key: keyof IManualUploadFields, value: string | number) => void;
}

export const ManualUploadContext = createContext<IManualUploadContext>({} as IManualUploadContext);

interface IManualUploadProviderProps {
  children: React.ReactNode;
}

export const ManualUploadProvider = ({ children }: IManualUploadProviderProps) => {
  const [manualUploadState, setManualUploadState] = useState<IManualUploadFields>({
    leaseTerm: null,
    transactionDate: null,
    transactionAmount: null,
    transactionDescription: null,
  });

  const updateManualUploadState = useCallback((key: string, value: string | number) => {
    setManualUploadState((curr) => ({ ...curr, [key]: value }));
  }, []);

  const value: IManualUploadContext = useMemo(
    () => ({
      manualUploadState,
      updateManualUploadState,
    }),
    [manualUploadState, updateManualUploadState],
  );

  return <ManualUploadContext.Provider value={value}>{children}</ManualUploadContext.Provider>;
};

export const useManualUploadContext = () =>
  useContextWrapper(ManualUploadContext, ContextName.RentAdvantageManualUploadContext);
