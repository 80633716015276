import styled from "@emotion/native";

import { ExpressiveLabel } from "@bwll/bw-components/next";
import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { ProductBannerProps } from "./ProductBanner.types";

export const Container = styled.View<Pick<ProductBannerProps, "backgroundColor" | "rounded">>`
  width: 100%;
  align-items: center;
  padding: ${styledValue(spacing.xxxs)};
  background-color: ${({ backgroundColor }) => backgroundColor ?? COLORS.PINK["050"]};
  border-top-left-radius: ${({ rounded }) => (rounded ? styledValue(borderRadius.s) : 0)};
  border-top-right-radius: ${({ rounded }) => (rounded ? styledValue(borderRadius.s) : 0)};
`;

export const Text = styled(ExpressiveLabel)`
  color: ${COLORS.PURPLE["900"]};
`;
