export const eid = {
  primer: {
    title: "Let’s verify your identity",
    subtitle: "This will only take a couple of minutes.",
    bulletsTitle: "Please read this before getting started:",
    firstBullet: "You have to do it in one sitting and your progress for this section won’t be saved.",
    secondBullet: "You’ll be asked 4 questions related to your credit profile to make sure it’s really you.",
    poweredBy: "Verification powered by",
    primaryButton: "ANSWER QUESTIONS",
  },
  equifaxMaintenance: {
    title: "Equifax is down for maintenance",
    subtitle: `We’re sorry, at this time, the Equifax system is down for maintenance. Typically, maintenance takes about half an hour, so please try again in 30 mins.\n\nWe’re very sorry for the inconvenience. Please do come back!`,
    primaryButton: "BACK TO LOGIN",
    footnote:
      "If you have any questions, feel free to email [info@borrowell.com](mailto:info@borrowell.com) or check out our list of [Frequently Asked Questions](https://helpcentre.borrowell.com/hc/en-us).",
  },
  questions: {
    title: "Question {{index}} of 4",
    nextQuestion: "NEXT QUESTION",
    submitAnswers: "SUBMIT ANSWERS",
    errorMessage: "Please select an option to continue.",
    none: "If none of the options apply to you, please select “None of the above”.",
  },
  eidRetry: {
    title: "The answers provided didn’t match Equifax’s records",
    subtitle: "You can try one more time to answer a new set of questions to verify your identity.",
    buttons: {
      answerNewQuestions: "ANSWER NEW QUESTIONS",
    },
  },
  uberFail: {
    title: "Sorry {{firstName}}, we couldn’t verify your identity",
    subtitle:
      "We know this can be frustrating. Below are some reasons as to why your identity couldn’t be verified.",
    firstBullet:
      "The answers you provided to the identity verification questions didn’t match Equifax’s records.",
    secondBullet: "The personal information you entered didn’t match Equifax’s file.",
    thirdBullet: "You have placed an alert on your Equifax Account.",
    fourthBullet: "Your phone number couldn’t be verified.",
    visitHelpCenter: "Please visit the Help Centre for more details.",
    primaryButton: "VISIT HELP CENTRE",
  },
  networkError: {
    title: "It looks like you’re not connected to the internet",
    message: "Please check your connection and try submitting your answers again.",
  },
};
