export const subscriptionStatus = {
  "canceled": {
    "title": "Annulation de l'abonnement",
    "message": "Votre abonnement a été annulé. Veuillez nous contacter si vous souhaitez vous réabonner à Le Bienfait du Loyer.",
    "status": "Annulation de l'abonnement",
    "tooltip": "Votre abonnement a été annulé. Veuillez nous contacter si vous souhaitez vous réabonner à Le Bienfait du Loyer.",
    "buttonText": "CONTACTEZ-NOUS"
  },
  "inactive": {
    "title": "Abonnement inactif",
    "message": "N'hésitez pas à nous contacter si vous souhaitez vous réinscrire à Le Bienfait du Loyer.",
    "status": "Abonnement inactif",
    "tooltip": "N'hésitez pas à nous contacter si vous souhaitez vous réinscrire à Le Bienfait du Loyer.",
    "buttonText": "CONTACTEZ-NOUS"
  },
  "pastDue": {
    "title": "Paiement de l'abonnement dû",
    "message": "Pour continuer à déclarer votre loyer, veuillez payer votre abonnement à Le Bienfait du Loyer. ",
    "status": "Paiement de l'abonnement dû",
    "tooltip": "Pour continuer à déclarer votre loyer, veuillez payer votre abonnement à Le Bienfait du Loyer. ",
    "buttonText": "PAYER L'ABONNEMENT"
  }
}
