import { SETTINGS_FIELDS } from "../types";

export const CBL_VERSION = "v1.0";
export const CBL_LAYOUT_TYPE = "Mobile_App";
export const CBL_CONTENT_TITLE = "Build_Credit";

// cbl attributes
export enum CBL_ANALYTICS_ATTRIBUTES {
  APPLICATION_ID = "applicationId",
  CURRENT_STEP_NAME = "currentStepName",
  CONTENT_TITLE = "contentTitle",
  VERSION = "version",
  SCREEN_NAME = "screenName",
  EVENT_TIMESTAMP = "eventTimestamp",
  LAYOUT_TYPE = "layoutType",
  REPORTING_STATUS_TOOLTIP = "reportingStatusTooltip",
  DOCUMENT_TYPE = "documentType",
  LINK_TYPE_PI = "PI_link_type",
  LINK_NAME_PI = "PI_link_name",
  PRICE_OPTION = "price_option",
  PRICE_AMOUNT = "price_amount",
  PRICE_TYPE = "price_type",
  OPTION_PLACEMENT = "option_placement",
  OPTION_NAME = "option_name",
  PRODUCT_NAME = "product_name",
  PRODUCT_IMPRESSION_ID = "product_impression_id",
  PLACEMENT_ORDER = "placement_order",
  CROSS_SELL_PRODUCT_TEXT = "cross_sell_product_text",
  MISSED_PAYMENT = "missed_payment",
}

// cbl steps name
export enum CBL_STEPS_NAMES {
  LANDING_PAGE = "CBL Education Step",
  CONFIRM_PERSONAL_DETAILS_PAGE = "CBL Personal Details Step",
  SUMMARY_PAGE = "CBL Program Summary Step",
  CBL_AGREEMENT_PAGE = "CBL Agreement Step",
  CBL_CONNECT_BANKING_PAGE = "CBL Connect Banking Step",
  CBL_PAD_AGREEMENT_PAGE = "CBL Pad Agreement Step",
  CONFIRMATION_PAGE = "CBL Application Confirmation Step",
}

export enum CBL_MANUAL_BANKING_STEPS_NAMES {
  ADD_MANUALLY = "MX Intro",
  ACCOUNT_INFO = "Account Details",
  UPLOAD_DOCUMENT = "Document Upload",
  CONFIRMATION_PAGE = "Bank Agreement",
}

export enum CBL_STEP_SCREEN_NAMES {
  LANDING_PAGE = "CBL Education Step Viewed",
  CONFIRM_PERSONAL_DETAILS_PAGE = "CBL Personal Details Viewed",
  SUMMARY_PAGE = "CBL Program Summary Viewed",
  CBL_AGREEMENT_PAGE = "CBL Agreement Viewed",
  CBL_AGREEMENT_PAGE_WIDGET = "CBL Agreement Viewed Widget",
  CBL_CONNECT_BANKING_PAGE = "CBL Connect Banking Viewed",
  CBL_CONNECT_BANKING_PAGE_WIDGET = "CBL Connect Banking Viewed Widget",
  CBL_PAD_AGREEMENT_PAGE = "CBL Pad Agreement Viewed",
  CBL_PAD_AGREEMENT_PAGE_WIDGET = "CBL Pad Agreement Viewed Widget",
  CONFIRMATION_PAGE = "CBL Application Confirmation Viewed",
}

export enum CBL_STEP_ERROR_SCREEN_NAMES {
  CONFIRM_PERSONAL_DETAILS_PAGE = "CBL_personal_details_error_screen",
}

export enum CBL_ANALYTICS_VALUES {
  CBL_ONBOARDING_FLOW = "cb_application",
  PROGRAM_SUMMARY_YOUR_SAVINGS = "Program_summary_your_savings",
  PROGRAM_SUMMARY_PROGRAM_FEE = "Program_summary_program_fee",
  CHANGE_FIRST_PAYMENT_DATE = "Change_first_payment_date",
  GO_TO_DASHBOARD = "Go_to_dashboard",
  CBL_SERVING_DASHBOARD = "CBL_servicing_dashboard",
  CBL_CONNECT_BANKING_CARD = "CBL_connect_banking_card",
  CBL_AGREEMENT = "CBL_Agreement",
  CBL_NATIVE_AGREEMENT = "CBL Native Agreement",
  CBL_NATIVE_AGREEMENT_CHECKBOX = "Mark CB Native Agreement",
  CBL_NATIVE_AGREEMENT_TYPE = "native",
  CBL_PAD = "PAD",
  CBL_HELP_CENTER = "help-center",
  CONNECT_BANK = "Connect_bank",
  WIDGET_CONTINUE_TO_START = "Continue",
  WIDGET_BANK_SELECTED = "Bank_selected",
  WIDGET_CRED_ENTERED = "Credential_entered",
  WIDGET_VERIFY = "Verify",
  WIDGET_MFA = "Mfa",
  WIDGET_BANK_CONNECTED_CONTINUE = "Continue_bank_connected",
  CBL_POPUP = "CBL Popup",
  CONTACT_SUPPORT = "Contact Support",
  CBL_POPUP_CTA_CLICK = "CBL Popup CTA Click",
  WIDGET_DISCLOSURE = "disclosure",
  WIDGET_SEARCH = "search",
  WIDGET_ENTER_CREDS = "enterCreds",
  WIDGET_CONNECTING = "connecting",
  ADD_MANUALLY = "ADD MANUALLY",
  EDIT_PERSONAL_INFORMATION = "Track if a user clicks on edit",
  SAVE_EDIT_PI = "Track if a user clicks on edit",
  RESTART_APPLICATION = "CBL Reapplication Viewed",
  PLAN_SELECTION = "cb_application_plan_selection",
  PRICE_PLANS_CTA = "Track if a user clicks on price options",
  DOCUMENT_UPLOAD_OPTION = "Track which types of documents users choose to upload",
  MANUAL_BANKING_OPEN_NATIVE_PAD = "Track clicks of bank agreement hyper link",
  MANUAL_BANKING_REQUEST_PAD = "Track clicks of request agreement at the end of bank agreement",
  MANUAL_BANKING_SIGN_PAD_CHECKBOX = "Track when checkbox is marked",
  OPEN_NATIVE_PAD_LINK_NAME = "cb_bank_agreement",
  OPEN_NATIVE_PAD_LINK_TEXT = "BANK AGREEMENT",
  REQUEST_NATIVE_PAD_LINK_NAME = "cb_request_agreement",
  REQUEST_NATIVE_PAD_LINK_TEXT = "REQUEST PAD AGREEMENT",
  SIGN_NATIVE_PAD_CHECKBOX = "Mark Bank Agreement",
  CBL_APPLICATION_CROSSSELL = "CB application crosssell",
  CBL_DASHBOARD_CROSSSELL = "CB dashboard crosssell",
  CB_ADHOC_PAYMENT_MAKE_PAYMENT = "CB Adhoc Payment Make Payment",
  CB_MARK_ADHOC_PAYMENT_AGREEMENT = "Mark CB Adhoc Payment Agreement",
  CB_ADHOC_PAYMENT_FLOW = "cb_adhoc_payment",
  CB_ADHOC_PAYMENT_CONFIRM = "CB Adhoc Payment Confirm",
}

export const CREDIT_FACTOR_ANALYTICS_VALUES = {
  TOOLTIP_PLACEMENT: {
    DASHBOARD: "Dashboard",
    CREDIT_FACTOR: "Credit Factor",
    CREDIT_FACTORS_PAGE: "Credit Factors Page",
  },
};

// mfa attributes
export enum MFA_ANALYTICS_ATTRIBUTES {
  ACTIVATION_FLOW = "activationFlow",
  LAYOUT_TYPE = "layoutType",
}

export const CBL_PI_LINK_TYPE = {
  [SETTINGS_FIELDS.ADDRESS]: "CB_update_residential_address",
  [SETTINGS_FIELDS.EMAIL]: "CB_update_email_address",
  [SETTINGS_FIELDS.PHONE]: "CB_update_phone_number",
  [SETTINGS_FIELDS.DOB]: "", // Don't send any event
  [SETTINGS_FIELDS.FULL_NAME]: "", // Don't send any event
} as const;

export enum CBL_PI_LINK_NAME {
  SAVE_CHANGES = "SAVE CHANGES",
  EDIT_INFO = "Edit",
}

export type CblPILinkType = (typeof CBL_PI_LINK_TYPE)[SETTINGS_FIELDS];

export enum PRICING_TIER_PRICE_TYPE {
  DEFAULT = "default",
  OTHER = "other_option",
}
