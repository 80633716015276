export const emailNotifications = {
  "title": "Communications par courriel que vous recevez de Borrowell",
  "description": "Borrowell vous envoie deux types de courriel : promotionnels et transactionnels (tels que des courriels concernant des mises à jour de votre score et de votre dossier de crédit, ou concernant des produits auxquels vous vous êtes inscrit). Vous pouvez actuellement désactiver les courriels promotionnels, mais pas les courriels transactionnels.",
  "transactionalEmails": {
    "title": "Courriels transactionnels",
    "description1": "Lorsque des modifications sont apportées à votre compte ou à vos produits, nous vous en informons afin que vous soyez toujours au courant des changements apportés à votre profil de crédit et des mises à jour importantes.",
    "description2": "Les courriels que vous recevez au sujet de votre pointage et de votre dossier de crédit sont des courriels transactionnels. Les courriels transactionnels ne sont pas tenus d'avoir un lien de désabonnement en vertu de la législation anti-pourriel du Canada.",
    "description3": "Vous pouvez choisir la manière dont vous souhaitez être informé des modifications apportées à votre profil de crédit [ici]()."
  },
  "promotionalEmails": {
    "title": "Courriels promotionnels",
    "description1": "Les courriels promotionnels nous permettent de vous informer sur les produits financiers qui correspondent à votre profil de crédit. Ces produits financiers peuvent vous aider à construire votre crédit ou à atteindre vos objectifs financiers.",
    "description2": "Pour désactiver ces courriels, allez au bas du courriel et cliquez sur le lien de désinscription."
  }
}
