import styled, { css } from "@emotion/native";

export const CardWrapper = styled.View`
  margin-bottom: 16px;
`;

export const listHeaderComponentStyle = css`
  position: relative;
  z-index: 1;
`;
