import { ReactNode, createContext, useState } from "react";

import { ContextName, useContextWrapper } from "../contextWrapper";
import { SessionProvider } from "../sessionContext";

export interface IRentAdvantageSessionContext {
  isAnExternalMember: boolean;
  setIsAnExternalMember: (value: boolean) => void;
}

interface IRentAdvantageSessionProviderProps {
  children: ReactNode;
}

export const RentAdvantageSessionContext = createContext<IRentAdvantageSessionContext>(
  {} as IRentAdvantageSessionContext,
);

export const RentAdvantageSessionProvider = ({ children }: IRentAdvantageSessionProviderProps) => {
  const [isAnExternalMember, setIsAnExternalMember] = useState(false);

  return (
    <SessionProvider>
      <RentAdvantageSessionContext.Provider value={{ isAnExternalMember, setIsAnExternalMember }}>
        {children}
      </RentAdvantageSessionContext.Provider>
    </SessionProvider>
  );
};

export const useRentAdvantageSessionContext = () =>
  useContextWrapper(RentAdvantageSessionContext, ContextName.RentAdvantageSessionContext);
