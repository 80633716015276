import styled from "@emotion/styled";
import { ReactNode } from "react";

import { mq } from "@bwll/bw-styles";

export interface ListElementProps {
  children: ReactNode;
}

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const ListElement = ({ children, ...props }: ListElementProps) => {
  const ListItem = styled.li(({ theme }) => mq({ ...theme.listItem }));

  return <ListItem {...props}>{children}</ListItem>;
};
