import _ from "lodash";
import { LinearGradientProps } from "react-native-linear-gradient";

export const styledValue = (value: number, unit = "px") => `${_.toString(value)}${unit}`;

export const styledLinearGradient = (linearGradient: LinearGradientProps) => {
  const colors = linearGradient.colors;
  colors.forEach((color, index) => {
    if (
      linearGradient.locations &&
      linearGradient.locations[index] !== undefined &&
      !color.toString().includes("%")
    ) {
      colors[index] = `${color} ${linearGradient.locations[index] * 100}%`;
    }
  });
  const concatedColors = colors.join(", ");
  let degree = "";
  if (linearGradient.useAngle && linearGradient.angle) {
    degree = `${linearGradient.angle}deg, `;
  }
  if (colors.length > 1) {
    return `linear-gradient(${degree}${concatedColors})`;
  }
  return concatedColors;
};
