import { IUseSubscriptions, useSubscriptions } from "@bwll/bw-hooks";
import { SUBSCRIPTION_STATUS } from "@bwll/bw-types";

export type SubscriptionStatusResult = ReturnType<typeof useSubscriptionStatus>;

export const useSubscriptionStatus = ({ status: listStatus, customApiOptions }: IUseSubscriptions = {}) => {
  const { data, isLoading, isFetched } = useSubscriptions({
    status: listStatus,
    customApiOptions: customApiOptions,
  });

  // TODO: filter for specific subscription products
  const activeSubscription = data?.find((x) => x.status === SUBSCRIPTION_STATUS.ACTIVE);
  const status = activeSubscription?.status ?? data?.[0].status;

  const shouldShowSubscriptionWarning =
    !!status && status !== SUBSCRIPTION_STATUS.ACTIVE && status !== SUBSCRIPTION_STATUS.TRIALING;

  return {
    status,
    isLoading,
    isFetched,
    shouldShowSubscriptionWarning,
  };
};
