export const transactionSelectionMethodPage = {
  "title": "Choisissez votre mode de sélection",
  "bankConnection": {
    "description": "<b>La connexion sécurisée de votre compte bancaire</b> importera automatiquement vos transactions, ce qui vous facilitera la tâche. Il vous suffira de vous connecter chaque mois pour vérifier la transaction.",
    "descriptionMD": "**La connexion sécurisée de votre compte bancaire** importera automatiquement vos transactions, ce qui vous facilitera la tâche. Il vous suffira de vous connecter chaque mois pour vérifier la transaction.",
    "link": "CONNECTER MON COMPTE"
  },
  "manualUpload": {
    "description": "<b>Téléverser et saisir les détails</b>, vous devrez entrer manuellement les détails de votre transaction et téléverser une preuve. Cela demande un peu plus d'efforts, mais c'est une bonne option si vous avez des difficultés à connecter votre compte bancaire.",
    "descriptionMD": "**Téléverser et saisir les détails**, vous devrez entrer manuellement les détails de votre transaction et téléverser une preuve. Cela demande un peu plus d'efforts, mais c'est une bonne option si vous avez des difficultés à connecter votre compte bancaire.",
    "link": "AJOUTER MANUELLEMENT"
  }
}
