import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const TitleWrapper = styled.View`
  flex-direction: row;
`;

export const IconWrapper = styled.View`
  margin-right: ${styledValue(spacing.xxs)};
`;
