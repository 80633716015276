import { creditCards } from "./creditCards";
import { errors } from "./errors";
import { productList } from "./productList";
import { productVerticalsNavBar } from "./productVerticalsNavBar";

export const marketplace = {
  creditCards,
  errors,
  productList,
  productVerticalsNavBar,
};
