import { buttons } from "./buttons";
import { errors } from "./errors";
import { forms } from "./forms";
import { inputs } from "./inputs";
import { progressBar } from "./progressBar";
import { sortFilter } from "./sortFilter";

export const components = {
  sortFilter,
  errors,
  inputs,
  progressBar,
  forms,
  buttons,
};
