export const cblPADScreen = {
  "reviewBankTitle": "Review your bank agreement",
  "reviewBankBody": "This allows us to withdraw your one-time payment.",
  "preAuthDebitAgreement": "Pre-authorized debit agreement",
  "body1": "In this Pre-Authorized Debit Agreement (this “**PAD Agreement**”), “**Payee**” refers to Refresh Solutions Inc., a subsidiary of Borrowell Inc., “**you**” and “**you**” refer to the below-named customer of Payee, and “**Bank Account**” means the deposit account described below, and includes any other bank account you may identify to the Payee from time to time for the purposes of this PAD Agreement.\nPlease complete all sections in order to instruct your financial institution to make payments to your Borrowell Credit Builder directly from your Bank Account.\n",
  "padBody1": "In this Pre-Authorized Debit Agreement (this “**PAD Agreement**”), “**Payee**” refers to Refresh Solutions Inc., a subsidiary of Borrowell Inc., “**you**” and “**your**” refer to the below-named customer of Payee, and “**Bank Account**” means the deposit account described below, and includes any other bank account you may identify to the Payee from time to time for the purposes of this PAD Agreement.",
  "biWeeklyPadBody1": "In this Pre-Authorized Debit Agreement (this “**PAD Agreement”**), “**Payee**” refers to Refresh Solutions Inc., a subsidiary of Borrowell Inc., “**you**” and “**your**” refer to the below-named customer of Payee, and “**Bank Account**” means the deposit account described below, and includes any other bank account you may identify to the Payee from time to time for the purposes of this PAD Agreement.",
  "padBody2": "Please complete all sections in order to instruct your financial institution to make payments to your Borrowell Credit Builder directly from your Bank Account.",
  "padBody3": "**Authority to Debit Account:** You authorize Payee, and any financial institution designated by Payee, from time to time, to issue and process pre-authorized debits (“PADs”) and debit the Bank Account indicated above. This is a personal PAD.",
  "adhocPadBody3": "**Authority to Debit Account:** You authorize Payee, and any financial institution designated by Payee,to issue and process a one-time pre-authorized debit (“PAD”) for the amount requested, on the date requested, identified on the preceding screen, and debit the Bank Account you authorized in connection with your Credit Builder. This is a personal PAD.",
  "oneTimePayment": "**One-Time Payment:** Your authorization will remain in effect until the PAD is processed by Payee, after which the PAD Agreement will no longer be valid. Any subsequent one-time payments will be authorized by a new PAD agreement.",
  "padBody4": "**Changes to Account Information:** You agree to inform the Payee promptly, in writing, of any change in the information provided above (including the Bank Account).",
  "padBody5": "**Pre-Notification Waiver: You waive your right to receive pre-notification of the amounts of the PADs or any change in the amounts of the PADs and agree that you do not require advance notice of the amounts of PADs or any change in the amounts of the PADs before the debits are processed.**",
  "confirmationWaiver": "**Confirmation Waiver:** You waive your right to receive a confirmation of this PAD Agreement prior to the date of the PAD.",
  "padBody6": "Under the rules of the Canadian Payments Association (“**CPA Rules**”), the Payee is required to verify details provided by you and provide you with notice confirming your instructions (“**Confirmation**”) at least 15 days before the due date of the first PAD. As permitted by the rules of the Canadian Payments Association and to enable an earlier implementation of your instructions, you agree to the reduction of the period between Confirmation and the due date of the first PAD to 3 days.",
  "padBody7": "**Payments:** At any time, you may authorize Payee to issue a PAD against the Bank Account for payment of a specified amount. Such authorization may be given to Payee by any means, including by way of telephone, electronic communication or on our secure mobile application. Your authorization for a one time or sporadic payment will constitute an agreement and direction to Payee for that payment only.",
  "padBody7Alt": "**Denied PADs**: If any PAD is denied due to non-sufficient funds or funds not cleared, I acknowledge that the Payee may re-present such PAD within 30 days following the return of the original PAD.",
  "padBody8": "**Recourse/Reimbursement:** You have certain recourse rights if any debit does not comply with this agreement. For example, you have the right to receive reimbursement for any debit that is not authorized or is not consistent with this PAD agreement. To obtain more information on your recourse rights, you may contact your financial institution or visit\n__[www.payments.ca](https://www.payments.ca)__.",
  "padBody9": "**Cancellation of Arrangement:** This PAD Agreement may be cancelled at any time upon providing 30 days’ notice, in writing, to the Payee at the e-mail address below or as the Payee may advise from time to time. You may obtain a sample cancellation form, or further information on your right to cancel a PAD Agreement, at your financial institution or by visiting __[www.payments.ca](https://www.payments.ca)__. This authorization only applies to the method of payment and you agree that cancellation of this Authorization does not terminate or otherwise have any effect on any contract for goods or services that exists between you and the Payee.",
  "padBody10": "**Contact:** If you require more information or have an issue regarding this PAD Agreement, you may contact the Payee by phone at 1-888-408-0480 or email at [buildcredit@borrowell.com]() or mail to 1 University Ave, 3rd Floor, Toronto, ON, M5J 2P1.",
  "borrowellContactInformation": "Borrower Contact Information",
  "borrowerCopy": "Borrower: ",
  "addressCopy": "Address: ",
  "phoneNumberCopy": "Phone Number: ",
  "emailAddressCopy": "Email Address: ",
  "borrowerBankAccountInformation": "Borrower Bank Account Information",
  "accountNumberCopy": "Account Number: ",
  "canadianFinancialInstitutionCopy": "Canadian Financial Institution: ",
  "transitNumberCopy": "Transit Number: ",
  "paymentInformation": "Payment Information",
  "paymentAmountCopy": "Payment Amount: ",
  "frequencyOfPaymentCopy": "Frequency of Payment: ",
  "paymentStartDateCopy": "Payment Start Date: ",
  "requestPadAgreement": "REQUEST AGREEMENT",
  "padAgreementSent": "PAD agreement sent",
  "padAgreementTerm": "I confirm that I have read and agree to the terms of this bank agreement"
}
