export const errorMessages = {
  network: {
    server: "We're having trouble reaching our servers, please try again later.",
    lockedAccount:
      "Oops, you have too many failed login attempts. Your account will be locked for 15 minutes to protect your security.",
    unknown: "Something went wrong, please try again.",
  },
  login: {
    password: "Oops, your email or password is incorrect.",
    mfa: "Please, enter your two-factor authentication code.",
    biometry: "Too many attempts. Please log in with your password.",
    biometryLockout:
      "For your security, logging in via {{type}} is temporarily disabled. Please try again in 30 seconds or log in with your password instead.",
    biometryDisabledByOS:
      "For your security, logging in via {{type}} is currently disabled. Please log in with your password.",
    biometryCancelled: "Biometric login was cancelled.",
    biometryServerError: "Please enter your password to keep your account secure.",
    untrusted:
      "We don't recognize this device associated to your account. Please check your email for more instructions.",
    accountDisabled: "We're having challenges logging you in. Please contact us for assistance.",
  },
};