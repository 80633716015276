import styled from "@emotion/native";

import { COLORS, borderWidth } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Cell } from "../../atoms/Cell";
import { Image } from "../../atoms/Image";
import { HorizontalPromoCardImageProps } from "./HorizontalPromoCard.types";

const IMAGE_HEIGHT = 72;

export const HorizontalPromoCardContainer = styled(Cell)`
  align-items: center;
  border: solid ${styledValue(borderWidth.small)} ${COLORS.NEUTRAL.COOL["200"]};
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const HorizontalPromoCardLeftContent = styled.View({
  flex: 1,
});

export const HorizontalPromoCardImage = styled(Image)<HorizontalPromoCardImageProps>`
  aspect-ratio: ${({ aspectRatio }) => styledValue(aspectRatio, "")};
  height: ${styledValue(IMAGE_HEIGHT)};
  width: ${({ aspectRatio }) => styledValue(IMAGE_HEIGHT * aspectRatio)};
`;
