import styled from "@emotion/styled";
import React, { ReactNode } from "react";

import mq from "@bwll/bw-styles/breakpoints";

import { ExpandIcon } from "./ExpandIcon";

export interface IAccordion {
  title: string;
  id: string;
  children: ReactNode;
}

interface IIsOpen {
  isOpen: boolean;
}

const AccordionWrapper = styled.div<IIsOpen>(({ isOpen, theme }) =>
  mq({
    padding: "0 0 0px 0",
    margin: "0 0 20px 0",
    position: "relative",
    backgroundColor: theme.COLORS.NEUTRAL.COOL["050"],
    borderRadius: "4px",
    ":last-child": {
      margin: "0",
    },
    ":hover": {
      backgroundColor: isOpen ? theme.COLORS.NEUTRAL.COOL["050"] : theme.COLORS.PRIMARY["050"],

      "&:after": {
        visibility: "visible",
      },
    },
    "&:after": {
      content: "''",
      position: "absolute",
      visibility: isOpen ? "visible" : "hidden",
      top: 0,
      left: 0,
      width: "5px",
      borderRadius: "4px 0 0 4px",
      background: theme.COLORS.PRIMARY["500"],
      height: "100%",
    },
  }),
);

const Title = styled.button(({ theme }) =>
  mq({
    fontFamily: "Lato",
    fontSize: ["16px", "18px", "18px"],
    fontWeight: "800",
    lineHeight: ["1.65", "1.59", "1.59"],
    color: theme.COLORS.NEUTRAL.COOL["900"],
    padding: "16px 19px 16px 19px",
    border: "none",
    backgroundColor: "transparent",
    width: "100%",
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    ":hover": {
      cursor: "pointer",
    },
    ":focus,:active": {
      outline: "none",
      boxShadow: "none",
    },
    //selector for child div will target and change the color of the expand icon
    "&:hover > div, &:hover > div:before,&:focus > div, &:focus > div:before": {
      backgroundColor: theme.COLORS.PRIMARY["500"],
      transition: "transform 0.5s ease",
    },
  }),
);

const Expandable = styled.div<IIsOpen>(({ isOpen, theme }) =>
  mq({
    maxHeight: isOpen ? "200vh" : "0vh",
    visibility: isOpen ? "" : "hidden",
    overflow: "hidden",
    transition: "all 0.4s ease",
    fontFamily: "Lato",
    fontSize: ["16px", "18px", "18px"],
    lineHeight: ["1.5", "1.44", "1.44"],
    color: theme.COLORS.NEUTRAL.COOL["600"],
    padding: isOpen ? "0 20px 20px" : "0px 20px",
    "ul,ol": {
      marginLeft: "20px",
    },

    "& > :last-child": {
      marginBottom: "0",
    },
  }),
);

/**
 * @deprecated non cross-platform components are deprecated, start using "bw-components/next" instead
 */
export const Accordion = ({ title, children, id }: IAccordion) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const panelId = `panel-${id}`;
  return (
    <AccordionWrapper isOpen={isOpen}>
      <Title onClick={() => setIsOpen((isOpen) => !isOpen)} aria-expanded={isOpen} aria-controls={panelId}>
        {title}
        <ExpandIcon isOpen={isOpen} />
      </Title>
      <Expandable isOpen={isOpen} id={panelId}>
        {children}
      </Expandable>
    </AccordionWrapper>
  );
};

export default Accordion;
