import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ANALYTICS_ATTRIBUTES,
  CreditCardCategoryKey,
  ImpressionedProductVerticalCreditCard,
  TabView,
  TrackEventFunc,
} from "@bwll/bw-types";
import { mapCreditCardToProductEventProps } from "@bwll/bw-utils";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

const pageEventProps = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: "product_catalog",
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "horizontal",
  [ANALYTICS_ATTRIBUTES.SHOWN_IN_CAROUSEL]: true,
} as const;

export const useCreditCardProductCategoriesAnalytics = (trackEvent: TrackEventFunc) => {
  const { productViewed, productClicked, buttonClicked } = useMarketplaceAnalyticsEvents(trackEvent);

  return useMemo(() => {
    return {
      trackProductViewed: (
        card: ImpressionedProductVerticalCreditCard,
        index: number,
        tabViewed: TabView,
        categoryKey: CreditCardCategoryKey,
      ) => {
        productViewed({
          ...mapCreditCardToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
          [ANALYTICS_ATTRIBUTES.TAB_VIEWED]: tabViewed,
          [ANALYTICS_ATTRIBUTES.PRODUCT_CAROUSEL_NAME]: categoryKey,
        });
      },
      trackProductClicked: (
        card: ImpressionedProductVerticalCreditCard,
        index: number,
        tabViewed: TabView,
        categoryKey: CreditCardCategoryKey,
      ) => {
        productClicked({
          ...mapCreditCardToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
          [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuidv4(),
          [ANALYTICS_ATTRIBUTES.TAB_VIEWED]: tabViewed,
          [ANALYTICS_ATTRIBUTES.PRODUCT_CAROUSEL_NAME]: categoryKey,
        });
      },
      trackButtonClicked: (categoryName: string, productsShown: number, totalProductsInCategory: number) => {
        buttonClicked({
          [ANALYTICS_ATTRIBUTES.VERTICAL]: "credit cards",
          [ANALYTICS_ATTRIBUTES.SUBVERTICAL]: categoryName,
          [ANALYTICS_ATTRIBUTES.NUMBER_OF_PRODUCTS_SHOWN]: productsShown,
          [ANALYTICS_ATTRIBUTES.NUMBER_OF_ADDITIONAL_PRODUCTS_SHOWN]: totalProductsInCategory,
        });
      },
    };
  }, [buttonClicked, productClicked, productViewed]);
};
