import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, LIST_SUBSCRIPTION_STATUS, QUERY_KEYS, Subscription } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

export interface IUseSubscriptions {
  status?: LIST_SUBSCRIPTION_STATUS;
  customApiOptions?: IApiHookOptions<Subscription[]>;
}

const fetchRentReportingSubscriptions = async (
  accessToken: string,
  baseUrl: string,
  status?: LIST_SUBSCRIPTION_STATUS,
) => {
  // TODO: Calling /subscription directly while we don't have a firewall for gateway
  const response = await axios.get<Subscription[]>(`${baseUrl}/api/subscription/subscriptions`, {
    headers: generateApiHeaders(accessToken),
    params: {
      ...(status && { status }),
    },
  });
  return response.data;
};

export const useSubscriptions = (props?: IUseSubscriptions) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_RENT_REPORTING_SUBSCRIPTIONS, accessToken),
    () => fetchRentReportingSubscriptions(accessToken, envConfigs.API_SUBSCRIPTION_URL, props?.status),
    {
      ...queryBehavior({
        expiresIn,
        ...props?.customApiOptions,
        enabled: !!accessToken && (props?.customApiOptions?.enabled ?? true),
      }),
    },
  );
};
