export const referYourFriends = {
  "termsAndConditions": "Des conditions générales s'appliquent. [Voir les détails]()",
  "shareButton": "INVITER DES AMIS",
  "sharingMessage": "J'utilise Borrowell pour vérifier gratuitement ma cote de crédit. Vous aussi, vous pouvez le faire. Inscrivez-vous ici.",
  "incentive": {
    "title": "Recommandez-nous à vos amis, obtenez {{earnValue}} $",
    "description": "Recevez {{earnValue}} $ pour chaque ami que vous recommandez à Borrowell et aidez-les à atteindre leurs objectifs financiers plus rapidement.",
    "hereIsHowItWorks": {
      "title": "Voici comment cela fonctionne :",
      "one": "Partagez votre lien de référence unique avec vos ami(e)s.",
      "two": "Votre ami(e) crée un compte chez Borrowell et reçoit gratuitement sa cote de crédit",
      "three": "Obtenez {{earnValue}} $ en récompense pour chaque ami que vous recommandez.",
      "four": "Vous recevrez un courriel pour réclamer votre récompense!"
    }
  },
  "contest": {
    "title": "Recevez {{earnValue}} $, donnez {{earnValue}} $, et courez la chance de gagner {{chanceToWinValue}} $",
    "description": "Jusqu'au 30 novembre 2021, recevez {{earnValue}} $ pour chaque ami que vous recommandez à Borrowell et courez la chance de gagner {{chanceToWinValue}} $. Votre ami recevra également {{earnValue}} $!",
    "hereIsHowItWorks": {
      "title": "Voici comment cela fonctionne :",
      "one": "Partagez votre lien de référence unique avec vos ami(e)s.",
      "two": "Votre ami(e) crée un compte chez Borrowell et reçoit gratuitement sa cote de crédit.",
      "three": "Obtenez {{earnValue}} $ de récompense pour chaque ami que vous recommandez, ainsi qu'une chance de gagner le grand prix de {{chanceToWinValue}} $.",
      "four": "Vous recevrez un courriel pour réclamer votre récompense!",
      "five": "Votre ami(e) recevra également un courriel lui permettant de réclamer sa récompense de {{earnValue}} $!"
    }
  },
  "card": {
    "linkText": "Recommandez-nous à vos amis"
  }
}
