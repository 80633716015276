/**
 * Figma design: https://www.figma.com/file/65OSsQFjKnU9M05mGWPl82/Q1-Prime-Prime-Picks-and-Compare-Accounts?type=design&node-id=1-43&mode=design&t=3nosu5kHGM9Nntp0-0
 */
import styled from "@emotion/native";

import { Body2, Cell } from "@bwll/bw-components/next";
import { COLORS, fontSize, fontWeights, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const RowContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${styledValue(spacing.xxs)};
`;

export const StyledCell = styled(Cell)`
  padding: 0 0 ${styledValue(spacing.xxxs)} 0;
`;

export const Header = styled.View`
  background-color: ${COLORS.GREEN["050"]};
  width: 100%;
  border-radius: ${styledValue(spacing.xxs)} ${styledValue(spacing.xxs)} 0 0;
  padding: ${styledValue(spacing.xxxs)};
`;

export const ImageWrapper = styled.View`
  min-width: 84px;
  margin-right: ${styledValue(spacing.xs)};
  border-radius: ${styledValue(spacing.xxs)};
  overflow: hidden;
  height: fit-content;
`;

export const CentralizedIconLink = styled.TouchableOpacity`
  margin-top: ${styledValue(spacing.xs)};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LinkText = styled(Body2)`
  color: ${COLORS.PRIMARY.DEFAULT};
  line-height: ${styledValue(lineHeight.m)};
  min-height: ${styledValue(lineHeight.m)};
  flex-shrink: 1;
`;

export const PreTitle = styled(Body2)`
  color: ${COLORS.NEUTRAL.COOL["600"]};
  font-weight: ${fontWeights.bold};
  font-size: ${styledValue(fontSize.xxs)};
  line-height: ${styledValue(lineHeight.xs)};
`;

export const ContentWrapper = styled.View`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-self: stretch;
`;

// Navive styled does not work with Icon.
export const iconStyle = {
  marginLeft: spacing.xxxs,
};

export const HeaderText = styled(Body2)`
  font-weight: bold;
  font-size: ${styledValue(fontSize.xxs)};
  line-height: ${styledValue(spacing.xs)};
  text-align: center;
  width: 100%;
  color: ${COLORS.NEUTRAL.WARM["700"]};
`;

export const HeaderTextBold = styled(Body2)`
  font-weight: bold;
  font-size: ${styledValue(fontSize.xxs)};
  line-height: ${styledValue(spacing.xs)};
  color: ${COLORS.PURPLE["700"]};
`;
