export const provinces = {
  "ab": {
    "abbr": "Alb.",
    "name": "Alberta"
  },
  "bc": {
    "abbr": "C.-B.",
    "name": "Colombie-Britannique"
  },
  "mb": {
    "abbr": "Man.",
    "name": "Manitoba"
  },
  "nb": {
    "abbr": "N.-B.",
    "name": "Nouveau-Brunswick"
  },
  "nl": {
    "abbr": "T.-N.-L.",
    "name": "Terre-Neuve-et-Labrador"
  },
  "nt": {
    "abbr": "T.N.-O.",
    "name": "Territoires du Nord-Ouest"
  },
  "ns": {
    "abbr": "N.-É.",
    "name": "Nouvelle-Écosse"
  },
  "nu": {
    "abbr": "Nt",
    "name": "Nunavut"
  },
  "on": {
    "abbr": "Ont.",
    "name": "Ontario"
  },
  "pe": {
    "abbr": "Î.-P.-É.",
    "name": "Île-du-Prince-Édouard"
  },
  "qc": {
    "abbr": "Qc",
    "name": "Québec"
  },
  "sk": {
    "abbr": "Sask.",
    "name": "Saskatchewan"
  },
  "yt": {
    "abbr": "Yn",
    "name": "Yukon"
  }
}
