import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Cell } from "../../atoms/Cell";

type CardCellProps = {
  hasShadow?: boolean;
  borderColor: string;
  backgroundColor: string;
};

export const CardCell = styled(Cell)<CardCellProps>(({ borderColor, backgroundColor }) => ({
  paddingHorizontal: spacing.xxxs,
  borderWidth: 1,
  borderColor: borderColor,
  backgroundColor: backgroundColor,
  flex: 1,
  display: "flex",
  flexDirection: "row",
}));

export const IconWrapper = styled.View`
  padding: ${styledValue(spacing.none)} ${styledValue(spacing.xxs)};
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.View`
  padding-right: ${styledValue(spacing.xxs)};
  flex: 1;
`;
