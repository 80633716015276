export const errorScreen = {
  "default": {
    "title": "Désolé, il y a eu un problème",
    "subtitle": "Une erreur s'est malheureusement produite. Veuillez __[nous contacter]()__ si vous avez des questions.",
    "ctaButton": "FERMER"
  },
  "cblSomethingWentWrong": {
    "title": "Désolé, il y a eu un problème",
    "subtitle": "Malheureusement, une erreur s'est produite et nous ne pouvons pas ouvrir de Credit Builder pour vous en ce moment. Veuillez [nous contacter]() si vous avez des questions.",
    "ctaButton": "FERMER"
  },
  "rentReportingSomethingWentWrong": {
    "title": "Nous sommes désolés, il semble qu'il y ait eu un problème",
    "subtitle": "Veuillez réessayer plus tard ou __[contacter le support]()__ pour obtenir de l'aide.",
    "ctaButton": "FERMER"
  }
}
