import { CREDIT_CARD_CATEGORY_KEYS } from "@bwll/bw-types";

export const creditCards = {
  title: "Credit Cards",
  viewAll: "View all",
  categories: {
    [CREDIT_CARD_CATEGORY_KEYS.TOP_PICKS]: {
      title: "Top picks for you",
      description: "Curated list of credit cards for your profile.",
      viewAll: {
        title: "Top credit cards await",
        description: "Explore top credit card picks personalized to match your financial profile and needs.",
      },
    },
    [CREDIT_CARD_CATEGORY_KEYS.TRAVEL]: {
      title: "Travel cards",
      description: "Best cards for travel points and perks.",
      viewAll: {
        title: "Explore beyond the horizon",
        description:
          "Discover a collection that truly suits your travel style, helping you find the right fit for your journey.",
      },
    },
    [CREDIT_CARD_CATEGORY_KEYS.CASH_BACK]: {
      title: "Cash back",
      description: "Cards offering cash back rewards.",
      viewAll: {
        title: "Unlock cash back rewards",
        description:
          "Explore a range of cash back credit cards for maximizing your savings on every day purchases, tailored to match your spending habits.",
      },
    },
    [CREDIT_CARD_CATEGORY_KEYS.REWARDS]: {
      title: "Rewards",
      description: "Cards with the best rewards.",
      viewAll: {
        title: "Earn rewards from spending",
        description:
          "Browse a variety of rewards cards to optimize perks and benefits on your every day spending.",
      },
    },
    [CREDIT_CARD_CATEGORY_KEYS.NO_FEE]: {
      title: "No fee",
      description: "Cards with no annual fees or first year waived.",
      viewAll: {
        title: "More credit, no fees",
        description:
          "Explore credit cards with no annual fees, so you can maximize rewards without extra costs.",
      },
    },
    [CREDIT_CARD_CATEGORY_KEYS.PRE_QUALIFIED_OFFERS]: {
      title: "Pre-qualified offers",
      description: "Feel confident about your credit card application.",
      viewAll: {
        title: "Discover your best match",
        description:
          "Apply for pre-qualified credit cards based on your credit report with a high confidence for approval.",
      },
    },
    [CREDIT_CARD_CATEGORY_KEYS.OTHER]: {
      title: "Other offers",
      description: "More credit card offers for the rest of your needs.",
      viewAll: {
        title: "Uncover a hidden gem",
        description:
          "Find your next credit card through a diverse array of offers for your personal financial needs.",
      },
    },
    [CREDIT_CARD_CATEGORY_KEYS.ALL]: {
      title: "Credit cards",
      description: "Discover your next credit card.",
    }
  },
};
