import { Route, Routes } from "react-router-dom";

import { useGoogleAnalyticsPageView } from "@bwll/bw-hooks";

import { PUBLIC_ROUTES } from "./constants";

import { CheckoutFailurePage } from "@app/screens/public/checkoutFailurePage";
import { CheckoutSuccessPage } from "@app/screens/public/checkoutSuccessPage";

const PublicRoutes = () => {
  useGoogleAnalyticsPageView();

  return (
    <Routes>
      <Route path={PUBLIC_ROUTES.PUBLIC_CHECKOUT_SUCCESS} element={<CheckoutSuccessPage />} />
      <Route path={PUBLIC_ROUTES.PUBLIC_CHECKOUT_FAILURE} element={<CheckoutFailurePage />} />
    </Routes>
  );
};

export const PublicRouter = () => {
  return <PublicRoutes />;
};

export default PublicRouter;
