import React, { useContext } from "react";

export enum ContextName {
  EnvironmentContext = "EnvironmentContext",
  NavigationContext = "NavigationContext",
  SessionContext = "SessionContext",
  BankContext = "BankContext",
  LoadingContext = "LoadingContext",
  FormContext = "FormContext",
  AddressFormContext = "AddressFormContext",
  LeaseFormContext = "LeaseFormContext",
  PlatformContext = "PlatformContext",
  ExperimentContext = "ExperimentContext",
  TreatmentContext = "TreatmentContext",
  ExperimentStoreContext = "ExperimentStoreContext",
  RentTransactionsContext = "RentTransactionsContext",
  RentAdvantageSessionContext = "RentAdvantageSessionContext",
  RentAdvantageManualUploadContext = "RentAdvantageManualUploadContext",
  AnalyticsContext = "AnalyticsContext",
  AndroidActionContext = "AndroidActionContext",

  // Quick Apply - Product Catalog
  QuickApplyContext = "QuickApplyContext",
}

export const useContextWrapper = <T>(parentContext: React.Context<T>, contextName: ContextName) => {
  const context = useContext(parentContext);

  if (!context) {
    throw new Error(`You are attempting to use ${contextName} outside of a ${contextName}Provider`);
  }
  return context;
};
