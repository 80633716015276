import styled from "@emotion/native";

import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { RegularText } from "../Default";

export const StyledParagraph = styled(RegularText)`
  font-size: ${styledValue(fontSize.xs)};
  line-height: ${styledValue(spacing.s)};
  letter-spacing: 0.24px;
  color: ${COLORS.NEUTRAL.WARM["700"]};
`;
