export const appIntro = {
  "weeklyCreditUpdates": {
    "title": "Mises à jour hebdomadaires du crédit",
    "subtitle": "Surveillez gratuitement votre cote et dossier de crédit sans nuire à votre crédit"
  },
  "understandYourCredit": {
    "title": "Comprendre votre crédit",
    "subtitle": "Obtenez des conseils personnalisés sur la manière d'améliorer votre cote de crédit"
  },
  "productsJustForYou": {
    "title": "Des produits juste pour vous",
    "subtitle": "Découvrez des produits personnalisés et évaluez vos probabilités d'approbation avant de faire votre demande"
  },
  "createAccount": "CRÉER UN COMPTE",
  "loginButton": "SE CONNECTER"
}
