/**
 * Figma design: https://www.figma.com/file/65OSsQFjKnU9M05mGWPl82/Q1-Prime-Prime-Picks-and-Compare-Accounts?type=design&node-id=1-43&mode=design&t=3nosu5kHGM9Nntp0-0
 */
import styled from "@emotion/native";

import { Body2 } from "@bwll/bw-components/next";
import { COLORS, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const RowContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ImageWrapper = styled.View`
  min-width: 84px;
  margin-right: ${styledValue(spacing.xs)};
  border-radius: ${styledValue(spacing.xxs)};
  overflow: hidden;
  height: fit-content;
`;

export const CentralizedIconLink = styled.TouchableOpacity`
  margin-top: ${styledValue(spacing.xs)};
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const LinkText = styled(Body2)`
  color: ${COLORS.PRIMARY.DEFAULT};
  line-height: ${styledValue(lineHeight.m)};
  min-height: ${styledValue(lineHeight.m)};
  flex-shrink: 1;
`;

export const ContentWrapper = styled.View`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-self: stretch;
`;

// Navive styled does not work with Icon.
export const iconStyle = {
  marginLeft: spacing.xxxs,
};
