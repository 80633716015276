import styled from "@emotion/native";
import { StyleSheet } from "react-native";
import { LinearGradientProps } from "react-native-linear-gradient";

import { Label } from "@bwll/bw-components/next";
import { COLORS, borderWidth } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ResumeCardStyles = StyleSheet.create({
  backgroundImageStyle: {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: -1,
    width: 237,
    height: 163,
  },
});

export const CardBorder = `${styledValue(borderWidth.small)} solid ${COLORS.PURPLE[100]}`;

export const ResumeCardContainer = styled.View(() => ({
  width: "100%",
  height: "100%",
  maxWidth: 342,
  position: "relative",
}));

export const LinearGradient: LinearGradientProps = {
  colors: ["rgba(224, 218, 240, 0.25)", "rgba(161, 133, 227, 0.3)"],
  locations: [0.3, 1.0],
  useAngle: true,
  angle: 120,
};

export const ContentContainer = styled.View(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const LabelContainer = styled.View(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const CardLabel = styled(Label)(() => ({
  letterSpacing: 1.12,
  lineHeight: 16,
}));
