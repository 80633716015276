import { newLogin } from "./newLogin";
import { forgotMyPassword } from "./forgotMyPassword";
import { trustedDevice } from "./trustedDevice";
import { biometry } from "./biometry";

export const login = {
  newLogin,
  forgotMyPassword,
  trustedDevice,
  biometry,
};

