import styled from "@emotion/native";
import { StyleSheet } from "react-native";

import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

const MIN_HEIGHT_CONTAINER = 150;

type IndicatorsContainerProps = {
  minWidth: number;
  maxWidth: number;
};

export const CarouselWrapper = styled.View`
  margin-horizontal: ${styledValue(-16)};
`;

export const OffersText = styled.View`
  margin-horizontal: ${styledValue(fontSize.s)};
`;

export const Container = styled.View`
  min-height: ${styledValue(MIN_HEIGHT_CONTAINER)};
  align-items: center;
`;

export const CarouselNavigatorContainer = styled.View`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IndicatorsContainer = styled.View<IndicatorsContainerProps>`
  margin-vertical: ${spacing.m};
  min-width: ${({ minWidth }) => styledValue(minWidth, "px")};
  max-width: ${({ maxWidth }) => styledValue(maxWidth, "px")};
`;

export const styles = StyleSheet.create({
  indicatorStyle: {
    width: spacing.xxxs,
    height: spacing.xxxs,
    borderRadius: spacing.xxxs,
    marginHorizontal: spacing.xxxs,
    backgroundColor: COLORS.PRIMARY.DEFAULT,
  },
});
