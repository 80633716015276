import styled from "@emotion/native";
import { Platform } from "react-native";

import { COLORS, fontSize, fonts, lineHeight } from "@bwll/bw-styles";
import { Platforms } from "@bwll/bw-types";

import { IsOpenProps } from "./Accordion.types";

export const AccordionWrapper = styled.View({
  borderRadius: 8,
  borderColor: COLORS.NEUTRAL.COOL["200"],
  borderWidth: 1,
  width: "100%",
});

export const TitleWrapper = styled.TouchableOpacity<IsOpenProps>(({ theme, isOpen }) => ({
  padding: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  ...(isOpen
    ? {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }
    : {
        borderRadius: 8,
      }),
}));

export const Title = styled.Text({
  fontFamily: Platform.OS === Platforms.WEB ? `${fonts.lato.regular}, Lato` : fonts.lato.regular,
  fontWeight: "bold",
  fontSize: fontSize.xs,
  lineHeight: lineHeight.m,
  letterSpacing: 0.32,
  color: COLORS.NEUTRAL.WARM["800"],
});

export const Expandable = styled.View<IsOpenProps>(({ isOpen }) => ({
  display: isOpen ? "flex" : "none",
  overflow: "hidden",
  borderTopWidth: 1,
  borderTopColor: COLORS.NEUTRAL.COOL["200"],
}));

export const Footer = styled.View({
  borderTopWidth: 1,
  borderTopColor: COLORS.NEUTRAL.COOL["200"],
});

export const IconWrapper = styled.View({
  marginLeft: 10,
  alignSelf: "center",
});
