import styled from "@emotion/native";

import { fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Body2 } from "../Body2";

export const ListWrapper = styled.View`
  display: flex;
`;

export const ListIcon = styled(Body2)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: ${styledValue(fontSize.xl)};
  line-height: ${styledValue(lineHeight.l)};
  margin-right: ${styledValue(spacing.xxxs)};
`;

export const ItemRow = styled.View`
  display: flex;
  flex-direction: row;
`;
