export const referYourFriends = {
  termsAndConditions: "Terms and conditions apply. [View details]()",
  shareButton: "INVITE FRIENDS",
  sharingMessage: "I use Borrowell to check my credit score for free. You can too. Sign up here.",
  incentive: {
    title: "Refer your friends, earn ${{earnValue}}",
    description:
      "Earn ${{earnValue}} for every friend you refer to Borrowell and help them achieve their financial goals faster.",
    hereIsHowItWorks: {
      title: "Here’s how it works:",
      one: "Share your unique referral link with your friends.",
      two: "Your friend creates an account with Borrowell and receives their free credit score.",
      three: "Earn a ${{earnValue}} reward for every friend you refer.",
      four: "You’ll receive an email to redeem your reward!",
    },
  },
  contest: {
    title: "Get ${{earnValue}}, give ${{earnValue}}, plus a chance to win ${{chanceToWinValue}}",
    description:
      "Until Nov 30, 2021, get ${{earnValue}} for each friend you refer to Borrowell plus a chance to win ${{chanceToWinValue}}. Your friend gets ${{earnValue}} too!",
    hereIsHowItWorks: {
      title: "Here’s how it works:",
      one: "Share your unique referral link with your friends.",
      two: "Your friend creates an account with Borrowell and receives their free credit score.",
      three:
        "Earn a ${{earnValue}} reward for every friend you refer, plus a chance to win the ${{chanceToWinValue}} grand prize.",
      four: "You’ll receive an email to redeem your reward!",
      five: "Your friend will get an email to redeem their ${{earnValue}} reward too!",
    },
  },
  card: {
    linkText: "Refer your friends",
  },
};
