import React from "react";

import { StyledParagraph } from "./Paragraph.styles";
import { ParagraphProps } from "./Paragraph.types";

export const Paragraph = ({ children, style, accessibilityLabel, testID, onPress }: ParagraphProps) => {
  return (
    <StyledParagraph
      accessibilityLabel={accessibilityLabel}
      style={style}
      testID={testID}
      accessibilityRole="text"
      onPress={onPress}
    >
      {children}
    </StyledParagraph>
  );
};
