import React, { createContext, useCallback, useMemo, useState } from "react";

import { RentReportingProfile } from "@bwll/bw-types";

import { ContextName, useContextWrapper } from "./contextWrapper";

export interface ILeaseFormFields {
  monthlyRentAmount: string;
  leaseStartDate: string;
  rentPaymentDueDate: string;
}

interface ILeaseFormContext {
  fields: ILeaseFormFields;
  updateLeaseField: (key: keyof ILeaseFormFields, value: string) => void;
  updateLease: (value: ILeaseFormFields) => void;
}

export interface IAddressFormFields {
  street: string;
  unitApt: string;
  city: string;
  province: string;
  postalCode: string;
}
interface IAddressFormContext {
  fields: IAddressFormFields;
  updateAddressField: (key: keyof IAddressFormFields, value: string) => void;
  updateAddress: (value: IAddressFormFields) => void;
  resetAddress: () => void;
}

export interface IFormContext {
  address: IAddressFormContext;
  lease: ILeaseFormContext;
}

export interface FormFields extends RentReportingProfile {
  address: IAddressFormFields;
  lease: ILeaseFormFields;
}

const FormContext = createContext<IFormContext>({} as IFormContext);
const LeaseFormContext = createContext<ILeaseFormContext>({} as ILeaseFormContext);
const AddressFormContext = createContext<IAddressFormContext>({} as IAddressFormContext);

interface IFormProvider {
  children: React.ReactNode;
}

export const FormProvider = ({ children }: IFormProvider) => {
  const [addressFormFields, setAddressFormFields] = useState<IAddressFormFields>({
    street: "",
    unitApt: "",
    city: "",
    province: "",
    postalCode: "",
  });

  const [leaseFormFields, setLeaseFormFields] = useState<ILeaseFormFields>({
    monthlyRentAmount: "",
    leaseStartDate: "",
    rentPaymentDueDate: "",
  });

  const updateAddressField = useCallback((key: any, value: any) => {
    setAddressFormFields((curr) => ({ ...curr, [key]: value }));
  }, []);

  const addressValue: IAddressFormContext = useMemo(
    () => ({
      fields: addressFormFields,
      updateAddressField,
      updateAddress: (value) => {
        setAddressFormFields(value);
      },
      resetAddress: () => {
        setAddressFormFields((currentAddress) => ({
          ...currentAddress,
          street: "",
          city: "",
          province: "",
          postalCode: "",
        }));
      },
    }),
    [addressFormFields, updateAddressField],
  );

  const updateLeaseField = useCallback((key: any, value: any) => {
    setLeaseFormFields((curr) => ({ ...curr, [key]: value }));
  }, []);

  const leaseValue: ILeaseFormContext = useMemo(
    () => ({
      fields: leaseFormFields,
      updateLeaseField,
      updateLease: (value) => {
        setLeaseFormFields(value);
      },
    }),
    [leaseFormFields, updateLeaseField],
  );

  const formValue: IFormContext = useMemo(
    () => ({
      address: addressValue,
      lease: leaseValue,
    }),
    [addressValue, leaseValue],
  );

  return (
    <FormContext.Provider value={formValue}>
      <AddressFormContext.Provider value={addressValue}>
        <LeaseFormContext.Provider value={leaseValue}>{children}</LeaseFormContext.Provider>
      </AddressFormContext.Provider>
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContextWrapper(FormContext, ContextName.FormContext);
export const useAddressContext = () => useContextWrapper(AddressFormContext, ContextName.AddressFormContext);
export const useLeaseContext = () => useContextWrapper(LeaseFormContext, ContextName.LeaseFormContext);
