import styled from "@emotion/native";

import { COLORS, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BoldText } from "../../atoms/Typography/Default";

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  padding: ${styledValue(spacing.xxs)};
`;

export const ButtonText = styled(BoldText)`
  font-size: ${styledValue(fontSize.xs)};
  line-height: ${styledValue(lineHeight.xs)};
  text-align: center;
  text-transform: uppercase;
  color: ${COLORS.PRIMARY["500"]};
`;

export const IconContainer = styled.View`
  margin-left: 3px;
  margin-bottom: 5px;
`;
