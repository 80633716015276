import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { AppInsightsProvider, initGoogleAnalytics } from "@bwll/bw-hooks";
import { initializeLocalization } from "@bwll/bw-locale";

import { NavigationResolver } from "./NavigationResolver";
import { config } from "./config";

initGoogleAnalytics({
  trackingId: "UA-56857616-1",
  options: {
    debug: process.env.NODE_ENV !== "production",
  },
});

const { localizationReady } = initializeLocalization({ interpolation: {} });

function App() {
  useEffect(() => {
    localizationReady();
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <AppInsightsProvider connectionString={config.APP_INSIGHTS_CONNECTION_STRING}>
          <NavigationResolver />
        </AppInsightsProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
