import { ReactNode } from "react";

import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";

import { TImpressionAnalyticsAttributes, TRAOnboardingAnalyticsAttributes } from "./featuresAttributes";

// Application common attributes that appear on any event.
type TCommonAnalyticsAttributes = {
  [ANALYTICS_ATTRIBUTES.INDIVIDUAL_CLIENT_ID]?: string;
};

/**
 * Component-Level Attributes: Button
 *
 * Attributes from the component properties that are relevant to be tracked
 */
export type TButtonAnalyticsAttributes = {
  [ANALYTICS_ATTRIBUTES.BUTTON_NAME]: string;
};

/**
 * Component-Level Attributes: ElementTracker
 *
 * Possible event names for Impression Tracking
 */
export const enum IMPRESSION_EVENT_NAMES {
  IMPRESSION_VIEW = "Impression View",
  PRODUCT_VIEWED = "Product Viewed",
  PROMO_CARD_VIEWED = "Promo Card Viewed",
}

/**
 * Component-Level Attributes: Link
 *
 * Possible event names for Impression Tracking
 */
export const enum ON_LINK_CLICK_EVENT_NAMES {
  BUTTON_CLICKED = "Button Clicked",
  LINK_CLICK = "Link Click",
  PRODUCT_CLICKED = "Product Clicked",
  PRODUCT_EXPANDED = "Product Expanded",
  PROMO_CARD_CLICKED = "Promo Card Clicked",
}

export type TLinkAnalyticsAttributes = {
  [ANALYTICS_ATTRIBUTES.LINK_TEXT]: string;
};

/**
 * Flow Names is used to complement event names, thereby, it's a required fields for shared analytics events.
 * The reasoning for that is to help organizing tables on Looker, which are separated by event names.
 *
 * E.g.:
 *      EVENT_NAME = "Button Click"
 *      FLOW_NAME = "Sign Up"
 *      ---------------------------
 *      Analytics Event Name = "Button Click: Sign Up"
 *
 */

export const enum FLOW_NAMES {
  RENT_ADVANTAGE_ONBOARDING = "Rent Advantage Onboarding",
  CREDIT_BUILDER = "Credit Builder",
  SIGN_UP = "Sign Up",
}

// Feature attributes that appear on any feature flow throughout the applications.
type TCommonFeatureAttributes = {
  [ANALYTICS_ATTRIBUTES.FLOW_NAME]?: FLOW_NAMES;
};

/**
 * List of all the feature types using shared analytics provider.
 * The features should be added with the | operator, so TypeScript is able to detect which attributes the event is using.
 *
 * E.g.:
 * export type TFeaturesAttributes = TRAOnboardingAnalyticsAttributes | TCreditBuilderAttributes;
 *
 */

export type TFeaturesAttributes = TRAOnboardingAnalyticsAttributes;

/**
 * TFeatureAnalyticsAttributes combines common attributes, which are sent for any feature flow, such as flowName.
 *
 * Generic TFeature purpose is to help selecting the required attributes on the screen level component.
 * The generics types are not mandatory, their goal is to serve as a guideline.
 *
 * E.g.:
 * const rentAdvantageAttributes: TFeaturesAttributes<TRAOnboardingAnalyticsAttributes> = {
 *   ...
 * }
 */
export type TFeatureAnalyticsAttributes<TFeature = TFeaturesAttributes> = TCommonFeatureAttributes & TFeature;

export type TSharedAnalyticsAttributes = {
  commonAttributes: TCommonAnalyticsAttributes;
  trackEvent: (eventName: string, eventAttributes: TFeatureAnalyticsAttributes) => void;
};

export type TAnalyticsProviderProps = {
  children: ReactNode;
} & TSharedAnalyticsAttributes;

export type TAnalyticsContext = {
  onButtonClick: (eventAttributes: TButtonAnalyticsAttributes & TFeatureAnalyticsAttributes) => void;
  impressionView: <TCustomComponentAttributes = TImpressionAnalyticsAttributes>(args: {
    eventName?: IMPRESSION_EVENT_NAMES;
    eventAttributes: TCustomComponentAttributes & TFeatureAnalyticsAttributes;
  }) => void;
  onLinkClick: <TCustomComponentAttributes = TLinkAnalyticsAttributes>(args: {
    eventName?: ON_LINK_CLICK_EVENT_NAMES;
    eventAttributes: TCustomComponentAttributes & TFeatureAnalyticsAttributes;
  }) => void;
};
