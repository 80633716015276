export const proofOfPayment = {
  "title": "Téléversez votre preuve de paiement",
  "titlePart1": "Téléversez votre",
  "titlePart2": "preuve de paiement",
  "titleTooltipTitle": "Preuve de paiement",
  "titleTooltipDescription": "Les preuves de paiement comprennent une copie du chèque de loyer, la confirmation du paiement par carte de crédit ou de débit, la confirmation du virement électronique et toute autre information dont nous avons besoin pour prouver que le paiement du loyer a été effectué.",
  "description": "Veuillez téléverser votre preuve de paiement pour la date d'échéance **{{ date }}**.",
  "descriptionNoMD": "Veuillez téléverser votre preuve de paiement pour la date d'échéance ",
  "requirementsDescription": "La preuve de paiement que vous téléversez doit comprendre les informations suivantes :",
  "requirementsList": {
    "yourName": "Votre nom",
    "transactionDate": "Date de la transaction",
    "transactionAmount": "Montant de la transaction",
    "transactionDescription": "Description de la transaction",
    "accountNumber": "Numéro de compte"
  },
  "notesDescription": "Lorsque vous téléversez votre preuve de paiement, veuillez tenir compte des éléments suivants :",
  "notesList": {
    "notProtected": "Veuillez vous assurer que le fichier n'est pas protégé par un mot de passe.",
    "eStatement": "Visitez votre banque en ligne et téléchargez un relevé électronique pour cette transaction dans l'un de ces formats de fichiers : **png, jpg, jpeg, pdf**.",
    "eStatementNoMD": "Visitez votre banque en ligne et téléchargez un relevé électronique pour cette transaction dans l'un de ces formats de fichiers : "
  },
  "fileLabel": "Fichier",
  "filesUploadingSectionTitle": "Téléversement",
  "filesReadySectionTitle": "Fichiers",
  "fileChangeButton": "MODIFIER",
  "fileMaxSize": "Taille max. du fichier : {{ mb }}MO",
  "confirmation": "Je confirme que la preuve de paiement que j'ai téléversé est authentique et exacte.",
  "uploadFileButton": "TÉLÉVERSER LE FICHIER",
  "chooseFileButton": "CHOISIR LE FICHIER",
  "actionSheet": {
    "browseFiles": "Parcourir les fichiers",
    "browsePhotos": "Parcourir les photos",
    "cancel": "Annuler"
  },
  "errors": {
    "fileSize": "Votre fichier dépasse la taille maximale de {{ mb }}MB",
    "fileFormat": "Le format de fichier doit être soit ",
    "or": " ou ",
    "fileFormatMD": "Le format de fichier doit être **png**, **jpg**, **jpeg** ou **pdf**.",
    "uploadError": "Une erreur s'est produite lors du téléversement de votre fichier."
  },
  "loading": {
    "title": "Téléversement de la preuve de paiement...",
    "description": "Ne fermez pas cette page, sinon vous perdrez vos progrès et devrez recommencer."
  }
}
