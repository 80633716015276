import styled from "@emotion/native";

import { COLORS, fontSize, fonts, letterSpacing, lineHeight } from "@bwll/bw-styles";

import { RegularText } from "../Default";

const BaseStyledComponent = styled(RegularText)(() => ({
  fontWeight: "700",
  lineHeight: lineHeight.m,
  letterSpacing: letterSpacing.m,
  color: COLORS.NEUTRAL.WARM["800"],
}));

export const PrimarySubtitleComponent = styled(BaseStyledComponent)(() => ({
  fontSize: fontSize.s,
}));

export const SecondarySubtitleComponent = styled(BaseStyledComponent)(() => ({
  fontSize: fontSize.xs,
}));
