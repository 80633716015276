import styled, { css } from "@emotion/native";

import { COLORS } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const DollarBillWrapper = styled.View`
  background-color: ${COLORS.GREEN["100"]};
  align-self: flex-start;
  padding-vertical: ${styledValue(2)};
  padding-horizontal: ${styledValue(8)};
  border-radius: ${styledValue(3)};
  border: ${styledValue(2)} solid ${COLORS.GREEN["600"]};
`;

export const tooltipStyle = css`
  align-self: flex-start;
`;

export const FirstYearValueWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;
