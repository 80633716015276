import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import { noop } from "@bwll/bw-utils";

import type { InfoTabsApi, InfoTabsProps, TabID } from "./InfoTabs.types";

const InfoTabsContext = createContext<InfoTabsApi>({
  activeTabID: "",
  changeTab: noop,
});

const InfoTabsProvider = ({ children, initialTabID, onPress }: InfoTabsProps) => {
  const [activeTabID, setActiveTabID] = useState<TabID | undefined>(initialTabID);

  const changeTab = useCallback(
    (tabID: TabID) => {
      setActiveTabID(tabID);
      onPress?.(tabID);
    },
    [onPress],
  );

  const internalApi = useMemo(
    () => ({
      changeTab,
      activeTabID,
    }),
    [changeTab, activeTabID],
  );

  return <InfoTabsContext.Provider value={internalApi}>{children}</InfoTabsContext.Provider>;
};

const useInfoTabs = () => {
  const context = useContext(InfoTabsContext);

  if (!context) {
    throw new Error("InfoTabsContext is not available");
  }

  return context;
};

export { useInfoTabs, InfoTabsProvider };
