export const onboardingStepsPage = {
  "button": "CONTINUER",
  "steps": [
    {
      "title": "Données personnelles et de location",
      "description": "Confirmez vos informations personnelles et ajoutez vos informations de location."
    },
    {
      "title": "Connectez votre compte et sélectionnez des transactions",
      "description": "Connectez votre compte bancaire et nous détecterons automatiquement les paiements de loyer et de services publics."
    },
    {
      "title": "Abonnement et accords",
      "description": "Confirmez votre inscription et signez un accord de paiement par débit préautorisé."
    }
  ]
}
