import styled from "@emotion/styled";

import { fontSize, lineHeight, mq, toRGBAString } from "@bwll/bw-styles";

interface IStylesProps {
  checked?: boolean;
  withBorder?: boolean;
  disabled?: boolean;
}

export const HiddenCheckbox = styled.input`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

export const StyledCheckbox = styled.div<IStylesProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: ${({ checked, theme }) => (checked ? theme.COLORS.PRIMARY["600"] : "transparent")};
  border: 2px solid
    ${({ checked, disabled, theme }) =>
      disabled
        ? theme.COLORS.NEUTRAL.COOL["500"]
        : checked
        ? "transparent"
        : theme.COLORS.NEUTRAL.COOL["600"]};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  & svg {
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
`;

export const Container = styled.div<IStylesProps>(
  ({ checked, withBorder = false, disabled = false, theme }) => {
    const common = {
      display: "flex",
      flexDirection: "column",
      ...theme.listItem,
      ...(disabled && { color: theme.COLORS.NEUTRAL.COOL["500"] }),
    };

    const borderStyles = {
      cursor: disabled ? "not-allowed" : "pointer",
      "*": {
        cursor: disabled ? "not-allowed" : "pointer",
      },
      border: `1px solid ${theme.COLORS.NEUTRAL.COOL["600"]}`,
      borderRadius: "8px",
      padding: "12px",
      ":hover": {
        border: `1px solid ${disabled ? theme.COLORS.NEUTRAL.COOL["300"] : theme.COLORS.PRIMARY["500"]}`,
        background: `${
          disabled ? theme.COLORS.NEUTRAL.COOL["100"] : toRGBAString(theme.COLORS.PRIMARY["050"], 0.2)
        }`,
      },
    };

    const checkedStyles = {
      border: `1px solid ${theme.COLORS.PRIMARY["500"]}`,
      background: `${toRGBAString(theme.COLORS.PRIMARY["050"], 0.2)}`,
    };

    const disabledStyles = {
      border: `1px solid ${theme.COLORS.NEUTRAL.COOL["300"]}`,
      background: `${theme.COLORS.NEUTRAL.COOL["100"]}`,
    };

    const finalBorderStyles = {
      ...borderStyles,
      ...(checked ? checkedStyles : {}),
      ...(disabled && disabledStyles),
    };

    return mq({
      ...common,
      ...(withBorder ? finalBorderStyles : {}),
    });
  },
);

export const Wrapper = styled.label<IStylesProps>`
  display: flex;
  flex-direction: ${({ withBorder }) => (withBorder ? "row-reverse" : "row")};
`;

export const CheckboxContainer = styled.div<IStylesProps>(({ checked, theme, withBorder = false }) =>
  mq({
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: "0px",
    padding: withBorder ? null : "10px",
    borderRadius: "100%",

    "&:focus-within": {
      backgroundColor: checked
        ? toRGBAString(theme.COLORS.PRIMARY["500"], 0.2)
        : toRGBAString(theme.COLORS.NEUTRAL.COOL[500], 0.2),
      animation: "backgroun-fade-in 500ms forwards",
      transition: "background-color 500ms, transform 2000ms cubic-bezier(0, 0, 0.2, 1)",
      borderRadius: "100%",
    },
  }),
);

export const LabelWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: auto 14px auto 0;
`;

export const Label = styled.label<IStylesProps>`
  font-size: ${fontSize.xs}px;
  font-weight: 600;
  color: ${({ disabled, theme }) =>
    disabled ? theme.COLORS.NEUTRAL.COOL["500"] : theme.COLORS.NEUTRAL.WARM["800"]};
  line-height: ${lineHeight.m}px;
  width: 60%;
`;

export const SubLabel = styled.label<IStylesProps>`
  font-size: ${fontSize.xs}px;
  font-weight: 600;
  color: ${({ disabled, theme }) =>
    disabled ? theme.COLORS.NEUTRAL.COOL["500"] : theme.COLORS.NEUTRAL.WARM["800"]};
  line-height: ${lineHeight.m}px;
`;

export const SubText = styled.label<IStylesProps>`
  font-size: ${fontSize.xxs}px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.COLORS.NEUTRAL.COOL["500"] : theme.COLORS.NEUTRAL.WARM["700"]};
  margin-top: 4px;
`;

export const SpanText = styled.span`
  ${mq({
    alignSelf: "center",
  })}
`;
