import React from "react";

import { StyledSubheading2 } from "./Subheading2.styles";
import { Subheading2Props } from "./Subheading2.types";

/**
 * Component for showing the Subheading2.
 *
 * @component
 * @example
 * return (
 *   <Subheading2 color={COLORS.NEUTRAL.WARM["700"]} addMargin>Hello World</Subheading2>
 * )
 */
export const Subheading2 = ({
  accessibilityLabel,
  style,
  testID,
  children,
  color,
  onPress,
  textAlign,
  underline,
}: Subheading2Props) => {
  return (
    <StyledSubheading2
      accessibilityLabel={accessibilityLabel}
      testID={testID}
      accessibilityRole="text"
      color={color}
      onPress={onPress}
      style={style}
      textAlign={textAlign}
      underline={underline}
    >
      {children}
    </StyledSubheading2>
  );
};
