import { spacing } from "./spacing";

export const borderRadius = {
  xs: spacing.xxxs,
  s: spacing.xxs,
  m: spacing.xs,
  default: spacing.l,
  circle: spacing.xxl,
} as const;

export type RadiusKeys = keyof typeof borderRadius;
export type StandardRadius = typeof borderRadius[RadiusKeys];

export const borderWidth = {
  none: 0,
  small: 1,
  default: 1.5,
};
