import styled from "@emotion/native";
import { Dimensions } from "react-native";

import { COLORS, spacing } from "@bwll/bw-styles";

export const StyledHeaderComponent = styled.SafeAreaView(() => ({
  flex: 1,
  padding: spacing.s,
  minWidth: Dimensions.get("window").width,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: COLORS.WHITE,
}));

export const StyledLeftComponent = styled.View(() => ({
  flex: 1,
  alignItems: "flex-start",
}));

export const StyledCenterComponent = styled.View(() => ({
  flex: 1,
  alignItems: "center",
}));

export const StyledRightComponent = styled.View(() => ({
  flex: 1,
  alignItems: "flex-end",
}));
