import React, { useCallback, useContext, useMemo } from "react";
import { ActivityIndicator, Pressable } from "react-native";

import { AnalyticsContext, TButtonAnalyticsAttributes } from "@bwll/bw-hooks";
import { COLORS, spacing } from "@bwll/bw-styles";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";

import { Icon } from "../Icon";
import { BUTTON_TEST_IDS } from "./Button.constants";
import {
  ButtonIconWrapper,
  ButtonSpaceHolder,
  ButtonTextColors,
  ButtonTextComponent,
  ButtonWrapper,
} from "./Button.styles";
import { ButtonPressableProps, ButtonProps, CombinedStylesCallback } from "./Button.types";
import { getButtonComponentStyleByType } from "./Button.utils";

/**
 * Component for showing the Button based on the style.
 * Accepts: Primary, Secondary, or Tertiary as type.
 * Setting disabled to true will use the Disabled styling as well as disable the button from being pressed
 */
type ButtonIconProps = Pick<ButtonProps, "iconName" | "iconAlignment"> & {
  iconColor: string;
};

const ButtonIcon = ({ iconAlignment = "right", iconName, iconColor }: ButtonIconProps) => (
  <ButtonIconWrapper testID={BUTTON_TEST_IDS.BUTTON_ICON} alignment={iconAlignment}>
    <Icon icon={iconName ?? "thumbs-up-outline"} size={spacing.xs} color={iconColor} />
  </ButtonIconWrapper>
);

const ButtonPressable = Pressable as React.ComponentType<ButtonPressableProps>;

export const Button = ({
  type,
  title,
  onPress,
  testID,
  accessibilityLabel,
  disabled,
  iconName,
  iconAlignment = "right",
  state = "light",
  isLoading = false,
  analyticsAttributes: featureAnalyticsAttributes,
  /**
   * It is recommended to have enableAnalyticsEvent default value as `false` to avoid duplicate events with previous Analytics Events implemented previously.
   * Its value should come from the feature flag
   */
  enableAnalyticsEvent = false,
  dataSet,
  style,
}: ButtonProps & ButtonPressableProps) => {
  const buttonStyles = useMemo(
    () => getButtonComponentStyleByType({ type, disabled, state, isLoading }),
    [type, state, disabled, isLoading],
  );

  const { onButtonClick } = useContext(AnalyticsContext);

  const combineStyles: CombinedStylesCallback = useCallback(
    ({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }, buttonStyles, style],
    [buttonStyles, style],
  );

  const iconColor = disabled ? COLORS.NEUTRAL.WARM["400"] : ButtonTextColors[type][state];

  const buttonIconProps = {
    iconAlignment,
    iconName,
    iconColor,
  };

  const componentAnalyticsAttributes = useMemo<TButtonAnalyticsAttributes>(
    () => ({
      [ANALYTICS_ATTRIBUTES.BUTTON_NAME]: title,
    }),
    [title],
  );

  const onButtonPress = () => {
    enableAnalyticsEvent &&
      featureAnalyticsAttributes &&
      onButtonClick?.({ ...componentAnalyticsAttributes, ...featureAnalyticsAttributes });
    onPress();
  };

  return (
    <ButtonPressable
      onPress={onButtonPress}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      testID={testID}
      disabled={disabled}
      style={combineStyles}
      dataSet={dataSet}
    >
      {isLoading && <ButtonSpaceHolder />}
      <ButtonWrapper isLoading={isLoading}>
        {iconName && iconAlignment === "left" && <ButtonIcon {...buttonIconProps} />}
        <ButtonTextComponent
          ellipsizeMode="clip"
          numberOfLines={1}
          type={type}
          disabled={disabled}
          state={state}
        >
          {title}
        </ButtonTextComponent>
        {iconName && iconAlignment === "right" && <ButtonIcon {...buttonIconProps} />}
      </ButtonWrapper>
      {isLoading && <ActivityIndicator />}
    </ButtonPressable>
  );
};
