import { useMemo } from "react";

import {
  AvailableLocale,
  DATE_FORMATS,
  ProofSubmissionStatus,
  RentConfirmationStatus,
  ServicingData,
} from "@bwll/bw-types";
import { calculateDaysToConfirmRent, formatCurrencyDollar, formatDate } from "@bwll/bw-utils";

import { useLatestCreditReport } from "../creditReport";
import { useRentProofSubmission } from "./api";
import { useLatestRentPeriodTransactions } from "./api/useLatestRentPeriodTransactions";
import { useProfile } from "./api/useProfile";
import {
  isRentReportingTradelineCreditorName,
  useRentReportingPaymentProfile,
} from "./api/useRentReportingPaymentProfile";
import { useSubscriptionStatus } from "./useSubscriptionStatus";

const dateFallback = new Date().toISOString();

export const useServicingData = (locale?: AvailableLocale) => {
  const { isLoading: isProfileLoading, data: profileData } = useProfile();
  const { isLoading: isLatestRentPeriodTrxsLoading, data: latestRentPeriodTrxs } =
    useLatestRentPeriodTransactions();
  const { isLoading: isPaymentProfileLoading, data: paymentProfile } = useRentReportingPaymentProfile();
  const { isLoading: isSubscriptionsLoading, status: subscriptionStatus } = useSubscriptionStatus();
  const { isLoading: isRentProofSubmissionLoading, data: rentProofSubmissionData } = useRentProofSubmission(
    {},
  );
  const { data: creditReportData } = useLatestCreditReport();

  const DAYS_TO_SHOW_CONFIRM_RENT_BANNER = 23;

  const account = useMemo(
    () =>
      creditReportData?.openAccounts?.find((item) => isRentReportingTradelineCreditorName(item.creditorName)),
    [creditReportData?.openAccounts],
  );

  const transformedData = useMemo<ServicingData>(() => {
    const daysToConfirmRent = calculateDaysToConfirmRent(profileData?.currentPeriodDueDate);

    rentProofSubmissionData?.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));
    const { attachments: rentProofSubmissionAttachments } = rentProofSubmissionData?.[0] || {
      attachments: [],
    };

    return {
      // Tenant Profile Data
      isProfileLoading,
      address: profileData?.rentalAddress?.formattedAddress ?? "-",
      leaseStartDate: formatDate({ dateString: profileData?.leaseStartDate, locale }),
      rentAmount: profileData?.rentAmount,
      currentRentPeriod: profileData?.currentRentPeriod,
      currentPeriodDueDate: profileData?.currentPeriodDueDate,
      currentPeriodDueDateFormatted: formatDate({
        dateString: profileData?.currentPeriodDueDate,
        dateFormat: DATE_FORMATS.MMMM_yyyy,
        defaultValue: "",
        locale,
      }),
      shouldConfirmRent:
        profileData?.rentConfirmationStatus === RentConfirmationStatus.CONFIRMATION_REQUIRED &&
        daysToConfirmRent <= DAYS_TO_SHOW_CONFIRM_RENT_BANNER,
      daysToConfirmRent,

      // Subscription Data
      isSubscriptionsLoading,
      subscriptionStatus: subscriptionStatus,

      // Latest Rent Period Transactions Data
      isLatestRentPeriodTrxsLoading,
      totalAmount: formatCurrencyDollar({
        amount: latestRentPeriodTrxs?.totalAmount,
        decimals: 2,
        locale,
      }),
      dateReported: formatDate({
        dateString: latestRentPeriodTrxs?.transactions?.[0]?.datePosted,
        ignoreOffset: true,
        locale,
      }),
      reportedToAgencyDate: formatDate({
        dateString: latestRentPeriodTrxs?.transactions?.[0]?.reportedToAgencyDate,
        defaultValue: "",
        locale,
      }),
      trxName: latestRentPeriodTrxs?.transactions?.[0]?.description ?? "-",
      rentPeriod: latestRentPeriodTrxs?.rentPeriod ?? "-",
      latestTransactions: latestRentPeriodTrxs?.transactions.map((transaction) => ({
        ...transaction,
        datePosted: formatDate({ dateString: transaction.datePosted, ignoreOffset: true, locale }),
      })),
      reportingStatus: latestRentPeriodTrxs?.transactions?.[0]?.reportingStatus,

      // Credit Report Data
      isPaymentProfileLoading,
      creditorName: account?.creditorName ?? "-",
      creditReportAmount: formatCurrencyDollar({
        amount: account?.highCredit,
        decimals: 2,
        locale,
      }),
      creditReportDate: formatDate({ dateString: account?.dateReported, defaultValue: "", locale }),
      paymentHistory: paymentProfile?.paymentProfileList ?? [],

      // Rent Proof Submission Data
      isRentProofSubmissionLoading,
      anyManualTransactionInReview: !!rentProofSubmissionData?.find(
        (r) => r.proofSubmissionStatus === ProofSubmissionStatus.Pending,
      ),
      anyManualTransactionApproved: !!rentProofSubmissionData?.find(
        (r) => r.proofSubmissionStatus === ProofSubmissionStatus.Approved,
      ),
      anyManualTransactionDraft: !!rentProofSubmissionData?.find(
        (r) => r.proofSubmissionStatus === ProofSubmissionStatus.Draft,
      ),
      rentProofSubmissionTransactions: rentProofSubmissionAttachments?.map((attachment) => ({
        transactionId: attachment.attachmentId,
        description: attachment.description ?? "",
        amount: attachment.amount ?? 0,
        datePosted: attachment.transactionDate ?? dateFallback,
      })),
      rentProofSubmissionRentPeriod: rentProofSubmissionData?.[0]?.rentPeriod,
      rentProofSubmissionData,
    };
  }, [
    account?.creditorName,
    account?.dateReported,
    account?.highCredit,
    isLatestRentPeriodTrxsLoading,
    isPaymentProfileLoading,
    isProfileLoading,
    isRentProofSubmissionLoading,
    isSubscriptionsLoading,
    latestRentPeriodTrxs?.rentPeriod,
    latestRentPeriodTrxs?.totalAmount,
    latestRentPeriodTrxs?.transactions,
    paymentProfile?.paymentProfileList,
    profileData?.currentPeriodDueDate,
    profileData?.currentRentPeriod,
    profileData?.leaseStartDate,
    profileData?.rentAmount,
    profileData?.rentConfirmationStatus,
    profileData?.rentalAddress?.formattedAddress,
    rentProofSubmissionData,
    subscriptionStatus,
    locale,
  ]);

  return transformedData;
};
