import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View<{ isOptionsDisplayed?: boolean }>`
  z-index: ${({ isOptionsDisplayed }) => (isOptionsDisplayed ? "2" : "0")};
`;

export const SelectContainer = styled.Pressable<{ isOptionsDisplayed?: boolean }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
  border-width: ${styledValue(1)};
  border-color: ${({ isOptionsDisplayed }) =>
    isOptionsDisplayed ? COLORS.PRIMARY["500"] : COLORS.NEUTRAL.COOL["600"]};
  border-bottom-color: ${({ isOptionsDisplayed }) =>
    isOptionsDisplayed ? COLORS.WHITE : COLORS.NEUTRAL.COOL["600"]};
  border-radius: ${styledValue(spacing.xxs)};
  border-bottom-right-radius: ${({ isOptionsDisplayed }) =>
    isOptionsDisplayed ? "0" : styledValue(spacing.xxs)};
  border-bottom-left-radius: ${({ isOptionsDisplayed }) =>
    isOptionsDisplayed ? "0" : styledValue(spacing.xxs)};
  padding-vertical: ${styledValue(spacing.xs)};
  padding-horizontal: ${styledValue(spacing.xs)};
`;

export const Row = styled.View`
  flex-direction: row;
  width: 100%;
`;

export const TextContainer = styled.View`
  flex-shrink: 1;
  overflow-wrap: anywhere;
`;

// Separate flex-shrink rule for the selected option text to give more space than the Sort By Text
export const SelectedOptionTextContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 5;
  overflow-wrap: anywhere;
`;

export const IconContainer = styled.View`
  flex-shrink: 0;
`;

export const OptionItemContainer = styled.Pressable<{ isActive?: boolean }>`
  flex-direction: row;
  padding: ${styledValue(spacing.xs)};
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? COLORS.PRIMARY["025"] : COLORS.WHITE)};
  border-radius: ${styledValue(spacing.xxs)};
`;

export const OptionContainer = styled.View`
  z-index: 100;
  width: 100%;
  top: 100%;
  position: absolute;
  background-color: ${COLORS.WHITE};
  border-width: ${styledValue(1)};
  border-bottom-left-radius: ${styledValue(spacing.xxs)};
  border-bottom-right-radius: ${styledValue(spacing.xxs)};
  border-color: ${COLORS.PRIMARY["500"]};
`;
