import styled, { css } from "@emotion/native";

import { COLORS, fontWeights, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const AccordionTitleContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

export const TransactionListItem = styled.View<{ isLastItem: boolean }>`
  padding-vertical: ${styledValue(spacing.xxs)};
  margin-horizontal: ${styledValue(spacing.xs)};
  flex-direction: row;
  justify-content: space-between;
  border-style: solid;
  border-bottom-width: ${({ isLastItem }) => (isLastItem ? 0 : "0.5px")};
  border-bottom-color: ${COLORS.NEUTRAL.COOL["200"]};
`;

export const TransactionListItemData = styled.View`
  width: 70%;
  padding-bottom: ${styledValue(spacing.xxxs)};
`;

export const TransactionListItemAmount = styled.View`
  align-items: flex-end;
  width: 30%;
`;

export const TransactionContainer = styled.View<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? COLORS.WHITE};
  border-radius: ${styledValue(spacing.xxs)};
`;

export const accordionStyle = (backgroundColor?: string) => css`
  background-color: ${backgroundColor ?? COLORS.WHITE};
`;

export const boldTextStyle = css`
  font-weight: ${fontWeights.semiBold};
`;

export const colorStyle = css`
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const boldColorStyle = css`
  ${colorStyle};
  ${boldTextStyle};
`;
