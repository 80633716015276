import { useEffect, useState } from "react";

import { useEnvironmentContext, usePlatformContext } from "@bwll/bw-hooks";
import { PLATFORM } from "@bwll/bw-types";

export const useZendeskChat = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { platform } = usePlatformContext();
  const { envConfigs } = useEnvironmentContext();

  useEffect(() => {
    if (platform != PLATFORM.WEB_APP) {
      throw new Error("useZendeskChat hook should only be used for web platform");
    }

    if (!isInitialized) {
      const script = document.createElement("script");
      script.async = true;
      script.id = "ze-snippet";
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${envConfigs.ZENDESK_CHAT_KEY}`;
      script.addEventListener("load", () => setIsInitialized(true));
      document.body.appendChild(script);
    }
  }, []);

  return { isInitialized };
};
