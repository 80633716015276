export function toRGB(hexColor: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
    if (!result || result.length < 3) {
        throw new Error(`Malformed hex passed to toRGB ${hexColor}`)
    }
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return { r, g, b }
}

export function toRGBString(hexColor: string) {
    const { r, g, b } = toRGB(hexColor)
    return `rgb(${r},${g},${b})`
}
