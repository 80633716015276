export const customerPortal = {
  "backButton": "RETOUR À LE BIENFAIT DU LOYER",
  "title": "Paramètres Le Bienfait du Loyer",
  "subscriptionPaymentMethod": "Abonnement et mode de paiement",
  "managedThroughStripe": "Géré par Stripe",
  "infoCard": {
    "title": "La désinscription fermera la transaction associée à votre paiement de loyer actuel.",
    "titleStripeSettings": "La désinscription fermera la transaction associée à votre paiement de loyer actuel.",
    "titleLeaseDetailsSettings": "Continuez à profiter de votre abonnement",
    "description": "En conservant votre abonnement, vous profiterez d'une excellente occasion de renforcer votre crédit.",
    "helpCentreLink": "[__Voir l'article du centre d'aide__]()",
    "learnMoreLink": "En savoir plus",
    "exploreBenefitsLink": "Explorer les avantages"
  },
  "subscriptionSettingsHeader": "Abonnement et mode de paiement",
  "leaseDetailsSettingsHeader": "Informations du bail",
  "rentalAddress": "Adresse de location",
  "monthlyRentAmount": "Montant mensuel du loyer"
}
