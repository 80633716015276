export const productVerticalsNavBar = {
  categories: {
    loans: "Loans",
    creditCards: "Credit Cards",
    carLoans: "Car Loans",
    mortgages: "Mortgages",
    bankAccounts: "Bank Accounts",
    insurance: "Insurance",
    buildCredit: "Build Credit",
    investing: "Investing",
  },
  showMore: "More",
};
