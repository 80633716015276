import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { ExperimentsJson, IExperiments, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, parseJsonString, queryBehavior } from "@bwll/bw-utils";

const fetchUserConfig = async (accessToken: string, apiGatewayUrl: string) => {
  const response: AxiosResponse<ExperimentsJson> = await axios.get(
    `${apiGatewayUrl}/api/user-configuration/experiments`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

const transformExperiments = (experiments: ExperimentsJson): IExperiments =>
  _.mapValues(
    _.mapKeys(experiments, (experiment) => experiment.name),
    ({ value, config }) => {
      return {
        treatment: value,
        config: config ? parseJsonString(config) : {},
      };
    },
  );

/** Fetch Split experiments from the web workspace */
export const useUserConfigExperiments = () => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_USER_CONFIG_EXPERIMENTS, accessToken),
    () => fetchUserConfig(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        enabled: !!accessToken,
      }),
      select: (data) => transformExperiments(data),
    },
  );
};

export type UserConfig = ReturnType<typeof useUserConfigExperiments>;
