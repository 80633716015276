import { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState } from "react";

import { ContextName, useContextWrapper } from "./contextWrapper";

export interface IProgressBar {
  totalSteps: number;
  currentStep: number;
  showProgressBar: boolean;
}

interface INavigationProviderProps {
  children: ReactNode;
}

export interface INavigationFields {
  onBackButton?: () => void;
  onNextButton?: () => void;
  onCloseButton?: () => void;
}

interface IStepProps {
  completed?: boolean;
  current?: boolean;
  color?: string;
  label?: {
    name: string;
  };
}

export interface IStepperProps {
  steps?: IStepProps[];
  color?: string;
  showLabels?: boolean;
  gap?: number;
}

export interface INavigationContext {
  fields: INavigationFields;
  stepperConfig: IStepperProps;
  setStepperConfig: Dispatch<SetStateAction<IStepperProps>>;
  withBorderBottom: boolean;
  setWithBorderBottom: Dispatch<SetStateAction<boolean>>;
  updateNavigationField: (key: keyof INavigationFields, value: () => void) => void;
}

export const NavigationContext = createContext<INavigationContext>({} as INavigationContext);

export const NavigationProvider = ({ children }: INavigationProviderProps) => {
  const [navigationFields, setNavigationFields] = useState<INavigationFields>({
    onBackButton: () => null,
    onNextButton: () => null,
    onCloseButton: () => null,
  });

  const [stepperConfig, setStepperConfig] = useState<IStepperProps>({});
  const [withBorderBottom, setWithBorderBottom] = useState(true);

  const navigationValue: INavigationContext = useMemo(
    () => ({
      fields: navigationFields,
      updateNavigationField: (key, value) => {
        setNavigationFields((curr) => {
          return { ...curr, [key]: value };
        });
      },
      stepperConfig,
      setStepperConfig,
      withBorderBottom,
      setWithBorderBottom,
    }),
    [navigationFields, stepperConfig, withBorderBottom],
  );

  return <NavigationContext.Provider value={navigationValue}>{children}</NavigationContext.Provider>;
};

export const useNavigationContext = () => useContextWrapper(NavigationContext, ContextName.NavigationContext);
