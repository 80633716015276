export const reportItem = {
  statusOpen: "OPEN",
  statusClosed: "CLOSED",
  categoryTypeRevolving: "Revolving",
  categoryTypeMortgage: "Mortgage",
  categoryTypeInstallment: "Instalment",
  categoryTypeOpen: "Open",
  categoryTypeForeignInquiries: "Foreign Inquiries",
  categoryTypeLocalInquiries: "Local Inquiries",
  categoryTypeCollections: "Collections",
  categoryTypeLegalItems: "Legal Items",
  categoryTypeBankruptcies: "Bankruptcies",
};