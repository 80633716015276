import styled, { css } from "@emotion/native";
import { Platform } from "react-native";

import { COLORS, fontSize, fontWeights, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { RegularText, SemiBoldText } from "../Default";
import { StyledBody1Props } from "./Body1.types";

const defaultBodyStyle = ({ color, textAlign }: StyledBody1Props) => css`
  font-size: ${styledValue(fontSize.s)};
  line-height: ${styledValue(spacing.m)};
  letter-spacing: ${styledValue(letterSpacing.xs)};
  color: ${color ?? COLORS.NEUTRAL.WARM["700"]};
  ${textAlign && `text-align: ${textAlign}`}
`;

const underlinedCss = ({ underline }: StyledBody1Props) =>
  underline &&
  `
  text-decoration-line: underline;
  text-decoration-style: ${Platform.OS === "android" ? "solid" : "dotted"};
`;

export const StyledBody1Regular = styled(RegularText)<StyledBody1Props>`
  ${defaultBodyStyle};
  ${underlinedCss}
`;

export const StyledBody1SemiBold = styled(SemiBoldText)<StyledBody1Props>`
  ${defaultBodyStyle};
  font-weight: ${fontWeights.semiBold};
  letter-spacing: ${styledValue(letterSpacing.m)};
  ${underlinedCss}
`;

export const StyledBody1Bold = styled(SemiBoldText)<StyledBody1Props>`
  ${defaultBodyStyle};
  font-weight: ${fontWeights.bold};
  letter-spacing: ${styledValue(letterSpacing.m)};
  ${underlinedCss}
`;
