export const reportItemLine = {
  dateReported: "Date Reported",
  dateDisposition: "Date Disposition",
  categoryOverviewTitle: "Overview",
  categoryDetailsTitle: "Account Details",
  balanceTitle: "Balance",
  highCreditTitle: "High Credit",
  pastDueTitle: "Past Due Amount",
  paymentDetailsTitle: "Payment Details",
  accountTypeTitle: "Type",
  lastActivityTitle: "Last Activity",
  openDateTitle: "Open Date",
  lastReportedTitle: "Last Reported",
  lastPaymentTitle: "Last Payment",
  datePaidTitle: "Date Paid",
  dateFiled: "Date Filed",
  dateSatisfied: "Date Satisfied",
  originalBalanceTitle: "Original Balance",
  currentBalanceTitle: "Current Balance",
  agency: "Agency",
  amount: "Amount",
  type: "Type",
  courtName: "Court Name",
  intentOrDispositionDate: "Disposition Date",
  status: "Status",
};