import facepaint from "facepaint";

export const WEB_MODAL_MAX_WIDTH = 592;
export const WEB_SCREEN_MAX_WIDTH = 1300;
export const WEB_SCREEN_PADDING = 16;
export const WEB_SCREEN_MAX_WIDTH_WITH_PADDING = WEB_SCREEN_MAX_WIDTH - WEB_SCREEN_PADDING * 2;

export const BREAKPOINTS = {
  MOBILE_MAXIMUM_WIDTH: 599,
  TABLET_MINIMUM_WIDTH: 600,
  TABLET_MAXIMUM_WIDTH: 899,
  DESKTOP_MINIMUM_WIDTH: 900,
};

// Universal nav bar has a custom breakpoint to switch between mobile and desktop layout
export const UNIVERSAL_NAVBAR_MOBILE_BREAKPOINT = 1120;
export const UNIVERSAL_NAVBAR_HEIGHTS = {
  MOBILE: 60,
  DESKTOP: 80,
} as const;

export const mediaQueries = [
  `(max-width: ${BREAKPOINTS.MOBILE_MAXIMUM_WIDTH}px)`,
  `(min-width: ${BREAKPOINTS.TABLET_MINIMUM_WIDTH}px) and (max-width: ${BREAKPOINTS.TABLET_MAXIMUM_WIDTH}px)`,
  `(min-width: ${BREAKPOINTS.DESKTOP_MINIMUM_WIDTH}px)`,
];

export const mq = facepaint(mediaQueries.slice(1).map((query) => `@media ${query}`));

export default mq;
