/**
 * Don't use the absolute "@app/utils"
 * Reason: helpers.ts depends on the `PAGE_ROUTES`, and the `PAGE_ROUTES` requires interceptSingleSpaRoute,
 * which lives inside utils. Importing it through the index.ts will cause the require cycle in the Vitest runtime.
 */
import { interceptSingleSpaRoute } from "../utils/routingHelpers";

export const PUBLIC_ROUTES = {
  PUBLIC_CHECKOUT_SUCCESS: interceptSingleSpaRoute("/public/checkout-success"),
  PUBLIC_CHECKOUT_FAILURE: interceptSingleSpaRoute("/public/checkout-failure"),
} as const;

export type PublicRoute = typeof PUBLIC_ROUTES[keyof typeof PUBLIC_ROUTES];
