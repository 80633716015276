import styled from "@emotion/native";
import { View } from "react-native";

import { StandardRadius, fontSize, letterSpacing, lineHeight } from "@bwll/bw-styles";

import { Cell } from "../../atoms/Cell";
import { Paragraph } from "../../atoms/Typography";

type CardCellProps = {
  borderRadius?: StandardRadius;
  hasShadow?: boolean;
};

export const CardCell = styled(Cell)<CardCellProps>`
  padding: 16px 16px 0px;
`;

export const CardHeading = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const CardDescription = styled(Paragraph)`
  font-size: ${fontSize.xs};
  line-height: ${lineHeight.m};
  letter-spacing: ${letterSpacing.m};
  min-height: 68px;
`;

export const CloseIcon = styled(View)`
  margin-left: auto;
`;
