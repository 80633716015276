export const preSelectedCard = {
  "titleTooltip": "Sur la base de votre dossier de crédit, vous avez été présélectionné pour ce prêt. Vous répondez aux critères d'admissibilité et avez d'excellentes chances d'être approuvé pour ce prêt.",
  "ctaMarkdown": "[__En savoir plus sur ce produit__]()",
  "cta": "En savoir plus sur ce produit",
  "title": "VOUS ÊTES PRÉSÉLECTIONNÉ",
  "approvalChanceTooltip": {
    "excellent": "Il s'agit d'une correspondance solide. Vous répondez aux critères d'admissibilité et, sur la base de votre profil de crédit, nos calculs montrent que vous avez d'excellentes chances d'être approuvé pour ce produit. Vous pouvez faire une demande en toute confiance.",
    "good": "Vos chances sont bonnes. Signes positifs : votre profil répond à toutes les conditions d'admissibilité du produit et votre profil de crédit suggère que vous avez des chances d'être approuvé. Vous pouvez faire une demande en toute confiance.",
    "fair": "Vos perspectives sont correctes. Sur la base de votre profil de crédit, nos calculs suggèrent que vous avez une chance raisonnable d'être approuvé pour ce produit. Vous pouvez en tenir compte si vous décidez de faire une demande.",
    "low": "La probabilité que vous soyez approuvé pour ce produit est faible. Bien que vous répondiez aux critères d'admissibilité de base pour ce produit, nous ne sommes pas certains, sur la base de votre profil de crédit, que vous serez approuvé. Faites une demande avec prudence.",
    "notEligible": "Juste un petit conseil... Nous avons remarqué que vous avez un compte ouvert avec ce partenaire. Malheureusement, vous avez atteint le nombre maximum de comptes autorisés par ce partenaire. Il est préférable de ne pas faire de demande pour le moment.",
    "none": "Nous ne sommes pas en mesure de calculer votre probabilité d'approbation pour ce produit pour le moment. Revenez plus tard."
  }
}
