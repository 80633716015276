import styled from "@emotion/native";

import { borderRadius, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Body2 } from "../Typography";
import { VerticalIconButtonProps } from "./VerticalIconButton.types";

type StyleProps = Pick<VerticalIconButtonProps, "buttonSize">;

export const StyledPressable = styled.Pressable<StyleProps>`
  width: ${({ buttonSize }) => styledValue(buttonSize)};
`;

export const BackgroundContainer = styled.View<StyleProps & { backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${styledValue(borderRadius.s)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ buttonSize }) => styledValue(buttonSize)};
  height: ${({ buttonSize }) => styledValue(buttonSize)};
`;

export const LabelContainer = styled.View<StyleProps>`
  width: ${({ buttonSize }) => styledValue(buttonSize)};
  margin-top: ${styledValue(spacing.xxxs)};
`;

export const LabelText = styled(Body2)`
  line-height: ${styledValue(fontSize.l)};
  text-align: center;
`;
