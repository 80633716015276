export const paymentProofComments = {
  title: "Add comments for your proof of payment",
  body1:
    "If the amount you entered is incorrect, please return to the previous screen to update your transaction amount.",
  body2:
    "Otherwise, you may give an explanation for the mismatch between the transaction amount and lease amount.",
  transactionAmount: "Transaction amount",
  rentAmountFromLease: "Rent amount from lease",
  labels: {
    comments: "Comment",
  },
  captions: {
    comments: "Comment must be under 500 characters.",
    charactersLeft: " ({{count}} left)",
  },
  buttonSubmitProof: "SUBMIT PROOF OF PAYMENT",
};
