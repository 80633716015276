import { toRGB } from './toRGB'

export function toRGBAString(hexColor: string, opacity: number) {
    const { r, g, b } = toRGB(hexColor)
    if (opacity > 1 || opacity < 0) {
        // eslint-disable-next-line no-console
        console.error('toRGBA expects an opacity between 0 and 1')
        return `rgba(${r},${g},${b},${opacity > 1 ? 1 : 0})`
    }
    return `rgba(${r},${g},${b},${opacity})`
}
