import styled from "@emotion/styled";

import { COLORS, borderRadius, borderWidth, fontSize, mq, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const promoMessagePart1Styles = {
  paragraph: {
    margin: 0,
    fontSize: spacing.xs,
    lineHeight: styledValue(spacing.m),
    color: COLORS.NEUTRAL.WARM[800],
  },
};

export const Container = styled.div({
  // maxWidth: styledValue(530),
  border: `${styledValue(borderWidth.small)} solid ${COLORS.NEUTRAL.WARM[200]}`,
  borderRadius: borderRadius.s,
  padding: spacing.m,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const PriceContainer = styled.div({
  display: "flex",
});

export const CurrencySymbol = styled.sup({
  fontSize: fontSize.l,
});

export const Strike = styled.s({
  textDecorationThickness: "from-font",
});

export const MessageContainer = styled.div(() =>
  mq({
    display: "flex",
    alignItems: ["flex-start", "flex-start", "center"],
    justifyContent: "center",
  }),
);

export const PromoMessage = styled.div({
  display: "flex",
  flexWrap: "wrap",
});
