export const reportOptions = {
  "reportBankruptcies": {
    "title": "Faillites",
    "subtitle": "Si vous avez déjà déposé une demande de mise en faillite ou une proposition de consommateur, celle-ci apparaît ici."
  },
  "reportLegal": {
    "title": "Éléments juridiques",
    "subtitle": "Tout document public figurant à votre dossier de crédit. Il peut s'agir d'éléments comme un jugement du tribunal."
  },
  "reportTrades": {
    "title": "Comptes de crédit",
    "subtitle": "Vous y trouverez tous les comptes ouverts ou fermés qui figurent dans votre dossier de crédit, comme les cartes de crédit, les prêts à versements périodiques, les hypothèques et les comptes de téléphonie mobile.",
    "deferred": "Différé",
    "helpCenterTitle": "Vous avez des questions sur votre dossier de crédit?",
    "helpCenterLink": "Visitez le centre d'aide"
  },
  "reportInquiries": {
    "title": "Enquêtes de crédit",
    "subtitle": "Les enquêtes de crédit sont des demandes de vérification de votre dossier de crédit. Il y a enquête approfondie lorsqu'un prêteur potentiel consulte votre dossier de crédit parce que vous avez fait une demande de crédit auprès de ce dernier, ce qui peut avoir une incidence sur votre cote de crédit."
  },
  "reportCollections": {
    "title": "Recouvrements",
    "subtitle": "Tous les comptes qui ont été envoyés en recouvrement, que le(s) solde(s) ait(aient) été payé(s) ou non. Attention : l'envoi d'un compte en recouvrement peut avoir un impact majeur sur votre cote de crédit."
  }
}
