import styled, { ReactNativeStyle, css } from "@emotion/native";

import { COLORS, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BoldText } from "../Typography/Default";
import { BUTTON_TYPE } from "./Button.types";
import { ThemeState } from "./Button.utils";

type ButtonTextComponentProps = {
  type: BUTTON_TYPE;
  state?: "dark" | "light";
  disabled?: boolean;
};

type StyleCreator = (props: {
  state: ThemeState;
  disabled?: boolean;
  isLoading?: boolean;
}) => ReactNativeStyle;

export const ButtonTextColors = {
  [BUTTON_TYPE.PRIMARY]: { light: COLORS.WHITE, dark: COLORS.PRIMARY.DEFAULT },
  [BUTTON_TYPE.SECONDARY]: { light: COLORS.PRIMARY.DEFAULT, dark: COLORS.WHITE },
  [BUTTON_TYPE.TERTIARY]: { light: COLORS.PRIMARY.DEFAULT, dark: COLORS.WHITE },
  [BUTTON_TYPE.ACCENT]: { light: COLORS.PRIMARY[900], dark: COLORS.PRIMARY[900] },
};

export const ButtonBackgroundColors = {
  [BUTTON_TYPE.PRIMARY]: { light: COLORS.PRIMARY.DEFAULT, dark: COLORS.WHITE },
  // Used for border only
  [BUTTON_TYPE.SECONDARY]: { light: COLORS.PRIMARY.DEFAULT, dark: COLORS.WHITE },
};

const INDICATOR_SIZE = 20;
export const ButtonSpaceHolder = styled.View`
  width: ${styledValue(INDICATOR_SIZE)};
`;

export const ButtonWrapper = styled.View<{ isLoading: boolean }>`
  flex-direction: row;
  padding-horizontal: ${({ isLoading }) => styledValue(isLoading ? 16 : 0)};
`;

export const ButtonTextComponent = styled(BoldText)<ButtonTextComponentProps>`
  font-size: ${styledValue(fontSize.xs)};
  line-height: ${styledValue(lineHeight.xs)};
  text-align: center;
  text-transform: uppercase;
  color: ${({ type, state = "light", disabled }) =>
    disabled ? COLORS.NEUTRAL.WARM["400"] : ButtonTextColors[type][state]};
`;

const baseStyles: StyleCreator = ({ isLoading }) => css`
  display: flex;
  flex-direction: row;
  justify-content: ${isLoading ? "space-between" : "center"};
  align-items: center;
  padding: ${styledValue(spacing.xs)};
  border-radius: 1000px;
`;

export const primaryButtonStyles: StyleCreator = (props) => css`
  ${baseStyles(props)};
  background: ${props.disabled
    ? COLORS.NEUTRAL.WARM["200"]
    : ButtonBackgroundColors[BUTTON_TYPE.PRIMARY][props.state]};
`;

export const secondaryButtonStyles: StyleCreator = (props) => css`
  ${baseStyles(props)};
  background: ${props.disabled ? COLORS.NEUTRAL.WARM["200"] : "transparent"};
  border: ${props.disabled
    ? "none"
    : `1px solid ${ButtonBackgroundColors[BUTTON_TYPE.SECONDARY][props.state!]}`};
`;

export const tertiaryButtonStyles: StyleCreator = (props) => css`
  ${secondaryButtonStyles(props)};
  border: none;
`;

export const accentButtonStyles: StyleCreator = (props) => css`
  ${baseStyles(props)};
  background: ${props.disabled ? COLORS.NEUTRAL.WARM["200"] : COLORS.YELLOW[400]};
`;

export const ButtonIconWrapper = styled.View<{ alignment: "left" | "right" }>`
  ${({ alignment }) =>
    alignment === "left"
      ? css`
          margin-right: ${styledValue(spacing.xxs)};
        `
      : css`
          margin-left: ${styledValue(spacing.xxs)};
        `}
`;
